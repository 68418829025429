<template>
  <div id="apps">
    <router-view />
    <div class="loading" v-show="isShow">
      <van-loading size="24px" color="white" vertical>加载中...</van-loading>
    </div>
    <!-- <van-loading v-show="isShow" size="24px" color="#1989fa" vertical>加载中...</van-loading> -->
  </div>
</template>
<style>
#apps {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  box-sizing: border-box;
  padding: constant(safe-area-inset-top) 0 constant(safe-area-inset-bottom);
  padding: env(safe-area-inset-top) 0 env(safe-area-inset-bottom);
}
body,
html {
  height: 100vh;
  /* background: pink; */
  overflow: hidden;
}

html {
  font-size: 100px;
}

.loading {
  position: fixed !important;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .4);
  width: 100%;
  height: 100%;
  z-index: 99999;
}

.van-loading {
  position: fixed !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}
</style>
<script>
window.onload = function () {
  document.addEventListener('touchstart', function (event) {
    if (event.touches.length > 1)
    {
      event.preventDefault()
    }
  })
  document.addEventListener('gesturestart', function (event) {
    event.preventDefault()
  })
}
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['isShow'])
  },
  mounted () {

  },
}
</script>