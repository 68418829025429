<!--
 * @Description:
 * @Version: 2.0
 * @Autor: zgh
 * @Date: 2023-04-19 16:12:21
-->

<template>
  <div class="PlaceAnOrder">
    <van-nav-bar title="下单" v-if="showBack" left-arrow @click-left="goBack" />

    <div class="content">
      <van-panel class="vehicleInformationCard">
        <template #header>
          <van-cell is-link class="topInfo" @click="so_item_show = true">
            <div class="carSubTypeName">{{ CARSUBTYPEName }}</div>
            <div class="info">
              <span>外：{{ AppearanceColoron }}</span>
              <span v-if="AppearanceColoron && InteriorColor">｜</span>
              <span v-if="InteriorColor != ''">内：{{ InteriorColor }}</span>
              <div>车型编码：{{ CARSUBTYPECode }}</div>
              <div v-if="soCarVin">VIN码：{{ soCarVin }}</div>
            </div>
          </van-cell>
        </template>
        <div class="price">
          <div>
            <span>裸车价</span>
            <span>¥ {{ thousand(CARSUBTYPEMSRP) }}</span>
          </div>
          <div>
            <span>订单款总额</span>
            <span>¥ {{ thousand(ZCAmount) }}</span>
          </div>
        </div>
      </van-panel>
      <van-popup
        v-model="so_item_show"
        position="right"
        :style="{ height: '100%', width: '100%', background: '#f2f2f2' }"
        class="popupClass"
      >
        <van-nav-bar title="订单商品" @click-right="so_item_show = false">
          <template #right>
            <van-icon name="cross" size="18" />
          </template>
        </van-nav-bar>
        <div class="popupContent">
          <van-panel>
            <template #header>
              <van-cell class="topInfo">
                <div class="carSubTypeName">{{ CARSUBTYPEName }}</div>
                <div class="info">
                  <span>外观{{ AppearanceColoron }}</span
                  >｜ <span>内饰{{ InteriorColor }}</span
                  >｜
                  <span>车型编码{{ CARSUBTYPECode }}</span>
                </div>
              </van-cell>
            </template>
            <div class="bottomInfo">
              <van-cell
                v-if="soCarVin"
                title="VIN码"
                :value="soCarVin"
                :border="false"
              />
              <van-cell
                title="裸车价"
                :value="`¥ ${thousand(CARSUBTYPEMSRP)}`"
                :border="false"
              />
            </div>
          </van-panel>
          <van-cell
            :style="{ marginBottom: '10px' }"
            title="装潢"
            :title-style="{ fontSize: '16px' }"
            :border="false"
            v-if="ZH_item.length > 0"
          >
            <template #label>
              <van-cell
                :style="{ padding: '10px 0' }"
                v-for="(item, $index) in ZH_item"
                :key="$index"
              >
                <van-row>
                  <van-col span="12"> {{ item.Goods_Name }} </van-col>

                  <van-col :style="{ textAlign: 'right' }" span="4">
                    x{{ item.qty }}</van-col
                  >
                  <van-col :style="{ textAlign: 'right' }" span="8">
                    {{ item.payClass }}
                  </van-col>
                  <van-col span="8" v-if="item.Goods_Source == '套餐'">
                    ¥{{ item.pack_goodsPrice }}
                  </van-col>
                </van-row>
                <van-row class="grey">
                  <van-col span="12">
                    {{ item.Goods_no || item.Goods_No }}</van-col
                  >
                  <van-col :style="{ textAlign: 'right' }" span="12">
                    ¥ {{ item.goodsPirce }}
                  </van-col>

                  <!-- <van-col span="8"> {{ item.Goods_Source }} </van-col> -->
                </van-row>

                <div v-if="item.Goods_Source == '套餐'">
                  <van-col> {{ item.pack_goodsno }} </van-col>
                  <!-- <van-col span="8"> {{ item.Goods_Source }} </van-col> -->
                </div>
              </van-cell>
              <van-cell
                :style="{ padding: '10px 0' }"
                title-class="grey"
                value-class="orange"
                title="小计"
                :value="`¥ ${thousand(ZH_Pirce)}`"
                :border="false"
              />
            </template>
          </van-cell>
          <van-cell
            :style="{ marginBottom: '10px' }"
            title="保险"
            :title-style="{ fontSize: '16px' }"
            :border="false"
            v-if="bx_show"
          >
            <template #label>
              <van-cell
                :style="{ padding: '10px 0' }"
                v-for="(item, $index) in Bx_item"
                :key="$index"
              >
                <van-row v-if="item.premiumAmount > 0">
                  <van-col span="8"> {{ Insurance_item_name }} </van-col>
                  <van-col span="8"> {{ item.InsProductName }} </van-col>
                  <van-col span="8">
                    {{ item.coverageAmount }} ￥{{
                      item.premiumAmount
                    }}</van-col
                  >
                </van-row>
              </van-cell>
              <van-cell
                :style="{ padding: '10px 0' }"
                title-class="grey"
                value-class="orange"
                title="小计"
                :value="`¥ ${thousand(Insurance_Pirce)}`"
                :border="false"
              />
            </template>
          </van-cell>
          <van-cell
            :style="{ marginBottom: '10px' }"
            title="延保"
            :title-style="{ fontSize: '16px', fontWeight: 'bold' }"
            :border="false"
            v-if="YB_item.length > 0"
          >
            <template #label>
              <van-cell
                :style="{ padding: '10px 0' }"
                v-for="(item, $index) in YB_item"
                :key="$index"
              >
                <van-row>
                  <van-col
                    :style="{ fontSize: '16px', fontWeight: 'bold' }"
                    span="8"
                    >延保产品{{ $index + 1 }}</van-col
                  >
                </van-row>
                <div class="grey" :style="{ fontWeight: 'initial' }">
                  <van-row>
                    <van-col span="8">延保产品</van-col>
                    <van-col span="16"> {{ item.ewProductName }} </van-col>
                  </van-row>
                  <van-row>
                    <van-col span="8">延保公司</van-col>
                    <van-col span="16"> {{ supplierName }} </van-col>
                  </van-row>
                  <van-row>
                    <van-col span="8">延保类型</van-col>
                    <van-col span="16"> {{ item.ewTypeName }} </van-col>
                  </van-row>
                  <van-row>
                    <van-col span="8">延保里程</van-col>
                    <van-col span="16"> {{ item.ewPeriod }} </van-col>
                  </van-row>
                  <van-row>
                    <van-col span="8">延保期限</van-col>
                    <van-col span="16">
                      {{ NewtimeFormat(item.ewStartDate) }}-{{
                        NewtimeFormat(item.ewEndDate)
                      }}
                    </van-col>
                  </van-row>
                  <van-row>
                    <van-col span="8">延保金额</van-col>
                    <van-col :style="{ textAlign: 'right' }" span="16">
                      ¥{{ thousand(item.ewAmount) }}
                    </van-col>
                  </van-row>
                </div>
              </van-cell>
              <van-cell
                :style="{ padding: '10px 0' }"
                title-class="grey"
                value-class="orange"
                title="小计"
                :value="`¥ ${thousand(EW_Pirce)}`"
                :border="false"
              />
            </template>
          </van-cell>
          <van-cell
            :style="{ marginBottom: '10px' }"
            title="金融"
            :title-style="{ fontSize: '16px', fontWeight: 'bold' }"
            :border="false"
            v-if="JR_item.length > 0"
          >
            <template #label>
              <van-cell
                :style="{ padding: '10px 0' }"
                v-for="(item, $index) in JR_item"
                :key="$index"
              >
                <div class="grey" :style="{ fontWeight: 'initial' }">
                  <van-row>
                    <van-col span="8">金融机构</van-col>
                    <van-col span="16"> {{ item.jinrong_Org }} </van-col>
                  </van-row>
                  <van-row>
                    <van-col span="8">金融方案</van-col>
                    <van-col span="16"> {{ jingrong_fangan }} </van-col>
                  </van-row>
                  <van-row>
                    <van-col span="8">首付金额</van-col>
                    <van-col span="16">
                      ¥{{ thousand(item.jinrong_sfPirce) }}
                    </van-col>
                  </van-row>
                  <van-row>
                    <van-col span="8">贷款金额</van-col>
                    <van-col span="16"
                      >¥{{ thousand(item.loanAmount) }}</van-col
                    >
                  </van-row>
                  <van-row>
                    <van-col span="8">服务费</van-col>
                    <van-col span="16" :style="{ textAlign: 'right' }">
                      ¥{{ thousand(item.jinrong_zxPirce) }}
                    </van-col>
                  </van-row>
                </div>
              </van-cell>
              <van-cell
                :style="{ padding: '10px 0' }"
                title-class="grey"
                value-class="orange"
                title="小计"
                :value="`¥ ${thousand(Financial_Price)}`"
                :border="false"
              />
            </template>
          </van-cell>
          <van-cell
            :style="{ marginBottom: '10px' }"
            title="代办"
            :title-style="{ fontSize: '16px', fontWeight: 'bold' }"
            :border="false"
            v-if="DB_item.length > 0"
          >
            <template #label>
              <van-cell
                :style="{ padding: '10px 0' }"
                v-for="(item, $index) in DB_item"
                :key="$index"
              >
                <div class="grey" :style="{ fontWeight: 'initial' }">
                  <van-row>
                    <van-col span="8">代办项目：</van-col>
                    <van-col span="16"> {{ item.GOODS_NAME }} </van-col>
                  </van-row>
                  <van-row>
                    <van-col span="8">代办金额：</van-col>
                    <van-col span="16" :style="{ textAlign: 'right' }">
                      ¥{{ thousand(item.dbPrice) }}
                    </van-col>
                  </van-row>
                </div>
              </van-cell>
              <van-cell
                :style="{ padding: '10px 0' }"
                title-class="grey"
                value-class="orange"
                title="小计"
                :value="`¥ ${thousand(agency_Price)}`"
                :border="false"
              />
            </template>
          </van-cell>
        </div>
        <van-cell
          :style="{ marginBottom: '10px' }"
          title="订单款合计"
          value-class="orange"
          :value="`¥ ${thousand(ZCAmount)}`"
          :title-style="{ fontSize: '16px', fontWeight: 'bold' }"
          :border="false"
          class="orderTotal"
        ></van-cell>
      </van-popup>
      <div
        class="titleBlock"
        style="font-size: 14px; padding: 10px 16px"
        v-if="ENV != 'HW'"
      >
        收款信息
      </div>
      <van-field
        required
        v-model="dingjin"
        :disabled="!!soNo"
        type="number"
        name="定金"
        label="定金"
        placeholder="请填写"
        v-if="ENV != 'HW'"
      />
      <van-field
        required
        v-model="sfjine"
        disabled
        type="number"
        name="首付金额"
        label="首付金额"
        v-if="sfjine > 0"
      />
      <div class="titleBlock" style="font-size: 14px; padding: 10px 16px">
        客户信息
      </div>
      <van-field required name="客户为开票人" label="客户为开票人">
        <template #input>
          <van-radio-group v-model="iskaipiaoperson" direction="horizontal">
            <van-radio name="是" @click="clickkaipiao(true)">是</van-radio>
            <van-radio name="否" @click="clickkaipiao(false)">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <div v-if="iskaipiaoperson != '是' && customerType == '个人'">
        <van-field
          v-model="CustomerName"
          required
          name="客户姓名"
          label="客户姓名"
          placeholder="请填写"
        />

        <van-field
          required
          v-model="CustomercardType"
          is-link
          readonly
          label="证件类型"
          placeholder="请选择"
          @click="CustomercardTypeshow = true"
        />

        <van-popup v-model="CustomercardTypeshow" position="bottom">
          <van-picker
            title="证件类型"
            :columns="cardTypeList"
            show-toolbar
            @cancel="Customercardidcancel"
            @confirm="onCustomercardidcancelConfirm"
          />
        </van-popup>
        <van-field
          class="ID_Numer"
          v-model="CustomercardID_Numer"
          required
          name="证件号码"
          label="证件号码"
          placeholder="请输入18位身份证号"
          maxlength="18"
          show-word-limit
        />
        <van-field
          v-model="CustomerLocation"
          is-link
          readonly
          label="所在地"
          placeholder="可选"
          @click="CustomerkaipiaoisPopup = true"
        />

        <van-popup v-model="CustomerkaipiaoisPopup" position="bottom">
          <van-cascader
            v-model="cascaderValue"
            title="请选择所在地区"
            :options="areaList"
            @close="CustomerkaipiaoisPopup = false"
            @finish="customeronFinish"
          />
        </van-popup>

        <van-field
          v-model="Customeraddress"
          name="街道地址"
          label="街道地址"
          placeholder="最多50位"
        />

        <van-field
          v-model="CustomerPhone"
          disabled
          name="联系电话"
          label="联系电话"
          placeholder="请输入联系电话"
          required
        />
      </div>

      <div v-if="iskaipiaoperson != '是' && customerType == '企业'">
        <van-field
          v-model="CustomerName"
          required
          name="企业名称"
          label="企业名称"
          placeholder="请填写"
        />

        <van-field
          class="ID_Numer"
          v-model="CustomercardID_Numer"
          required
          name="社会统一代码"
          label="社会统一代码"
          placeholder="请输入18位社会统一代码"
          maxlength="18"
          show-word-limit
        />

        <van-field
          v-model="CustomerPhone"
          disabled
          name="联系电话"
          label="联系电话"
          placeholder="请输入联系电话"
          required
        />
      </div>
      <div class="titleBlock" style="font-size: 14px; padding: 10px 16px">
        开票信息
        <van-popover
          placement="top-start"
          trigger="click"
          v-model="showPopover"
          theme="dark"
        >
          <template #reference>
            <van-icon
              :style="{ marginLeft: '5px' }"
              size="20px"
              color="#cbccce"
              name="question"
            />
          </template>

          <div class="tipsCenter">
            仅支持个人身份证和填写本人电话的情况下，才能签署电子合同。
          </div>
        </van-popover>
      </div>
      <van-field required name="开票类型" label="开票类型">
        <template #input>
          <van-radio-group
            :disabled="iskaipiaoperson == '是'"
            v-model="kaipiaoType"
            direction="horizontal"
            @change="kaipiaoRadio"
          >
            <van-radio name="个人">个人</van-radio>
            <van-radio name="企业">企业</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <div v-if="kaipiaoType == '个人'">
        <van-field
          v-model="kaipiaopersonName"
          required
          name="开票人姓名"
          label="开票人姓名"
          placeholder="请填写"
        />
        <van-field
          required
          v-model="cardType"
          is-link
          readonly
          label="证件类型"
          placeholder="请选择"
          @click="cardTypeshow = true"
        />

        <van-popup v-model="cardTypeshow" position="bottom">
          <van-picker
            title="证件类型"
            :columns="cardTypeList"
            show-toolbar
            @cancel="cardidcancel"
            @confirm="oncardidcancelConfirm"
          />
        </van-popup>
        <van-field
          class="ID_Numer"
          v-model="cardID_Numer"
          required
          name="证件号码"
          label="证件号码"
          :rules="[
            { required: true, message: '请填写身份证号' },
            { validator, message: '身份证号码格式错误！' },
          ]"
          placeholder="请输入18位身份证号"
          maxlength="18"
          show-word-limit
        />

        <van-field
          v-model="Location"
          is-link
          readonly
          label="所在地"
          placeholder="可选"
          @click="kaipiaoisPopup = true"
        />

        <van-popup v-model="kaipiaoisPopup" position="bottom">
          <van-cascader
            v-model="cascaderValue"
            title="请选择所在地区"
            :options="areaList"
            @close="kaipiaoisPopup = false"
            @finish="onFinish"
          />
        </van-popup>

        <van-field
          v-model="address"
          name="街道地址"
          label="街道地址"
          placeholder="最多50位"
        />

        <van-field
          v-model="Phone"
          maxlength="11"
          :disabled="iskaipiaoperson == '是'"
          name="联系电话"
          label="联系电话"
          required
          placeholder="请输入联系电话"
        />
      </div>
      <div v-if="kaipiaoType == '企业'">
        <van-field
          v-model="kaipiaopersonName"
          required
          name="开票企业名称"
          label="开票企业名称"
          placeholder="请填写"
        />
        <van-field
          v-model="cardID_Numer"
          name="社会统一代码"
          show-word-limit
          label="社会统一代码"
          required
          placeholder="请输入18位社会统一代码"
          maxlength="18"
        />

        <van-field
          v-model="Phone"
          name="联系电话"
          minlength="11"
          maxlength="11"
          label="联系电话"
          required
          placeholder="请输入联系电话"
        />
      </div>
      <div class="titleBlock" style="font-size: 14px; padding: 10px 16px">
        其他信息
      </div>
      <van-field required name="审批类型" label="审批类型">
        <template #input>
          <van-radio-group v-model="approveType" direction="horizontal">
            <van-radio name="开票申请">开票申请</van-radio>
            <van-radio name="订单申请">订单申请</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        required
        v-model="shangpai"
        is-link
        readonly
        label="上牌方式"
        placeholder="请选择"
        @click="shangpaishow = true"
      />
      <van-popup v-model="shangpaishow" position="bottom">
        <van-picker
          title="上牌方式"
          :columns="shangpailist"
          show-toolbar
          @cancel="shangpaicancel"
          @confirm="onshangpaiConfirm"
        />
      </van-popup>
      <van-field
        required
        v-model="qudao"
        is-link
        readonly
        label="销售类型和渠道"
        placeholder="请选择"
        @click="qudaoshow = true"
      />
      <van-popup v-model="qudaoshow" position="bottom">
        <van-picker
          title="销售类型和渠道"
          :columns="qudaoTypeList"
          show-toolbar
          @cancel="qudaocancel"
          @confirm="onqudaoConfirm"
        />
      </van-popup>
      <van-field
        required
        v-model="jcDate"
        is-link
        readonly
        label="约定提车时间"
        @click="jcshow = true"
      />
      <!-- 日期弹出层 -->
      <van-popup
        v-model="jcshow"
        position="bottom"
        :close-on-click-overlay="false"
      >
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          title="选择年月日"
          @cancel="closePopup"
          @confirm="onJcConfirm"
        />
      </van-popup>
      <van-field required name="二手车置换" label="二手车置换">
        <template #input>
          <van-radio-group v-model="ershouchezh" direction="horizontal">
            <van-radio name="是">是</van-radio>
            <van-radio name="否">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <van-field
        v-if="ershouchezh == '是'"
        v-model="zhihuanPrice"
        name="置换金额"
        label="置换金额"
        type="number"
      />
      <van-field
        v-if="ENV == 'SS'"
        v-model="commission2S"
        name="二网佣金"
        label="二网佣金"
        type="number"
      />
      <van-field
        v-if="ENV != 'SS'"
        v-model="CurrentOgg"
        is-link
        readonly
        label="所属机构"
        placeholder="可选"
        @click="CurrentOggshow = true"
      />
      <van-field
        v-if="ENV == 'SS'"
        v-model="CurrentOgg"
        is-link
        readonly
        label="所属机构"
        placeholder="可选"
        @click="selectiveMechanism"
      />
      <van-popup v-model="CurrentOggshow" v-if="ENV != 'SS'" position="bottom">
        <van-picker
          title="所属机构"
          :columns="CurrentOggList"
          show-toolbar
          @cancel="CurrentOggcancel"
          @confirm="onCurrentOggConfirm"
        />
      </van-popup>

      <van-field
        v-model="teshuYd"
        name="特殊约定"
        label="特殊约定"
        placeholder="选填，本公司与客户的特殊预定事项"
      />
      <van-field
        v-model="Order_remarks"
        name="订单备注"
        label="订单备注"
        placeholder="选填"
      />
      <div
        class="titleBlock"
        v-if="attachmentsSwitch"
        style="font-size: 14px; padding: 10px 16px"
      >
        附件({{ fileList.length }}/5)
      </div>
      <div v-if="attachmentsSwitch" class="upload_div">
        <div v-if="isAndroid">
          <van-uploader
            v-model="fileList"
            :max-count="fileList.length"
            preview-size="55px"
            class="uploader_android"
            ref="file"
          />
          <div class="van-uploader__wrapper" v-show="fileList.length < 5">
            <div
              @click="readAndroid"
              class="van-uploader__upload van-uploader__uploadAndroid"
            >
              <i
                class="van-icon van-icon-photograph van-uploader__upload-icon"
              ></i>
            </div>
          </div>
        </div>
        <div v-else>
          <van-uploader
            v-model="fileList"
            :max-count="5"
            :after-read="afterRead"
            preview-size="60px"
            class="uploader"
            ref="file"
          />
        </div>
      </div>
    </div>

    <div class="footerButton">
      <van-button
        type="primary"
        size="large"
        :disabled="btnStatus"
        :loading="btnStatus"
        loading-text="订单提交中..."
        round
        @click="btnSub"
        >提交订单</van-button
      >
    </div>
    <van-popup
      v-model="isShowMechanism"
      position="right"
      closeable
      :style="{ height: '100%', width: '100%' }"
      class="popupClass"
    >
      <van-cell class="popupTitle">二网机构</van-cell>
      <form action="/">
        <van-search
          v-model="ZH_value"
          shape="round"
          background="#fff"
          placeholder=""
          bind:search="ZHonSearch"
          @clear="ZHonSearch"
          @blur="ZHonSearch"
          @search="ZHonSearch"
          @cancel="ZHonSearch"
          @keypress="ZHonSearch"
        />
      </form>
      <!-- 搜索框 -->

      <van-tabs @click="onClick" v-model="so2sOrgType">
        <van-tab title="客户">
          <van-cell
            clickable
            v-for="item in customerData"
            :key="item.OBJECTNO"
            :title="item.OBJECTNAME + '(' + item.OBJECTNO + ')'"
            :title-class="{ titleActive: item.OBJECTNO === store2SNo }"
            @click="chooseMechanism(item)"
          />
        </van-tab>
        <van-tab title="门店">
          <van-cell
            clickable
            v-for="item in storeData"
            :key="item.OBJECTNO"
            :title="item.OBJECTNAME + '(' + item.OBJECTNO + ')'"
            :title-class="{ titleActive: item.OBJECTNO === store2SNo }"
            @click="chooseMechanism(item)"
          />
        </van-tab>
      </van-tabs>
    </van-popup>
  </div>
</template>
<script>
import { areaList } from "../PlaceAnOrder/area.js";
import { thousand } from "utils";
import {
  GetTo2SOrg,
  GetCertificateType,
  GetSaleType,
  GetCusIntention,
  SaveCarSalesOrder,
  GetEmployeeInfo,
  GetBasValueByTypeASC,
  Get2SOrgNameByType,
  GetCustomerInfoByCusNo,
  GetUsedCarByCusNo,
} from "../../api/PlaceAnOrder";
import { uploadFile } from "../../api/upload";

import {
  GetSubTypeBySeries,
  GetFinProductBySupplier,
} from "../../api/OrderGoods";

export default {
  name: "OrderGoods",
  props: {
    houseutilizationColumns: {
      type: Array, //数组类型;也可以是Object
    },
    UserName: {
      type: String,
    },
  },
  beforeRouteEnter(to, from, next) {
    console.log(to);
    next((vm) => {
      if (from.name == "OrderDetails") {
        // 如果是下单完成页面进入返回列表页
        vm.$router.replace({
          path: "../SaleOrderList/SaleOrderList",
          query: {
            ...vm.$route.query,
          },
        });
      }
    });
  },
  data() {
    return {
      showBack: true,
      fileList: [],
      isShowMechanism: false,
      customerData: [],
      storeData: [],
      // 处理千分位
      thousand,
      showPopover: false,
      sfjine: "",
      cusSelfpayInterestAmt: "",
      Order_remarks: "",
      teshuYd: "",
      CurrentOgg: "",
      store2SNo: "",
      zhihuanPrice: "",
      commission2S: "",
      ershouchezh: "是",
      CarTypeCode: "",
      CARSUBTYPECode: "",
      CARSUBTYPEName: "",
      CARSUBTYPEMSRP: "",
      jingrong_fangan: "",
      CARSUBTYPEBRANDCODE: "",
      CARSUBTYPEMODELCODE: "",
      CARSUBTYPESERIESCODE: "",
      ISDIRECTSALE: "",

      AppearanceColoron: "",
      InteriorColor: "",
      ZCAmount: "",
      so_item_show: false,
      ZH_item: [],
      Bx_item: [],
      YB_item: [],
      DB_item: [],
      JR_item: [],
      arrgoodsItems: [],
      arrInsOrderItems: [],
      arrloanOrder: [],
      arrewOrders: [],
      arrdbpushList: [],
      ZH_Pirce: "",
      Insurance_item_name: "",
      Insurance_Pirce: "",
      bx_show: false,
      Financial_Price: "",
      agency_Price: "",
      db_show: false,
      iskaipiaoperson: "是",
      shangpailist: [],
      cardTypeList: [],
      qudaoTypeList: [],
      CurrentOggList: [],
      shangpaishow: false,
      cardTypeshow: false,
      shangpai: "",
      cardType: "",
      kaipiaopersonName: "",
      kaipiaoType: "",
      customerType: "",
      cardID_Numer: "",
      Location: "",
      kaipiaoisPopup: false,
      qudaoshow: false,
      areaCode: "",
      address: "",
      Phone: "",
      CustomerName: "",
      CustomercardType: "",
      CustomercardTypeshow: false,
      CustomercardID_Numer: "",
      CustomerLocation: "",
      Customeraddress: "",
      CustomerPhone: "",
      jcshow: false,
      CurrentOggshow: false,
      approveType: "开票申请",
      invoiceCompanyNo: "",
      hesuanbumen: "",
      qudao: "",
      currentDate: new Date(),
      dingjin: 0,
      jcDate: "",
      cascaderValue: "",
      areaList,
      CustomerkaipiaoisPopup: false,
      kaipiaoqy_Phone: "",
      kaipiaoqy_name: "",
      kaipiaoqy_Code: "",
      GetActivityModel: {
        storeNo: "",
      },
      GetCusIntentionModel: {
        INTENTION_NO: "",
      },
      //车系
      CarSeriesModel: {
        SeriesCode: "",
      },
      GetFinProductModel: {
        useOrgNo: "",
        provider_no: "",
      },
      jinrong_OrgNo: "",
      LoanOrderproductNo: "",
      CUSNO: "",
      so_subModel: {
        optType: 1,
        soMstrInfo: {
          soNo: "",
          storeNo: "",
          approveType: "",
          cusNo: "",
          cusMobile: "",
          cusName: "",
          cusIsPerson: "",
          clientProvinceName: "",
          clientCityName: "",
          clientDistrictName: "",
          clientAddress: "",
          cusCertifiCateNo: "",
          actualOwnerCusNo: "",
          actualCertifiCateNo: "",
          actualCertificateType: "",
          cusCertificateType: "",
          actualMobile: "",
          actualName: "",
          actualCusIsPerson: "",
          plateUserProvinceName: "",
          plateUserCityName: "",
          plateUserDistrictName: "",
          plateUserAddress: "",
          saleTypeName: "",
          saleChannelName: "",
          carUseType: "",
          estimatedDeliveryCarDate: "",
          storePurpose: "",
          getCarPersonName: "",
          GetCarPersonCardNo: "",
          isUsedCarDisplace: true,
          usedCarPurchaseAmount: 0.0,
          carIDRegistrationWayName: "",
          soSalesPsnNo: "",
          createDate: "2022-12-28 11:37:03",
          updateDate: "2022-12-28 11:37:04",
          updatePsnNo: "",
          soDeposit: 0,
          saasOrderSource: "线下",
          freezeFlag: "0",
          revertFlag: "0",
          orderNo: "",
          versionNumber: 0,
          intentionId: "",
          buyType: "",
          carNature: "",
          usedCarPurchaseNo: "", //二手车收购流水号
        },
        carInfo: {
          soCarBrandCode: "",
          soCarClassCode: "",
          soCarTypeCode: "",
          soCarSubTypeCode: "",
          soCarColorNo: "",
          soCarInnerColorNo: "",
          soCarSalePrice: 0,
          soCarVin: "",
        },
        soCarVin: "",
        goodsItems: [],
        insOrder: {
          InsCorpNo: "",
          InsOrderItems: [
            {
              InsProductName: "",
              coverageAmount: 0,
              premiumAmount: 0,
              receiveAmount: 0,
            },
          ],
        },
        loanOrder: {
          productProviderNo: "",
          productNo: "",
          loanTerms: 0,
          loanAmount: 0,
          serviceCharge: 0,
        },
        ewOrder: {
          eWId: "",
          ewProductNo: "",
          ewAmount: 0,
          ewStartDate: "",
          ewEndDate: "",
          WMType: "",
          EwPTime: "",
        },
        isEWS: "1",
        ewOrders: [],
        collectionItems: [],
        EW_Pirce: "",
        ATTACHMENTS_PATHS: [],
      },
      orderInfo: "",
      MODELCODE: "",
      btnStatus: false,
      ZH_value: "",
      so2sOrgType: 0,
      attachmentsSwitch: process.env.VUE_APP_BASE_UPLOAD_SWITCH == "true",
      isAndroid: null,
    };
  },
  methods: {
    goBack() {
      if (this.showBack) {
        this.$router.go(-1);
      }
      // this.$router.back();
    },
    afterRead(val) {
      console.log(val, "val----------");
      console.log(this.fileList, "fileList");
      let formData = new FormData();
      formData.append("file", val.file);
      uploadFile(formData).then((res) => {
        console.log(res, "uploadFile");
        if (res.data.code) {
          this.fileList[this.fileList.length - 1].url = res.data.data;
        } else {
          this.$toast("上传失败！");
        }
      });
    },
    //安卓上传成功回调
    afterReadAndroid(val) {
      this.fileList[this.fileList.length - 1].url = val;
    },
    //安卓失败/取消回调
    cancelUpload() {
      this.fileList = this.fileList.filter((item) => item.url);
    },
    readAndroid() {
      this.fileList.push({
        isImage: true,
        url: false,
      });
      let uploadurl =
        process.env.VUE_APP_BASE_UPLOAD + "v1.0/api/file/oss/upload";
      console.log(uploadurl, "uploadurl");
      window.android.readAndroid(uploadurl); // 调用原生app的函数--readAndroid是安卓提供的方法
    },
    dingjinChange() {
      if (this.dingjin != "") {
        this.dingjin = this.dingjin.match(/\d{1,}(\.\d{1,2})?/g)[0];
        this.$toast("定金限制小数点后两位，已默认修改！");
      }
    },
    sub_model() {
      return new Promise((resolve) => {
        let so_subModel = Object.assign(this.so_subModel);
        console.log("定叫你", this.dingjin, this.dingjin == "");
        if (this.dingjin === "") {
          this.$toast("请填写定金！");
          return;
        }
        if (this.dingjin != "") {
          var point = this.dingjin.indexOf(".");
          console.log("point" + point);
          console.log("dingjin" + this.dingjin.length);
          if (
            this.dingjin.length - point > 3 &&
            this.dingjin.length > 0 &&
            point > 0
          ) {
            this.$toast("定金限制小数点后两位！");
            return;
          }
          this.dingjin = this.dingjin.match(/\d{1,}(\.\d{1,2})?/g)[0];
        }
        if (this.iskaipiaoperson == "否") {
          if (this.CustomerName == "") {
            this.$toast("请填写客户姓名！");
            return;
          }
          if (this.CustomercardType == "" && this.kaipiaoType == "个人") {
            this.$toast("请填写客户证件类型！");
            return;
          }
          if (this.CustomercardID_Numer == "") {
            this.$toast("请填写客户证件号码！");
            return;
          }
          if (this.CustomerPhone == "") {
            this.$toast("请填写客户电话号码！");
            return;
          }
        }
        if (this.kaipiaoType == "个人") {
          if (this.kaipiaopersonName == "") {
            this.$toast("请填写开票人姓名！");
            return;
          }
          if (this.cardType == "") {
            this.$toast("请填写开票人证件类型！");
            return;
          }
          if (this.cardID_Numer == "") {
            this.$toast("请填写开票人证件号码！");
            return;
          }
          if (this.Phone == "") {
            this.$toast("请填写开票人电话号码！");
            return;
          }
          if (this.Phone.length < 11) {
            this.$toast("开票人电话号码格式错误");
            return;
          }
        } else if (this.kaipiaoType == "企业") {
          if (this.cardID_Numer == "" && this.kaipiaoType == "企业") {
            this.$toast("请输入18位社会统一代码!");
            return;
          }
          if (this.Phone == "" && this.kaipiaoType == "企业") {
            this.$toast("请填写企业电话号码！");
            return;
          }
        }
        if (!this.shangpai) {
          this.$toast("请选择上牌方式！");
          return;
        }

        if (!this.qudao) {
          this.$toast("请选择销售类型和渠道！");
          return;
        }

        if (!this.jcDate) {
          this.$toast("请选择约定提车时间！");
          return;
        }
        this.btnStatus = true;
        if (this.soStatus == "已生效") {
          so_subModel.optType = 1; //提交
        } else {
          so_subModel.optType = 0; //保存
        }

        so_subModel.soMstrInfo.storeNo = this.$route.query.Store_No;
        so_subModel.soMstrInfo.approveType = this.approveType;
        so_subModel.soMstrInfo.invoiceCompanyNo = this.invoiceCompanyNo;

        so_subModel.soMstrInfo.cusNo = this.CUSNO;
        this.cusIsPerson = this.kaipiaoType;
        so_subModel.soMstrInfo.cusIsPerson =
          this.customerType == "企业" ? "公司" : "个人";
        so_subModel.soMstrInfo.actualOwnerCusNo =
          this.iskaipiaoperson == "是"
            ? this.CUSNO
            : this.so_subModel.soMstrInfo.actualOwnerCusNo;

        //开票人信息
        so_subModel.soMstrInfo.actualCusIsPerson =
          this.kaipiaoType == "企业" ? "公司" : "个人"; //开票类型
        so_subModel.soMstrInfo.actualName = this.kaipiaopersonName;
        so_subModel.soMstrInfo.actualCertificateType =
          this.kaipiaoType == "企业" ? "组织机构代码证" : this.cardType;
        so_subModel.soMstrInfo.actualCertifiCateNo = this.cardID_Numer;
        so_subModel.soMstrInfo.plateUserAddress = this.address;
        so_subModel.soMstrInfo.actualMobile = this.Phone;
        so_subModel.soMstrInfo.plateUserProvinceName =
          this.Location.split("/")[0];
        so_subModel.soMstrInfo.plateUserCityName = this.Location.split("/")[1];
        so_subModel.soMstrInfo.plateUserDistrictName =
          this.Location.split("/")[2];

        if (this.iskaipiaoperson == "是") {
          // 客户为开票人：则 实际车主 actual开头的  和 客户 cus  为同样的数据
          // 客户不为开票人：则 开票信息所填的 客户为 实际车主  actual开头  客户信息为 cus 开头的
          // 客户姓名，证件类型，证件号码，所在地，街道地址，联系电话，
          // actualName
          so_subModel.soMstrInfo.cusName = this.kaipiaopersonName;
          so_subModel.soMstrInfo.cusCertificateType =
            this.kaipiaoType == "企业" ? "组织机构代码证" : this.cardType;
          so_subModel.soMstrInfo.cusCertifiCateNo = this.cardID_Numer;
          so_subModel.soMstrInfo.cusMobile = this.Phone;
          so_subModel.soMstrInfo.clientAddress = this.address;
          so_subModel.soMstrInfo.clientProvinceName =
            this.Location.split("/")[0];
          so_subModel.soMstrInfo.clientCityName = this.Location.split("/")[1];
          so_subModel.soMstrInfo.clientDistrictName =
            this.Location.split("/")[2];
        } else {
          //客户信息
          so_subModel.soMstrInfo.cusName = this.CustomerName;
          so_subModel.soMstrInfo.cusCertificateType =
            this.customerType == "企业"
              ? "组织机构代码证"
              : this.CustomercardType;
          so_subModel.soMstrInfo.cusCertifiCateNo = this.CustomercardID_Numer;
          so_subModel.soMstrInfo.cusMobile = this.CustomerPhone;
          so_subModel.soMstrInfo.clientAddress = this.Customeraddress;
          so_subModel.soMstrInfo.clientProvinceName =
            this.CustomerLocation.split("/")[0];
          so_subModel.soMstrInfo.clientCityName =
            this.CustomerLocation.split("/")[1];
          so_subModel.soMstrInfo.clientDistrictName =
            this.CustomerLocation.split("/")[2];
        }

        so_subModel.soMstrInfo.saleTypeName = this.qudao.split("/")[0];
        so_subModel.soMstrInfo.saleChannelName = this.qudao.split("/")[1];

        // so_subModel.soMstrInfo.carUseType = "私用"; //没赋值的地方

        so_subModel.soMstrInfo.estimatedDeliveryCarDate = this.jcDate;
        // so_subModel.soMstrInfo.storePurpose = "2"; //没赋值的地方

        so_subModel.soMstrInfo.getCarPersonName = this.CustomerName;
        so_subModel.soMstrInfo.GetCarPersonCardNo = this.CustomercardID_Numer;
        so_subModel.soMstrInfo.isUsedCarDisplace =
          this.ershouchezh == "是" ? 1 : 0;
        so_subModel.soMstrInfo.usedCarPurchaseAmount =
          this.ershouchezh == "是" ? this.zhihuanPrice : 0.0;
        so_subModel.soMstrInfo.usedCarPurchaseNo =
          this.ershouchezh == "是" ? this.usedCarPurchaseNo : "";
        //置换金额
        if (this.ENV == "SS")
          so_subModel.soMstrInfo.commission2S = this.commission2S;
        so_subModel.soMstrInfo.carIDRegistrationWayName = this.shangpai;

        so_subModel.soMstrInfo.createDate = this.getNowDateTime();
        so_subModel.soMstrInfo.updateDate = this.getNowDateTime();

        so_subModel.soMstrInfo.soDeposit = this.dingjin;
        so_subModel.soMstrInfo.saasOrderSource = "线下";
        so_subModel.soMstrInfo.freezeFlag = "0";
        so_subModel.soMstrInfo.revertFlag = "0";
        so_subModel.soMstrInfo.versionNumber = 0;
        so_subModel.soMstrInfo.intentionId = this.INTENTION_NO;
        so_subModel.soMstrInfo.buyType = "";
        so_subModel.soMstrInfo.carNature = "";
        so_subModel.carInfo.soCarBrandCode = this.CARSUBTYPEBRANDCODE;
        so_subModel.carInfo.soCarClassCode = this.CARSUBTYPESERIESCODE;
        console.log("为啥子", this.CARSUBTYPECode);
        // so_subModel.carInfo.soCarTypeCode = this.CARSUBTYPECode;
        so_subModel.carInfo.soCarTypeCode = this.CarTypeCode;
        so_subModel.carInfo.soCarSubTypeCode = this.CARSUBTYPEMODELCODE;
        so_subModel.carInfo.soCarColorNo = this.AppearanceColoronCode;
        so_subModel.carInfo.soCarInnerColorNo = this.InteriorColorCode;
        so_subModel.carInfo.soCarSalePrice = this.CARSUBTYPEMSRP;
        so_subModel.carInfo.soCarVin = this.soCarVin;

        so_subModel.goodsItems = this.arrgoodsItems;
        if (this.INS_BRANCHNO == "undefined" || this.INS_BRANCHNO == "") {
          delete so_subModel.insOrder;
        } else {
          so_subModel.insOrder.InsCorpNo = this.INS_BRANCHNO;
          so_subModel.insOrder.InsOrderItems = this.arrInsOrderItems;
        }
        if (this.arrewOrders.length > 0) {
          so_subModel.ewOrder.eWId = this.arrewOrders[0].eWId;
          so_subModel.ewOrder.ewProductNo = this.arrewOrders[0].ewProductNo;
          so_subModel.ewOrder.ewAmount = this.arrewOrders[0].ewAmount;
          so_subModel.ewOrder.ewStartDate = this.arrewOrders[0].ewStartDate;
          so_subModel.ewOrder.ewEndDate = this.arrewOrders[0].ewEndDate;
          so_subModel.ewOrder.WMType = this.arrewOrders[0].WMType;
          so_subModel.ewOrder.EwPTime = this.arrewOrders[0].EwPTime;

          so_subModel.ewOrders = this.arrewOrders;
        } else {
          delete so_subModel.ewOrder;
          delete so_subModel.ewOrders;
        }

        if (this.arrloanOrder.length > 0) {
          so_subModel.loanOrder.productProviderNo =
            this.arrloanOrder[0].productProviderNo;
          so_subModel.loanOrder.productNo = this.arrloanOrder[0].productNo;
          so_subModel.loanOrder.loanTerms = this.arrloanOrder[0].loanTerms;
          so_subModel.loanOrder.loanAmount = this.arrloanOrder[0].loanAmount;
          so_subModel.loanOrder.serviceCharge =
            this.arrloanOrder[0].serviceCharge;
          so_subModel.loanOrder.cusSelfpayInterestAmt =
            this.arrloanOrder[0].cusSelfpayInterestAmt;
        } else {
          delete so_subModel.loanOrder;
        }
        if (
          this.arrdbpushList.length > 0 &&
          this.arrdbpushList[0].itemNo != ""
        ) {
          so_subModel.collectionItems = this.arrdbpushList;
        } else {
          delete so_subModel.collectionItems;
        }
        so_subModel.soMstrInfo.remark = this.Order_remarks;
        so_subModel.soMstrInfo.spc_agm = this.teshuYd;
        so_subModel.soMstrInfo.store2SNo = this.store2SNo;
        so_subModel.soMstrInfo.soNo = this.soNo;
        console.log("我有值吗", this.$route.query.soNo, this.$route.query.soNo);
        if (this.soNo) {
          so_subModel.soMstrInfo.orderNo = this.orderNo;
          //
        } else {
          so_subModel.soMstrInfo.orderNo = "E" + this.uuid(8, 16);
        }
        //附件赋值
        if (this.fileList.length > 0) {
          so_subModel.ATTACHMENTS_PATHS = this.fileList
            .filter((item) => item.url)
            .map((item) => {
              return { ATTA_PATH: item.url };
            });
        }
        // console.log("执行完", so_subModel);
        setTimeout(() => {
          // console.log("执行完倒计时");
          resolve(so_subModel);
        }, 1000);
      });
    },
    async btnSub() {
      if (this.fileList.length > 0) {
        console.log(
          this.fileList.filter((item) => !item.url),
          "this.fileList.filter(item=>!item.url)"
        );
        if (this.fileList.filter((item) => !item.url).length > 0) {
          this.$toast("附件上传中，请稍后提交");
          return false;
        }
      }
      this.sub_model().then((so_subModel) => {
        console.log("下单信息", so_subModel);
        // debugger
        this.SaveCarSalesOrder(so_subModel);
      });
      // if (this.so_subModel != {})
      // {
      //   console.log("测试数据", this.so_subModel)
      // }
      //整车订单下单
    },
    async SaveCarSalesOrder(so_subModel) {
      await SaveCarSalesOrder(so_subModel).then((res) => {
        if (res.data.IsSuccess) {
          var outerOrderNo = res.data.result.outerOrderNo;
          if (this.soNo) {
            this.$toast("修改成功！");
          } else {
            this.$toast("下单成功！");
          }

          sessionStorage.removeItem("temp");
          sessionStorage.removeItem("otherInfo");
          sessionStorage.removeItem("orderInfo");
          if (!this.soNo) {
            this.$router.replace({
              path: "../OrderDetails/OrderDetails",
              query: {
                // ...this.$route.query,
                EMPLOYEE_NO: this.$route.query.EMPLOYEE_NO,
                INTENTION_NO: this.$route.query.INTENTION_NO,
                isAndroidOrIOS: this.$route.query.isAndroidOrIOS,
                Store_No: this.$route.query.Store_No,
                soNo: outerOrderNo,
              },
            });
          } else {
            this.$router.replace({
              path: "../OrderDetails/OrderDetails",
              query: {
                EMPLOYEE_NO: this.$route.query.EMPLOYEE_NO,
                INTENTION_NO: this.$route.query.INTENTION_NO,
                isAndroidOrIOS: this.$route.query.isAndroidOrIOS,
                Store_No: this.$route.query.Store_No,
                soNo: this.$route.query.soNo || this.$route.query.soNo,
              },
            });
          }
        } else {
          this.$toast(res.data.msg);
        }
        this.btnStatus = false;
      });
    },
    NewtimeFormat(time) {
      let year = time.split("/")[0];
      let month = time.split("/")[1];
      let day = time.split("/")[2].substring(0, 2);
      return year + "/" + month + "/" + day;
    },
    //金融方案
    async NewAddjinrongfangan() {
      this.GetFinProductModel.useOrgNo = this.$route.query.Store_No;
      this.GetFinProductModel.provider_no = this.jinrong_OrgNo;
      await GetFinProductBySupplier(this.GetFinProductModel).then((res) => {
        if (res.data.IsSuccess) {
          this.jinrongfanganList = res.data.result;
          const result = this.jinrongfanganList.filter(
            (u) => u.FIN_PRODUCT_NO == this.LoanOrderproductNo
          );
          if (result.length > 0) {
            this.jingrong_fangan = result[0].FIN_PRODUCT_NAME;
          }
          this.jinrongfanganList = res.data.result;
        }
      });
    },
    onshangpaiConfirm(e) {
      this.shangpaishow = false;
      this.shangpai = e.text;
    },
    shangpaicancel(e) {
      this.shangpaishow = false;
      this.shangpai = e.text;
    },
    cardidcancel(e) {
      this.cardTypeshow = false;
      this.cardType = e.text;
    },
    oncardidcancelConfirm(e) {
      this.cardTypeshow = false;
      this.cardType = e.text;
    },
    Customercardidcancel(e) {
      this.CustomercardTypeshow = false;
      this.CustomercardType = e.text;
    },
    onCustomercardidcancelConfirm(e) {
      this.CustomercardTypeshow = false;
      this.CustomercardType = e.text;
    },
    qudaocancel(e) {
      console.log("选择", e);
      this.qudaoshow = false;
      this.qudao = e.text;
    },
    onqudaoConfirm(e) {
      console.log("类型", this.ISDIRECTSALE);
      if (this.ENV == "SS" && this.ISDIRECTSALE != "") {
        if (this.ISDIRECTSALE == 0 && e[0] == "直售订单") {
          this.$toast("该车型不属于直售类型");
          return;
        }
        if (this.ISDIRECTSALE == 1 && e[0] != "直售订单") {
          this.$toast("该车型只能选直售订单");
          return;
        }
      }
      this.qudaoshow = false;
      this.qudao = e.map((option) => option).join("/");
    },

    uuid(len, radix) {
      var chars =
        "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split(
          ""
        );
      var uuid = [],
        i;
      radix = radix || chars.length;

      if (len) {
        for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
      } else {
        var r;
        uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
        uuid[14] = "4";
        for (i = 0; i < 36; i++) {
          if (!uuid[i]) {
            r = 0 | (Math.random() * 16);
            uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
          }
        }
      }
      return uuid.join("");
    },

    onJcConfirm(value) {
      this.jcshow = false;
      this.jcDate = this.timeFormat(value);
    },
    CurrentOggcancel(e) {
      this.CurrentOggshow = false;
      this.CurrentOgg = e.text;
    },
    onCurrentOggConfirm(e) {
      this.CurrentOggshow = false;
      this.CurrentOgg = e.text;
      this.store2SNo = e.value;
    },
    // 时间格式化
    timeFormat(time) {
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      return year + "-" + month + "-" + day;
    },

    getNowDateTime() {
      var myDate = new Date();
      var year = myDate.getFullYear(); //获取当前年
      var mon = myDate.getMonth() + 1; //获取当前月
      var date = myDate.getDate(); //获取当前日
      var hours = myDate.getHours(); //获取当前小时
      var minutes = myDate.getMinutes(); //获取当前分钟
      var seconds = myDate.getSeconds(); //获取当前秒
      var now =
        year +
        "-" +
        mon +
        "-" +
        date +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds;
      return now;
    },
    clickkaipiao(item) {
      if (item) {
        this.kaipiaoType = this.customerType;
        this.kaipiaopersonName = this.CustomerName;
        this.cardType = this.CustomercardType;
        this.cardID_Numer = this.CustomercardID_Numer;
        this.Location = this.CustomerLocation;
        this.address = this.Customeraddress;
        this.Phone = this.CustomerPhone;
        this.so_subModel.soMstrInfo.actualOwnerCusNo = this.CUSNO;
      } else {
        this.kaipiaopersonName = "";
        this.cardType = "";
        this.cardID_Numer = "";
        this.Location = "";
        this.address = "";
        this.Phone = "";
        this.so_subModel.soMstrInfo.actualOwnerCusNo = "";
      }
    },
    closePopup() {
      this.jcshow = false;
    },
    onFinish(selectedOptions) {
      this.kaipiaoisPopup = false;
      this.Location = selectedOptions.selectedOptions
        .map((option) => option.text)
        .join("/");
    },
    customeronFinish(selectedOptions) {
      this.CustomerkaipiaoisPopup = false;
      this.CustomerLocation = selectedOptions.selectedOptions
        .map((option) => option.text)
        .join("/");
    },
    validator(val) {
      const card15 =
        /^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}[0-9Xx]$/;
      const card18 =
        /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      return card15.test(val) || card18.test(val);
    },
    // 获取二网机构列表
    async GetTo2SOrg() {
      this.GetActivityModel.storeNo = this.$route.query.Store_No;
      await GetTo2SOrg(this.GetActivityModel).then((res) => {
        let newList = res.data.result.map((item) => {
          return {
            text: item.ORGNAME,
            value: item.ORGNO,
          };
        });
        this.CurrentOggList = [...this.CurrentOggList, ...newList];
        this.CurrentOgg =
          this.CurrentOggList.length && this.store2SNo > 0
            ? this.CurrentOggList.filter((u) => u.value == this.store2SNo)[0]
                .text || ""
            : "";
      });
    },
    async Get2SOrgNameByType(data) {
      console.log("2----data", data);
      await Get2SOrgNameByType(data).then((res) => {
        if (res.data.IsSuccess) {
          if (this.so2sOrgType == 0) {
            this.customerData = res.data.result.list;
            this.CurrentOgg =
              res.data.result.list.filter((r) => r.OBJECTNO == this.store2SNo)
                .length > 0
                ? res.data.result.list.filter(
                    (r) => r.OBJECTNO == this.store2SNo
                  )[0].OBJECTNAME
                : "";
          } else {
            this.storeData = res.data.result.list;
            this.CurrentOgg =
              res.data.result.list.filter((r) => r.OBJECTNO == this.store2SNo)
                .length > 0
                ? res.data.result.list.filter(
                    (r) => r.OBJECTNO == this.store2SNo
                  )[0].OBJECTNAME
                : "";
          }
        }
      });
    },
    selectiveMechanism() {
      this.isShowMechanism = !this.isShowMechanism;
      const data = {
        so2sOrgType: this.so2sOrgType == 0 ? "CUS" : "ORG",
        storeNo: this.$route.query.Store_No,
      };
      if (this.customerData.length == 0) {
        this.Get2SOrgNameByType(data);
      }
    },
    onClick(index) {
      console.log("选择", index);
      this.so2sOrgType = index;
      this.ZHonSearch();
    },
    chooseMechanism(item) {
      console.log("item", item);
      this.CurrentOgg = item.OBJECTNAME;
      this.store2SNo = item.OBJECTNO;
      this.isShowMechanism = !this.isShowMechanism;
    },

    ZHonSearch() {
      //this.$store.commit('showLoading');
      let data = {};
      data = {
        so2sOrgType: this.so2sOrgType == 0 ? "CUS" : "ORG",
        storeNo: (this.storeNo = this.$route.query.Store_No),
        searchStr: this.ZH_value,
      };
      this.Get2SOrgNameByType(data);
    },
    // 获取

    async GetEmployeeInfo() {
      await GetEmployeeInfo({ USER_NAME: this.$route.query.EMPLOYEE_NO }).then(
        (res) => {
          if (res.data.IsSuccess) {
            this.so_subModel.soMstrInfo.soSalesPsnNo =
              res.data.result[0].EMPLOYEE_NO;
            this.so_subModel.soMstrInfo.updatePsnNo =
              res.data.result[0].EMPLOYEE_NO;
          }
        }
      );
    },

    // 查询意向
    async GetCusIntention() {
      this.GetCusIntentionModel.INTENTION_NO = this.INTENTION_NO;
      // this.CUSNO = this.$route.query.INTENTION_NO
      await GetCusIntention(this.GetCusIntentionModel).then((res) => {
        if (res.data.IsSuccess && res.data.result.length > 0) {
          this.kaipiaoType =
            res.data.result[0].CUSISPERSON == "公司" ? "企业" : "个人";
          this.customerType =
            res.data.result[0].CUSISPERSON == "公司" ? "企业" : "个人";
          this.CustomerName = res.data.result[0].CUS_NAME;
          this.CustomerPhone = res.data.result[0].CUS_MOBILE;
          this.CustomercardID_Numer = res.data.result[0].CERTIFICATENO;
          this.CustomercardType = res.data.result[0].CERTIFICATETYPE;
          this.cusIsPerson = res.data.result[0].CUSISPERSON;
          this.CUSNO = res.data.result[0].CUSNO;
          if (
            res.data.result[0].CONTRACT_PROVINCE_NAME != "" ||
            res.data.result[0].CONTRACT_CITY_NAME != "" ||
            res.data.result[0].CONTRACT_REGION_NAME != ""
          ) {
            this.CustomerLocation =
              res.data.result[0].CONTRACT_PROVINCE_NAME +
              "/" +
              res.data.result[0].CONTRACT_CITY_NAME +
              "/" +
              res.data.result[0].CONTRACT_REGION_NAME;
            this.Location =
              res.data.result[0].CONTRACT_PROVINCE_NAME +
              "/" +
              res.data.result[0].CONTRACT_CITY_NAME +
              "/" +
              res.data.result[0].CONTRACT_REGION_NAME;
            //this.cascaderValue = res.data.result[0].CONTRACT_REGION_CODE;
            this.areaList[0].values = {
              text: res.data.result[0].CONTRACT_PROVINCE_NAME,
              code: res.data.result[0].CONTRACT_PROVINCE_CODE,
            };
          }
          this.CUSNO = res.data.result[0].CUSNO;
          console.log(this.CUSNO, "this.CUSNO--GetCusIntention");
          this.kaipiaopersonName = res.data.result[0].CUS_NAME;
          this.cardType = res.data.result[0].CERTIFICATETYPE;
          this.cardID_Numer = res.data.result[0].CERTIFICATENO;
          this.Phone = res.data.result[0].CUS_MOBILE;
        }
      });
    },
    // 大客户
    async GetCustomerInfoByCusNo() {
      const params = { keyWord: this.$route.query.OUT_SRC_CUS_NO };
      await GetCustomerInfoByCusNo(params).then((res) => {
        if (res.data.IsSuccess && res.data.result.length > 0) {
          this.kaipiaoType =
            res.data.result[0].CUSISPERSON == "公司" ? "企业" : "个人";
          this.customerType =
            res.data.result[0].CUSISPERSON == "公司" ? "企业" : "个人";
          this.CustomerName = res.data.result[0].OWNERNAME;
          this.CustomerPhone = res.data.result[0].MOBILE;
          this.CustomercardID_Numer = res.data.result[0].CERTIFICATENO;
          this.CustomercardType = res.data.result[0].CERTIFICATETYPE;
          this.cusIsPerson = res.data.result[0].CUSISPERSON;
          if (
            res.data.result[0].CONTRACT_PROVINCE_NAME != "" ||
            res.data.result[0].CONTRACT_CITY_NAME != "" ||
            res.data.result[0].CONTRACT_REGION_NAME != ""
          ) {
            this.CustomerLocation =
              res.data.result[0].CONTRACT_PROVINCE_NAME +
              "/" +
              res.data.result[0].CONTRACT_CITY_NAME +
              "/" +
              res.data.result[0].CONTRACT_REGION_NAME;
            this.Location =
              res.data.result[0].CONTRACT_PROVINCE_NAME +
              "/" +
              res.data.result[0].CONTRACT_CITY_NAME +
              "/" +
              res.data.result[0].CONTRACT_REGION_NAME;
            //this.cascaderValue = res.data.result[0].CONTRACT_REGION_CODE;
            this.areaList[0].values = {
              text: res.data.result[0].CONTRACT_PROVINCE_NAME,
              code: res.data.result[0].CONTRACT_PROVINCE_CODE,
            };
          }
          this.CUSNO = res.data.result[0].CUSNO;

          this.kaipiaopersonName = res.data.result[0].OWNERNAME;
          this.cardType = res.data.result[0].CERTIFICATETYPE;
          this.cardID_Numer = res.data.result[0].CERTIFICATENO;
          this.Phone = res.data.result[0].MOBILE;
        }
      });
    },
    // 获取证件类型
    async GetCertificateType() {
      await GetCertificateType().then((res) => {
        let newList = res.data.result.map((item) => {
          let text = "text";
          let value = "value";
          return {
            [text]: item.TEXT,
            [value]: item.VALUE,
          };
        });
        this.cardTypeList = [...this.cardTypeList, ...newList];
      });
    },
    // 获取销售渠道和来源
    async GetSaleType() {
      await GetSaleType().then((res) => {
        const list = res.data.result.filter((v) => {
          if (v.children == null) {
            v.children = [{ value: "", text: "" }];
          }
          return v;
        });
        this.qudaoTypeList = list;
      });
    },
    async GetBasValueByTypeASC() {
      await GetBasValueByTypeASC().then((res) => {
        this.shangpailist = res.data.result.map((v) => {
          return { value: v.ID, text: v.VALUE };
        });
      });
    },

    //车型基础数据
    async GetSubTypeBySeries() {
      this.CarSeriesModel.SeriesCode = decodeURIComponent(this.SERIESCODE);
      console.log("carsInfo", this.SERIESCODE);
      await GetSubTypeBySeries(this.CarSeriesModel).then((res) => {
        console.log("res", res);
        if (res.data.IsSuccess && res.data.result.length > 0) {
          let carsInfo = res.data.result.filter(
            (i) => i.MODELCODE == this.MODELCODE
          );
          console.log("获取", carsInfo);
          if (carsInfo.length > 0) {
            this.carInfo = carsInfo[0];
            this.CARSUBTYPEName = carsInfo[0].MODELNAME;
            this.CarTypeCode = carsInfo[0].CARTYPECODE;
            this.CARSUBTYPECode = carsInfo[0].MODELCODE;
            this.CARSUBTYPEBRANDCODE = carsInfo[0].BRANDCODE;
            this.CARSUBTYPEMODELCODE = carsInfo[0].MODELCODE;
            this.CARSUBTYPESERIESCODE = carsInfo[0].SERIESCODE;
          }
        }
      });
    },
    getInfo() {
      let data = JSON.parse(sessionStorage.getItem("orderInfo"));
      console.log(
        JSON.parse(sessionStorage.getItem("orderInfo")),
        "JSON.parse(sessionStorage.getItem('orderInfo'))"
      );
      if (data) {
        this.dingjin = data.getSoMstrInfo.soDepost;
        this.kaipiaopersonName = data.getSoMstrInfo.actualOwnerCusName;
        console.log("--===", data.getSoMstrInfo.ISINVOICE);
        this.iskaipiaoperson =
          data.getSoMstrInfo.ISINVOICE == "1" ? "是" : "否";
        this.cardType = data.getSoMstrInfo.ACTUALCERTIFICATETYPE;
        this.address = data.getSoMstrInfo.clientAddress;
        this.cardID_Numer = data.getSoMstrInfo.ACTUALCERTIFICATENO;
        this.Phone = data.getSoMstrInfo.actualOwnerCusMobile;
        this.Location = data.getSoMstrInfo.clientProvinceName
          ? data.getSoMstrInfo.clientProvinceName +
            "/" +
            data.getSoMstrInfo.clientCityName +
            "/" +
            data.getSoMstrInfo.clientDistrictName
          : "";
        this.CustomerName = data.getSoMstrInfo.cusName;
        this.CustomercardType = data.getSoMstrInfo.cusCertificateType;
        this.CustomercardID_Numer = data.getSoMstrInfo.CUSCERTIFICATENO;
        this.CustomerPhone = data.getSoMstrInfo.cusMobile;
        this.Customeraddress = data.getSoMstrInfo.clientAddress;
        this.CustomerLocation = data.getSoMstrInfo.clientProvinceName
          ? data.getSoMstrInfo.clientProvinceName +
            "/" +
            data.getSoMstrInfo.clientCityName +
            "/" +
            data.getSoMstrInfo.clientDistrictName
          : "";
        this.soStatus = data.getSoMstrInfo.soStatus;
        this.shangpai = data.getSoMstrInfo.carIDRegistrationWayName;

        this.ershouchezh =
          data.getSoMstrInfo.isUsedCarDisplace == "0" ? "否" : "是";
        this.ATTACHMENTS_PATHS = data.ATTACHMENTS_PATHS || [];
        this.setImgUpload();
        this.approveType = data.getSoMstrInfo.ApprovalType;
        this.invoiceCompanyNo = data.getSoMstrInfo.invoiceCompanyOrgNo;
        this.jcDate = data.getSoMstrInfo.estimatedDeliveryCarDate.split(" ")[0];
        this.store2SNo = data.getSoMstrInfo.store2SNo;
        this.Order_remarks = data.getSoMstrInfo.remark;
        this.teshuYd = data.getSoMstrInfo.spc_agm;
        this.zhihuanPrice = data.getSoMstrInfo.usedCarPurchaseAmount;
        this.usedCarPurchaseNo = data.getSoMstrInfo.usedCarPurchaseNo;
        if (this.ENV == "SS")
          this.commission2S = data.getSoMstrInfo.commission2S;
        // this.cusNo = data.getSoMstrInfo.intentionNo;
        this.CUSNO = data.getSoMstrInfo.cusNo;
        this.so_subModel.soMstrInfo.actualOwnerCusNo =
          data.getSoMstrInfo.actualOwnerCusNo;
        this.so_subModel.soMstrInfo.soSalesPsnNo =
          data.getSoMstrInfo.soSalesPsnNo;
        // data.getSoMstrInfo.so2sOrgType = 'CUS'
        this.so2sOrgType = data.getSoMstrInfo.so2sOrgType == "CUS" ? 0 : 1;
        console.log("youwo ", data.getSoMstrInfo.so2sOrgType);
        if (this.ENV == "SS")
          this.onClick(data.getSoMstrInfo.so2sOrgType == "CUS" ? 0 : 1);
      }
    },
    kaipiaoRadio() {
      if (this.iskaipiaoperson != "是") {
        this.kaipiaopersonName = "";
        this.cardType = "";
        this.cardID_Numer = "";
        this.Location = "";
        this.address = "";
        this.Phone = "";
      }
    },
    GetUsedCar() {
      console.log(this.CUSNO, "this.CUSNO--GetUsedCar");
      GetUsedCarByCusNo({ cusNo: this.CUSNO }).then((res) => {
        console.log(res, "res.result");
        const result = JSON.parse(res.data.result);
        console.log(result, "result");
        if (result.length > 0) {
          this.usedCarPurchaseNo = result[0].USEDCARPURCHASENO; //二手车收购流水号
          this.zhihuanPrice = result[0].USEDCARPURCHASEAMOUNT; //二手车置换价格
        }
      });
    },
    setImgUpload() {
      this.fileList = [];
      this.ATTACHMENTS_PATHS.forEach((item) => {
        this.fileList.push({
          url: item.ATTA_PATH,
          isImage: true,
        });
      });
    },
  },
  created() {
    console.log(
      process.env.VUE_APP_BASE_UPLOAD_SWITCH,
      "process.env.VUE_APP_BASE_UPLOAD_SWITCH"
    );
    console.log(this.attachmentsSwitch, "attachmentsSwitch");
    window.afterReadAndroid = this.afterReadAndroid;
    window.cancelUpload = this.cancelUpload;

    let u = navigator.userAgent;
    console.log(navigator.userAgent, "navigator.userAgent");
    this.isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //g
    console.log("类型", this.ENV);
    let localStorageParams = null;
    this.MODELCODE = this.$route.query.MODELCODE;
    console.log(this.$route.params, "this.$route.params");
    if (this.$route.params.CARSUBTYPECode != null) {
      let params = this.$route.params;
      params.jcDate = params.jcDate ? params.jcDate.split(" ")[0] : "";
      // sessionStorage.setItem("otherInfo", JSON.stringify(params)); //存入 参数： 1.调用的值 2.所要存入的数据
      localStorageParams = JSON.parse(sessionStorage.getItem("otherInfo"));
      this.orderInfo = JSON.parse(sessionStorage.getItem("orderInfo"));
    } else {
      localStorageParams = JSON.parse(sessionStorage.getItem("temp"));
    }
    // if(localStorageParams){
    // }

    console.log("获取缓存数据", localStorageParams);
    this.orderNo = this.orderInfo ? this.orderInfo.getSoMstrInfo.OrderNo : "";
    this.cusNo = this.orderInfo ? this.orderInfo.getSoMstrInfo.cusNo : "";
    this.soNo = this.$route.query.soNo || this.$route.query.soNo;
    // this.soCarVin = localStorageParams.soCarVin ? localStorageParams.soCarVin : this.orderInfo ? this.orderInfo.getCarInfo.soCarVin : ''
    this.soCarVin = localStorageParams.soCarVin
      ? localStorageParams.soCarVin
      : "";
    this.EMPLOYEE_NO = localStorageParams.EMPLOYEE_NO;
    this.INTENTION_NO = localStorageParams.INTENTION_NO;

    this.jcDate = localStorageParams.jcDate;
    this.shangpai = localStorageParams.shangpai;

    this.saleTypeName = this.orderInfo
      ? this.orderInfo.getSoMstrInfo.saleTypeName
      : "";
    this.saleChannelName = this.orderInfo
      ? this.orderInfo.getSoMstrInfo.saleChannelName
      : "";
    if (this.saleTypeName != undefined || this.saleChannelName != undefined) {
      this.qudao = this.saleTypeName
        ? this.saleTypeName + "/" + this.saleChannelName
        : "";
    }
    this.SERIESCODE = localStorageParams.SERIESCODE;
    this.MODELCODE = localStorageParams.MODELCODE;
    // console.log("我呢", this.orderInfo.getSoMstrInfo.isDirectSale);
    this.CARSUBTYPECode = localStorageParams.CARSUBTYPECode;
    this.CarTypeCode = localStorageParams.CARTYPECODE;
    this.CARSUBTYPEName = localStorageParams.CARSUBTYPEName;
    this.ISDIRECTSALE = localStorageParams.ISDIRECTSALE;
    this.CARSUBTYPEMSRP = localStorageParams.Barecar_price;
    this.AppearanceColoron = localStorageParams.AppearanceColoron;
    this.InteriorColor = localStorageParams.InteriorColor;
    if (localStorageParams.ZH_item != "[]") {
      this.ZH_item = JSON.parse(localStorageParams.ZH_item);
    }
    if (localStorageParams.Bx_item != "[]") {
      this.Bx_item = JSON.parse(localStorageParams.Bx_item);
    }

    if (localStorageParams.arrgoodsItems != "[]") {
      this.arrgoodsItems = JSON.parse(localStorageParams.arrgoodsItems);
    }
    if (localStorageParams.arrInsOrderItems != "[]") {
      this.arrInsOrderItems = localStorageParams.arrInsOrderItems
        ? JSON.parse(localStorageParams.arrInsOrderItems)
        : [];
    }
    if (localStorageParams.arrloanOrder != "[]") {
      this.arrloanOrder = JSON.parse(localStorageParams.arrloanOrder);
      this.jinrong_OrgNo = this.arrloanOrder[0].productProviderNo;
      this.jingrong_fangan = localStorageParams.jingrong_fangan;
      this.sfjine = this.arrloanOrder[0].firstPayAmount;
      this.cusSelfpayInterestAmt = this.arrloanOrder[0].cusSelfpayInterestAmt;
    }
    if (localStorageParams.arrewOrders != "[]") {
      this.arrewOrders = JSON.parse(localStorageParams.arrewOrders);
      this.supplierName = localStorageParams.supplierName;
      this.EW_Pirce = this.arrewOrders.reduce(
        (sum, e) => sum + Number(e.ewAmount || 0),
        0
      );
    }
    if (localStorageParams.arrdbpushList != "[]") {
      this.arrdbpushList = JSON.parse(localStorageParams.arrdbpushList);
    }

    this.YB_item = JSON.parse(localStorageParams.YB_item);
    this.DB_item = JSON.parse(localStorageParams.DB_item);
    this.JR_item = JSON.parse(localStorageParams.JR_item);
    this.ZCAmount = localStorageParams.ZCAmount;
    this.ZH_Pirce = localStorageParams.ZH_Pirce;
    this.Insurance_item_name = localStorageParams.Insurance_item_name;
    this.Insurance_Pirce = localStorageParams.Insurance_Pirce;
    if (
      this.Bx_item &&
      this.Bx_item.reduce((sum, e) => sum + Number(e.premiumAmount || 0), 0) > 0
    ) {
      this.bx_show = true;
    }
    if (
      this.Bx_item &&
      this.DB_item.reduce((sum, e) => sum + Number(e.dbPrice || 0), 0) > 0
    ) {
      this.db_show = true;
    }
    this.Financial_Price = localStorageParams.Financial_Price;
    this.agency_Price = localStorageParams.agency_Price;
    this.iskaipiaoperson = this.orderInfo
      ? this.orderInfo.getSoMstrInfo.ISINVOICE == "1"
        ? "是"
        : "否"
      : "是";
    this.ershouchezh = "是";
    this.kaipiaoType = this.orderInfo
      ? this.orderInfo.getSoMstrInfo.ACTUALCUSISPERSON == "公司"
        ? "企业"
        : "个人"
      : "个人";
    this.customerType = this.orderInfo
      ? this.orderInfo.getSoMstrInfo.CUSISPERSON == "公司"
        ? "企业"
        : "个人"
      : "个人";
    this.approveType = localStorageParams.approveType || "开票申请";
    this.AppearanceColoronCode = localStorageParams.AppearanceColoronCode;
    this.InteriorColorCode = localStorageParams.InteriorColorCode;
    this.INS_BRANCHNO = localStorageParams.INS_BRANCHNO;

    // this.CARSUBTYPEName = carsInfo[0].MODELNAME;
    // this.CarTypeCode = carsInfo[0].CARTYPECODE;
    // this.CARSUBTYPECode = carsInfo[0].MODELCODE;
    // this.CARSUBTYPEBRANDCODE = carsInfo[0].BRANDCODE;
    // this.CARSUBTYPEMODELCODE = carsInfo[0].MODELCODE;
    // this.CARSUBTYPESERIESCODE = carsInfo[0].SERIESCODE;
    this.GetSubTypeBySeries();

    if (!this.soNo) {
      console.log("2", !this.soNo);
      if (this.$route.query.INTENTION_NO) this.GetCusIntention();
      if (this.$route.query.OUT_SRC_CUS_NO) this.GetCustomerInfoByCusNo();
    }

    if (this.ENV != "SS") this.GetTo2SOrg();

    this.GetCertificateType();
    this.GetSaleType();
    this.GetBasValueByTypeASC();
    this.GetEmployeeInfo();
    this.getInfo();
    this.showBack = this.$route.query.isAndroidOrIOS != "WECHAT";
  },
  watch: {
    kaipiaoType: {
      handler: function (newVal, oldVal) {
        console.log("0", newVal, oldVal);
        if (oldVal) {
          // this.kaipiaopersonName = '';
          // this.cardType = '';
          // this.cardID_Numer = '';
          // this.Location = '';
          // this.address = '';
          // this.Phone = ''
        }
      },
      deep: true,
    },
    Phone: {
      handler: function (newVal, oldVal) {
        console.log("0", newVal, oldVal, this.iskaipiaoperson);
        let data = JSON.parse(sessionStorage.getItem("orderInfo"));
        let _vm = this;
        _vm.so_subModel.soMstrInfo.actualOwnerCusNo = "";
        if (
          newVal == this.CustomerPhone &&
          this.kaipiaoType == "个人" &&
          this.iskaipiaoperson == "否"
        ) {
          this.$dialog
            .confirm({
              confirmButtonText: "设置",
              cancelButtonText: "取消",
              message: "开票人和客户手机号一致，是否设置客户为开票人?",
            })
            .then(() => {
              console.log("点击了确认按钮噢", _vm.Phone, this.iskaipiaoperson);
              this.iskaipiaoperson = "是";
              _vm.kaipiaopersonName = _vm.CustomerName;
              _vm.cardType = _vm.CustomercardType;
              _vm.cardID_Numer = _vm.CustomercardID_Numer;
              _vm.Location = _vm.CustomerLocation;
              _vm.address = _vm.Customeraddress;
              _vm.Phone = _vm.CustomerPhone;
              _vm.so_subModel.soMstrInfo.actualOwnerCusNo = _vm.CUSNO;
            })
            .catch(() => {
              // on cancel
              console.log("设置2");
              _vm.Phone = "";
            });
          console.log(1);
        }
        if (newVal == data.getSoMstrInfo.actualOwnerCusMobile) {
          _vm.so_subModel.soMstrInfo.actualOwnerCusNo =
            data.getSoMstrInfo.actualOwnerCusNo;
        }
      },
      deep: true,
    },
    ershouchezh: {
      handler: function (newVal) {
        if (newVal === "是") {
          this.GetUsedCar();
        } else {
          console.log("清除二手车置换信息");
          this.usedCarPurchaseNo = ""; //二手车收购流水号
          this.zhihuanPrice = 0.0; //二手车置换价格
        }
      },
    },
    //初始化二手车门店
    CUSNO: {
      handler: function (newVal) {
        if (newVal !== "") {
          if (this.usedCarPurchaseNo === "" || !this.usedCarPurchaseNo) {
            this.GetUsedCar();
          }
        }
      },
    },
  },
};
</script>
<style lang="less" scoped>
.PlaceAnOrder {
  background: #fcfcfc;
  display: flex;
  flex-direction: column;
  height: 100%;

  .content {
    flex: 1;
    padding-bottom: 10px;
    overflow: scroll;
    height: calc(100vh - 144px);
    background: #f2f2f2;
  }

  .vehicleInformationCard {
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  }

  .topInfo {
    border-radius: 10px 10px 0 0;
    white-space: initial;
    flex: none;

    .carSubTypeName {
      font-size: 18px;
      font-weight: bold;
    }

    .info {
      font-size: 12px;
      word-wrap: break-word;
      overflow: scroll;
      margin-top: 10px;
      color: #5b5b5b;
      line-height: 25px;
    }
  }

  .price {
    padding: 10px 16px;
    line-height: 25px;
    font-size: 12px;
    color: #5b5b5b;
    word-wrap: break-word;

    > div {
      display: flex;
      justify-content: space-between;
    }
  }

  .bottomInfo {
    .van-cell__value {
      color: #323233;
    }
  }

  .grey {
    color: #6b6b71 !important;
  }

  .orange {
    color: @themeColor !important;
  }

  .titleBlock {
    color: #b4b5b9;
    background: #f3f4f6;
    display: flex;
    align-items: center;
  }
}

.tipsCenter {
  font-size: 12px;
  width: 150px;
  padding: 10px;
}

.ID_Numer {
  /deep/ .van-field__value {
    display: flex;
    justify-content: space-between;

    .van-field__body {
      flex: 1;
      margin-right: 5px;
    }
  }
}

.popupClass {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.popupContent {
  overflow: scroll;
}

.orderTotal {
  flex: none;
}

.footerButton {
  // position: fixed;
  // bottom: 0;
  width: 100%;
  padding: 16px;
  display: flex;
  background: #fff;
}

.popupTitle {
  font-size: 14px;
  font-weight: bold;
  flex: none;

  .van-cell__value {
    text-align: center;
    padding-top: 2px;
  }
}

.titleActive {
  color: @themeColor;
}
.upload_div {
  background-color: #fff;
  height: 100px;
}
.uploader_android {
  padding: 20px 0px 20px 15px;
  float: left;
}
.van-uploader__uploadAndroid {
  margin-top: 20px;
  float: left;
  width: 1.3rem;
  height: 1.3rem;
}
.uploader {
  padding: 20px 15px;
}
</style>
