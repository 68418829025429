/*
 //Author: 杨强
 //Date: 2023-04-17 10:05:07
 //LastEditTime: 2023-09-06 10:24:57
 //LastEditors: 杨强
 //Description: 
 //FilePath: \bzm-sales-h5 - 副本\src\api\PlaceAnOrder.js
 */
import request from "../../utils/request";

//二网机构
// export const GetTo2SOrg = data => request('/BAS/BaseInfo/GetTo2SOrg', data, 'POST')
export function GetTo2SOrg(data) {
  return request({
    url: '/BAS/BaseInfo/GetTo2SOrg',
    method: 'post',
    data
  })
}

//获取证件类型
// export const GetCertificateType = data => request('/BAS/BaseInfo/GetCertificateType', data, 'POST')
export function GetCertificateType(data) {
  return request({
    url: '/BAS/BaseInfo/GetCertificateType',
    method: 'post',
    data
  })
}

//获取销售类型和销售渠道

// export const GetSaleType = data => request('BAS/BaseInfo/GetSaleType', data, 'POST')
export function GetSaleType(data) {
  return request({
    url: 'BAS/BaseInfo/GetSaleType',
    method: 'post',
    data
  })
}


//查询意向信息
// export const GetCusIntention = data => request('/SalesOrder/BAS/GetCusIntention', data, 'POST')
export function GetCusIntention(data) {
  return request({
    url: '/SalesOrder/BAS/GetCusIntention',
    method: 'post',
    data
  })
}


//保存订单接口
// export const SaveCarSalesOrder = data => request('/Saas/SaveCarSalesOrder', data, 'POST')
export function SaveCarSalesOrder(data) {
  return request({
    url: '/Saas/SaveCarSalesOrder',
    method: 'post',
    data
  })
}



//保存订单接口
// export const GetEmployeeInfo = data => request('SalesOrder/BAS/GetEmployeeInfo', data, 'POST')
export function GetEmployeeInfo(data) {
  return request({
    url: 'SalesOrder/BAS/GetEmployeeInfo',
    method: 'post',
    data
  })
}

//新增获取上牌方式
export function GetBasValueByTypeASC(data) {
  return request({
    url: 'BAS/BaseInfo/GetBasValueByTypeASC',
    method: 'post',
    data
  })
}

//二网机构
export function Get2SOrgNameByType(data) {
  return request({
    url: 'Saas/Get2SOrgNameByType',
    method: 'post',
    data
  })
}

// 客户
export function GetCustomerInfoByCusNo(data) {
  return request({
    url: 'CRM/Customer/GetCustomerInfoByCusNo',
    method: 'post',
    data
  })
}

// 根据客户编码获取二手车收购记录
export function GetUsedCarByCusNo(data) {
  return request({
    url: '/CRM/Customer/GetUsedCarByCusNo',
    method: 'post',
    data
  })
}









