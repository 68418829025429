<!--
 * /*Author: 杨强
 * /*Date: 2023-10-10 17:11:55
 * /*LastEditTime: 2023-10-12 14:18:30
 * /*LastEditors: 杨强
 * /*Description: 
 * /*FilePath: \bzm-sales-h5 - 副本\src\views\DeliveryList\DeliveryList.vue
-->
<template>
  <div class="orderList">
    <!-- 搜索框 -->
    <van-nav-bar title="标题" :left-arrow="showBack" @click-left="goBack">
      <template #title>
        <form action="/">
          <van-search v-model="value" shape="round" background="none" placeholder="搜索" @search="SearchDeliveryList"
            @blur="SearchDeliveryList" @clear="SearchDeliveryList" @cancel="SearchDeliveryList"
            @keypress="SearchDeliveryList" />
        </form>
      </template>
      <van-icon name="filter-o" slot="right" @click="openPop">筛选</van-icon>
    </van-nav-bar>
    <van-tabs @click="SelectiveState" v-model="active" sticky swipeable>
      <van-tab v-for="items in list" :key="items" :title="items">
      </van-tab>
    </van-tabs>
    <van-cell :style="{ background: 'transparent', flex: 'none', }" :title="`共${totalCount}个订单`"></van-cell>
      <van-list v-model="loading" :finished="finished" :immediate-check="false" finished-text="没有更多了"
        @load="GetDeliveryList" class="DeliveryVanlist" offset="20">
        <van-panel v-for="item in so_List" :key="item.SO_NO" :title="`计划时间${item.SO_CAR_FCST_DELIVERY_DATE} `"
          :status="item.STATUS" class="panel-item" @click="SkipPage(item)">
          <van-cell :title="`客户姓名：${item.CUS_NAME}`" :title-style="{ fontSize: '16px', fontWeight: 'bold' }"
            :border="false" class="padding-bot0"></van-cell>
          <van-cell :title="`${item.TYPE_NAME} ${item.SUB_TYPE_NAME}`"
            :title-style="{ fontSize: '16px', fontWeight: 'bold' }" :border="false" class="padding-top0"></van-cell>
          <van-cell class="grey" :title="`VIN码：${item.VIN || ''}`" :border="false"></van-cell>
          <van-cell class="grey" :title="`订单归属：${item.SALE_NAME} | 门店名称：${item.STORE_NAME}`" :border="false"></van-cell>
          <van-cell title=" ">
            <van-button round plain hairline type="warning" size="small" style="padding:0 10px;height:30px"
              @click.stop="delivery(item)" v-if="item.STATUS == '待交车'" :disabled="displayButton">交车</van-button>
          </van-cell>
        </van-panel>
      </van-list>

    <van-popup closeable @closed='closePop' v-model="isShowDecorationScreening" position="right"
      :style="{ height: '100vh', width: '100%' }" class="popup-class">
      <div class="content">
        <van-cell class="popup-title" title="筛选" />
        <van-cell title="销售顾问" is-link :value="model.SALE_INFO.SALE_NAME || '请选择'" @click="selectSale()" />
        <van-cell title="开票时间" is-link :value="model.kpTime || '请选择'" @click="selectTime('kpTime')" />
        <van-cell title="预计交车时间" is-link :value="model.jcTime || '请选择'" @click="selectTime('jcTime')" />
        <van-cell title="完成交车时间" is-link :value="model.wcTime || '请选择'" @click="selectTime('wcTime')" />
        <van-calendar v-model="show" type="range" @confirm="onConfirm" :min-date="minDate" :max-date="maxDate"
          :allow-same-day="true" />
      </div>
      <div class="footer-button">
        <van-row gutter="10" style="width:100%">
          <van-col span="4" class="reset-button button" @click="closeDecoration">重置</van-col>
          <van-col span="19" class="confirm-button button" @click="confirmationSearch">确定</van-col>
        </van-row>
      </div>
    </van-popup>
    <van-popup closeable @closed='closeSaleList' v-model="isShowSaleList" position="right"
      :style="{ height: '100vh', width: '100%' }" class="popup-class">
      <div class="content">
        <van-cell class="popup-title" title="销售列表" />
        <van-search v-model="saleSearch" shape="round" background="none" placeholder="搜索" @search="SearchSaleList"
          @blur="SearchSaleList" @clear="SearchSaleList" @cancel="SearchSaleList" @keypress="SearchSaleList" />
        <div class="body-content">
          <van-list v-model="saleLoading" :finished="saleFinished" :immediate-check="false" finished-text="没有更多了"
            @load="GetSaleList" offset="20" class="sale-list" style="padding:0">
            <van-cell v-for="item in saleList" :key="item.SALE_NO" :title="item.SALE_NAME"
              :class="[SALE_INFO == item ? 'titleActive' : '']" @click="changeSale(item)" />
          </van-list>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { GetDeliveryList, GetSaleList, GetDeliveryConfirmation,SaveDeliveryConfirmationNew } from "../../api/DeliveryList";
export default {
  data () {
    return {
      showBack:true,
      list: ['全部', '待交车', '已交车', '已关闭'],
      active: 1,
      isShowDecorationScreening: false,
      isShowSaleList: false,
      isLoading: false,
      so_List: [],
      displayButton: false,
      value: "",
      loading: false,
      finished: false,
      minDate: new Date(), //日期可选最小值
      maxDate: new Date(), //日期可选最大值
      pageSize: {
        hasMore: true,
        pagesize: 10,
        pagestart: 1,
        totalCount: 0
      },
      totalCount: 0,
      SEARCH_STR: "", //模糊搜索条件
      STATUS: "",//交车状态
      SALE_NAME: "",//销售顾问
      STORE_NO: "",//门店编码

      timeTitle: '',
      kpTime: '',
      jcTime: '',
      wcTime: '',
      show: false,
      SALE_INFO: {},

      saleLoading: false,
      saleFinished: false,
      saleList: [],
      saleSearch: '',
      model: {
        kpTime: '', jcTime: '', wcTime: '', timeTitle: '', SALE_INFO: []
      }
    };
  },
  mounted () { },
  methods: {

    async GetSaleList (length) {
      const params = {
        STORE_NO: this.$route.query.Store_No,
        EMPLOYEE_NO: this.$route.query.EMPLOYEE_NO,
        SALE_NAME: this.saleSearch,
        iDisplayStart: length == 0 ? 0 : this.saleList.length,
        iDisplayLength: 16,
      }
      await GetSaleList(params).then(res => {

        if (res.data.IsSuccess)
        {
          if (length == 0) { this.saleList = res.data.result } else { this.saleList.push(...res.data.result); }
          if (res.data.msg.hasMore) this.saleFinished = false;
          if (!res.data.msg.hasMore) this.saleFinished = true;
        } else
        {
          this.$toast(res.data.msg)
          this.saleList = []
        }
        this.saleLoading = false;
      })
    },

    async GetDeliveryList (length) {
      const params = {
        iDisplayStart: length == 0 ? 0 : this.so_List.length,
        iDisplayLength: 10,

        SEARCH_STR: this.value,
        STATUS: this.list[this.active],
        SALE_NO: this.SALE_INFO.SALE_NO || this.$route.query.EMPLOYEE_NO,
        // SALE_NO: 'all',
        USER_NO: this.$route.query.EMPLOYEE_NO,
        STORE_NO: this.$route.query.Store_No,
        KP_DATE_START: this.kpTime ? this.kpTime.split(' - ')[0] + ' 00:00:00' : '',
        KP_DATE_END: this.kpTime ? this.kpTime.split(' - ')[1] + ' 23:59:59' : '',
        YJJC_DATE_START: this.jcTime ? this.jcTime.split(' - ')[0] + ' 00:00:00' : '',
        YJJC_DATE_END: this.jcTime ? this.jcTime.split(' - ')[1] + ' 23:59:59' : '',
        JC_DATE_START: this.wcTime ? this.wcTime.split(' - ')[0] + ' 00:00:00' : '',
        JC_DATE_END: this.wcTime ? this.wcTime.split(' - ')[1] + ' 23:59:59' : '',

      }
      await GetDeliveryList(params).then((res) => {

        if (res.data.IsSuccess)
        {
          console.log(params,"GetDeliveryList.params")
          //返回结果与选中tab不一致时不渲染列表数据（修复： 待交车数据返回太慢，选中交车后会再次渲染待交车数据）
          if(params.STATUS== this.list[this.active]){
            if (length == 0) { this.so_List = res.data.result } else { this.so_List.push(...res.data.result) }
            this.totalCount = res.data.msg.totalCount;
            if (res.data.msg.hasMore) this.finished = false;
            if (!res.data.msg.hasMore) this.finished = true;
          }
          
        } else
        {
          this.$toast(res.data.msg)
          this.so_List = []
        }
        this.loading = false;
        this.displayButton = false
      }).catch(() => {
        this.finished = false;
        this.loading = false;
      })

    },
    async GetDeliveryConfirmation (NO) {
      this.$store.commit('showLoading');
      const params = {
        BIZ_REF_NO: NO,
        USER_ID:this.$route.query.EMPLOYEE_NO
      }
      //商社客制化---直接交车
      if(this.ENV == "SS"){
        await SaveDeliveryConfirmationNew(params).then(res => {
          console.log(res,"商社客制化---直接交车")
          this.$toast(res.data.msg)
          if(res.data.IsSuccess){
            this.GetDeliveryList(0)
          }
        })
      }else{
        await GetDeliveryConfirmation(params).then(res => {
        this.$store.commit('hideLoading');
        if (!res.data.IsSuccess)
        {
          this.$dialog.alert({
            message: res.data.msg,
          })
        } else
        {
          this.$router.push({
            path: "/DeliveryList/DeliveryConfirmationForm",
            query: {
              ...this.$route.query,
              soNo: NO,
            },
          });
        }
        this.displayButton = false
      })
      }
      
    },
    onRefresh () {
      this.GetDeliveryList().then(() => {
        this.isLoading = false;
      })
    },
    SelectiveState (index) {
      this.active = index
      this.so_List = []
      this.GetDeliveryList(0)
    },
    SearchDeliveryList () {
      this.GetDeliveryList(0)
    },
    SearchSaleList () {
      this.GetSaleList(0)
    },
    delivery (item) {
      this.displayButton = true
      this.GetDeliveryConfirmation(item.SO_NO)
      console.log("---");
    },
    SkipPage (item) {
      //商社客制化-交车后不跳转交车确认单页面
      if (this.ENV != "SS") {
        if (item.STATUS != '待交车' && item.STATUS != '已退单') {
          this.$router.push({
            path: "/DeliveryList/DeliveryConfirmationForm",
            query: {
              ...this.$route.query,
              soNo: item.SO_NO,
            },
          });
        }
      }
      

    },
    selectTime (title) {
      this.timeTitle = title
      this.show = true
    },
    selectSale () {
      this.GetSaleList(0)
      this.isShowSaleList = true
    },
    changeSale (item) {
      this.isShowSaleList = false
      // if (!item) item = { SALE_NO: "all", SALE_NAME: "全部" };
      this.model.SALE_INFO = item
      console.log("选择", item);
    },
    closeSaleList () {
      this.isShowSaleList = false
    },
    formatDate (date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    onConfirm (date) {
      const [start, end] = date;
      this.show = false;
      this.$data.model[this.timeTitle] = `${this.formatDate(start)} - ${this.formatDate(end)}`;
    },
    confirmationSearch () {
      this.kpTime = this.model.kpTime
      this.jcTime = this.model.jcTime
      this.wcTime = this.model.wcTime
      this.timeTitle = this.model.timeTitle
      this.SALE_INFO = this.model.SALE_INFO
      this.GetDeliveryList(0)
      this.isShowDecorationScreening = false;
    },
    closeDecoration () {
      this.model = { kpTime: '', jcTime: '', wcTime: '', timeTitle: '', SALE_INFO: [] }
      this.kpTime = ''
      this.jcTime = ''
      this.wcTime = ''
      this.timeTitle = ''
      this.SALE_INFO = []
      console.log("关闭");
      this.GetDeliveryList(0)
    },
    closePop () {
      this.model = { kpTime: '', jcTime: '', wcTime: '', timeTitle: '', SALE_INFO: [] }
      this.isShowDecorationScreening = false
      this.GetDeliveryList(0)
    },
    openPop () {
      this.isShowDecorationScreening = true
      this.model = { kpTime: this.kpTime, jcTime: this.jcTime, wcTime: this.wcTime, timeTitle: this.timeTitle, SALE_INFO: this.SALE_INFO }
    },
    goBack () {
      this.backforward()
    },
    backforward () {
      // 返回APP菜单中心页面
      let u = navigator.userAgent
      let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      try
      {
        if (isAndroid)
        {
          window.android.webClose(); // 调用原生app的函数--webClose是安卓提供的方法
        }
        if (isIOS)
        {
          window.webkit.messageHandlers.webClose.postMessage(""); //--webClose是ios提供的方法
        }
      } catch (e)
      {
        window.history.back()
      }

    },


  },
  watch: {
  },
  computed: {},
  created () {
    let nowDat = new Date();
    let dateY = nowDat.getFullYear();
    let dateM = nowDat.getMonth();
    let dateD = nowDat.getDate();
    // 设置日期可选最小值minDate、最大值maxDate
    this.minDate = new Date('2020', '00', '01'),
      //日历可选范围为一年，dateY + 1
      this.maxDate = new Date(dateY + 1, dateM, dateD);
    this.GetDeliveryList(0)
    this.showBack = this.$route.query.isAndroidOrIOS != 'WECHAT' 
  },
};
</script>

<style lang="less" scoped>
::v-deep .van-search {
  background: none;
}
// .van-list {
//   height: calc(100vh - 134px) !important;
// }

.orderList {
  position: absolute;
  height: 100%;
  width: 100%;
  // height: 100vh;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;

  .DeliveryVanlist {
    padding: 16px;
    padding-top: 0;
    overflow: scroll;
    height: calc(100vh - 134px) !important;
  }
  .panel-item {
    margin-bottom: 16px;
    border-radius: 10px;

    .van-panel__header {
      border-radius: 10px;

      .van-cell__title {
        color: #5e5e5e;
        width: 70%;
        flex: none;
      }
    }

    .van-cell::after {
      content: none;
    }

    .padding-top0 {
      padding-top: 0;
    }

    .padding-bot0 {
      padding-bottom: 0;
    }

    .van-hairline--top::after {
      border-top-width: 0;
    }
  }

  .grey {
    color: #5e5e5e;
    padding-top: 0px;
    padding-bottom: 0px;

    .van-cell__value--alone {
      color: #5e5e5e;
    }

    .van-tag {
      font-weight: bold;
    }
  }

  .content {
    background: #f2f2f1;

    .user_img {
      width: 90px;
      height: 90px;
      border: 5px solid #f5f5f5;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .body-content {
      height: calc(100vh - 110px);
      background: #fff;
      padding: 10px;
      font-size: 12px;

      /deep/.sale-list {
        overflow: scroll;
        height: calc(100vh - 180px);
      }
    }

    .user_name {
      margin-top: 10px;
      font-size: 24px;
      font-weight: 500;
      color: #000001;
    }

    .user_store {
      margin-top: 10px;
      font-size: 15px;
      font-weight: 400;
      color: #666666;
      background: #f5f5f5;
      padding: 7px 20px;
      border-radius: 30px;

      .aaa {
        border: 0;
        background: none;
        font-size: 15px;
        width: 100%;
      }
    }
  }
}

/*滚动条样式*/
/deep/ .scrollbar {
  white-space: nowrap;

  .el-scrollbar {
    display: flex;
    justify-content: space-around;
    padding: 0 10px;
  }

  /deep/ .el-scrollbar__wrap {
    overflow: scroll;
    width: 110%;
    height: 100vh;
  }

  .container {
    height: 60vh;
    overflow: auto;
  }
}

.popup-class {
  display: flex;
  flex-direction: column;
}

.content {
  width: 100%;
  height: calc(100vh - 65px);
  background: #fff;

  .popup-title {
    font-size: 14px;
    font-weight: bold;
    flex: none;

    .van-cell__title {
      text-align: center;
      padding-top: 2px;
    }
  }
}

.footer-button {
  display: flex;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #ddd;
  padding: 10px 20px;

  .button {
    font-size: 12px;
    padding: 10px;
    border-radius: 2px;
    text-align: center;
  }

  .reset-button {
    background: #e2e2e2;
    color: #000;

    margin-right: 10px;
  }

  .confirm-button {
    background: #f7772c;
    color: #fff;
  }

}

.titleActive {
  color: #f7772c;
}</style>
