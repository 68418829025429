/*
 //Author: 杨强
 //Date: 2023-09-20 14:43:14
 //LastEditTime: 2023-11-01 11:28:30
 //LastEditors: 杨强
 //Description: 
 //FilePath: \bzm-sales-h5 - 副本\utils\requestUpload.js
 */
import axios from "axios";

const service = axios.create({
  baseURL:  process.env.VUE_APP_BASE_UPLOAD,
  timeout: 100000
})


export default service;
