<!--
 * /*Author: 杨强
 * /*Date: 2023-08-03 11:44:15
 * /*LastEditTime: 2023-10-27 15:28:21
 * /*LastEditors: 杨强
 * /*Description: 
 * /*FilePath: \bzm-sales-h5 - 副本\src\views\LockCarList\NoLockCarInfo.vue
-->
<template>
  
  <div class="InvCarInfo">
    
    <van-nav-bar title="车辆详情" :left-arrow="showBack" @click-left="goBack">
    </van-nav-bar>
    <div class="content">
    <van-cell :title="`${carInfo.CAR_TYPE} ${carInfo.CAR_SUBTYPE}`"
      :title-style="{ fontSize: '16px', fontWeight: 'bold' }" :border="false" class="padding-top0"></van-cell>
    <van-cell class="grey" :title="`外观${carInfo.CAR_COLOR} | 内饰${carInfo.CAR_INNER_COLOR} | ${carInfo.CAR_STATUS}`"
      :border="false"></van-cell>
    <van-cell class="grey" :title="`销售指导价：${carInfo.IN_PRICE ? `${(parseInt(carInfo.IN_PRICE) / 10000).toFixed(2)}万` : 0}`"
      :border="false"></van-cell>
    <div class="btnDiv">
      <van-button size="small" disabled plain type="primary" style="margin-right: 20px;">库存{{ carInfo.INV_REC_DAYS
        || '0' }}天</van-button>
      <van-button size="small" disabled type="default" style="margin-right: 20px;">车龄{{
        carInfo.INV_PUR_DAYS == '0' ? '无' : `${carInfo.INV_PUR_DAYS}天` }}</van-button>
      <van-button size="small" disabled type="default" style="margin-right: 20px;">{{
        carInfo.INV_STATUS }}</van-button>
    </div>
    <van-cell class="cellPanel">
      <div class="grey" :style="{ fontSize: '16px' }">
        <van-row>
          <van-col span="8">Vin</van-col>
          <van-col span="16"> {{ carInfo.VIN }} </van-col>
        </van-row>
      </div>
    </van-cell>

    <van-panel :style="{ border: 'none', margin: '10px 0 0 0', padding: '10px 0px' }">
      <template #header>
        <van-cell class="topInfo">
          <div class="carName">车辆信息</div>
        </van-cell>
      </template>
      <div class="bottomInfo van-cell__value_left">
        <van-cell class="grey" title="车龄" :value="`${carInfo.INV_PUR_DAYS}天`" :border="false" />
        <van-cell class="grey" title="质损日期" :value="carInfo.ZHISUN_DATE || '-'" :border="false" />
        <van-cell class="grey" title="出厂日期" :value="carInfo.OUT_FACTORY_DATE || '-'" :border="false" />
        <van-cell class="grey" title="发动机号" :value="carInfo.ENGINE_NO || '-'" :border="false" />
        <van-cell class="grey" title="合格证号" :value="carInfo.CERTIFICATENO || '-'" :border="false" />
        <van-cell class="grey" title="增配信息" :value="carInfo.ZP || '-'" :border="false" />
        <van-cell class="grey" title="前装信息" :value="carInfo.QZ || '-'" :border="false" />
      </div>
    </van-panel>

    <van-panel :style="{ border: 'none', padding: '10px 0px' }">
      <template #header>
        <van-cell class="topInfo">
          <div class="carName">库存信息</div>
        </van-cell>
      </template>
      <div class="bottomInfo van-cell__value_left">
        <van-cell title="入库日期" :value="carInfo.INV_CREATE_DATE || '-'" :border="false" />
        <van-cell title="出库日期" :value="carInfo.SALES_DATE || '-'" :border="false" />
        <van-cell title="存放位置" :value="`${carInfo.INV_WAREHOUSE} - ${carInfo.INV_LOCATION}`" :border="false" />
        <van-cell title="钥匙号" :value="carInfo.KEY_NO !== '' ? carInfo.KEY_NO : '-'" :border="false" />
      </div>
    </van-panel>

    <van-panel :style="{ border: 'none', padding: '10px 0px' }">
      <template #header>
        <van-cell class="topInfo">
          <div class="carName">采购信息</div>
        </van-cell>
      </template>
      <div class="bottomInfo van-cell__value_left">
        <van-cell title="供应商" :value="carInfo.CONTRACT_PART_A || '-'" :border="false" />
        <van-cell title="采购价" :value="carInfo.IN_PRICE || '-'" :border="false" />
        <van-cell title="税率" :value="carInfo.TAX_RATE || '-'" :border="false" />
        <van-cell title="资金来源" :value="carInfo.MONEYSOURCE || '-'" :border="false" />
        <van-cell title="采购单号" :value="carInfo.CONTRACT_PUR_NO || '-'" :border="false" />
        <van-cell title="采购日期" :value="carInfo.IN_STORE_DATE || '-'" :border="false" />
        <van-cell title="付款日期" :value="carInfo.PAY_DATE || '-'" :border="false" />
        <van-cell title="免息截止日期" :value="carInfo.FREEINTERESTDATE || '-'" :border="false" />
      </div>
    </van-panel>
    </div>
  </div>
</template>
<script>
import { GetAvailableCarInvInfo, GetCarInvIncreasedDet } from "../../api/LockCarList";
export default {
  data () {
    return {
      showBack:true,
      vin: '',
      Store_No: '',
      carInfo: {
        INV_ID: "",
        VIN: "",
        INV_WAREHOUSE: "",
        INV_STATUS: "",
        INV_CREATE_DATE: "",
        SALES_DATE: "",
        INV_LOCATION: "",
        SO_NO: "",
        IS_LOCK: "0",
        IN_PRICE: "",
        OUT_FACTORY_DATE: "",
        STD_PUR_PRICE: "",
        REC_PRICE: "",
        REC_DATE: "",
        PAY_DATE: "",
        SOURCE_FROM: "",
        CAR_PARTS_AMT: "0",
        CAR_COLOR: "",
        CAR_INNER_COLOR: "",
        KEY_NO: "",
        ENGINE_NO: "",
        CERTIFICATENO: "",
        CAR_STATUS: "",
        INV_PUR_DAYS: "0",
        INV_REC_DAYS: "",
        MONEYSOURCE: "",
        CONTRACT_PART_A: "",
        TAX_RATE: "",
        CONTRACT_PUR_NO: "",
        IN_STORE_DATE: "",
        ZHISUN_DATE: "",
        FREEINTERESTDATE: "",
      }
    }
  },
  methods: {
    GetCarInfo () {
      const param = {
        storeNo: this.Store_No || '2000200',
        vin: this.vin
      }
      GetAvailableCarInvInfo(param).then(res => {
        this.carInfo = res.data.result[0]
      })
    },
    goBack () {
      if(this.showBack){
        //返回上一页
        this.$router.go(-1);
      }
    },
    // 获取配车/锁车车辆增配及前装信息
    CarInvIncreased () {
      const param = {
        shop_code: this.Store_No || '2000200',
        vin: this.vin
      }
      GetCarInvIncreasedDet(param).then(res => {
        console.log(res.data, 'res.data')
        if (res.data.result.list.count > 0)
        {
          this.carInfo.ZP = "是";
          this.carInfo.QZ = "是"
        } else
        {
          this.carInfo.ZP = "";
          this.carInfo.QZ = ""
        }

      })
    }
  },
  created () {
    this.vin = this.$route.query.vin;
    this.Store_No = this.$route.query.Store_No;
    this.GetCarInfo();
    this.CarInvIncreased();
    this.showBack = this.$route.query.isAndroidOrIOS != 'WECHAT' 
  }
}
</script>
<style lang="less" scoped>
::v-deep .van-nav-bar__title {
  width: 88%;
  max-width: 88% !important;
}

.InvCarInfo {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  flex-direction: column;
  .content {
    flex: 1;
    padding-bottom: 10px;
    overflow: scroll;
    height: calc(100vh - 60px);
    background: #f2f2f2;
  }

  .btnDiv {
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 15px;
    background: white;
    border-bottom: 1px solid #f5f5f5;
  }

  .cellPanel {
    padding: 10px 0;
  }

  .topInfo {
    border-radius: 10px 10px 0 0;
    white-space: initial;
    flex: none;

    .info {
      font-size: 12px;
      word-wrap: break-word;
      overflow: scroll;
      margin-top: 10px;
      color: #5b5b5b;
      line-height: 25px;
    }
  }

  .carName {
    font-size: 18px;
    font-weight: bold;
  }

  .bottomInfo {
    .van-cell__value {
      color: #323233;
    }
  }

  .van-cell__value_left {
    .van-cell__title {
      width: 40%;
      flex: none;
    }

    .van-cell__value {
      text-align: left;
    }
  }
}

.grey {
  color: #5e5e5e;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 16px;
}</style>
