<template>
  <div></div>
</template>
<script >
export default {
  created () {
    console.log("刷新当前");
    this.$router.replace({ path: '/approvalForm',query: this.$route.query, })
  }
}
</script>