import request from "../../utils/requestAPI";

// 用户登录
// export const WorkFlowLogin = data => request('/WorkFlow/WorkFlowQuery/WorkFlowLogin', data, 'POST')
export function WorkFlowLogin(data) {
  return request({
    url: '/WorkFlow/WorkFlowQuery/WorkFlowLogin',
    method: 'post',
    data
  })
}
// 切换用户
// export const SwitchUserID = data => request('/WorkFlow/WorkFlowQuery/SwitchUserID', data, 'POST')
export function SwitchUserID(data) {
  return request({
    url: '/WorkFlow/WorkFlowQuery/SwitchUserID',
    method: 'post',
    data
  })
}

// 免登录
// export const WorkFlowFreeLogin = data => request('/WorkFlow/WorkFlowQuery/WorkFlowFreeLogin', data, 'POST')
export function WorkFlowFreeLogin(data) {
  return request({
    url: '/WorkFlow/WorkFlowQuery/WorkFlowFreeLogin',
    method: 'post',
    data
  })
}