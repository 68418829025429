// 处理精度
export const toFixed = (value, precision = 2) =>
  Number.isNaN(Number(value)) ?
  undefined :
  Number(Number(value).toFixed(precision));
// 处理千分位
export const thousand = (value, precision = 2) =>
  toFixed(value, precision) ?.toLocaleString('en-US');


// 处理对象的null值转为空字符串
export const null2str = (data) => {
  if (typeof data != 'object' || data === null || data === 'null') {
    data = '';
    return data;
  } else {
    for (let x in data) {
      if (data[x] === null || data[x] === 'null') { // 如果是null 把直接内容转为 ''
        data[x] = '';
      } else {
        if (Array.isArray(data[x])) { // 是数组遍历数组 递归继续处理
          data[x] = data[x].map(z => {
            console.log(z)
            return null2str(z);
          });
        }
        if (typeof (data[x]) === 'object') { // 是json 递归继续处理
          data[x] = null2str(data[x])
        }
      }
    }
    return data;
  }
}