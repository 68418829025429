<!--
 * @Description: 
 * @Version: 2.0
 * @Autor: zgh
 * @Date: 2021-11-22 13:31:36
 * @LastEditors: zgh
 * @LastEditTime: 2021-11-22 13:35:26
-->
<template>
  <div>
    <ve-table :columns="columns" :table-data="tableData" />
  </div>
</template>

<script>
import { getGoodsDetails } from "../../api/details";
export default {
  data() {
    return {
      columns: [],
      tableData: []
    }
  },
  mounted() {
    this.getFormList()
  },
  methods: {
    async getFormList() {
      let data = {
        ID: this.$route.query.id,
        FlowType: this.$route.query.type,
        Order: this.$route.query.Order
      }
      await getGoodsDetails(data).then(res => {
        console.log(res.data.result[0])
        this.columns = res.data.result[0].columns
        this.tableData = res.data.result[0].tableData
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ve-table .ve-table-container .ve-table-content {
  width: max-content;
}
</style>