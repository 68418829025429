import request from "../../utils/requestAPI";

// 获取审批流内容明细
// export const getApprovalDetail = data => request('/WorkFlow/WorkFlowQuery/WorkFlowDetail', data, 'POST')
export function getApprovalDetail(data) {
  return request({
    url: '/WorkFlow/WorkFlowQuery/WorkFlowDetail',
    method: 'post',
    data
  })
}

// 获取商品明细1
// export const getGoodsDetail1 = data => request('/WorkFlow/WorkFlowQuery/GoodsDetailPage',data, 'POST')
export function getGoodsDetail1(data) {
  return request({
    url: '/WorkFlow/WorkFlowQuery/GoodsDetailPage',
    method: 'post',
    data
  })
}

//提交下一步
// export const workFlowApprove = data => request('/WorkFlow/WorkFlowQuery/WorkFlowApprove',data, 'POST')
export function workFlowApprove(data) {
  return request({
    url: '/WorkFlow/WorkFlowQuery/WorkFlowApprove',
    method: 'post',
    data
  })
}

//免登录
// export const WorkFlowQuery = data => request('WorkFlow/WorkFlowQuery/',data, 'POST')
export function WorkFlowQuery(data) {
  return request({
    url: 'WorkFlow/WorkFlowQuery/',
    method: 'post',
    data
  })
}