<!--
 * /*Author: 杨强
 * /*Date: 2023-08-11 13:55:31
 * /*LastEditTime: 2023-11-14 11:53:27
 * /*LastEditors: 杨强
 * /*Description: 
 * /*FilePath: \bzm-sales-h5 - 副本\src\views\home\components\approvalList.vue
-->
<template>
  <div class="approvalList">
    <!-- 搜索框和下拉框 -->
    <div class="top">
      <!-- 搜索框 -->
      <form action="/">
        <van-search v-model="value" shape="round" background="#fff" placeholder="搜索申请人、客户名称、车架号" @search="onSearch"
          @blur="onSearch" @clear="onSearch" @cancel="onSearch" @keypress="onSearch" />
      </form>
      <!-- 下拉菜单 -->
      <van-dropdown-menu active-color="#F7772C" :close-on-click-outside="false">
        <van-dropdown-item v-model="FlowType" :options="option1" ref="DropdownItem1" @change="onchange" />
        <van-dropdown-item title="申请时间" ref="DropdownItem2">
          <template>
            <div class="applicationTime" @click="show = !show">
              <div>申请时间：</div>
              <div :style="data == '' ? 'color:#999999' : 'color:#000'">{{ data == '' ? '请选择时间' : data }}</div>
            </div>
          </template>
        </van-dropdown-item>
      </van-dropdown-menu>

    </div>

    <!-- card -->
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" :disabled="refreshDisabled">
      <van-list class="approvalVanList" :style="showBack?'height: calc(100vh - 5rem);':'height: calc(100vh - 3rem);'" id="listScorll" v-model="loading" :finished="finished" :immediate-check="false"
        finished-text="没有更多了" @load="onLoad" :offset="120">
        <div class="card" @click="goToForm(item)" v-for="(item, index) in approvalList" :key="index">
          <div class="card_title">
            <div class="title_name">{{ item.FLOWNAME }}</div>
            <div class="title_tag">{{ item.STATUS }}</div>
          </div>
          <div class="card_content">
            <div>审批流水号：{{ item.FLOWNO }}</div>
            <div>关联单号：{{ item.FORMNO }}</div>
            <div>申请机构：{{ item.STORE_NAME }}</div>
            <div>申请人：{{ item.EMPLOYEE_NAME }}</div>
            <div>申请时间：{{ item.REQUEST_DATE | formatDate }}</div>
            <div>提交审核时间：{{ item.UPDATE_DATE | formatDate }}</div>
            <div>客户名称：{{ item.ASSISTANT_NAME }}</div>
            <div>车架号：{{ item.VIN }}</div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>


    <!-- 日期弹出层 -->
    <van-popup v-model="show" position="bottom" :close-on-click-overlay="false">
      <van-datetime-picker v-model="currentDate" type="date" title="选择年月日" @cancel="closePopup" @confirm="updateData" />
    </van-popup>

  </div>
</template>

<script>
import { getFlowType } from "../../../api/home"
export default {
  // props: [
  //   "approvalList",
    
  // ],
  props: {
    approvalList:{
      type: Array,
      default: function(){
        return []
      },
    },
    Status: {
      type: String,
      default: null,
    },
    showBack:{
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      scrollTop: 0,
      refreshDisabled: false,
      loading: false,
      finished: false,
      refreshing: false,
      currentDate: new Date(),
      show: false,
      value: '',
      FlowType: '',
      data: '',
      option1: [
        { text: '审批流类型', value: '' }
      ]
    };
  },
  mounted() {
    this.getTypeList()
    let listScorll = document.querySelector('#listScorll');
    listScorll.addEventListener('scroll', () => {
      this.scrollTop = listScorll.scrollTop//data中定义scrollTop为0
    });
  },
  watch: {
    scrollTop(newVal) {
      if (newVal <= 0) {
        this.refreshDisabled = false
      } else {
        this.refreshDisabled = true
      }

    }
  },
  methods: {
    goToForm(value) {
      this.$router.push({
        path: '../../approvalForm',
        query: {
          ...this.$route.query,
          ...{ id: value.FLOWNO },
          ...{ type: value.FLOW_TYPE },
          ...{ StoreId: value.STORE },
          ...{Status:this.Status}
        }
      })
    },

    // 搜索
    onSearch() {
      this.$emit('param', this.value, this.data, this.FlowType)
    },
    // 日期弹出层 取消
    closePopup() {
      this.show = false
    },

    // 日期弹出层 确认
    updateData() {
      this.data = this.timeFormat(this.currentDate);
      this.show = false;
      this.onSearch()
    },

    // 获取审批类型列表
    async getTypeList() {
      await getFlowType().then(res => {
        let newList = res.data.result.map(item => {
          let text = 'text'
          let value = 'value'
          return {
            [text]: item.FLOWNAME,
            [value]: item.FLOWNO
          }
        })
        this.option1 = [...this.option1, ...newList]
        // this.option1.unshift({text: '审批流类型', value: ''})
      })
    },

    onLoad() {
      console.log("有我事没");
      this.$emit("queryList")
    },

    onRefresh() {
      console.log("我呢");
      this.$emit("refreshList")
    },
    onchange() {
      this.onSearch()
    },
    // 时间格式化
    timeFormat(time) {
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      return year + '-' + month + '-' + day
    },

    // 关闭菜单展示
    closeMenu() {
      this.$refs.DropdownItem1.toggle(false)
      this.$refs.DropdownItem2.toggle(false)
    }
  }
}
</script>

<style lang="less" scoped>
.approvalList {
  height: calc(100% - 44px); //去掉标题
  background: #F5F5F5;
  overflow: hidden;

  .approvalVanList {
    background: #fff;
    padding: 0.42667rem;
    padding-top: 0;
    overflow: scroll;
    // height: calc(100vh - 5rem);
  }


}

.van-search {
  border-top: 1px solid #F5F5F5;
}

/deep/.van-dropdown-menu__bar {
  box-shadow: none;
}

.applicationTime {
  font-size: 13px; 
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;
}

.card {
  font-size: 13px; 
  color: #999999;
  border-bottom: 1px solid #F0F0F0;
  margin: 0 10px 0 10px;
  padding-left: 23px;
  position: relative;

  &:first-child {
    margin-top: 8px
  }

  &:last-child {
    margin-bottom: 8px;
    border-bottom: 0;
  }

  .card_title {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
    align-items: center;
    // border-bottom: 1px solid #F5F5F5;

    &::before {
      content: '';
      position: absolute;
      left: -15px;
      width: 20px;
      height: 20px;
      background: url("../../../assets/sp.png") no-repeat center;
      background-size: cover;
    }

    .title_name {
      // font-size: 15px; 
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
    }

    .title_tag {
      font-size: 11px; 
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #F7772C;
      text-align: center;
      width: 41px;
      height: 16px;
      background: rgba(247, 119, 44, 0.1);
      border-radius: 2px 2px 2px 2px;
    }
  }

  .card_content {
    padding: 0px 0px 10px;
    font-size: 13px; 
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 18px;

    .content_line {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
