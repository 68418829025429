import request from "../../utils/requestAPI";

// 获取商品明细
// export const getGoodsDetails = data => request('/WorkFlow/WorkFlowQuery/WorkFlowGoodsList', data, 'POST')
export function getGoodsDetails(data) {
  return request({
    url: '/WorkFlow/WorkFlowQuery/WorkFlowGoodsList',
    method: 'post',
    data
  })
}

// export const GetCertificateType = data => request('/BAS/BaseInfo/GetCertificateType', data, 'POST')
// export function GetCertificateType(data) {
//   return request({
//     url: '/BAS/BaseInfo/GetCertificateType',
//     method: 'post',
//     data
//   })
// }

