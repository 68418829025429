import request from "../../utils/requestUpload";


export function uploadFile(data) {
  return request({
    url: 'v1.0/api/file/oss/upload',
    method: 'post',
    data,
  })
}
