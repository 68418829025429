import md5 from "js-md5";
import axios from "axios";
import store from '@/store/index.js'
import {
  Toast
} from 'vant'

let Authorization = "TBig3J0fL3he82rXxZTEO6elcK0zx767";
let AppKey = "WjI9kgNr8X0r43FRiDoVyRLN5b4qe2jo";
let AppSecret = "V5MX7mf08nWl4M1OYP1r6TMpb7Hxiop6";
let SignMethod = "md5";
let Timestamp = Date.parse(new Date()) / 1000;
const service = axios.create({
  // baseURL: 'http://111.33.37.250:31303/',
  baseURL: process.env.VUE_APP_BASE_XD,
  timeout: 100000
})

// 请求拦截
service.interceptors.request.use(
  config => {
    console.log("param", config);
    // if (config.url != '/NCS/NewCarSalesOrder/GetCarSalesOrderList') {
    //   store.commit('showLoading');
    // }
    let param = config.data ? JSON.stringify(config.data) : '';

    //加密消息体
    let strConcat = AppSecret + "App-Key" + AppKey + "Authorization" + Authorization + "param" + param + "Sign-Method" + SignMethod + "Timestamp" + Timestamp + AppSecret;
    let md5Str = md5(strConcat);
    let strSign = md5Str.toUpperCase(); //转大写
    config.headers = {
      'Authorization': Authorization,
      'App-Key': AppKey,
      'Timestamp': Timestamp,
      'Sign-Method': SignMethod,
      'Sign': strSign,
    }
    return config;
  },
  error => {
    setTimeout(() => {
      store.commit('hideLoading');
    }, 1000);

    return Promise.reject(error);
  }
);

// 响应拦截
service.interceptors.response.use(
  response => {
    setTimeout(() => {
      store.commit('hideLoading');
    }, 1000);
    return response;
  },
  error => {
    // 请求失败
    setTimeout(() => {
      store.commit('hideLoading');
    }, 1000);
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          // 对400错误您的处理
          console.log("错误请求");
          break;
        case 403:
          // 对 403 错误进行处理
          console.log("拒绝访问");
          break;
        case 404:
          // 对 404 错误进行处理
          console.log("请求错误,未找到该资源");
          break;
        case 500:
          // 对 500 错误进行处理
          console.log("服务器端出错");
          break;
        case 502:
          // 对 502 错误进行处理
          console.log("网络错误");
          break;
        case 503:
          // 对 503 错误进行处理
          console.log("服务不可用");
          break;
        case 504:
          // 对 504 错误进行处理
          console.log("网络超时");
          break;
        default:
          // 如果以上都不是的处理
          return Promise.reject(error);
      }
    } else {
      Toast('连接到服务器失败');
      console.log("连接到服务器失败");
    }
  }
);
export default service;
// export default (url = '', data = {}, type) => {
//   type = type.toUpperCase();
//   let param = JSON.stringify(data);
//   //加密消息体
//   let strConcat = AppSecret + "App-Key" + AppKey + "Authorization" + Authorization + "param" + param + "Sign-Method" + SignMethod + "Timestamp" + Timestamp + AppSecret;
//   let md5Str = md5(strConcat);
//   let strSign = md5Str.toUpperCase(); //转大写
//   console.log("VUE_APP_BASE_API2", process.env);
//   return request({
//     method: type,
//     url: url,
//     data: data,
//     headers: {
//       'Authorization': Authorization,
//       'App-Key': AppKey,
//       'Timestamp': Timestamp,
//       'Sign-Method': SignMethod,
//       'Sign': strSign,
//     }
//   })
// }