import Vue from "vue"
import Vuex from "vuex"
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    isShow: false,
  },
  mutations: {
    // 展示loading的方法
    showLoading(state) {
      console.log("show", state.isShow);
      state.isShow = true
    },
    // 隐藏loading的方法
    hideLoading(state) {
      console.log("hide", state.isShow);
      state.isShow = false
    },
  },
  actions: {},
  modules: {}
})
