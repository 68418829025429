<!--
 * /*Author: 杨强
 * /*Date: 2023-04-17 10:05:07
 * /*LastEditTime: 2023-11-03 17:28:38
 * /*LastEditors: 杨强
 * /*Description: 
 * /*FilePath: \bzm-sales-h5 - 副本\src\views\approvalForm\components\goodsDetails.vue
-->
<template>
  <div class="goodsDetails">
    <div class="content" v-if="JSON.stringify(detailInfo) != '{}'">
      <div class="content_title" @click="isExpansion">
        <div class="vant-title_name">
          {{ detailInfo.title }}
        </div>
        <div>
          {{titleAmountShow?'￥'+titleAmount:''  }}
          <van-icon name="arrow-down" :class="[isexpansion ? 'spanRotate' : 'spanReset']" />
        </div>
        
      </div>
      <div v-if="titleBx&&isexpansion" class="cars-info" style="margin-top: 5px;">{{ titleBx }}</div>
      <div class="talbeContent"  v-show="isexpansion"
        v-for="item, index in detailInfo.tableData" :key="index">
        <div class="cars" v-show="detailInfo.H6">{{ detailInfo.H6 }}</div>
        <div class="cars-info" v-show="detailInfo.color">{{ detailInfo.color }}</div>
        <div v-show="detailInfo.isShowList" v-for="(items, key) in item" :key="key" class="table-list">
          <span class="details-name">{{ key }}：</span>
          <span class="details-content">{{ items }}</span>
        </div>
        <div v-if="!detailInfo.isShowList" >
          <div style=" display: flex; justify-content: space-between" > 
            <div style="margin-bottom: 10px;" class="cars"> {{ item.cardInfo.titleA }}
              <span class="cars-info" style="background-color: #f5f5f5; margin-left: 10px;">{{ item.cardInfo.titleB }}</span>
            </div>
            <div class="cars-info">￥{{ item.cardInfo.amount }}</div>
          </div>
          <div>
            <span class="cars-info">{{ item.cardInfo.text }}</span>
          </div>
        </div>
        
      </div>
    </div>

  </div>
</template>

<script>
import { thousand } from "utils";
export default {
  props: ['detailInfo', 'detailsName'],
  data () {
    return {
      // 处理千分位
      thousand,
      initialNumber: 0, //初始条数
      currentNumber: 5, //结束条数
      initial: false, //上一页置灰
      current: false, //下一页置灰
      totalPages: 1, //总页数
      currentPage: 1, //当前页数
      list: [],
      isexpansion: true,
      url1: require('../../../assets/up.png'),
      url2: require('../../../assets/down.png'),
      activeNames: [],
      flow: '',
      titleAmount: 0,
      titleAmountShow:false,
      titleBx:'',
    };
  },
  methods: {
    onChange (event) {
      this.activeNames = event
    },
    isExpansion () {
      this.isexpansion = !this.isexpansion
    },
    //展示内容设置
    initDetail(){
      switch(this.detailInfo.title){
        case '表单内容':
          this.detailInfo.tableData.forEach((items) => {
            delete items.实际车主
            delete items.车主手机
            delete items.车款金额
            delete items.预交车日期
          });
          this.detailInfo.isShowList = true
          break;
        case '整车' :
          this.detailInfo.tableData.forEach((items) => {
            this.titleAmountShow = true
            this.titleAmount= items.销售报价
          });
          this.detailInfo.isShowList = true
          break;  
        case '选配' :
          this.titleAmountShow = true
          this.detailInfo.tableData.forEach((items,index) => {
            this.titleAmount+= parseFloat(items.金额 * items.数量)
            this.detailInfo.tableData[index].cardInfo = {
              titleA: items.精品名称,
              titleB: '客户付费',
              amount: parseFloat(items.金额 * items.数量),
              text: '商品编码:'+items.精品编码+' | 单价：' + items.金额+' | 数量：' +items.数量
            }
          });
          console.log(this.detailInfo.tableData,"this.detailInfo.tableData")
          this.detailInfo.isShowList = false
          break;
        case '装潢' :
          this.titleAmountShow = true
          this.detailInfo.tableData.forEach((items,index) => {
            if(items.费用类别 == '客户付费'){
              this.titleAmount+= parseFloat(items.金额 * items.数量) 
            }
            delete items.维修类型
            delete items.销售类型
            delete items.价格方案
            this.detailInfo.tableData[index].cardInfo = {
              titleA: items.商品名称,
              titleB: items.费用类别,
              amount: parseFloat(items.金额 * items.数量),
              text: '商品编码:'+items.商品编码+' | 单价：' + items.金额+' | 数量：' +items.数量
            }
          });
          this.detailInfo.isShowList = false
          break;
        case '保险' :
          this.titleAmountShow = true
          
          this.detailInfo.tableData.forEach((items,index) => {
            this.titleAmount+= parseFloat(items.实收金额) 
            this.titleBx=items.保险公司
            this.detailInfo.tableData[index].cardInfo = {
              titleA: items.险种名称,
              titleB: '',
              amount: parseFloat(items.实收金额),
              text: '保额:'+items.保额+' | 保费：' + items.保费
            }
          });
          this.detailInfo.isShowList = false
          break;
        case '延保' :
          this.titleAmountShow = true
          this.detailInfo.tableData.forEach((items) => {
            this.titleAmount+= parseFloat(items.延保金额)
          });
          this.detailInfo.isShowList = true
          break;
        case '金融' :
          this.titleAmountShow = true
          var tempItem={};
          this.detailInfo.tableData.forEach(items=> {
            this.titleAmount+= parseFloat(items.服务费)
            tempItem= {
              "贷款方式":items.贷款方式,
              "产品提供方":items.产品提供方,
              "首付金额":items.首付金额,
              "贷款金额":items.贷款金额,
              "贷款期限":items.贷款期限,
              "厂家补贴":items.厂家补贴,
              "服务费":items.服务费,
            }
          });
          this.detailInfo.tableData[0] = tempItem
          this.detailInfo.isShowList = true
          break;
        case '其它收款' :
          this.titleAmountShow = true
          this.detailInfo.tableData.forEach((items,index) => {
            this.titleAmount+= parseFloat(items.代收金额)
            this.detailInfo.tableData[index].cardInfo = {
              titleA: items.项目名称,
              titleB: '',
              amount: parseFloat(items.代收金额),
              text: '销售方式:'+items.销售方式+' | 代收成本：' + items.代收成本
            }
          });
          this.detailInfo.isShowList = false
          break;
        default:
          break;
      }
    }
  },
  created () {
    console.log('04', this.detailInfo);
    this.initDetail();
  }
};
</script>

<style lang="less" scoped>
/deep/ .ve-table .ve-table-container .ve-table-content {
  width: max-content;
  min-width: 1500px;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
}

.title {
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 22px;
}

.name {
  min-width: 120px;
}

.subheading {
  font-size: 13px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #111111;
  line-height: 18px;
}

.content-title {
  font-size: 13px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
}

.border {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 8px;
}

.grey {
  background: rgba(102, 102, 102, 0.1);
  border-radius: 2px 2px 2px 2px;
  opacity: 1;
  font-size: 11px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  padding: 2px 4px;
  margin-right: 8px;
}

.goodsDetails {
  background: #fff;
  border-radius: 4px;

  .content {
    padding: 10px;


    .content_title {
      // padding: 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .vant-title_name {
        font-size: 14px;
        // display: flex;
        // align-items: center;
      }

      .spanRotate {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        transition: transform .5s;
      }

      .spanReset {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transition: transform .5s;
      }


      // .title_name::before {
      //   content: '';
      //   width: 4px;
      //   display: inline-block;
      //   height: 22px;
      //   vertical-align: middle;
      //   background: #ef7910;
      //   margin-right: 5px;
      // }

      .title_btn {
        font-size: 13px;
        border: 1px solid #f7772c;
        padding: 0 13px;
        line-height: 22px;
        border-radius: 4px;
        color: #f7772c;
      }
    }

    .talbeContent {
      padding: 4px 8px;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      border: 1px solid #E5E5E5;
      overflow: hidden;
      margin: 10px 0;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .cars {
      font-size: 13px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;

    }

    .cars-info {
      font-size: 11px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      padding: 4px 0;
    }

    .table-list {
      font-size: 13px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      padding: 4px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .details-name {
        min-width: 100px;
        text-align: left;
      }

      .details-content {
        text-align: right;
      }
    }

    .content_list {
      .goodsCard {
        margin-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #f5f5f5;

        .goodsName {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .goodsCard_bottom {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 13px;
          color: #999999;
        }
      }

      .goodsCard:last-child {
        border: 0;
      }
    }
  }

  .btn {
    text-align: center;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #F5F5F5;
    padding: 10px 0;

    .btn_img {
      width: 9px;
      height: 4px;
      margin-left: 5px;
      display: grid;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .gray {
    border: 1px solid #999;
    color: #999;
  }
}
</style>
