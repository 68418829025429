<template>
  <div class="user">
    <div class="content">
      <!-- 头像 -->
      <div class="user_img">
        <img src="../../assets/user.jpg" alt="" />
      </div>
      <!-- 名称 -->
      <div class="user_name">{{ userName }}</div>
      <!-- 门店 -->
      <div class="user_store">
        <div @click="showPopup">{{ currentColumns }}</div>
        <van-popup position="bottom" v-model="show">
          <van-picker
            title="标题"
            show-toolbar
            :columns="columns"
            @confirm="onConfirm"
            @cancel="onCancel"
          />
        </van-popup>
      </div>
    </div>
  </div>
</template>

<script>
import { SwitchUserID } from "../../api/login";
export default {
  data() {
    return {
      show: false,
      value1: 0,
      value2: "a",
      currentColumns: JSON.parse(sessionStorage.getItem("store")),
      userName: JSON.parse(sessionStorage.getItem("userInfo")).员工姓名,
      columns: JSON.parse(
        sessionStorage.getItem("storeList").replace(/门店名称/g, "text")
      ),
    };
  },
  mounted() {},
  methods: {
    showPopup() {
      this.show = true;
    },
    onCancel() {
      this.show = false;
    },
    async onConfirm(picker) {
      console.log(picker);
      let condition = {
        StoreName: picker.text,
        EmployeeNo: JSON.parse(sessionStorage.getItem("userInfo")).编号,
      };

      await SwitchUserID(condition).then((res) => {
        console.log(res);
        this.show = false;
        this.currentColumns = res.data.result[0].STORE_NAME;
        sessionStorage.setItem(
          "store",
          JSON.stringify(res.data.result[0].STORE_NAME)
        );
      });

      //  sessionStorage.setItem("userInfo",JSON.stringify(res.data.result[0]))
    },
  },
};
</script>

<style lang="less" scoped>
.user {
  height: 100%;
  background: #f5f5f5;
  .content {
    background: #fff;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .user_img {
      width: 90px;
      height: 90px;
      border: 5px solid #f5f5f5;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .user_name {
      margin-top: 10px;
      font-size: 24px;
      font-weight: 500;
      color: #000001;
    }
    .user_store {
      margin-top: 10px;
      font-size: 15px;
      font-weight: 400;
      color: #666666;
      background: #f5f5f5;
      padding: 7px 20px;
      border-radius: 30px;
      .aaa {
        border: 0;
        background: none;
        font-size: 15px;
        width: 100%;
      }
    }
  }
}

/deep/.van-dropdown-menu__bar {
  background: none;
  box-shadow: none;
  height: auto;
}
</style>
