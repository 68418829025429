import request from "../../utils/request";

// 获取交车列表
export function GetDeliveryList(data) {
  return request({
    url: '/Saas/GetDeliveryList',
    method: 'post',
    data
  })
}

//(商社-交车)
export function SaveDeliveryConfirmationNew(data) {
  return request({
    url: 'Saas/SaveDeliveryConfirmationNew',
    method: 'post',
    data
  })
}

//  保存交车确认单
export function SaveDeliveryConfirmation(data) {
  return request({
    url: '/Saas/SaveDeliveryConfirmation',
    method: 'post',
    data
  })
}

// 获取交车确认单
export function GetDeliveryConfirmation(data) {
  return request({
    url: '/Saas/GetDeliveryConfirmation',
    method: 'post',
    data
  })
}


// 获取销售顾问
export function GetSaleList(data) {
  return request({
    url: '/Saas/GetSaleList',
    method: 'post',
    data
  })
}

// 更新销售顾问
export function UpdateSalesOrderSale(data) {
  return request({
    url: '/NCS/NewCarSalesOrder/UpdateSalesOrderSale',
    method: 'post',
    data
  })
}