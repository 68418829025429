<!--
 * /*Author: 杨强
 * /*Date: 2023-08-03 12:23:52
 * /*LastEditTime: 2023-11-14 11:06:21
 * /*LastEditors: 杨强
 * /*Description: 
 * /*FilePath: \bzm-sales-h5 - 副本\src\views\home\home.vue
-->
<template>
  <div class="home">
    <van-nav-bar title="审批列表" v-if="showBack" left-arrow @click-left="goBack"></van-nav-bar>
    <van-tabs :class="showBack?'approveLists':''" v-model="condition.Status" title-active-color="#F7772C" color="#F7772C" sticky
      offset-top="0px" @click="conversion">
      <van-tab v-for="(item, index) in tabs" :key="index" :title="item.title" :name="item.name" />
      <approval-list :Status="condition.Status" @param="getCondition" @queryList="queryList" @refreshList="refreshList" :approvalList="approvalList" :showBack="showBack"
        ref="childrenApproval" :statusType="condition.Status"></approval-list>
    </van-tabs>
  </div>
</template>

<script>
import { getApprovalList } from "../../api/home";
import approvalList from "./components/approvalList.vue";
export default {
  components: { approvalList },
  data () {
    return {
      showBack:true,
      value: '',
      tabs: [

        {
          title: "待我审批",
          name: "待审批",
        },
        {
          title: "我已审批",
          name: "已审批",
        },
        {
          title: "我的提交",
          name: "已提交",
        },
        
        // {
        //   title: "全部",
        //   name: "",
        // },

      ],
      approvalList: [],
      totalCount: 0, //总条数
      condition: {
        iDisplayStart: 0, //起始条数
        // UserCode: JSON.parse(sessionStorage.getItem("userInfo")).编号, //审批员工编号
        Status: "待审批", //审批流状态
        EmployeeName: "", //申请人
        RequestDate: "", //申请时间
        FlowType: "", //审批流类型
        // StoreName: JSON.parse(sessionStorage.getItem("store")),
        Store_No: this.$route.query.Store_No || '',//门店编码
        EMPLOYEE_NO: this.$route.query.EMPLOYEE_NO || '',//员工编码
        isAndroidOrIOS: this.$route.query.isAndroidOrIOS || '',//区分安卓还是IOS
        FLOWNO: this.$route.query.FLOWNO || '',// 审批流单号
      },
      msg: {}
    };
  },
  mounted () {
    this.getList();
  },
  methods: {
    goBack () {
      this.backforward()
    },
    backforward () {
      // 返回APP菜单中心页面
      let u = navigator.userAgent
      let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      try
      {
        if (isAndroid)
        {
          window.android.webClose(); // 调用原生app的函数--webClose是安卓提供的方法
        }
        if (isIOS)
        {
          window.webkit.messageHandlers.webClose.postMessage(""); //--webClose是ios提供的方法
        }
      } catch (e)
      {
        window.history.back()
      }
    },
    // 根据查询条件查询
    getCondition (applicant, time, FlowType) {
      this.approvalList = [];
      this.condition.keyWords = applicant; //申请人
      this.condition.RequestDate = time; //申请时间
      this.condition.FlowType = FlowType; //申请时间
      this.condition.iDisplayStart = 0;
      this.totalCount = 0;
      this.$refs.childrenApproval.loading = false;
      this.$refs.childrenApproval.finished = true;
      this.getList();
    },

    // 上拉加载数据
    queryList () {
      this.condition.iDisplayStart += 10;
      console.log("上拉");
      // alert(1)
      this.getList();
    },

    // 下拉刷新数据
    refreshList () {
      console.log("下拉刷新");
      this.condition.iDisplayStart = 0;
      this.getList("refresh");
    },

    // 获取审批表单列表数据
    async getList (value) {
      this.condition.UserId = this.$route.query.EMPLOYEE_NO
      this.condition.StoreNo = this.$route.query.Store_No

      await getApprovalList(this.condition).then((res) => {
        console.log("获取数据", res, value, this.condition);
        this.msg = res.data.msg
        if (this.condition.iDisplayStart == 0)
        {
          this.approvalList = res.data.result;
        } else
        {
          console.log("获取数据", res.data.result);
          this.approvalList = [...this.approvalList, ...res.data.result];
        }
        this.$refs.childrenApproval.finished = false;
        this.$refs.childrenApproval.loading = false;
        this.$refs.childrenApproval.refreshing = false;
        if (!this.msg.hasMore)
        {
          this.$refs.childrenApproval.finished = true;
        }
        if (!res.data.IsSuccess)
        {
          this.$refs.childrenApproval.finished = true;
        }
      })
    },

    //切换tabs标签
    conversion (name) {
      this.condition.Status = name;
      this.condition.iDisplayStart = 0;
      this.condition.RequestDate = "";
      this.condition.EmployeeName = "";
      this.$refs.childrenApproval.value1 = "";
      this.$refs.childrenApproval.value = this.condition.keyWords;
      this.$refs.childrenApproval.data = "";
      this.approvalList = [];
      this.$refs.childrenApproval.loading = true;
      this.$refs.childrenApproval.closeMenu();
      this.getList("refresh");
    },
  },
  created(){
    this.showBack = this.$route.query.isAndroidOrIOS != 'WECHAT' 
  }
};
</script>

<style lang="less" scoped>
.home {
  -webkit-transform: translateZ(0);
  height: 100%;
  background: #F5F5F5;
  overflow: hidden;

  /deep/.van-tabs__content {
    height: 100%;
  }
}

.approveLists {
  height: calc(100% - 70px)
}

/deep/.van-pull-refresh {
  // height: calc(100% - 104px);
}
</style>
