<!--
 * /*Author: 杨强
 * /*Date: 2023-08-03 11:44:15
 * /*LastEditTime: 2023-11-14 11:36:03
 * /*LastEditors: 杨强
 * /*Description: 
 * /*FilePath: \bzm-sales-h5 - 副本\src\views\approvalForm\approvalForm.vue
-->
<template>
   
  <div class="vant-approvalForm" :style="showBack?'padding-top:70px':''">
    <van-nav-bar title="审批详情" v-if="showBack" left-arrow @click-left="goBack" fixed style="z-index: 100;" />
    <div class="header-top" >
      <div class="flex">
        <div class="vant-title">{{ flow.FLOWNAME }}</div>
        <van-tag type="warning">{{ flowDetails.流程状态 }}</van-tag>
      </div>
      <div class="flex subheading">发起人：{{ flowDetails.发起人 }} | 发起时间：{{ flowDetails.发起时间 }}</div>
      <!-- <div class="content-title flex">
        流程类别：{{ flowDetails.流程类别 }}
      </div> -->
      <div class="content-title flex">
        流水号：{{ flowDetails.流水号 }}
      </div>
      <div class="content-title flex">
        当前节点：{{ flowDetails.当前节点 }}
      </div>
      <div class="content-title flex" v-if="ENV == 'HW'">
        申请原因：{{ REASON_FOR_RETURN }}
      </div>
    </div>
     <!-- 内容 -->
    <van-tabs v-model="active" title-active-color="#F7772C" color="#F7772C" scrollspy sticky :offset-top="showBack?'69px':'0px'"  >
      <van-tab v-for="item in detailList" :key="item.title" :title="item.title">
        <goods-details :detailInfo="item"></goods-details>
      </van-tab>
      <van-tab :title="'附件'" v-if="flowDetails.附件地址&&attachmentsSwitch">
        <approval-attachments :attachments="flowDetails.附件地址" v-if="attachmentsSwitch"></approval-attachments>
      </van-tab>
      <van-tab :title="'审批历史'">
        <approval-history :historyList="flowDetails.流程处理"></approval-history>
      </van-tab>
    </van-tabs>
    <!-- 底部 -->
    <div class="bottom_btn" v-if="flowDetails.流程状态 == '流转中'&& showApprovel">
      <div class="btn_box">
        <div class="refuse_btn" @click="closePopup">拒绝</div>
        <div class="agree_btn" @click="showPopup">同意</div>
      </div>
    </div>
    <!-- 弹窗 -->
    <van-popup v-model="show" position="bottom" :style="{ height: '250px' }">
      <div class="confirmationBox">
        <div class="confirmationBox_title">确定同意吗？</div>
        <div class="confirmationBox_content">
          <van-field class="content_text" v-model="value" placeholder="请输入审批意见（选填）" type="textarea" rows="4" />
        </div>
        <div class="confirmationBox_btn" @click="workFlowApprove('审批通过')">
          确定同意
        </div>
      </div>
    </van-popup>
    <!--拒绝 弹窗 -->
    <van-popup v-model="close" position="bottom" :style="{ height: '250px' }">
      <div class="confirmationBox">
        <div class="confirmationBox_title">确定拒绝吗？</div>
        <div class="confirmationBox_content">
          <van-field class="content_text" v-model="value" placeholder="请输入审批意见（选填）" type="textarea" rows="4" />
        </div>
        <div class="confirmationBox_btn" @click="workFlowApprove('审批拒绝')">
          确定拒绝
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getApprovalDetail, workFlowApprove, WorkFlowQuery } from "../../api/approvalForm";
import { WorkFlowFreeLogin } from "../../api/OrderGoods";
import { getFlowType } from "../../api/home"
import ApprovalHistory from "./components/approvalHistory.vue";
import GoodsDetails from "./components/goodsDetails.vue";
import ApprovalAttachments from "./components/approvalAttachments.vue";
export default {
  components: {
    GoodsDetails,
    ApprovalHistory,
    ApprovalAttachments
  },
  props: ['statusType'],
  data () {
    return {
      showBack:true,
      ID: this.$route.query.id, //流水号
      FlowType: this.$route.query.type, //审批流类型
      active: 0,
      show: false,
      close: false,
      value: "",
      flowDetails: {}, // 审批流程详情
      approvalContent: {}, // 表单内容
      detailsName: [], // 明细名称
      detailList: [], // 商品明细1
      orderIndex: "1",
      LoginModel: {
        USER_NAME: "",
      },
      EMPLOYEE_NO: "",
      STATUS: '',
      flow: '',
      REASON_FOR_RETURN: '',
      showApprovel:true,
      attachmentsSwitch: process.env.VUE_APP_BASE_UPLOAD_SWITCH
      // condition: {}
    };
  },

  mounted () {
    this.getFlowType()
    this.getDetails();
    this.WorkFlowQuery();
    this.EMPLOYEE_NO = encodeURI(this.$route.query.EMPLOYEE_NO);
    this.WorkFlowFreeLogin();
    this.showApprovelBtn();
  },
  methods: {
    goBack () {
      this.$router.go(-1);
    },
    showApprovelBtn(){
      console.log(this.$route.query.Status,"this.$route.query.status")
      if(this.$route.query.Status != "待审批"){
        this.showApprovel = false
      }
    },
    showPopup () {
      this.show = true;
    },

    closePopup () {
      this.close = true;
    },
    //获取当前登录用户信息
    async WorkFlowFreeLogin () {
      this.LoginModel.USER_NAME = this.EMPLOYEE_NO == "" ? "" : this.EMPLOYEE_NO;
      if (sessionStorage.getItem("userInfo") !== null)
      {
        return
      }
      await WorkFlowFreeLogin(this.LoginModel).then((res) => {
        if (res.data.IsSuccess)
        {
          sessionStorage.setItem(
            "userInfo",
            JSON.stringify(res.data.result[0])
          );
          sessionStorage.setItem(
            "storeList",
            JSON.stringify(res.data.result[0].门店信息)
          );
          sessionStorage.setItem(
            "store",
            JSON.stringify(res.data.result[0].门店信息[0].门店名称)
          );
        }
        else
        {
          this.$toast(res.data.msg);
        }
      });
    },

    // 查询审批内容
    async getDetails () {
      let condition = {
        ID: this.$route.query.id,
        FlowType: this.$route.query.type,
        StoreID: this.$route.query.StoreId,
      };
      await getApprovalDetail(condition).then((res) => {
        const detauilForm = JSON.parse(res.data.result[1].JSONTEXT);
        this.flowDetails = res.data.result[0]
        this.STATUS = detauilForm.STATUS
        this.REASON_FOR_RETURN = detauilForm.REASON_FOR_RETURN
        //将后端返回的FROM中type为text的处理成一个数组后再整理为一个对象
        const FormTextList = detauilForm.FORM.map((item) => {
          if (item.TYPE === "TEXT") return { [item.TITLE]: item.VALUE };
          else return null;
        });
        FormTextList.forEach((item) => {
          this.approvalContent = { ...this.approvalContent, ...item };
        });
        //将后端返回的FROM中type为list的处理成一个数组
        // const detailList = []

        detauilForm.FORM.forEach((item) => {

          if (item.TYPE === "LIST" && item.VALUELIST.length > 0)
            this.detailList.push({
              title: item.TITLE,
              index: this.sortDetail(item.TITLE),
              H6: item.H6,
              color: item.VALUE,
              valueList: item.VALUELIST,
            });
          console.log("02", item);
        });
        this.detailList.forEach((item) => {
          console.log("03", item);
          if (item)
          {
            this.detailsName.push(item.title);
          }
          if (item.valueList)
          {
            item["columns"] = [];
            item["tableData"] = [];
            item.valueList.forEach((detailItem, detailIndex) => {
              if (detailIndex === 0)
              {
                detailItem.TITLE.forEach((titleItem) => {
                  item.columns.push({
                    field: titleItem,
                    key: titleItem,
                    title: titleItem,
                    align: "center",
                  });
                });
              } else
              {
                detailItem["valueData"] = {};
                detailItem.TITLE.forEach((ValueItem, ValueIndex) => {
                  detailItem.valueData = {
                    ...detailItem.valueData,
                    [item.columns[ValueIndex].title]: ValueItem,
                  };
                });
                item.tableData.push(detailItem.valueData);
              }
            });
          }
        });
        this.detailList.sort((a, b) => a.index- b.index)
        console.log(this.detailList,"this.detailList")
      });
    },

    sortDetail(title){
      var detailIndex ;
      switch (title) {
        case "表单内容":
          detailIndex = 1
        break;
        case "整车":
          detailIndex = 2
        break;
        case "选配":
          detailIndex = 3
        break;
        case "装潢":
          detailIndex = 4
        break;
        case "保险":
          detailIndex = 5
        break;
        case "金融":
          detailIndex = 6
        break;
        case "延保":
          detailIndex = 7
        break;
        case "其他收款":
          detailIndex = 8
        break;
        default:
          break;
      }
      return detailIndex
    },

    async WorkFlowQuery () {

      await WorkFlowQuery({ USER_NAME: this.$route.query.EMPLOYEE_NO }).then(() => {

      })
    },
    //提交意见
    async workFlowApprove (data) {
      let condition = {
        // FlOWTYPE: this.$route.query.type,
        // STOREID: this.$route.query.StoreId,
        // FlOWNO: this.$route.query.id,
        USER_NAME: JSON.parse(sessionStorage.getItem("userInfo")).员工姓名,
        USER_ID: JSON.parse(sessionStorage.getItem("userInfo")).ID,
        // USER_NO: JSON.parse(sessionStorage.getItem("userInfo")).编号,
        // REMARK: this.value,
        // WF_STATE: data,
        // Reply: 0,
        FlOWNO: this.$route.query.id,
        // USER_NAME:this.$route.query.StoreId,
        // USER_ID:this.$route.query.EMPLOYEE_NO,
        USER_NO: this.$route.query.EMPLOYEE_NO,
        STOREID: this.$route.query.StoreId,
        REMARK: this.value,
        WF_STATE: data,
        FlOWTYPE: this.$route.query.type,
        Reply: 0,
      };
      await workFlowApprove(condition).then((res) => {
        if (data == "审批通过")
        {
          this.show = false;
        } else
        {
          this.close = false;
        }
        this.value = "";
        this.$toast(res.data.msg);
        // this.$router.replace({ path: '/supplierAllBack', query: this.$route.query, })
        this.$router.replace({ path: '/home', query: this.$route.query, })
      });

    },
    async getFlowType () {
      await getFlowType().then(res => {
        this.flow = res.data.result.find(item => item.FLOWNO == this.$route.query.type)
        console.log("--", res, this.flow);
      })
    },
  },
  created(){
    this.showBack = this.$route.query.isAndroidOrIOS != 'WECHAT' 
  }
};
</script>

<style lang="less" scoped>
.vant-approvalForm {
  font-size: 15px;
  height: 100%;
  overflow-y: auto;
  margin: 0px;
  width: 100%;
  // padding-top:70px;


  .vant-title {
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 22px;
  }

  .subheading {
    font-size: 13px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #111111;
    line-height: 18px;
  }

  .content-title {
    font-size: 13px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 18px;
  }

  .header-top {
    background: #fff;
    padding: 16px;
    border-bottom: 2px solid #f5f5f5;
    max-height: 200px;
    min-height: 150px;

    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4px 0;
    }


  }

  .content-body {
    height: calc(100vh - 330px);
    // overflow: hidden;
    // position: relative;

    .van-tabs {
      height: 100%;
      overflow: scroll;
    }

    // /deep/.van-sticky--fixed {
    //   position: fixed;
    //   top: 270px;
    //   right: 0;
    //   left: 0;
    //   z-index: 99;
    // }
  }

  // .heightBody {
    // height: calc(100% - 270px);
  // }




  .header_top {
    color: #999999;
    border-bottom: 1px solid #f5f5f5;
    padding: 10px 15px;

    div {
      display: flex;
      justify-content: space-between;
      padding: 1px 0;
      align-items: center;
    }

    .header_num {
      color: #141414;

      .header_tag {
        font-size: 14px;
        color: #19bbee;
        background: #e8f8fd;
        text-align: center;
        padding: 0 4px;
        border-radius: 2px;
      }
    }
  }

  .bottom_btn {
    width: 100%;
    height: 60px;
    position: fixed;
    bottom: 0;
    background-color: #fff;
    box-shadow: 10px 10px 15px #000;
    z-index: 10;

    .btn_box {
      height: 100%;
      margin: 0 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        width: 100%;
        height: 36px;
        text-align: center;
        line-height: 36px;
        border-radius: 2px;
        margin: 0 8px;
      }

      .refuse_btn {
        color: #666666;
        border: 1px solid #DCDCDC;

      }

      .agree_btn {
        color: #fff;
        background: #f7772c;
      }
    }
  }

  .confirmationBox {
    height: 100%;
    padding: 0 15px;

    .confirmationBox_title {
      font-size: 17px;
      padding: 15px 0;
      display: flex;
      align-items: center;
    }

    .confirmationBox_title::before {
      content: "";
      width: 4px;
      display: inline-block;
      height: 22px;
      vertical-align: middle;
      background: #ef7910;
      margin-right: 5px;
    }

    .confirmationBox_content {
      margin-bottom: 10px;

      .content_text {
        width: 100%;
        border: 1px solid #e6e6e6;
        border-radius: 4px;
        font-size: 15px;
      }
    }

    .confirmationBox_btn {
      height: 40px;
      text-align: center;
      line-height: 40px;
      background-color: #f7772c;
      border-radius: 4px;
      color: #fff;
    }
  }

  .van-tab__pane {
    padding: 10px 15px 0px;
    border-radius: 8px;
  }

  .van-tab__pane:last-child {
    padding: 10px 15px 80px;
    border-radius: 8px;
  }

  /deep/.van-tabs__content {
    // height:calc(100vh - 310px);
    // overflow-y: scroll;
    background: #f5f5f5;
  }


}
</style>
