<!--
 * /*Author: 杨强
 * /*Date: 2023-08-03 11:44:15
 * /*LastEditTime: 2023-11-08 10:52:08
 * /*LastEditors: 杨强
 * /*Description: 
 * /*FilePath: \bzm-sales-h5 - 副本\src\views\OrderDetails\OrderDetails.vue
-->
<template>
  <div class="orderGoods">
    <van-nav-bar
      title="订单详情"
      v-if="showBack"
      left-arrow
      @click-left="goBack"
    >
      <template
        #right
        v-if="dataInfo.getSoMstrInfo && dataInfo.getSoMstrInfo.IsUpdateSale"
      >
        <van-popover
          theme="dark"
          trigger="click"
          placement="bottom-end"
          v-model="showPopover1"
          :actions="actions"
          @select="showSalesStaffList"
        >
          <template #reference>
            <van-icon name="ellipsis" size="30px" />
          </template>
        </van-popover>
      </template>
    </van-nav-bar>
    <div
      class="orderGoodsCenter"
      :style="
        showBack ? 'height: calc(100% - 180px);' : 'height: calc(100% - 110px);'
      "
    >
      <van-panel>
        <template #header>
          <van-cell class="topInfo">
            <div class="carSubTypeName">{{ so_car_type }}</div>
            <div class="info">
              <span>外观{{ so_outercolor }}</span
              >｜ <span>内饰{{ so_Innercolor }}</span
              >｜
              <span>车型编码{{ soCarSubTypeCode }}</span>
            </div>
          </van-cell>
        </template>
        <div class="bottomInfo">
          <van-cell
            title-style="color:#626262"
            title="裸车价"
            :value="`¥ ${thousand(so_amout)}`"
            :border="false"
          />
          <!-- 选配 -->
          <van-cell
            :border="false"
            v-if="INV_INCREASED.length > 0"
            :style="{ fontSize: '16px' }"
            :title-style="{ fontWeight: 'bold', color: '#626262' }"
          >
            <div @click="orderXpDetailShow = true" style="color: #82afe6">
              查看详情
            </div>
            <template #title>
              <span>选配</span>
              <van-popover
                placement="top-start"
                trigger="click"
                v-model="showXpPopover"
                theme="dark"
              >
                <template #reference>
                  <van-icon
                    :style="{ marginLeft: '5px', marginTop: '4px' }"
                    size="20px"
                    color="#cbccce"
                    name="question"
                  />
                </template>
                <div class="tipsCenter">厂家增配+门店前装</div>
              </van-popover>
            </template>
          </van-cell>
          <van-cell
            :style="{ paddingTop: '0px' }"
            title-style="color:#626262"
            v-for="(item, $index) in xpItem_List"
            :key="$index"
            :title="item.Item_Name"
            :value="`¥ ${thousand(item.Item_Price)}`"
            :border="false"
          />

          <van-cell
            title="增值业务"
            :border="false"
            v-if="zzItem_List.length > 0"
            :style="{ fontSize: '16px' }"
            :title-style="{ fontWeight: 'bold', color: '#626262' }"
          >
            <div @click="orderDetailShow = true" style="color: #82afe6">
              查看详情
            </div>
          </van-cell>
          <van-cell
            :style="{ paddingTop: '0px' }"
            title-style="color:#626262"
            v-for="(item, $index) in zzItem_List"
            :key="$index"
            :title="item.Item_Name"
            :value="`¥ ${thousand(item.Item_Price)}`"
            :border="false"
          />
          <van-cell
            class="orderPayment"
            :value="`¥ ${thousand(soAmt)}`"
            :border="false"
            v-if="zzItem_List.length > 0 || xpItem_List.length > 0"
          >
            <template #title>
              <span>订单款</span>
              <van-popover
                placement="top-start"
                trigger="click"
                v-model="showPopover"
                theme="dark"
              >
                <template #reference>
                  <van-icon
                    :style="{ marginLeft: '5px', marginTop: '4px' }"
                    size="20px"
                    color="#cbccce"
                    name="question"
                  />
                </template>
                <div class="tipsCenter">
                  订单款={{
                    "裸车价" +
                    (xpItem_List.length > 0
                      ? "+" +
                        xpItem_List
                          .map((C) =>
                            C.Item_Name == "前装"
                              ? "门店" + C.Item_Name
                              : "厂家" + C.Item_Name
                          )
                          .join("+")
                      : "") +
                    zzItem_List.reduce(
                      (str, item, index) =>
                        (str += `${
                          item.Item_Name != "保险" && item.Item_Name != "金融"
                            ? "+" + item.Item_Name
                            : ""
                        }`),
                      ""
                    )
                  }}
                </div>
              </van-popover>
            </template>
          </van-cell>
        </div>
      </van-panel>
      <van-panel :style="{ border: 'none', margin: '10px 0px' }">
        <template #header>
          <van-cell class="topInfo">
            <div class="carSubTypeName">订单收款进度</div>
          </van-cell>
        </template>
        <div class="bottomInfo">
          <van-cell title="购车方式" :value="skfangshi" :border="false" />
          <van-cell
            class="orange-bold"
            title="定金（待收款）"
            :value="`¥ ${thousand(dingjin)}`"
            :border="false"
          />
          <van-cell v-if="qudao != '直售订单' && ENV != 'HW'">
            <div :style="{ textAlign: 'right' }">
              <van-button
                type="default"
                size="small"
                plain
                round
                @click="pushFaPriceShow = true"
                border="false"
                >推定金</van-button
              >
            </div>
          </van-cell>
          <van-cell v-if="qudao != '直售订单' && ENV == 'HW'">
            <div
              :style="{ textAlign: 'right' }"
              v-if="
                soStatus.indexOf('已生效') > -1 ||
                soStatus.indexOf('已结算') > -1
              "
            >
              <van-button
                type="default"
                size="small"
                plain
                round
                @click="pushFaPriceShow = true"
                border="false"
                >推定金</van-button
              >
            </div>
          </van-cell>

          <!-- v-if="soStatus.indexOf('草稿') == -1" -->
          <van-cell
            class="orange-bold"
            title="订单款（待收款）"
            :value="`¥ ${thousand(soAmt)}`"
            :border="false"
          />
        </div>
      </van-panel>
      <van-panel :style="{ border: 'none', margin: '10px 0px' }">
        <template #header>
          <van-cell class="topInfo">
            <div class="carSubTypeName">
              订单统计数据
              <van-icon
                @click="statisticalDataShow = true"
                size="20px"
                color="#cbccce"
                name="question"
              />
            </div>
          </van-cell>
        </template>
        <div class="bottomInfo">
          <!-- <van-cell title="新车优惠" :value="`¥ ${thousand(xcyh)}`" :border="false" /> -->
          <van-cell
            title="裸车毛利"
            :value="`¥ ${thousand(carGP)}`"
            :border="false"
          />
          <van-cell
            title="衍生业务毛利"
            :value="`¥ ${thousand(ZPGP)}`"
            :border="false"
          />
          <van-cell
            title="综合毛利"
            :value="`¥ ${thousand(allGP)}`"
            :border="false"
          />
        </div>
      </van-panel>
      <!-- 订单统计数据弹窗 -->
      <van-action-sheet
        v-model="statisticalDataShow"
        title="订单统计数据说明"
        :style="{ height: '60%' }"
      >
        <ul :style="{ fontSize: '16px', padding: '20px', lineHeight: '30px' }">
          <li>1.新车优惠金额＝指导价﹣（裸车价＋选配价）</li>
          <li>
            2.裸车去税毛利＝（裸车价＋订单增配金额﹣前装收入﹣裸车采购价）／税率
          </li>
          <li>
            3.衍生业务去税毛利＝前装毛利＋新车装潢毛利＋保险毛利＋延保毛利＋金融毛利＋代办毛利
          </li>
          <li>4.综合去税毛利＝裸车去税毛利＋衍生业务去税毛利</li>
        </ul>
      </van-action-sheet>

      <van-panel :style="{ border: 'none', margin: '10px 0px' }">
        <template #header>
          <van-cell class="topInfo">
            <div class="carSubTypeName">客户信息</div>
          </van-cell>
        </template>
        <div class="bottomInfo van-cell__value_left">
          <van-cell title="客户姓名" :value="customerName" :border="false" />
          <van-cell
            title="联系电话"
            v-if="ISINVOICE == 0"
            :value="customerPhone"
            :border="false"
          />
          <van-cell
            title="证件类型"
            v-if="ISINVOICE == 0"
            :value="customerType"
            :border="false"
          />
          <van-cell
            title="证件号码"
            v-if="ISINVOICE == 0"
            :value="customerNO"
            :border="false"
          />

          <van-cell
            title="开票类型"
            :value="kxlx == '公司' ? '企业' : kxlx"
            :border="false"
          />
          <van-cell
            title="开票企业名称"
            v-if="kxlx == '公司'"
            :value="kpr"
            :border="false"
          />
          <van-cell
            title="社会统一代码"
            v-if="kxlx == '公司'"
            :value="cardTypeNumber"
            :border="false"
          />
          <van-cell
            title="联系电话"
            v-if="kxlx == '公司'"
            :value="lxdh"
            :border="false"
          />
          <van-cell
            title="开票人"
            v-if="kxlx == '个人'"
            :value="kpr"
            :border="false"
          />
          <van-cell
            title="联系电话"
            v-if="kxlx == '个人'"
            :value="lxdh"
            :border="false"
          />
          <van-cell
            title="证件类型"
            v-if="kxlx == '个人'"
            :value="cardType"
            :border="false"
          />
          <van-cell
            title="证件号码"
            v-if="kxlx == '个人'"
            :value="cardTypeNumber"
            :border="false"
          />
          <van-cell
            title="开票状态"
            v-if="kxlx == '个人'"
            :value="kpstatus"
            :border="false"
          />
        </div>
      </van-panel>
      <van-panel :style="{ border: 'none', margin: '10px 0px' }">
        <template #header>
          <van-cell class="topInfo">
            <div class="carSubTypeName">其他信息</div>
          </van-cell>
        </template>
        <div class="bottomInfo van-cell__value_left">
          <van-cell title="订单编号" :value="souche_orderno" :border="false">
            <template #right-icon>
              <van-button
                v-if="souche_orderno"
                type="default"
                @click="copyText(souche_orderno)"
                size="small"
                plain
                round
                border="false"
                :style="{
                  width: '55px',
                  marginLeft: '10px',
                  marginTop: '-5px',
                }"
                >复制</van-button
              >
            </template>
          </van-cell>
          <van-cell title="ERP订单号" :value="ERP_soNo" :border="false" />
          <van-collapse v-model="active" :border="false" accordion>
            <van-collapse-item title="全部展开" name="1" class="redundancy">
              <van-cell title="车辆VIN码" :value="Vin" :border="false" />
              <van-cell title="审批类型" :value="kpsq" :border="false" />
              <van-cell title="上牌方式" :value="shangpai" :border="false" />
              <van-cell
                title="二手车置换"
                :value="ershouchezh"
                :border="false"
              />
              <van-cell title="预计交车时间" :value="jcDate" :border="false" />
              <van-cell title="下单时间" :value="so_create" :border="false" />
              <van-cell title="销售门店" :value="sa_org" :border="false" />
              <van-cell title="销售归属" :value="so_sale" :border="false" />
              <van-cell title="联系电话" :value="soMOBILE" :border="false" />
              <van-cell title="销售类型渠道" :value="qudao" :border="false" />
              <van-cell
                title="所属二网机构"
                :value="CurrentOgg"
                :border="false"
              />
              <van-cell title="特殊约定" :value="teshuYd" :border="false" />
              <van-cell
                title="订单备注"
                :value="Order_remarks"
                :border="false"
              />
            </van-collapse-item>
          </van-collapse>
        </div>
      </van-panel>
      <van-panel
        v-if="attachmentsSwitch && fileList.length > 0"
        :style="{ border: 'none', margin: '10px 0px' }"
      >
        <template #header>
          <van-cell class="topInfo">
            <div class="carSubTypeName">附件</div>
          </van-cell>
        </template>
        <div class="bottomInfo van-cell__value_left">
          <van-uploader
            v-model="fileList"
            :deletable="false"
            multiple
            :max-count="fileList.length"
            preview-size="55px"
            class="uploader"
            ref="file"
          />
        </div>
      </van-panel>
    </div>
    <div v-if="ENV != 'SS'">
      <div class="warning" v-if="soStatusShow">
        <van-icon :name="seal" />请先提交订单审批
      </div>
      <div class="warning" v-if="!soStatusShow">
        <van-icon :name="seal" />{{ soStatus }}
      </div>
    </div>
    <div v-if="ENV == 'SS'">
      <div class="warning" v-if="soStatusShow && !valueAddStatus">
        <van-icon :name="seal" />请先提交订单审批
      </div>
      <div class="warning" v-if="!soStatusShow && !valueAddStatus">
        <van-icon :name="seal" />{{ soStatus }}
      </div>
      <div class="warning" v-if="valueAddStatus">
        <van-icon :name="seal" />{{ valueAddStatus }}
      </div>
    </div>

    <div class="footerButton">
      <van-button
        :style="{ marginLeft: '10px' }"
        type="default"
        :disabled="displayButton"
        plain
        round
        border="false"
        @click="DeliveryVehicle()"
        v-if="relFlag.indexOf('已出库') > -1 && !deliveryDate"
        >交车</van-button
      >
      <van-button
        :style="{ marginLeft: '10px' }"
        type="default"
        plain
        round
        border="false"
        @click="cancelledDraft()"
        v-if="ENV == 'HW' && soStatus.indexOf('草稿') > -1"
        >作废草稿单</van-button
      >
      <van-button
        :style="{ marginLeft: '10px' }"
        type="default"
        plain
        round
        border="false"
        @click="closeDraft()"
        v-if="
          soStatus.indexOf('已生效') > -1 &&
          relFlag.indexOf('已出库') == -1 &&
          ENV == 'HW'
        "
        >关闭订单</van-button
      >
      <!--  -->
      <van-button
        :style="{ marginLeft: '10px' }"
        type="default"
        plain
        round
        border="false"
        @click="closeOrder('Cancel')"
        v-if="soStatusShow && ENV != 'HW'"
        >作废草稿单</van-button
      >
      <van-button
        :style="{ marginLeft: '10px' }"
        type="default"
        plain
        round
        border="false"
        @click="closeOrder('Close')"
        v-if="
          soStatus.indexOf('已生效') > -1 &&
          relFlag.indexOf('已出库') == -1 &&
          ENV != 'HW'
        "
        >关闭订单</van-button
      >
      <van-button
        :style="{ marginLeft: '10px' }"
        type="default"
        plain
        round
        border="false"
        @click="orderEdit"
        v-if="soStatusShow"
        >修改草稿单</van-button
      >
      <van-button
        :style="{ marginLeft: '10px' }"
        type="default"
        plain
        round
        border="false"
        @click="orderEdit"
        v-if="
          soStatus.indexOf('已生效') > -1 && relFlag.indexOf('已出库') == -1
        "
        >修改订单</van-button
      >
      <van-button
        :style="{ marginLeft: '10px' }"
        type="primary"
        round
        @click="submitApproval"
        :disabled="soDisabled"
        v-if="soStatusShow"
        >提交审批</van-button
      >
    </div>
    <!-- 选择定金弹窗 -->
    <van-popup
      v-model="pushFaPriceShow"
      position="right"
      :style="{ height: '100%', width: '100%' }"
    >
      <van-nav-bar title="推定金" @click-left="pushFaPriceShow = false">
        <template #left>
          <van-icon name="cross" />
        </template>
      </van-nav-bar>
      <van-field
        required
        v-model="pushPriceModel"
        type="text"
        :formatter="inputHandler"
        name="推定金"
        label="推定金"
        placeholder="请填写"
      />
      <div style="padding: 0px 16px">
        <van-button type="primary" round size="large" @click="pushSub"
          >确定</van-button
        >
      </div>
      <van-cell
        title="定金推送记录"
        :border="false"
        :style="{ marginTop: '20px' }"
      ></van-cell>
      <van-row :style="{ fontSize: '13px', padding: '0px 16px' }">
        <van-col span="10">推送时间 </van-col>
        <van-col span="10"> 操作人 </van-col>
        <van-col span="4" :style="{ textAlign: 'right' }"> 推送金额</van-col>
      </van-row>
      <van-cell v-for="(item, $index) in pushListModel" :key="$index">
        <van-row :style="{ fontSize: '12px', color: '#6b6b71' }">
          <van-col span="10"> {{ item.ARD_DATE }} </van-col>
          <van-col span="10"> {{ item.OPT_USER_NAME }}</van-col>
          <van-col span="4" :style="{ textAlign: 'right' }">
            {{ `¥ ${thousand(item.ARD_AMT)}` }}
          </van-col>
        </van-row>
      </van-cell>
    </van-popup>

    <van-popup v-model="shangpaishow" position="bottom">
      <van-picker
        title="上牌方式"
        :columns="shangpailist"
        show-toolbar
        @cancel="shangpaicancel"
        @confirm="onshangpaiConfirm"
      />
    </van-popup>

    <!-- 日期弹出层 -->
    <van-popup
      v-model="jcshow"
      position="bottom"
      :close-on-click-overlay="false"
    >
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        @cancel="closePopup"
        @confirm="onJcConfirm"
      />
    </van-popup>

    <!-- 订单详情增值业务弹出框 -->
    <van-popup
      v-model="orderDetailShow"
      position="right"
      :style="{ height: '100%', width: '100%' }"
      class="valueAddedPopup"
    >
      <van-nav-bar
        title="增值业务订单"
        right-text="刷新"
        left-arrow
        @click-left="orderDetailShow = false"
      />
      <div class="valueAddedPopupContent">
        <van-cell :style="{ marginBottom: '10px' }">
          <div class="carSubTypeName orange">{{ status }}</div>
          <div :style="{ marginBottom: '10px' }">
            增值业务内容以订单完成后展示内容为准
          </div>
        </van-cell>
        <van-cell
          :style="{ marginBottom: '10px' }"
          :border="false"
          v-if="getGoodsInfos.length > 0"
        >
          <template #title>
            <div class="van-title">
              <span :style="{ fontSize: '16px', fontWeight: 'bold' }"
                >装潢</span
              >
              <span class="grey">{{ status }}</span>
            </div>
          </template>
          <template #label>
            <van-cell
              :style="{ padding: '10px 0' }"
              v-for="(item, $index) in getGoodsInfos"
              :key="$index"
            >
              <van-row>
                <van-col span="12" class="black"> {{ item.itemName }} </van-col>
                <van-col class="grey" :style="{ textAlign: 'right' }" span="4">
                  x{{ item.qty }}</van-col
                >
                <van-col class="black" :style="{ textAlign: 'right' }" span="8">
                  ¥ {{ item.damount || item.amount }}</van-col
                >
              </van-row>
              <van-row class="grey">
                <van-col span="24">商品编码 {{ item.itemNo }}</van-col>
                <van-col span="24">厂商编码 {{ item.itemNo }}</van-col>
                <van-col span="24">销售模式 {{ item.payModeName }} </van-col>
              </van-row>
            </van-cell>
            <van-cell
              :style="{ padding: '10px 0' }"
              class="black"
              title="小计"
              :value="`¥ ${thousand(ZH_Pirce)}`"
              :border="false"
            />
          </template>
        </van-cell>
        <van-cell
          :style="{ marginBottom: '10px' }"
          :border="false"
          v-if="bx_show"
        >
          <template #title>
            <div class="van-title">
              <span :style="{ fontSize: '16px', fontWeight: 'bold' }"
                >保险</span
              >
              <span class="grey">{{ status }}</span>
            </div>
          </template>
          <template #label>
            <van-col>{{ getInsOrder.InsCorpName }} </van-col>
            <van-cell
              :style="{ padding: '10px 0' }"
              v-for="(item, $index) in getInsOrder.getInsOrderItems"
              :key="$index"
            >
              <van-row v-if="item.coverageAmount != 0">
                <van-col span="12"> {{ item.InsProductName }} </van-col>
                <van-col span="12">
                  {{ item.premiumAmount }} ￥{{ item.coverageAmount }}</van-col
                >
              </van-row>
            </van-cell>
            <van-cell
              :style="{ padding: '10px 0' }"
              class="black"
              title="小计"
              :value="`¥ ${thousand(Insurance_Pirce)}`"
              :border="false"
            />
          </template>
        </van-cell>
        <van-cell
          :style="{ marginBottom: '10px' }"
          :border="false"
          v-if="getEWOrders.length > 0"
        >
          <template #title>
            <div class="van-title">
              <span :style="{ fontSize: '16px', fontWeight: 'bold' }"
                >延保</span
              >
              <span class="grey">{{ status }}</span>
            </div>
          </template>
          <template #label>
            <van-cell
              :style="{ padding: '10px 0' }"
              v-for="(item, $index) in getEWOrders"
              :key="$index"
            >
              <van-row>
                <van-col
                  :style="{ fontSize: '16px', fontWeight: 'bold' }"
                  span="8"
                  >延保产品{{ $index + 1 }}</van-col
                >
              </van-row>
              <div class="grey" :style="{ fontWeight: 'initial' }">
                <van-row>
                  <van-col span="8">延保产品</van-col>
                  <van-col span="16"> {{ item.ewProductName }} </van-col>
                </van-row>
                <van-row>
                  <van-col span="8">延保公司</van-col>
                  <van-col span="16"> {{ item.supplierName }} </van-col>
                </van-row>
                <van-row>
                  <van-col span="8">延保类型</van-col>
                  <van-col span="16"> {{ item.ewTypeName }} </van-col>
                </van-row>
                <van-row>
                  <van-col span="8">延保里程</van-col>
                  <van-col span="16"> {{ item.ewPeriod }} </van-col>
                </van-row>
                <van-row>
                  <van-col span="8">延保期限</van-col>
                  <van-col span="16">
                    {{ NewtimeFormat(item.ewStartDate) }}-{{
                      NewtimeFormat(item.ewEndDate)
                    }}
                  </van-col>
                </van-row>
                <van-row>
                  <van-col span="8">延保金额</van-col>
                  <van-col :style="{ textAlign: 'right' }" span="16">
                    ¥{{ thousand(item.ewAmount) }}
                  </van-col>
                </van-row>
              </div>
            </van-cell>
            <van-cell
              :style="{ padding: '10px 0' }"
              class="black"
              title="小计"
              :value="`¥ ${thousand(EW_Pirce)}`"
              :border="false"
            />
          </template>
        </van-cell>
        <van-cell
          :style="{ marginBottom: '10px' }"
          :border="false"
          v-if="getLoanOrder != ''"
        >
          <template #title>
            <div class="van-title">
              <span :style="{ fontSize: '16px', fontWeight: 'bold' }"
                >金融</span
              >
              <span class="grey">{{ status }}</span>
            </div>
          </template>
          <template #label>
            <!-- v-for="(item, $index) in JR_item"
                                                                                    :key="$index" -->
            <van-cell :style="{ padding: '10px 0' }">
              <div class="grey" :style="{ fontWeight: 'initial' }">
                <van-row>
                  <van-col span="8">金融机构</van-col>
                  <van-col span="16">
                    {{ getLoanOrder.productProviderName }}
                  </van-col>
                </van-row>
                <van-row>
                  <van-col span="8">金融方案</van-col>
                  <van-col span="16"> {{ jingrong_fangan }} </van-col>
                </van-row>
                <van-row>
                  <van-col span="8">首付比例</van-col>
                  <van-col span="16">
                    {{ thousand(getLoanOrder.firstPayRate) }}%
                  </van-col>
                </van-row>
                <van-row>
                  <van-col span="8">贷款金额</van-col>
                  <van-col span="16"
                    >¥{{ thousand(getLoanOrder.loanAmount) }}</van-col
                  >
                </van-row>
                <van-row>
                  <van-col span="8">服务费</van-col>
                  <van-col span="16" :style="{ textAlign: 'right' }">
                    ¥{{ thousand(getLoanOrder.serviceCharge) }}
                  </van-col>
                </van-row>
              </div>
            </van-cell>
            <van-cell
              :style="{ padding: '10px 0' }"
              class="black"
              title="小计"
              :value="`¥ ${thousand(Financial_Price)}`"
              :border="false"
            />
          </template>
        </van-cell>
        <van-cell
          :style="{ marginBottom: '10px' }"
          :border="false"
          v-if="db_show"
        >
          <template #title>
            <div class="van-title">
              <span :style="{ fontSize: '16px', fontWeight: 'bold' }"
                >代办</span
              >
              <span class="grey">{{ status }}</span>
            </div>
          </template>
          <template #label>
            <van-cell
              :style="{ padding: '10px 0' }"
              v-for="(item, $index) in getCollectionItems"
              :key="$index"
            >
              <div class="grey" :style="{ fontWeight: 'initial' }">
                <van-row>
                  <van-col span="8">代办项目：</van-col>
                  <van-col span="16"> {{ item.itemName }} </van-col>
                </van-row>
                <van-row>
                  <van-col span="8">代办金额：</van-col>
                  <van-col span="16" :style="{ textAlign: 'right' }">
                    ¥{{ thousand(item.collectionAmount) }}
                  </van-col>
                </van-row>
              </div>
            </van-cell>
            <van-cell
              :style="{ padding: '10px 0' }"
              class="black"
              title="小计"
              :value="`¥ ${thousand(agency_Price)}`"
              :border="false"
            />
          </template>
        </van-cell>
        <van-cell
          :style="{ backgroundColor: 'transparent' }"
          class="grey"
          title="注：小计只计算客户付费金额"
          :border="false"
        />
      </div>
    </van-popup>
    <van-popup
      closeable
      @closed="closeSaleList"
      v-model="isShowSaleList"
      position="right"
      :style="{ height: '100vh', width: '100%' }"
      class="popup-class"
    >
      <div class="content">
        <van-cell class="popup-title" title="销售列表" />
        <van-search
          v-model="saleSearch"
          shape="round"
          background="none"
          placeholder="搜索"
          @search="SearchSaleList"
          @blur="SearchSaleList"
          @clear="SearchSaleList"
          @cancel="SearchSaleList"
          @keypress="SearchSaleList"
        />
        <div class="body-content">
          <van-list
            v-model="saleLoading"
            :finished="saleFinished"
            :immediate-check="false"
            finished-text="没有更多了"
            @load="GetSaleList"
            offset="20"
            class="sale-list"
            style="padding: 0"
          >
            <van-cell
              v-for="item in saleList"
              :key="item.SALE_NO"
              :title="item.SALE_NAME"
              :class="[
                dataInfo.getSoMstrInfo.soSalesPsn == item.SALE_NO
                  ? 'titleActive'
                  : '',
              ]"
              @click="changeSale(item)"
            />
          </van-list>
        </div>
      </div>
    </van-popup>

    <!-- 订单详情选配弹出框 -->
    <van-popup
      v-model="orderXpDetailShow"
      position="right"
      :style="{ height: '100%', width: '100%' }"
      class="valueAddedPopup"
    >
      <van-nav-bar
        title="增配/前装"
        right-text="关闭"
        left-arrow
        @click-left="orderXpDetailShow = false"
        @click-right="orderXpDetailShow = false"
        style="margin-bottom: 10px"
      />
      <div class="valueAddedPopupContent">
        <van-panel
          v-for="item in INV_INCREASED"
          :key="item.GOODS_NO"
          :border="false"
        >
          <van-cell class="bold" style="padding-left: 10%" :border="false">
            <div slot="title" style="display: flex">
              <div style="width: 75%">{{ item.GOODS_NAME }}</div>
              <div style="width: 25%">
                <van-button
                  size="small"
                  disabled
                  style="padding: 0 10px; float: right"
                >
                  {{ item.INV_TYPE }}
                </van-button>
              </div>
            </div>
          </van-cell>
          <van-cell
            class="grey"
            style="padding-left: 10%"
            :title="`商品编码：${item.GOODS_NO}`"
            :border="false"
          ></van-cell>
          <van-cell
            class="grey"
            style="padding-left: 10%"
            :title="`厂家编码：${item.GOODSVENDOR_NO || ''}`"
            :border="false"
          ></van-cell>
          <van-cell
            class="grey"
            style="padding-left: 10%"
            :title="`零售价：¥ ${item.PAY_AMT}`"
            :value="`x${item.QTY}`"
            :border="false"
          ></van-cell>
        </van-panel>
        <van-panel>
          <van-cell
            class="bold"
            style="padding-left: 10%; margin-bottom: 20px"
            :border="false"
            title="售价"
            >¥ {{ thousand(XP_price) }}</van-cell
          >
        </van-panel>
      </div>
    </van-popup>

    <!-- 作废 -->

    <van-popup v-model="showNullifyDraft" position="bottom">
      <van-nav-bar :title="NullifyDraftTitle" />
      <!-- <van-radio-group v-model="checked" > -->
      <!-- <van-cell-group inset> -->
      <!-- <van-cell v-for="i in NullifyDraftList" :key="i.ID" :title="i.VALUE" clickable @click="checked = i.VALUE" -->
      <!-- :class="checked == i.VALUE ? 'active-radio' : ''"> -->
      <!-- <template #right-icon> -->
      <!-- <van-radio :name="i.VALUE"></van-radio> -->
      <!-- </template> -->
      <!-- </van-cell> -->
      <!-- </van-cell-group> -->
      <!-- </van-radio-group> -->
      <van-cell-group inset>
        <van-field
          v-model="message"
          rows="4"
          autosize
          label=""
          type="textarea"
          maxlength="200"
          placeholder="请输入原因"
          show-word-limit
        />
      </van-cell-group>
      <div class="flex-button">
        <van-button
          color="#7c7979"
          plain
          @click="
            showNullifyDraft = false;
            checked = '';
          "
          >取消</van-button
        >
        <van-button color="#F7772C" @click="OrderOperation">确认</van-button>
      </div>
    </van-popup>

    <!-- 关闭 -->
    <van-popup
      v-model="showCloseDraftSheet"
      position="bottom"
      :style="{ height: '50%' }"
    />
  </div>
</template>

<script>
import { GetCarSalesOrder } from "../../api/SalesOrderList";
import { GetFinProductBySupplier } from "../../api/OrderGoods";
import {
  PushDepositSave,
  GetPrePayAmt,
  UpdateSoMstrStatus,
  OrderCloseDrop,
} from "../../api/SalesOrderDetails";
import {
  GetTo2SOrg,
  GetEmployeeInfo,
  Get2SOrgNameByType,
} from "../../api/PlaceAnOrder";
import { SaveCarSalesOrder } from "../../api/PlaceAnOrder";
import {
  GetDeliveryConfirmation,
  SaveDeliveryConfirmationNew,
  GetSaleList,
  UpdateSalesOrderSale,
} from "../../api/DeliveryList";
import { thousand, null2str } from "utils";
import seal from "img/seal.svg";
export default {
  data() {
    return {
      soDisabled: false,
      showBack: true,
      actions: [{ text: "更换销售归属" }],
      fileList: [],
      leftArrow: true,
      dataInfo: {},
      status: "处理中",
      seal,
      stepsActive: 2,
      active: 0,
      statisticalDataShow: false,
      showPopover: false,
      showXpPopover: false,
      showPopover1: false,
      thousand,
      Order_Item: decodeURIComponent(this.$route.query),
      so_sale: "",
      soMOBILE: "",
      so_customer: "",
      shangpailist: [
        { text: "公司办理", value: "公司办理" },
        { text: "客户自理", value: "客户自理" },
      ],
      cardTypeList: [
        { text: "身份证", value: "身份证" },
        { text: "港澳居民来往内地通行证", value: "港澳居民来往内地通行证" },
        { text: "台湾居民来往大陆通行证", value: "台湾居民来往大陆通行证" },
        { text: "护照", value: "护照" },
      ],
      CurrentOggList: [],
      getCollectionItems: [],
      getLoanOrder: [],
      getInsOrder: [],
      getGoodsInfos: [],
      getEWOrders: [],
      agency_Price: "",
      Financial_Price: "",
      Insurance_Pirce: "",
      EW_Pirce: "",
      ZH_Pirce: "",
      db_show: false,
      bx_show: false,
      so_car_type: "",
      so_outercolor: "",
      so_Innercolor: "",
      soCarSubTypeCode: "",
      so_amout: "",
      so_create: "",
      soAmt: "",
      shangpaishow: false,
      cardTypeshow: false,
      orderDetailShow: false,
      orderXpDetailShow: false,
      orderDetailList: [],
      jcshow: false,
      dingdanprice: "",
      currentDate: new Date(),
      pushFaPriceShow: false,
      pushPriceModel: "",
      pushListModel: [],
      zzItem_List: [],
      xpItem_List: [],
      skfangshi: "",
      dingjin: "",
      carGP: "",
      ZPGP: "",
      allGP: "",
      customerName: "",
      customerPhone: "",
      customerType: "",
      customerNO: "",
      kpstatus: "",
      kpr: "",
      ISINVOICE: "",
      lxdh: "",
      kxlx: "",
      cardType: "",
      cardTypeNumber: "",
      ERP_soNo: "",
      Vin: "",
      kpsq: "",
      shangpai: "",
      sa_org: "",
      phone: "",
      qudao: "",
      CurrentOgg: "-",
      commission2S: "-",
      so2sOrgType: "",
      teshuYd: "-",
      Order_remarks: "-",
      jcDate: "",
      deliveryFlag: "",
      deliveryDate: "",
      ershouchezh: "-",
      sono: "",
      souche_orderno: "",
      GetCarSalesOrderModel: {
        soNo: "",
      },
      PushDepositModel: {
        orderNo: "",
        bizType: "",
        depositAmount: "",
        operateEmpNo: "",
      },
      GetPrePayAmtModel: {
        StoreNO: "",
        soNo: "",
      },
      LoanOrderproductNo: "",
      GetFinProductModel: {
        useOrgNo: "",
        provider_no: "",
      },
      soStatus: "",
      relFlag: "",
      payStatus: "",
      soStatusShow: false,
      jinrongfanganList: [],
      jingrong_fangan: "",
      //获取二网参数
      GetActivityModel: {
        storeNo: "",
      },
      displayButton: false,

      isShowSaleList: false,
      saleLoading: false,
      saleFinished: false,
      saleList: [],
      saleSearch: "",
      showNullifyDraft: false,
      showCloseDraftSheet: false,
      checked: "",
      NullifyDraftTitle: "",
      NullifyDraftList: [],
      message: "",
      ATTACHMENTS_PATHS: [],
      attachmentsSwitch: process.env.VUE_APP_BASE_UPLOAD_SWITCH == "true",
      INV_INCREASED: [],
      XP_price: 0,
      //代办成本是否显示
      dbcbShow: process.env.VUE_APP_BASE_ENV === "YJ",
    };
  },
  methods: {
    inputHandler() {
      var obj = event.target;
      var t = obj.value.charAt(0);
      obj.value = obj.value
        .replace(".", "$#$") //把第一个字符'.'替换成'$#$'
        .replace(/\./g, "") //把其余的字符'.'替换为空
        .replace("$#$", ".") //把字符'$#$'替换回原来的'.'
        .replace(/[^\d.]/g, "") //只能输入数字和'.'
        .replace(/^\./g, "") //不能以'.'开头
        .replace(/([0-9]+\.[0-9]{2})[0-9]*/, "$1"); //只保留2位小数
      if (t == "-") {
        obj.value = "-" + obj.value;
      }

      console.log(obj.value);
      return obj.value;
    },
    goBack() {
      // this.$router.go(-1);
      if (this.showBack) {
        console.log("返回");
        this.$router.replace({
          path: "../SaleOrderList/SaleOrderList",
          query: {
            ...this.$route.query,
          },
        });
      }
    },
    showSalesStaffList() {
      console.log("更换销售");
      this.isShowSaleList = true;
      this.GetSaleList(0);
    },
    SearchSaleList() {
      this.GetSaleList(0);
    },

    async GetSaleList(length) {
      const params = {
        STORE_NO: this.$route.query.Store_No,
        EMPLOYEE_NO: this.$route.query.EMPLOYEE_NO,
        SALE_NAME: this.saleSearch,
        iDisplayStart: length == 0 ? 0 : this.saleList.length,
        iDisplayLength: 17,
      };
      await GetSaleList(params).then((res) => {
        if (res.data.IsSuccess) {
          if (length == 0) {
            this.saleList = res.data.result.filter((r) => r.SALE_NO != "all");
          } else {
            this.saleList.push(
              ...res.data.result.filter((r) => r.SALE_NO != "all")
            );
          }
          if (res.data.msg.hasMore) this.saleFinished = false;
          if (!res.data.msg.hasMore) this.saleFinished = true;
        } else {
          this.$toast(res.data.msg);
          this.saleList = [];
        }
        this.saleLoading = false;
      });
    },

    changeSale(item) {
      if (item.SALE_NO == this.dataInfo.getSoMstrInfo.soSalesPsn) {
        this.$toast("销售为同一人，无需修改！");
        return;
      }
      console.log("选择", item);
      this.$dialog
        .confirm({
          title: "更换销售顾问",
          message: `是否将当前销售顾问【${this.dataInfo.getSoMstrInfo.soSalesPsnName}】更换为【${item.SALE_NAME}】?`,
        })
        .then(() => {
          this.UpdateSalesOrderSale(item);
        })
        .catch(() => {
          // on cancel
        });
    },

    async UpdateSalesOrderSale(item) {
      let params = {
        soNo: this.sono,
        saleNo: item.SALE_NO,
      };
      await UpdateSalesOrderSale(params).then((res) => {
        this.$toast(res.data.msg);
        if (res.data.IsSuccess) {
          this.dataInfo.getSoMstrInfo.soSalesPsnName = item.SALE_NAME;
          setTimeout(() => {
            this.isShowSaleList = false;
            this.InItDetails();
          }, 2000);
        }
        console.log("---", res);
      });
    },
    closeSaleList() {
      this.isShowSaleList = false;
    },
    // 销售顾问
    selectSalesConsultant() {
      console.log("搜索", this.salesConsultant_value);
    },

    // 日期弹出层 取消
    closePopup() {
      this.jcshow = false;
    },
    // 时间格式化
    timeFormat(time) {
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      return year + "-" + month + "-" + day;
    },
    // 时间格式化
    NewtimeFormat(time) {
      let year = time.split("/")[0];
      let month = time.split("/")[1];
      let day = time.split("/")[2].substring(0, 2);
      return year + "-" + month + "-" + day;
    },
    btnSub() {
      console.log("index=" + 1);
    },
    onshangpaiConfirm(e) {
      this.shangpaishow = false;
      this.shangpai = e.text;
    },
    shangpaicancel(e) {
      this.shangpaishow = false;
      this.shangpai = e.text;
    },
    pushFaPrice() {},
    cardidcancel(e) {
      this.cardTypeshow = false;
      this.cardType = e.text;
    },
    oncardidcancelConfirm(e) {
      this.cardTypeshow = false;
      this.cardType = e.text;
    },
    onJcConfirm(value) {
      this.jcshow = false;
      this.jcDate = this.timeFormat(value);
    },
    async GetEmployeeInfo() {
      await GetEmployeeInfo({ USER_NAME: this.$route.query.EMPLOYEE_NO }).then(
        (res) => {
          if (res.data.IsSuccess) {
            this.PushDepositModel.operateEmpNo = res.data.result[0].EMPLOYEE_NO;
          }
        }
      );
    },
    async pushSub() {
      if (this.pushPriceModel == 0 || this.pushPriceModel == "") {
        this.$toast("请输入正确的推送金额");
        return;
      }
      this.PushDepositModel.orderNo = this.sono;
      this.PushDepositModel.bizType = "NC";
      this.PushDepositModel.depositAmount = this.pushPriceModel;
      // this.PushDepositModel.operateEmpNo = this.$route.query.EMPLOYEE_NO;
      // 修改
      await PushDepositSave(this.PushDepositModel).then((res) => {
        if (res.data.IsSuccess) {
          this.$toast("推送订金成功");
          this.GetPrePayAmt();
        } else {
          this.$toast(res.data.msg);
        }
      });
    },
    //金融方案
    async NewAddjinrongfangan() {
      this.GetFinProductModel.useOrgNo = this.$route.query.Store_No;
      this.GetFinProductModel.provider_no = this.jinrong_OrgNo;
      await GetFinProductBySupplier(this.GetFinProductModel).then((res) => {
        if (res.data.IsSuccess) {
          if (this.sono != "") {
            this.jinrongfanganList = res.data.result;
            const result = this.jinrongfanganList.filter(
              (u) => u.FIN_PRODUCT_NO == this.LoanOrderproductNo
            );

            if (result.length > 0) {
              this.jingrong_fangan = result[0].FIN_PRODUCT_NAME;
            }
          }
          this.jinrongfanganList = res.data.result;
        }
      });
    },

    async InItDetails() {
      this.$store.commit("showLoading");
      this.GetCarSalesOrderModel.soNo = this.sono;
      this.GetCarSalesOrderModel.UserId = this.$route.query.EMPLOYEE_NO;
      await GetCarSalesOrder(this.GetCarSalesOrderModel).then((res) => {
        console.log("获取数据", res);
        const getSoMstrInfo = null2str(res.data.result.getSoMstrInfo);
        if (res.data.IsSuccess) {
          this.valueAddStatus = getSoMstrInfo.valueAddStatus;
          this.dataInfo = res.data.result;
          this.ATTACHMENTS_PATHS = res.data.ATTACHMENTS_PATHS;
          this.so_customer = getSoMstrInfo.cusName;
          this.ERP_soNo = getSoMstrInfo.soNo;
          this.souche_orderno = getSoMstrInfo.OrderNo;
          this.kpsq = getSoMstrInfo.ApprovalType;
          this.shangpai = getSoMstrInfo.carIDRegistrationWayName;
          this.ershouchezh = getSoMstrInfo.isUsedCarDisplace == 1 ? "是" : "否";
          this.jcDate = getSoMstrInfo.estimatedDeliveryCarDate;
          this.deliveryFlag = getSoMstrInfo.deliveryFlag;
          this.deliveryDate = getSoMstrInfo.deliveryDate;
          this.so_create = getSoMstrInfo.createDate;
          this.sa_org = getSoMstrInfo.storeName;
          this.so_sale = getSoMstrInfo.soSalesPsnName;
          this.soMOBILE = getSoMstrInfo.soMOBILE;
          this.phone = getSoMstrInfo.cusMobile;
          this.qudao = getSoMstrInfo.saleTypeName;
          this.CurrentOggList.filter((u) => u.value == getSoMstrInfo.store2SNo);

          this.CurrentOgg =
            this.CurrentOggList.filter(
              (u) => u.value == getSoMstrInfo.store2SNo
            )[0] || "";
          this.soStatus = getSoMstrInfo.soStatus;
          this.relFlag = getSoMstrInfo.relFlag;
          this.payStatus = getSoMstrInfo.payStatus;

          this.Order_remarks = getSoMstrInfo.remark;
          this.teshuYd = getSoMstrInfo.spc_agm;
          this.commission2S = getSoMstrInfo.commission2S;
          this.so2sOrgType = getSoMstrInfo.so2sOrgType;
          this.store2SNo = getSoMstrInfo.store2SNo;
          if (this.ENV == "SS") this.Get2SOrgNameByType();
          //根据订单状态控制是否显示编辑按钮功能
          console.log(
            "生效",
            this.soStatus.indexOf("草稿") > -1,
            this.relFlag.indexOf("已出库")
          );
          if (
            this.soStatus.indexOf("草稿") > -1 ||
            this.soStatus.indexOf("申请被拒绝") > -1
          ) {
            this.soStatusShow = true;
          } else {
            this.soStatusShow = false;
          }
          //车辆信息
          this.Vin = res.data.result.getCarInfo.soCarVin;
          this.so_car_type = res.data.result.getCarInfo.soCarSubTypeName;
          this.so_outercolor = res.data.result.getCarInfo.soCarColorName;
          this.so_Innercolor = res.data.result.getCarInfo.soCarInnerColorName;
          this.soCarSubTypeCode = res.data.result.getCarInfo.soCarSubTypeCode;
          // this.so_amout = getSoMstrInfo.soCarAmt;
          this.so_amout = res.data.result.getCarInfo.soCarSalePrice;
          this.soAmt = getSoMstrInfo.soAmt;
          // this.kpr = getSoMstrInfo.actualOwnerCusName;
          //  this.cardTypeNumber = getSoMstrInfo.GetCarPersonCardNo;
          //开票人为客户
          this.ISINVOICE = getSoMstrInfo.ISINVOICE;
          this.kxlx = getSoMstrInfo.ACTUALCUSISPERSON;
          this.kpr = getSoMstrInfo.actualOwnerCusName;
          this.lxdh = getSoMstrInfo.actualOwnerCusMobile;
          this.cardType = getSoMstrInfo.ACTUALCERTIFICATETYPE;
          this.cardTypeNumber = getSoMstrInfo.ACTUALCERTIFICATENO;
          if (getSoMstrInfo.ISINVOICE == 0) {
            this.customerName = getSoMstrInfo.cusName;
            this.customerPhone = getSoMstrInfo.cusMobile;
            this.customerType = getSoMstrInfo.cusCertificateType;
            this.customerNO = getSoMstrInfo.CUSCERTIFICATENO;
          } else {
            this.customerName = this.kpr;
            this.customerPhone = this.lxdh;
            this.customerType = this.cardType;
            this.customerNO = this.cardTypeNumber;
          }
          this.kpstatus = getSoMstrInfo.invoiceFlag;
          this.skfangshi = res.data.result.payType;
          this.zzItem_List = [];
          this.xpItem_List = [];
          this.getGoodsInfos = [];
          if (res.data.result.getGoodsInfos != null) {
            let goods_amount = res.data.result.getGoodsInfos.reduce(
              (sum, e) => {
                if (e.comboNo == "" && e.payModeNo == 0) {
                  return sum + Number(e.damount || 0);
                } else {
                  return sum + Number(0);
                }
              },
              0
            );
            let combos_amount = res.data.result.getGoodsCombos
              ? res.data.result.getGoodsCombos.length > 0
                ? res.data.result.getGoodsCombos.reduce((sum, e) => {
                    if (e.payModeNo == 0) {
                      return sum + (Number(e.amount) * Number(e.qty) || 0);
                    } else {
                      return sum + Number(0);
                    }
                  }, 0)
                : 0
              : 0;
            this.zzItem_List.push({
              Item_Name: "装潢",
              Item_Price: goods_amount + combos_amount,
            });
            this.getGoodsInfos = res.data.result.getGoodsCombos
              ? res.data.result.getGoodsCombos.filter((res) => {
                  res.itemNo = res.comboNo;
                  res.itemName = res.comboName;
                  res.amount = Number(res.amount);
                  return res;
                })
              : [];
            res.data.result.getGoodsInfos.forEach((res) => {
              if (res.comboNo == "") {
                this.getGoodsInfos.push(res);
              }
            });
            const Goods = res.data.result.getGoodsInfos.reduce((sum, e) => {
              if (e.payModeNo == 0 && e.comboNo == "") {
                return sum + Number(e.damount || 0);
              }
              return sum + 0;
            }, 0);
            const Combos = res.data.result.getGoodsCombos
              ? res.data.result.getGoodsCombos.reduce((sum, e) => {
                  if (e.payModeNo == 0) {
                    return sum + (Number(e.amount) * Number(e.qty) || 0);
                  } else {
                    return sum + 0;
                  }
                }, 0)
              : 0;
            this.ZH_Pirce = Combos + Goods;
          }

          if (res.data.result.getEWOrders != null) {
            let combos_amount = res.data.result.getEWOrders
              ? res.data.result.getEWOrders.length > 0
                ? res.data.result.getEWOrders.reduce((sum, e) => {
                    return sum + (Number(e.ewAmount) || 0);
                  }, 0)
                : 0
              : 0;
            this.zzItem_List.push({
              Item_Name: "延保",
              // Item_Price: res.data.result.getEWOrders[0].ewAmount,
              Item_Price: combos_amount,
            });
            this.getEWOrders = res.data.result.getEWOrders;
            this.EW_Pirce = res.data.result.getEWOrders.reduce(
              (sum, e) => sum + Number(e.ewAmount || 0),
              0
            );
          }

          if (res.data.result.getLoanOrder != null) {
            this.zzItem_List.push({
              Item_Name: "金融",
              Item_Price: res.data.result.getLoanOrder.serviceCharge,
            });
            this.getLoanOrder = res.data.result.getLoanOrder;
            this.Financial_Price = this.getLoanOrder.serviceCharge;
            this.jinrong_OrgNo = this.getLoanOrder.productProviderNo;
            this.LoanOrderproductNo = this.getLoanOrder.productNo;
            this.NewAddjinrongfangan();
          }
          if (res.data.result.getCollectionItems != null) {
            this.zzItem_List.push({
              Item_Name: "代办",
              Item_Price: getSoMstrInfo.soAgentAmt,
            });
            this.db_show = true;
            this.getCollectionItems = res.data.result.getCollectionItems;
            console.log("this.dbcb_show" + this.dbcb_show);
            if (this.dbcbShow) {
              this.agency_Price = res.data.result.getCollectionItems.reduce(
                (sum, e) => sum + Number(e.collectionAmount || 0),
                0
              );
            } else {
              this.agency_Price = res.data.result.getCollectionItems.reduce(
                (sum, e) => sum + Number(e.collectionCostAmount || 0),
                0
              );
            }
          }

          if (res.data.result.getInsOrder != null) {
            this.getInsOrder = res.data.result.getInsOrder;
            this.Insurance_Pirce = res.data.result.getInsOrder.getInsOrderItems
              ? res.data.result.getInsOrder.getInsOrderItems.reduce(
                  (sum, e) => sum + Number(e.premiumAmount || 0),
                  0
                )
              : "";

            this.zzItem_List.push({
              Item_Name: "保险",
              Item_Price: this.Insurance_Pirce,
            });

            this.bx_show = true;
          }
          if (res.data.result.grossProfit != null) {
            this.carGP = res.data.result.grossProfit.carGP;
            this.allGP = res.data.result.grossProfit.allGP;
            this.ZPGP = res.data.result.grossProfit.ZPGP;
          }
          //选配
          console.log(this.zzItem_List, "zzItem_List");

          this.INV_INCREASED = res.data.result.INV_INCREASED || [];

          if (this.INV_INCREASED.length > 0) {
            this.XP_price = this.INV_INCREASED.reduce(function (prev, curr) {
              return prev + curr.PAY_AMT * curr.QTY;
            }, 0);

            let zp_Price = res.data.result.INV_INCREASED.reduce((res, cur) => {
              let val = cur.INV_TYPE == "增配" ? cur.PAY_AMT * cur.QTY : 0;
              return res + val;
            }, 0);
            console.log(zp_Price, "zp_Price");
            if (zp_Price > 0) {
              this.xpItem_List.push({
                Item_Name: "增配",
                Item_Price: zp_Price,
              });
            }

            let qz_Price = res.data.result.INV_INCREASED.reduce((res, cur) => {
              let val = cur.INV_TYPE == "前装" ? cur.PAY_AMT * cur.QTY : 0;
              return res + val;
            }, 0);
            if (qz_Price > 0) {
              this.xpItem_List.push({
                Item_Name: "前装",
                Item_Price: qz_Price,
              });
            }
            console.log(this.xpItem_List, "this.xpItem_List");
          }
          //显示图片
          this.setfileList(res.data.result.ATTACHMENTS_PATHS || []);
        } else {
          this.$toast(res.data.msg);
        }
      });
    },

    async GetPrePayAmt() {
      this.GetPrePayAmtModel.SO_NO = this.sono;
      this.GetPrePayAmtModel.StoreNO = this.$route.query.Store_No;
      await GetPrePayAmt(this.GetPrePayAmtModel).then((res) => {
        if (res.data.IsSuccess) {
          this.pushListModel = res.data.result;
          this.dingjin = this.pushListModel.reduce(
            (sum, e) => sum + Number(e.ARD_AMT || 0),
            0
          );
        }
      });
    },
    setfileList(attachments) {
      this.fileList = [];
      attachments.forEach((item) => {
        this.fileList.push({
          url: item.ATTA_PATH,
          isImage: true,
        });
      });
      console.log(this.fileList, "this.fileList");
    },
    orderEdit() {
      sessionStorage.removeItem("orderInfo");
      sessionStorage.removeItem("temp");
      this.$router.push({
        path: "../OrderGoods/OrderGoods",
        query: {
          ...this.$route.query,
          // soNo: this.sono,
        },
      });
    },
    DeliveryVehicle() {
      this.displayButton = true;
      this.GetDeliveryConfirmation(this.sono);
    },
    async GetDeliveryConfirmation(NO) {
      this.$store.commit("showLoading");
      const params = {
        BIZ_REF_NO: NO,
        USER_ID: this.$route.query.EMPLOYEE_NO,
      };
      //商社客制化---直接交车
      if (this.ENV == "SS") {
        console.log("1111");
        await SaveDeliveryConfirmationNew(params).then((res) => {
          console.log(res, "商社客制化---直接交车");
          this.$toast(res.data.msg);
          this.InItDetails();
        });
      } else {
        await GetDeliveryConfirmation(params).then((res) => {
          this.$store.commit("hideLoading");
          if (!res.data.IsSuccess) {
            this.$dialog.alert({
              message: res.data.msg,
            });
          } else {
            this.$router.push({
              path: "/DeliveryList/DeliveryConfirmationForm",
              query: {
                ...this.$route.query,
                soNo: NO,
                type: "OrderGoods",
              },
            });
          }
          this.displayButton = false;
        });
      }
    },
    async OrderCloseDrop(params) {
      await OrderCloseDrop(params).then((res) => {
        console.log(res);
        this.NullifyDraftList = res.data.result;
        this.showNullifyDraft = true;
      });
    },

    closeOrder(val) {
      //关闭订单
      // --shopCode  门店编码
      // --bizOrderNo 业务单据编码(大搜车业务编码)
      // --orderStatus 订单状态【订单关闭 Close ；作废  Cancel;  】
      // --optUserNo  3.0员工账号编码
      this.$dialog
        .confirm({
          title: `${val == "Close" ? "关闭" : "作废"}订单`,
          message: `是否确认${val == "Close" ? "关闭" : "作废"}订单`,
        })
        .then(() => {
          this.UpdateSoMstrStatus(val);
        })
        .catch(() => {
          // on cancel
        });
    },
    cancelledDraft() {
      this.NullifyDraftTitle = "作废草稿单";
      const params = {
        shopCode: this.$route.query.Store_No,
        Type_Name: "订单作废原因",
      };
      this.OrderCloseDrop(params);
      console.log("浩物作废");
    },
    closeDraft() {
      this.NullifyDraftTitle = "关闭订单";
      console.log("浩物关闭");
      const params = {
        shopCode: this.$route.query.Store_No,
        Type_Name: "订单关闭原因",
      };
      this.OrderCloseDrop(params);
    },
    CloseCurrentOrder() {
      console.log("关闭订单");
    },
    async OrderOperation() {
      if (!this.message) {
        this.$dialog.alert({
          message: `请输入${this.NullifyDraftTitle}的原因`,
        });
        return;
      }
      console.log("--", this.message);
      const params = {
        shopCode: this.$route.query.Store_No,
        orderStatus: this.NullifyDraftTitle == "关闭订单" ? "Close" : "Cancel",
        optUserId: this.$route.query.EMPLOYEE_NO,
        bizOrderNo: this.dataInfo.getSoMstrInfo.OrderNo,
        reason: this.message,
      };
      console.log("--params", params);

      await UpdateSoMstrStatus(params).then((res) => {
        if (res.data.IsSuccess) {
          this.message = "";
          this.showNullifyDraft = false;
          this.InItDetails();
        }
        this.$dialog.alert({
          message: res.data.msg,
        });
      });
    },
    async i(params) {
      await UpdateSoMstrStatus(params).then((res) => {
        if (res.data.IsSuccess) {
          this.showNullifyDraft = false;
          this.InItDetails();
        }
        this.$dialog.alert({
          message: res.data.msg,
        });
      });
    },
    async UpdateSoMstrStatus(val) {
      const params = {
        shopCode: this.$route.query.Store_No,
        orderStatus: val,
        optUserId: this.$route.query.EMPLOYEE_NO,
        bizOrderNo: this.dataInfo.getSoMstrInfo.OrderNo,
      };
      await UpdateSoMstrStatus(params).then((res) => {
        if (res.data.IsSuccess) {
          this.InItDetails();
        }
        this.$dialog.alert({
          message: res.data.msg || "操作失败",
        });
      });
    },
    getNowDateTime() {
      var myDate = new Date();
      var year = myDate.getFullYear(); //获取当前年
      var mon = myDate.getMonth() + 1; //获取当前月
      var date = myDate.getDate(); //获取当前日
      var hours = myDate.getHours(); //获取当前小时
      var minutes = myDate.getMinutes(); //获取当前分钟
      var seconds = myDate.getSeconds(); //获取当前秒
      var now =
        year +
        "-" +
        mon +
        "-" +
        date +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds;
      return now;
    },
    submitApproval() {
      this.$dialog
        .confirm({
          title: "提交审核",
          message: "是否确定提交审核?",
        })
        .then(() => {
          this.soDisabled = true;
          this.SubmitApproval();
        })
        .catch(() => {
          // on cancel
        });
    },
    async ProcessingInput() {
      this.$store.commit("showLoading");
      var result = await this.InItDetails().then((res) => {
        console.log("--获取数据", res, this.dataInfo);
        console.log("提交2");
        let goodsItems = [];
        let ewOrder = [];
        let collectionItems = [];
        // let damountSum = this.dataInfo.getGoodsInfos && this.dataInfo.getGoodsInfos.reduce((sum, e) => sum + Number(e.damount || 0), 0);
        this.dataInfo.getGoodsInfos &&
          this.dataInfo.getGoodsInfos.forEach((item) => {
            if (item.comboNo == "") {
              goodsItems.push({
                itemNo: item.itemNo,
                damount: Number(item.damount),
                discount: Number(item.discount),
                payModeNo: item.payModeNo,
                payModeName: item.payModeName,
                qty: item.qty,
                sellTypeCode: "XS",
                maintainClassNo: "3",
                price:
                  Number(item.damount) /
                  Number(item.qty) /
                  Number(item.discount),
              });
              console.log("我", goodsItems);
            } else {
              goodsItems.push({
                itemNo: item.itemNo,
                comboNo: item.comboNo,
                sellTypeCode: "XS",
                maintainClassNo: "3",
                payModeNo: item.payModeNo,
                payModeName: item.payModeName,
                qty: item.qty,
                damount: Number(item.damount),
                discount: Number(1),
                price: Number(item.damount),
                // ori_Price: item.ori_Price,
                // totalComboAmt: this.dataInfo.getGoodsCombos.filter(r=> r.itemNo == item.comboNo )[0].amount,//套餐的价格
                // damountSum: damountSum,//套餐销售总价格
              });
            }
          });

        // this.dataInfo.getGoodsCombos && this.dataInfo.getGoodsCombos.forEach(item => {
        //   goodsItems.push({
        //     itemNo: item.itemNo,
        //     comboNo: item.comboNo,
        //     damount: Number(item.price) * Number(item.qty),
        //     discount: 1,
        //     price: item.price

        //   })

        // })
        this.dataInfo.getEWOrders &&
          this.dataInfo.getEWOrders.forEach((item) => {
            ewOrder.push({
              eWId: item.EWOrderNo,
              ewProductNo: item.ewProductNo,
              ewAmount: item.ewAmount,
              ewStartDate: item.ewStartDate,
              ewEndDate: item.ewEndDate,
              WMType: item.WMType || "",
              EwPTime: item.EwPTime || "",
            });
          });

        this.dataInfo.getCollectionItems &&
          this.dataInfo.getCollectionItems.forEach((item) => {
            collectionItems.push({
              itemNo: item.itemNo,
              collectionAmount: item.collectionAmount,
              collectionCostAmount: item.collectionCostAmount,
            });
          });

        const so_subModel = {};
        so_subModel.isApprove = 1;
        so_subModel.EW_Pirce = "";
        so_subModel.isEWS = "1";
        so_subModel.optType = 1;
        so_subModel.ATTACHMENTS_PATHS = this.dataInfo.ATTACHMENTS_PATHS;
        so_subModel.soMstrInfo = {
          // ...this.dataInfo.getSoMstrInfo,
          soNo: this.dataInfo.getSoMstrInfo.soNo,
          storeNo: this.dataInfo.getSoMstrInfo.storeNo,
          approveType: this.dataInfo.getSoMstrInfo.ApprovalType,
          cusNo: this.dataInfo.getSoMstrInfo.cusNo,
          cusMobile: this.dataInfo.getSoMstrInfo.cusMobile,
          cusName: this.dataInfo.getSoMstrInfo.cusName,
          cusIsPerson: this.dataInfo.getSoMstrInfo.CUSISPERSON,
          clientProvinceName: "",
          cusCertifiCateNo: this.dataInfo.getSoMstrInfo.CUSCERTIFICATENO,
          actualOwnerCusNo: this.dataInfo.getSoMstrInfo.actualOwnerCusNo,
          actualCertifiCateNo: this.dataInfo.getSoMstrInfo.ACTUALCERTIFICATENO,
          actualCertificateType:
            this.dataInfo.getSoMstrInfo.ACTUALCERTIFICATETYPE,
          cusCertificateType: this.dataInfo.getSoMstrInfo.cusCertificateType,
          actualCusIsPerson: this.dataInfo.getSoMstrInfo.ACTUALCUSISPERSON,
          actualMobile: this.dataInfo.getSoMstrInfo.actualOwnerCusMobile,
          actualName: this.dataInfo.getSoMstrInfo.actualOwnerCusName,
          plateUserProvinceName: "",
          saleTypeName: this.dataInfo.getSoMstrInfo.saleTypeName,
          saleChannelName: this.dataInfo.getSoMstrInfo.saleChannelName,
          carUseType: this.dataInfo.getSoMstrInfo.carUseType,
          storePurpose: this.dataInfo.getSoMstrInfo.storePurpose,
          getCarPersonName: this.dataInfo.getSoMstrInfo.getCarPersonName,
          GetCarPersonCardNo: this.dataInfo.getSoMstrInfo.GetCarPersonCardNo,
          isUsedCarDisplace: Number(
            this.dataInfo.getSoMstrInfo.isUsedCarDisplace
          ),
          usedCarPurchaseAmount:
            this.dataInfo.getSoMstrInfo.usedCarPurchaseAmount,
          usedCarPurchaseNo: this.dataInfo.getSoMstrInfo.usedCarPurchaseNo,
          carIDRegistrationWayName:
            this.dataInfo.getSoMstrInfo.carIDRegistrationWayName,
          soSalesPsnNo: this.dataInfo.getSoMstrInfo.soSalesPsnNo,
          updatePsnNo: this.dataInfo.getSoMstrInfo.updatePsnNo,
          soDeposit: this.dataInfo.getSoMstrInfo.soDepost,
          saasOrderSource: "线下",
          freezeFlag: "0",
          revertFlag: "0",
          orderNo: this.dataInfo.getSoMstrInfo.OrderNo,
          versionNumber: 0,
          intentionId: this.dataInfo.getSoMstrInfo.intentionNo,
          buyType: this.dataInfo.getSoMstrInfo.buyType,
          carNature: this.dataInfo.getSoMstrInfo.carNature,
          remark: this.dataInfo.getSoMstrInfo.remark,
          spc_agm: this.dataInfo.getSoMstrInfo.spc_agm,
          store2SNo: this.dataInfo.getSoMstrInfo.store2SNo,
          estimatedDeliveryCarDate:
            this.dataInfo.getSoMstrInfo.estimatedDeliveryCarDate.split(" ")[0],
          createDate: this.getNowDateTime(),
          updateDate: this.getNowDateTime(),
          invoiceCompanyNo: this.dataInfo.getSoMstrInfo.invoiceCompanyOrgNo,
        };
        if (this.ENV == "SS")
          so_subModel.soMstrInfo.commission2S =
            this.dataInfo.getSoMstrInfo.commission2S;
        so_subModel.carInfo = {
          // ...this.dataInfo.getCarInfo,
          soCarBrandCode: this.dataInfo.getCarInfo.soCarBrandCode,
          soCarClassCode: this.dataInfo.getCarInfo.soCarClassCode,
          soCarTypeCode: this.dataInfo.getCarInfo.soCarTypeCode,
          soCarSubTypeCode: this.dataInfo.getCarInfo.soCarSubTypeCode,
          soCarColorNo: this.dataInfo.getCarInfo.soCarColorNo,
          soCarInnerColorNo: this.dataInfo.getCarInfo.soCarInnerColorNo,
          soCarSalePrice: this.dataInfo.getCarInfo.soCarSalePrice,
          soCarVin: this.dataInfo.getCarInfo.soCarVin,
        };
        so_subModel.goodsItems = [...goodsItems];
        if (this.dataInfo.getInsOrder) {
          so_subModel.insOrder = {
            InsCorpNo: this.dataInfo.getInsOrder.InsCorpNo,
            InsOrderItems: this.dataInfo.getInsOrder.getInsOrderItems || [],
          };
          console.log("==99", this.dataInfo.getInsOrder, so_subModel.insOrder);
        }
        if (this.dataInfo.getLoanOrder) {
          so_subModel.loanOrder = {
            productProviderNo: this.dataInfo.getLoanOrder.productProviderNo,
            productNo: this.dataInfo.getLoanOrder.productNo,
            loanTerms: this.dataInfo.getLoanOrder.loanTerms,
            loanAmount: this.dataInfo.getLoanOrder.loanAmount,
            serviceCharge: this.dataInfo.getLoanOrder.serviceCharge,
            cusSelfpayInterestAmt:
              this.dataInfo.getLoanOrder.cusSelfpayInterestAmt,
          };
        }
        if (this.dataInfo.getEWOrders) {
          so_subModel.ewOrder = { ...ewOrder[0] };
          so_subModel.ewOrders = [...ewOrder];
        }
        if (this.dataInfo.getCollectionItems) {
          so_subModel.collectionItems = [...collectionItems];
        }
        return so_subModel;
      });
      return Promise.resolve(result);
    },
    SubmitApproval() {
      this.ProcessingInput().then((so_subModel) => {
        console.log("so_subModel,", so_subModel);
        if (so_subModel) {
          SaveCarSalesOrder(so_subModel).then((res) => {
            console.log(res);

            if (res.data.IsSuccess) {
              this.zzItem_List = [];
              this.InItDetails().then(() => {
                this.soDisabled = false;
                // this.$store.commit('hideLoading');
                this.$toast("提交审批成功！");
              });
            } else {
              this.soDisabled = false;
              // this.$store.commit('hideLoading');
              this.$toast(res.data.msg);
            }
          });
        }
      });
    },

    copyText(val) {
      // 模拟 输入框
      SaveCarSalesOrder();
      var cInput = document.createElement("input");
      cInput.value = val;
      document.body.appendChild(cInput);
      cInput.select(); // 选取文本框内容

      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
      // Input要在正常的编辑状态下原生复制方法才会生效

      document.execCommand("copy");

      this.$toast("复制成功");
      // 复制成功后再将构造的标签 移除
      document.body.removeChild(cInput);
    },

    // 获取二网机构列表
    async GetTo2SOrg() {
      this.GetActivityModel.storeNo = this.$route.query.Store_No;
      await GetTo2SOrg(this.GetActivityModel).then((res) => {
        let newList = res.data.result.map((item) => {
          let text = "text";
          let value = "value";
          return {
            [text]: item.ORGNAME,
            [value]: item.ORGNO,
          };
        });
        this.CurrentOggList = newList;
      });
    },
    async Get2SOrgNameByType() {
      const data = {
        so2sOrgType: this.so2sOrgType,
        storeNo: this.$route.query.Store_No,
        store2S_CusNo: this.store2SNo,
      };
      await Get2SOrgNameByType(data).then((res) => {
        let list = res.data.result.list.filter(
          (v) => v.OBJECTNO == this.store2SNo
        );
        this.CurrentOgg = list.length > 0 ? list[0].OBJECTNAME : "";
        console.log(this.store2SNo, this.CurrentOgg);
      });
    },
  },
  created() {
    sessionStorage.removeItem("orderInfo");
    sessionStorage.removeItem("temp");
    sessionStorage.removeItem("otherInfo");
    // 获取传过来的参数
    // this.sono = decodeURIComponent(this.$route.query.soNo || this.$route.query.soNo);
    this.sono = this.$route.query.soNo || this.$route.query.soNo;
    this.InItDetails();
    this.GetPrePayAmt();
    if (this.ENV != "SS") this.GetTo2SOrg();
    this.GetEmployeeInfo();
    this.showBack = this.$route.query.isAndroidOrIOS != "WECHAT";
  },
};
</script>
<style lang="less" scoped>
.orderGoods {
  // background: #f2f2f2;
  height: 100%;
  overflow: hidden;

  // .van-nav-bar {
  //   width: 100%;
  //   position: fixed;
  //   top: 0;
  // }

  .orderGoodsCenter {
    overflow: scroll;
    background: #f2f2f2;
    // height: calc(100% - 160px);
    // position: fixed;
    // top: 71px;
    // left: 0;
    width: 100%;
    // font-size: 0;
    // margin-top: 70px;
  }

  .topInfo {
    border-radius: 10px 10px 0 0;
    white-space: initial;
    flex: none;

    .info {
      font-size: 12px;
      word-wrap: break-word;
      overflow: scroll;
      margin-top: 10px;
      color: #5b5b5b;
      line-height: 25px;
    }
  }

  .carSubTypeName {
    font-size: 18px;
    font-weight: bold;
  }

  .bottomInfo {
    .van-cell__value {
      color: #323233;
    }
  }

  .grey {
    color: #6b6b71 !important;
  }

  .orange {
    color: @themeColor !important;
  }

  .orange-bold {
    .van-cell__title,
    .van-cell__value {
      font-weight: bold;
      color: @themeColor;
    }
  }

  .orange-bold {
    .van-cell__title,
    .van-cell__value {
      font-weight: bold;
      color: @themeColor;
    }
  }

  .black {
    color: #323233;
    font-weight: bold;

    .van-cell__title,
    .van-cell__value {
      color: #323233;
      font-weight: bold;
    }
  }

  .bold {
    font-weight: bold;
  }

  .orderPayment {
    color: #1b1b1b;
    font-weight: bold;

    .van-cell__title {
      display: flex;
      align-items: center;
    }
  }

  .redundancy {
    display: flex;
    flex-direction: column-reverse;

    /deep/ .van-collapse-item__content {
      padding: 0;
    }

    /deep/.van-cell {
      justify-content: center;

      .van-cell__title {
        flex: none;
      }
    }
  }

  .warning {
    background: #fdf7d7;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    // position: fixed;
    // bottom: 50px;
    font-size: 14px;
    width: 100%;
    color: #26212d;

    .van-icon {
      margin-right: 5px;
      color: @themeColor;
      font-size: 16px;
    }
  }

  .footerButton {
    // width: 100%;
    padding: 10px;
    float: right;
    height: 50px;
    background: #fff;
    font-size: 0;
    // display: flex;
    // justify-content: right;
    // align-items: center;
  }

  .van-cell__value_left {
    .van-cell__title {
      width: 40%;
      flex: none;
    }

    .van-cell__value {
      text-align: left;
    }
  }

  .valueAddedPopup {
    background: #f2f2f2;
    display: flex;
    flex-direction: column;
    height: 100vh;

    .van-title {
      display: flex;
      justify-content: space-between;
    }

    .valueAddedPopupContent {
      overflow: scroll;
    }
  }
}

.tipsCenter {
  font-size: 12px;
  width: 150px;
  padding: 10px;
}

.popup-title {
  font-size: 14px;
  font-weight: bold;
  flex: none;

  .van-cell__title {
    text-align: center;
    padding-top: 2px;
  }
}

.titleActive {
  color: #f7772c;
}

/deep/.van-radio__icon--round .van-icon {
  border: 0;
}

/deep/.van-radio__icon--checked .van-icon {
  color: #f7772c;
  background-color: rgba(0, 0, 0, 0);
}

/deep/.active-radio .van-cell__title {
  color: #f7772c;
}

.flex-button {
  display: flex;
  justify-content: space-between;
  padding: 15px;

  button {
    width: 48%;
  }
}
.uploader {
  padding: 20px 15px;
}
.bold {
  font-weight: bolder;
  font-size: 14px;
  padding-top: 2px;
  padding-bottom: 2px;
  // font-size:large;
}

.grey {
  color: #5e5e5e;
  padding-top: 0px;
  padding-bottom: 0px;

  .van-cell__value--alone {
    color: #5e5e5e;
  }

  .van-tag {
    font-weight: bold;
  }
}
.van-cell::after {
  border-bottom: 0px solid #ebedf0 !important;
}
</style>
