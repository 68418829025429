<template>
  <div class="user">
    <van-nav-bar title="标题" :left-arrow="showBack" @click-left="goBack">
      <template #title>
        <van-tabs v-model="Index" class="tabs">
          <van-tab title="选择车型"></van-tab>
          <van-tab title="输入VIN码"></van-tab>
        </van-tabs>
      </template>
    </van-nav-bar>
    <div class="content">
      <div v-show="Index == 0" class="line">
        <!-- 搜索框 -->
        <form action="/">
          <van-search v-model="Brandvalue" shape="round" background="#fff" placeholder="请输入品牌车系" @search="CarTypeSearch"
            @blur="CarTypeSearch" @clear="CarTypeSearch" @cancel="CarTypeSearch" @keypress="CarTypeSearch" />
        </form>
        <div class="list">
          <!-- <van-pull-refresh v-model="refreshing" @refresh="onRefresh"> -->
          <!-- <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad"> -->
          <van-cell clickable v-for="(item, index) in items" :key="index" :title="item.BRANDNAME" :title-class="{
            titleActive: CarBrandInfoModel.BrandCode === item.BRANDCODE,
          }" @click="getRowId(item)" />
          <!-- </van-list> -->
          <!-- </van-pull-refresh> -->
        </div>

        <!-- 没懂为什么写两份暂时注销 -->
        <!-- <div>
                              <div
                                class="content_details"
                                v-for="(item, index) in items"
                                :key="index"
                                @click="getRowId(item.BRANDCODE)"
                              >
                                {{ item.BRANDNAME }}
                              </div>
                            </div>

                            <div class="contentList">
                              <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                                <van-list
                                  v-model="loading"
                                  :finished="finished"
                                  :immediate-check="false"
                                  finished-text="没有更多了"
                                  @load="onLoad"
                                >
                                  <div
                                    class="card"
                                    @click="getRowId(item.BRANDCODE)"
                                    v-for="(item, index) in items"
                                    :key="index"
                                  >
                                    <div class="card_title">
                                      <div class="title_name">{{ item.BRANDNAME }}</div>
                                    </div>
                                  </div>
                                </van-list>
                              </van-pull-refresh>
                            </div> -->
      </div>
      <div v-show="Index == 1" class="line">
        <div class="content_userName input_box">
          <!-- <van-field v-model="ModelVIN" placeholder="输入17位或后6位VIN码车辆" maxlength="17" /> -->
          <form action="/">
            <van-search v-model="ModelVIN" shape="round" background="#fff" placeholder="输入17位或后6位VIN码车辆"
              @search="vinQuery"  maxlength="17" @clear="vinQuery" @cancel="vinQuery"
               />
          </form>
          <van-list class="vinVanlist">
            <van-cell v-for="(item,index) in vehicleInformation" :key="index" @click="selectVehicle(item)">
              <div :class="[selectedCar == item ? 'cars-list check-border' : 'cars-list']">
                <div class="cars-name">{{ item.SUBTYPE_NAME }}</div>
                <div class="cars-info">{{ item.INCOLOR }} | {{ item.OUTCOLOR }}</div>
                <div class="cars-info">车型编码 {{ item.SUBTYPE_CODE }}</div>
                <div class="cars-info">VIN码 {{ item.VIN }}</div>
              </div>
            </van-cell>

          </van-list>
          <div class="footerButton">
            <van-button v-if="JSON.stringify(selectedCar) === '{}'" type="primary" size="large" round
              @click="vinQuery">查询</van-button>
            <van-button v-else type="primary" size="large" round @click="confirm">确认</van-button>
            <!-- <van-button type="primary" v-else size="large" round @click="btnCheckVin">确认</van-button> -->
          </div>
        </div>
      </div>
    </div>
    <!-- 选择车型弹窗 -->
    <van-popup v-model="Brandshow" position="right" class="popupClass"
      :style="{ top: '46px', height: 'calc(100% - 46px)' }" :overlay-style="{ backgroundColor: 'rgba(0, 0, 0, 0)' }">
      <div class="brandCode">
        {{ CarBrandInfoModel.BrandName }}
      </div>
      <van-cell-group inset>
        <van-cell v-for="(item, $index) in tabs" :key="$index" @click="ClickBrand(item)">
          <div class="describe">
            <div>{{ item.MODELNAME }}</div>
            编码：{{ item.MODELCODE }}
          </div>
          <div class="price">
            <span class="label">厂商指导价 </span>
            <span class="value">{{ item.GUIDEPRICE }}</span>
          </div>
        </van-cell>
      </van-cell-group>
    </van-popup>

    <!-- 选择车系弹窗 -->
    <van-popup v-model="SubTypeshow" position="right" class="popupClass"
      :style="{ top: '46px', height: 'calc(100% - 46px)' }" :overlay-style="{ backgroundColor: 'rgba(0, 0, 0, 0)' }">
      <van-list>
        <div class="brandCode">
          {{ CarBrandInfoModel.BrandName }}
        </div>
        <van-cell-group inset>
          <van-cell v-for="(item, $index) in subtype" :key="$index" :title="item.SERIESNAME" :title-class="{
            titleActive: CarSeriesModel.SeriesCode === item.SERIESCODE,
          }" @click="Clicksubtype(item)" />
        </van-cell-group>
      </van-list>
    </van-popup>
  </div>
</template>

<script>
import {
  GetAllBrand,
  GetSeriesByBrand,
  GetSubTypeBySeries,
  QueryCarInvByVin, WorkFlowFreeLogin
} from "../../api/OrderGoods";
export default {
  data () {
    return {
      showBack:true,
      showTitle: true,
      loading: false,
      finished: false,
      refreshing: false,
      //表单结束
      Brandvalue: "",
      Index: 0,
      ModelVIN: "",
      CARTYPEModel: {
        brandCode: "",
        brandName: "",
        brandId: "",
        IsNewCar: "",
      },
      Brandshow: false,
      parentMessage: "Parent",
      items: [],
      tabs: [],
      subtype: [],
      show: false,
      value1: 0,
      value2: "a",
      isShowName: false,
      //品牌
      CarBrandInfoModel: {
        BrandCode: "",
        BrandName: "",
      },
      //车系
      CarSeriesModel: {
        SeriesCode: "",
      },
      CarBrandModel: {
        BrandName: "",
      },
      LoginModel: {
        USER_NAME: "",
      },
      //查询颜色
      CarColorModel: {
        modelCode: "",
      },
      //通过vin查询
      CarInvModel: {
        shopCode: "",
        vin: "",
      },
      SubTypeshow: false,
      INTENTION_NO: "",
      OUT_SRC_CUS_NO: '',
      EMPLOYEE_NO: "",
      Store_No: "",
      isAndroidOrIOS: "",
      LoginMsg: "",
      vehicleInformation: [],
      selectedCar: {},
    };
  },
  // beforeRouteEnter (to, from, next) {
  //   next(vm => {
  //     console.log('to', to)
  //     console.log('from', from)
  //     if (from.fullPath !== '/')
  //     {
  //       vm.showTitle = true
  //     }
  //   })
  // },
  mounted () {
    // sessionStorage.removeItem('otherInfo')
  },
  methods: {
    // 返回
    goBack () {
      if(!this.showBack){
          return
      }
      const orderInfo = sessionStorage.getItem("orderInfo");
      console.log("111111", orderInfo)
      if (orderInfo != null && orderInfo != "")
      {
        this.$router.go(-1)
      }
      else
      {
        this.backforward()
      }
    },
    backforward () {
      // 返回APP菜单中心页面
      sessionStorage.removeItem("otherInfo")
      let u = navigator.userAgent
      let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      try {
        if (isAndroid) {
          window.android.webClose(); // 调用原生app的函数--webClose是安卓提供的方法
        }
        if (isIOS) {
          window.webkit.messageHandlers.webClose.postMessage(""); //--webClose是ios提供的方法
        }
      } catch (e) {
        window.history.back()
      }
    },
    onLoad () {
      this.$emit("queryList");
    },
    onRefresh () {
      console.log("xiala ");
      this.$emit("refreshList");
    },
    onchange () {
      this.onSearch();
    },
    async vinQuery () {
      console.log(this.ModelVIN,"this.ModelVIN")
      if (this.ModelVIN == "")
      {
        this.$toast("请输入Vin码" + this.ModelVIN);
        return;
      }
      const params = {
        vin: this.ModelVIN,
        shopCode: this.$route.query.Store_No,
      }
      await QueryCarInvByVin(params).then((res) => {
        console.log(res,"vinQuery")
        if (res.data.IsSuccess && res.data.result.totalCount != 0)
        {
          this.vehicleInformation = res.data.result.list
          if (res.data.result.totalCount >= 1) this.selectedCar = res.data.result.list[0]

        } else
        {
          this.vehicleInformation = []
          this.$toast(res.data.msg || "当前车架号未查询到任何车辆信息，请确认车架号！");
          return;
        }
      })
    },

    async btnCheckVin () {
      if (this.ModelVIN == "")
      {
        this.$toast("请输入VIN码" + this.ModelVIN);
        return;
      }

      this.CarInvModel.vin = this.ModelVIN;
      this.CarInvModel.shopCode = this.$route.query.Store_No;
      await QueryCarInvByVin(this.CarInvModel).then((res) => {
        if (res.data.IsSuccess)
        {
          this.subtype = res.data.result;
          console.log("车架号", res.data.result);
          if (res.data.result.totalCount == 0)
          {
            this.$toast("请输入正确的VIN码");
            return;
          } else
          {

            // sessionStorage.removeItem('orderInfo')
            // sessionStorage.removeItem('temp')
            if (this.$route.query.soNo)
            {
              const orderInfo = JSON.parse(sessionStorage.getItem('orderInfo'))
              orderInfo.getCarInfo.soCarVin = ''
              orderInfo.getCarInfo.aaa = ''
              sessionStorage.setItem("orderInfo", JSON.stringify(orderInfo))
              const otherInfo = JSON.parse(sessionStorage.getItem('otherInfo'))
              otherInfo.AppearanceColoron = ''
              otherInfo.AppearanceColoronCode = ''
              otherInfo.InteriorColor = ''
              otherInfo.InteriorColorCode = ''
              otherInfo.Barecar_price=''
              sessionStorage.setItem("otherInfo", JSON.stringify(otherInfo))
            }

            this.$router.push({
              path: "../OrderGoods/OrderGoods",
              query: {
                vin: res.data.result.list[0].VIN,
                INTENTION_NO: this.$route.query.INTENTION_NO,
                OUT_SRC_CUS_NO: this.$route.query.OUT_SRC_CUS_NO,
                EMPLOYEE_NO: this.$route.query.EMPLOYEE_NO,
                Store_No: this.$route.query.Store_No,
                isAndroidOrIOS: this.$route.query.isAndroidOrIOS,
                MODELCODE: res.data.result.list[0].SUBTYPE_CODE,
                SERIESCODE: res.data.result.list[0].CLASS_CODE,
                CARTYPECODE: res.data.result.list[0].CARTYPE_CODE,
                soNo: this.$route.query.soNo,
              },
            });
          }
        } else
        {
          this.$toast(res.data.msg);
          return;
        }
      })
    },
    async confirm () {

      this.CarInvModel.vin = this.ModelVIN;
      this.CarInvModel.shopCode = this.$route.query.Store_No;
      if (this.$route.query.soNo)
      {
        const orderInfo = JSON.parse(sessionStorage.getItem('orderInfo'))
        orderInfo.getCarInfo.soCarVin = ''
        orderInfo.getCarInfo.aaa = ''
        sessionStorage.setItem("orderInfo", JSON.stringify(orderInfo))
        const otherInfo = JSON.parse(sessionStorage.getItem('otherInfo'))
        otherInfo.AppearanceColoron = ''
        otherInfo.AppearanceColoronCode = ''
        otherInfo.InteriorColor = ''
        otherInfo.InteriorColorCode = ''
        otherInfo.Barecar_price=''
        sessionStorage.setItem("otherInfo", JSON.stringify(otherInfo))
      }
      this.$router.push({
        path: "../OrderGoods/OrderGoods",
        query: {
          vin: this.selectedCar.VIN,
          INTENTION_NO: this.$route.query.INTENTION_NO,
          OUT_SRC_CUS_NO: this.$route.query.OUT_SRC_CUS_NO,
          EMPLOYEE_NO: this.$route.query.EMPLOYEE_NO,
          Store_No: this.$route.query.Store_No,
          isAndroidOrIOS: this.$route.query.isAndroidOrIOS,
          MODELCODE: this.selectedCar.SUBTYPE_CODE,
          SERIESCODE: this.selectedCar.CLASS_CODE,
          CARTYPECODE: this.selectedCar.CARTYPE_CODE,
          soNo: this.$route.query.soNo,
        },
      });

    },
    showPopup () {
      this.show = true;
    },
    onCancel () {
      this.show = false;
    },
    async getRowId (item) {
      this.CarBrandInfoModel.BrandCode = item.BRANDCODE;
      this.CarBrandInfoModel.BrandName = item.BRANDNAME;
      await GetSeriesByBrand(this.CarBrandInfoModel).then((res) => {
        console.log("当前被点击的id=" + res);
        this.subtype = res.data.result;
      });
      this.SubTypeshow = true;
    },
    ClickBrand (brandItem) {
      console.log("车型", brandItem);
      if (this.LoginMsg != "" || !brandItem.GUIDEPRICE)
      {
        this.$toast(this.LoginMsg || '指导价为空');
        return;
      }
      // sessionStorage.removeItem('orderInfo')
      // sessionStorage.removeItem('temp')
      if (this.$route.query.soNo)
      {
        const orderInfo = JSON.parse(sessionStorage.getItem('orderInfo'))
        orderInfo.getCarInfo.soCarVin = ''
        orderInfo.getCarInfo.aaa = ''
        sessionStorage.setItem("orderInfo", JSON.stringify(orderInfo))
        const otherInfo = JSON.parse(sessionStorage.getItem('otherInfo'))
        otherInfo.AppearanceColoron = ''
        otherInfo.AppearanceColoronCode = ''
        otherInfo.InteriorColor = ''
        otherInfo.InteriorColorCode = ''
        otherInfo.Barecar_price=''
        otherInfo.Barecar_price=''
        sessionStorage.setItem("otherInfo", JSON.stringify(otherInfo))
      }

      this.$router.push({
        path: "../OrderGoods/OrderGoods",
        query: {
          MODELCODE: brandItem.MODELCODE,
          SERIESCODE: brandItem.SERIESCODE,
          CARTYPECODE: brandItem.CARTYPECODE,
          INTENTION_NO: encodeURI(this.$route.query.INTENTION_NO),
          OUT_SRC_CUS_NO: this.$route.query.OUT_SRC_CUS_NO,
          EMPLOYEE_NO: encodeURI(this.$route.query.EMPLOYEE_NO),
          Store_No: encodeURI(this.$route.query.Store_No),
          isAndroidOrIOS: encodeURI(this.$route.query.isAndroidOrIOS),
          soNo: this.$route.query.soNo,
        },
      });
    },
    async Clicksubtype (brandItem) {
      console.log("brandItem=" + brandItem.SERIESCODE);
      this.CarSeriesModel.SeriesCode = brandItem.SERIESCODE;
      await GetSubTypeBySeries(this.CarSeriesModel).then((res) => {
        // const list =res.data.result.sort((a, b) => {
        //   console.log("aaa",a.MODELNAME.split("款")[0] ,b.MODELNAME.split("款")[0]);
        //   return b.MODELNAME.split("款")[0]-a.MODELNAME.split("款")[0]

        // })
        // console.log("list",list);
        this.tabs = res.data.result;
      });

      this.Brandshow = true;
    },
    // 获取品牌列表
    async GetAllBrand () {
      this.CarBrandModel.BrandName = this.Brandvalue == "" ? "" : this.Brandvalue;
      this.CarBrandModel.StoreNo = this.$route.query.Store_No
      await GetAllBrand(this.CarBrandModel).then((res) => {
        this.items = res.data.result;
      });
    },
    CarTypeSearch () {
      this.GetAllBrand();
    },
    async WorkFlowFreeLogin () {
      this.LoginModel.USER_NAME = this.EMPLOYEE_NO == "" ? "" : this.EMPLOYEE_NO;
      await WorkFlowFreeLogin(this.LoginModel).then((res) => {
        if (res.data.IsSuccess)
        {
          console.log(res);
        }
        else
        {
          this.$toast(res.data.msg);
        }
        this.LoginMsg = res.data.msg;
      });
    },
    onChange () { },
    selectVehicle (item) {
      console.log("-", item);
      this.selectedCar = item
    },
  },
  watch: {
    ModelVIN() {
      this.vinQuery()
    }
  },
  computed: {},
  created () {
    this.INTENTION_NO = encodeURI(this.$route.query.INTENTION_NO);
    this.OUT_SRC_CUS_NO = this.$route.query.OUT_SRC_CUS_NO;
    this.EMPLOYEE_NO = encodeURI(this.$route.query.EMPLOYEE_NO);
    this.Store_No = encodeURI(this.$route.query.Store_No);
    this.isAndroidOrIOS = encodeURI(this.$route.query.isAndroidOrIOS);
    this.WorkFlowFreeLogin();
    this.GetAllBrand();
    this.showBack = this.$route.query.isAndroidOrIOS != 'WECHAT' 
  },
};
</script>

<style lang="less" scoped> .user {
    height: 100%;
   background: #f5f5f5;

   /deep/ .van-nav-bar {
     .van-nav-bar__content {
       .van-nav-bar__title {
         font-weight: initial !important;
         width: -webkit-fill-available;
         max-width: 60% !important;
       }
     }
   }

   .titleActive {
     color: @themeColor;
   }

   .content {
     background: #fff;
     height: calc(100vh - 100px);
     overflow-y: scroll;

     .van-search {
       background: #f2f2f2 !important;

       .van-search__content {
         background: #fff;
       }
     }

     .list {
       height: calc(100vh - 46px - 54px);
       overflow: auto;
     }

     .vinVanlist{
      height: calc(100vh - 4.5rem);
      padding-bottom: 1rem;
       overflow: auto;
     }

     .user_img {
       width: 90px;
       height: 90px;
       border: 5px solid #f5f5f5;
       border-radius: 50%;
       overflow: hidden;

       img {
         width: 100%;
         height: 100%;
       }
     }

     .user_name {
       margin-top: 10px;
       font-size: 24px;
       font-weight: 500;
       color: #000001;
     }

     .user_store {
       margin-top: 10px;
       font-size: 15px;
       font-weight: 400;
       color: #666666;
       background: #f5f5f5;
       padding: 7px 20px;
       border-radius: 30px;

       .aaa {
         border: 0;
         background: none;
         font-size: 15px;
         width: 100%;
       }
     }

     .content_userName {
       // margin-top: 15px;
     }

     .footerButton {
       position: fixed;
       bottom: 0;
       width: 100%;
       padding: 0 16px;
       background: #fff;
     }
   }
 }

 .cars-list {
   border: 1px solid #f2f2f2;
   background: #f2f2f2;
   padding: 10px;
   width: auto;
   border-radius: 3px;

   .cars-name {
     font-size: 18px;
     font-weight: bold;
   }

   .cars-info {
     margin-top: 5px;
     font-size: 12px;
     color: #a1a1a1;
   }
 }

 .check-border {
   border: 1px solid #f7772c;
 }

 .popupClass {
   transform: none;
   width: 80%;
   box-shadow: -5px 0 5px -5px rgba(0, 0, 0, 20%);

   .brandCode {
     font-size: 18px;
     font-weight: bold;
     text-align: center;
     line-height: 38px;
     border-bottom: 1px solid #ebedf0;
   }

   .describe {
     font-size: 13px;
     word-wrap: break-word;
   }

   .price {
     color: @themeColor;
     margin-top: 15px;
     font-size: 12px;

     .label {
       color: #a1a1a1;
     }

     .value {
       color: @themeColor;
     }
   }
 }

 /deep/.van-dropdown-menu__bar {
   background: none;
   box-shadow: none;
   height: auto;
 }

 /*滚动条样式*/
 /deep/ .scrollbar {
   white-space: nowrap;

   .el-scrollbar {
     display: flex;
     justify-content: space-around;
     padding: 0 10px;
   }

   /deep/ .el-scrollbar__wrap {
     overflow: scroll;
     width: 110%;
     height: 100%;
   }

   .container {
     height: 60vh;
     overflow: auto;
   }



 }
</style>
