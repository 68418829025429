<!--
 * /*Author: 杨强
 * /*Date: 2023-08-03 11:44:15
 * /*LastEditTime: 2023-08-14 13:39:40
 * /*LastEditors: 杨强
 * /*Description: 
 * /*FilePath: \bzm-sales-h5 - 副本\src\views\SalesOrderDeliveryList\SalesOrderDeliveryList.vue
-->
<template>
  <div class="SalesOrderDeliveryList">
    <!-- 搜索框 left-arrow @click-left="goBack" -->
    <van-nav-bar title="标题"  :left-arrow="showBack" @click-left="goBack">
      <template #title>
        <van-search v-model="SEARCH_STR" shape="round" background="none" placeholder="客户、手机号、VIN码、销售顾问"
          @search="SearchSalesOrderDeliveryList(0)" @blur="SearchSalesOrderDeliveryList(0)"
          @clear="SearchSalesOrderDeliveryList(0)" @cancel="SearchSalesOrderDeliveryList(0)"
          @keypress="SearchSalesOrderDeliveryList(0)" />
      </template>
    </van-nav-bar>
    <!-- tab -->
    <van-tabs @click="SelectiveState" v-model="active" sticky swipeable>
      <van-tab v-for="items in list" :key="items" :title="items">
      </van-tab>
    </van-tabs>
    <van-notice-bar color="black" mode="closeable">该列表只展示订单状态为收齐尾款待出库的订单</van-notice-bar>
    <van-cell :style="{ background: 'transparent', flex: 'none', }" :title="`共${pageSize.totalCount}个订单`">
      <van-dropdown-menu class="dropMenu">
        <van-dropdown-item v-model="orderBy" @change="OrderChange" :options="orderByOptions" />
      </van-dropdown-menu>
    </van-cell>
    <!-- 列表 -->
      <van-list class="SalesOrderVanList" v-model="listLoading" :finished="finished" :immediate-check="false" finished-text="没有更多了"
        @load="SearchSalesOrderDeliveryList" offset="20">
        <van-panel v-for="item in so_List" :key="item.soNo"
          :title="item.status === '待出库' ? `预计交车时间:${item.soCarFcstDeliveryDate}` : `出库时间:${item.relDate}`"
          :status="item.status" class="panel-item">
          <van-cell :title="`${item.soCartypeName} ${item.soCarsubtypeName}`"
            :title-style="{ fontSize: '16px', fontWeight: 'bold' }" :border="false" class="padding-top0"></van-cell>
          <van-cell class="grey" :title="`外观${item.soCarColor} + 内饰${item.soCarInnercolor}`" :border="false"></van-cell>
          <van-cell class="grey" :title="`VIN码:${item.vin || ''} `"
            :border="false"></van-cell>
          <van-cell class="grey" :title="`开票人：${item.soActualowner}`"
          :border="false"></van-cell>
          <van-cell class="grey" :title="`销售：${item.salesName || ''}`" :border="false"></van-cell>
          <van-cell class="grey" :title="`手机：${item.mobile}`" :border="false"></van-cell>
          <van-cell class="grey" :title="`订单号：${item.soNo || ''}`" :border="false"></van-cell>
          <van-cell title=" ">
            <van-button round plain hairline type="warning" size="small" style="padding:0 10px;height:30px"
              @click.stop="DeliveryNew(item)" v-if="item.status === '待出库' && item.enabledRel === '1'">确认出库</van-button>
          </van-cell>
        </van-panel>
      </van-list>
  </div>
</template>
<script>
import { GetSalesOrderDeliveryList, SalesOrderDeliveryNew } from "../../api/SalesOrderDeliveryList";
export default {
  data () {
    return {
      showBack:true,
      list: ['待出库', '已出库', '全部'],
      so_List: [],//列表数据
      active: 0,
      searchValue: '',
      listLoading: false,
      finished: false,
      pageSize: {
        hasMore: true,
        pagesize: 10,
        pagestart: 1,
        totalCount: 0
      },
      SEARCH_STR: "", //模糊搜索条件
      STATUS: "待出库",//出库状态 默认待出库
      STORE_NO: "",//门店编码
      orderBy: '预计交车时间最早',//排序
      orderByOptions: [
        { text: '预计交车时间最早', value: '预计交车时间最早' },
        { text: '预计交车时间最晚', value: '预计交车时间最晚' },
        { text: '出库时间最早', value: '出库时间最早' },
        { text: '出库时间最晚', value: '出库时间最晚' },
      ]
    }
  },
  methods: {
    //切换tab页
    SelectiveState (index) {
      console.log("切换", index);
      this.active = index
      this.so_List = []
      this.STATUS = this.list[index]
      this.listLoading = true
      this.SearchSalesOrderDeliveryList(0);
    },
    //排序更改
    OrderChange (value) {
      console.log("排序更改", value);
      this.so_List = []
      this.orderBy = value;
      this.listLoading = true
      this.SearchSalesOrderDeliveryList(0)
    },
    //配车/锁车列表查询
    async SearchSalesOrderDeliveryList (length) {
      //参数组合
      console.log("配车/锁车", length);
      const params = {
        storeNo: this.$route.query.Store_No,//2000200  12247
        status: this.STATUS === '全部' ? '' : this.STATUS,
        iDisplayStart: length == 0 ? 0 : this.so_List.length,
        iDisplayLength: 10,
        sort: this.orderBy,
        searchStr: this.SEARCH_STR
      }
      await GetSalesOrderDeliveryList(params).then((res) => {

        if (res.data.IsSuccess)
        {
          console.log("res", res);
          if (length == 0) { this.so_List = res.data.result }
          else
          {
            this.so_List.push(...res.data.result);
          }
          this.pageSize.totalCount = res.data.msg.totalCount;
          if (res.data.msg.hasMore) this.finished = false;
          if (!res.data.msg.hasMore) this.finished = true;
          this.listLoading = false;
        } else
        {
          this.$toast(res.data.msg)
          this.so_List = []
        }
        this.listLoading = false;
      }).catch(() => {
        this.finished = false;
        this.listLoading = false;
      })
    },
    //出库
    DeliveryNew (item) {
      this.$dialog.confirm({
        message: '您确认车辆已开票并完成出库？',
      }).then(() => {
        const param = {
          outerOrderNo: item.soucheOrderno,
          shopCode: this.$route.query.Store_No,
          vin: item.vin,
          OperaterPsn: this.$route.query.EMPLOYEE_NO
        }
        SalesOrderDeliveryNew(param).then(res => {
          if (res.data.IsSuccess)
          {
            if (res.data.msg)
            {
              this.$toast(res.data.msg)
            }
            else
            {
              this.$toast('操作成功！')
              this.listLoading = true
              this.SearchSalesOrderDeliveryList(0)
            }
          } else
          {
            this.$toast(res.data.msg)
          }
          console.log(res, "res")
        })
      }).catch(() => {
        return
      });
    },
    goBack () {
      if(this.showBack){
        this.backforward()
      }
    },
    backforward () {
      // 返回APP菜单中心页面
      let u = navigator.userAgent
      let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      try {
        if (isAndroid) {
          window.android.webClose(); // 调用原生app的函数--webClose是安卓提供的方法
        }
        if (isIOS) {
          window.webkit.messageHandlers.webClose.postMessage(""); //--webClose是ios提供的方法
        }
      } catch (e) {
        window.history.back()
      }
    },
  },
  created () {
    // this.listLoading = true
    console.log("创建", length);
    this.SearchSalesOrderDeliveryList(0);
    this.showBack = this.$route.query.isAndroidOrIOS != 'WECHAT' 
  },
}
</script>
<style lang="less" scoped>
.SalesOrderDeliveryList {
  position: absolute;
  height: 100%;
  width: 100%;
  // height: 100vh;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;

  .SalesOrderVanList {
    padding: 16px;
    padding-top: 0;
    overflow: scroll;
    overflow-y: scroll;
    height: calc(100% - 114px);
  }

  .panel-item {
    margin-bottom: 16px;
    border-radius: 10px;

    .van-panel__header {
      border-radius: 10px;

      .van-cell__title {
        color: #5e5e5e;
        width: 80%;
        flex: none;
      }
    }

    .van-cell::after {
      content: none;
    }

    .padding-top0 {
      padding-top: 0;
    }

    .padding-bot0 {
      padding-bottom: 0;
    }

    .van-hairline--top::after {
      border-top-width: 0;
    }
  }

  .grey {
    color: #5e5e5e;
    padding-top: 0px;
    padding-bottom: 0px;

    .van-cell__value--alone {
      color: #5e5e5e;
    }

    .van-tag {
      font-weight: bold;
    }
  }

  .grey-right {
    color: #5e5e5e;
    padding-top: 5px;
    padding-bottom: 0px;
    text-align: right;

    .van-cell__value--alone {
      color: #5e5e5e;
    }

    .van-tag {
      font-weight: bold;
    }
  }

  .content {
    background: #f2f2f1;

    .user_img {
      width: 90px;
      height: 90px;
      border: 5px solid #f5f5f5;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .body-content {
      height: calc(100vh - 110px);
      background: #fff;
      padding: 10px;
      font-size: 12px;

      /deep/.sale-list {
        overflow: scroll;
        height: calc(100vh - 180px);
      }
    }

    .user_name {
      margin-top: 10px;
      font-size: 24px;
      font-weight: 500;
      color: #000001;
    }

    .user_store {
      margin-top: 10px;
      font-size: 15px;
      font-weight: 400;
      color: #666666;
      background: #f5f5f5;
      padding: 7px 20px;
      border-radius: 30px;

      .aaa {
        border: 0;
        background: none;
        font-size: 15px;
        width: 100%;
      }
    }
  }
}

.dropMenu {
  height: 20px;
  background: none;
}

.dropMenu>.van-dropdown-menu__bar {
  height: 20px;
  background: none;
  box-shadow: none;
  float: right;
}

.dropMenu .van-dropdown-menu__item {
  height: 20px;
  background: none;
}

.dropMenu .van-dropdown-menu__title {
  height: 20px;
  background: none;
  font-size: 12px;
}


input.van-field__control::-webkit-input-placeholder {
font-size: 12px;

}

/deep/ .van-nav-bar__title{
  width:77% !important;
  max-width: 77%!important;
}
</style>
