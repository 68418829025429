<!--
 * /*Author: 杨强
 * /*Date: 2023-08-03 12:23:52
 * /*LastEditTime: 2023-08-14 13:37:07
 * /*LastEditors: 杨强
 * /*Description: 
 * /*FilePath: \bzm-sales-h5 - 副本\src\views\LockCarList\LockCarList.vue
-->
<template>
  <div class="LockList">
    <!-- 搜索框 -->
    <van-nav-bar title="标题" :left-arrow="showBack" @click-left="goBack">
      <template #title>
        <van-search v-model="SEARCH_STR" shape="round" background="none" placeholder="搜索车型、客户、手机号、VIN码"
          @search="SearchLockCarList(0)" @blur="SearchLockCarList(0)" />
      </template>
    </van-nav-bar>
    <!-- tab -->
    <van-tabs @click="SelectiveState" v-model="active" sticky swipeable>
      <van-tab v-for="items in list" :key="items" :title="items">
      </van-tab>
    </van-tabs>
    <van-cell :style="{ background: 'transparent', flex: 'none', }" :title="`共${pageSize.totalCount}个订单`">
      <van-dropdown-menu class="dropMenu">
        <van-dropdown-item v-model="orderBy" @change="OrderChange" :options="orderByOptions" />
      </van-dropdown-menu>
    </van-cell>
    <!-- 列表 -->
      <van-list v-model="listLoading" :finished="finished" :immediate-check="false" finished-text="没有更多了"
        @load="SearchLockCarList" class="LockVanList" offset="20">
        <van-panel v-for="item in so_List" :key="item.soNo" :title="item.estimatedDeliveryCarDate"
          :status="item.vin ? '已配车' : '待配车'" class="panel-item">
          <van-cell :title="`${item.soCarTypeName} ${item.soCarSubTypeName}`"
            :title-style="{ fontSize: '16px', fontWeight: 'bold' }" :border="false" class="padding-top0"></van-cell>
          <van-cell class="grey" :title="`外观${item.carColorName} + 内饰${item.carInnerColorName}`"
            :border="false"></van-cell>
          <van-cell class="grey" :title="`预计交车时间：${item.estimatedDeliveryCarDate || ''}  |  销售：${item.saleNmae}`"
            :border="false"></van-cell>
          <van-cell class="grey" :title="`客户：${item.OWNERNAME || ''}  | 客户手机：${item.MOBILE}`" :border="false"></van-cell>
          <van-cell class="grey" :title="`订单号：${item.soNo || ''}`" :border="false"></van-cell>
          <van-cell class="grey-right" :title="`VIN码：${item.vin || ''}`" :title-style="{ fontSize: '14px' }"
            :border="false"></van-cell>
          <van-cell title=" ">
            <van-button round hairline type="primary" size="small" style="width: 60px; padding:0 10px;height:30px"
              @click.stop="UnlockCar(item)" v-if="item.vin">解锁</van-button>
            <van-button round hairline type="primary" size="small" style="width: 60px; padding:0 10px;height:30px"
              @click.stop="SkipPage(item)" v-if="!item.vin">配车</van-button>
          </van-cell>
        </van-panel>
      </van-list>
  </div>
</template>
<script>
import { GetLockCarList, SoUnlockCar } from "../../api/LockCarList";
export default {
  data () {
    return {
      showBack:true,
      list: ['待配车', '已配车', '全部'],
      so_List: [],//列表数据
      active: 0,
      searchValue: '',
      listLoading: false,
      finished: false,
      pageSize: {
        hasMore: true,
        pagesize: 10,
        pagestart: 1,
        totalCount: 0
      },
      SEARCH_STR: "", //模糊搜索条件
      STATUS: "待配车",//配车状态 默认待配车
      STORE_NO: "",//门店编码
      orderBy: 1,//排序
      orderByOptions: [
        { text: '预计交车时间最近', value: 1 },
        { text: '下单时间最近', value: 0 },
      ]
    }
  },
  methods: {
    //切换tab页
    SelectiveState (index) {
      this.active = index
      this.so_List = []
      this.STATUS = this.list[index]
      this.SearchLockCarList(0);
    },
    //排序更改
    OrderChange (value) {
      this.so_List = []
      this.orderBy = value;
      this.SearchLockCarList(0)
    },
    //配车/锁车列表查询
    SearchLockCarList (length) {
      //参数组合
      const params = {
        storeNo: this.$route.query.Store_No,//2000200  12247
        newLockCarStatus: this.STATUS,
        lockCarStatus: true,
        iDisplayStart: length == 0 ? 0 : this.so_List.length,
        iDisplayLength: 10,
        newestTime: this.orderBy,
        searchStr: this.SEARCH_STR
      }
      GetLockCarList(params).then((res) => {
        if (res.data.IsSuccess)
        {
          console.log("00",length);
          if (length == 0) { this.so_List = res.data.result }
          else
          {
            this.so_List.push(...res.data.result);
          }
          this.pageSize.totalCount = res.data.msg.totalCount;
          if (res.data.msg.hasMore) this.finished = false;
          if (!res.data.msg.hasMore) this.finished = true;
        } else
        {
          this.$toast(res.data.msg)
          this.so_List = []
        }
        this.listLoading = false;
      })
    },
    //跳转配车页面
    SkipPage (item) {
      this.$router.push({
        path: "/LockCarList/LockCar",
        query: {
          ...this.$route.query,
          estimatedDeliveryCarDate: item.estimatedDeliveryCarDate,
          vin: item.vin,
          soCarTypeName: item.soCarTypeName,
          soCarSubTypeName: item.soCarSubTypeName,
          carColorName: item.carColorName,
          carInnerColorName: item.carInnerColorName,
          saleNmae: item.saleNmae,
          OWNERNAME: item.OWNERNAME,
          MOBILE: item.MOBILE,
          soNo: item.soNo,
        },
      });
    },
    //解锁
    UnlockCar (item) {
      this.$dialog.confirm({
        message: '确认解锁该车辆？',
      }).then(() => {
        const param = {
          soNo: item.soNo,
        }
        SoUnlockCar(param).then(res => {
          console.log("res.data.msg",res)
          if (res.data.IsSuccess)
          {

            if (res.data.msg)
            {
              this.$toast(res.data.msg)
            }
            else
            {
              this.$toast('操作成功！')
              this.SearchLockCarList(0)
            }
          } else
          {
            this.$toast(res.data.msg)
          }
          console.log(res, "res")
        })
      }).catch(() => {
        return
      });
    },
    goBack () {
      if(this.showBack){
        this.backforward()
      }
    },
    backforward () {
      // 返回APP菜单中心页面
      let u = navigator.userAgent
      let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      try {
        if (isAndroid) {
          window.android.webClose(); // 调用原生app的函数--webClose是安卓提供的方法
        }
        if (isIOS) {
          window.webkit.messageHandlers.webClose.postMessage(""); //--webClose是ios提供的方法
        }
      } catch (e) {
        window.history.back()
      }
    },
  },
  created () {
    this.SearchLockCarList(0);
    this.showBack = this.$route.query.isAndroidOrIOS != 'WECHAT' 
  },
}
</script>
<style lang="less" scope>
::v-deep .van-nav-bar__title {
  width: 88%;
  max-width: 88% !important;
}



.LockList {
  position: absolute;
  height: 100%;
  width: 100%;
  // height: 100vh;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;

  .LockVanList {
    padding: 16px;
    padding-top: 0;
    overflow: scroll;
    height: calc(100vh - 134px);
 }
  .panel-item {
    margin-bottom: 16px;
    border-radius: 10px;

    .van-panel__header {
      border-radius: 10px;

      .van-cell__title {
        color: #5e5e5e;
        width: 70%;
        flex: none;
      }
    }

    .van-cell::after {
      content: none;
    }

    .padding-top0 {
      padding-top: 0;
    }

    .padding-bot0 {
      padding-bottom: 0;
    }

    .van-hairline--top::after {
      border-top-width: 0;
    }
  }

  .grey {
    color: #5e5e5e;
    padding-top: 0px;
    padding-bottom: 0px;

    .van-cell__value--alone {
      color: #5e5e5e;
    }

    .van-tag {
      font-weight: bold;
    }
  }

  .grey-right {
    color: #5e5e5e;
    padding-top: 5px;
    padding-bottom: 0px;
    text-align: right;

    .van-cell__value--alone {
      color: #5e5e5e;
    }

    .van-tag {
      font-weight: bold;
    }
  }

  .content {
    background: #f2f2f1;

    .user_img {
      width: 90px;
      height: 90px;
      border: 5px solid #f5f5f5;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .body-content {
      height: calc(100vh - 110px);
      background: #fff;
      padding: 10px;
      font-size: 12px;

      /deep/.sale-list {
        overflow: scroll;
        height: calc(100vh - 180px);
      }
    }

    .user_name {
      margin-top: 10px;
      font-size: 24px;
      font-weight: 500;
      color: #000001;
    }

    .user_store {
      margin-top: 10px;
      font-size: 15px;
      font-weight: 400;
      color: #666666;
      background: #f5f5f5;
      padding: 7px 20px;
      border-radius: 30px;

      .aaa {
        border: 0;
        background: none;
        font-size: 15px;
        width: 100%;
      }
    }
  }
}

.dropMenu {
  height: 20px;
  background: none;
}

.dropMenu>.van-dropdown-menu__bar {
  height: 20px;
  background: none;
  box-shadow: none;
  float: right;
}

.dropMenu .van-dropdown-menu__item {
  height: 20px;
  background: none;
}

.dropMenu .van-dropdown-menu__title {
  height: 20px;
  background: none;
  font-size: 12px;
}
</style>
