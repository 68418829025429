import Vue from 'vue'
import {
  Button,
  Tabbar,
  TabbarItem,
  Tab,
  Tabs,
  Search,
  DropdownMenu,
  DropdownItem,
  Step,
  Steps,
  Popup,
  Field,
  DatetimePicker,
  Toast,
  List,
  PullRefresh,
  Loading,
  Picker,
  Checkbox,
  CheckboxGroup,
  RadioGroup,
  Radio,
  Area,
  Cascader,
  NavBar,
  Cell,
  CellGroup,
  Panel,
  Tag,
  Icon,
  Stepper,
  Dialog,
  Popover,
  Row,
  Col,
  ActionSheet,
  collapse,
  CollapseItem,
  Calendar,
  Progress,
  Form,
  IndexBar,
  IndexAnchor,
  Divider,
  NoticeBar,
  Uploader
} from 'vant'
Vue.use(Button).use(Toast).use(Checkbox).use(CheckboxGroup)
  .use(Tabbar).use(Picker).use(TabbarItem).use(Tab).use(Tabs).
use(Search).use(DropdownMenu).use(DropdownItem).use(Step).
use(Steps).use(Popup).use(Field).use(DatetimePicker).
use(List).use(PullRefresh).use(Loading).use(Radio).
use(RadioGroup).use(Area).use(Cascader).
use(NavBar).use(Cell).use(CellGroup).use(Panel).use(Tag)
  .use(Icon).use(Stepper).use(Dialog).use(Popover).use(Row).use(Col)
  .use(ActionSheet).use(collapse).use(CollapseItem).use(Calendar).use(Progress).use(Form)
  .use(IndexBar).use(IndexAnchor).use(Divider).use(NoticeBar).use(Uploader)