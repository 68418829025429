import request from "../../utils/request";

// 获取配车/锁车列表
export function GetLockCarList(data) {
  return request({
    url: '/NCS/NewCarSalesOrder/GetSoLockCarList',
    method: 'post',
    data
  })
}

// 获取配车/锁车车辆选择列表
export function GetNoLockCarList(data) {
  return request({
    url: '/NCS/NewCarSalesOrder/GetSoNoLockCarList',
    method: 'post',
    data
  })
}

// 获取配车/锁车车辆信息
export function GetAvailableCarInvInfo(data) {
  return request({
    url: '/NCS/NewCarInv/GetAvailableCarInvInfo',
    method: 'post',
    data
  })
}

// 获取配车/锁车车辆增配及前装信息
export function GetCarInvIncreasedDet(data) {
  return request({
    url: '/NCS/NewCarInv/GetCarInvIncreasedDet',
    method: 'post',
    data
  })
}

// 配车
export function SoLockCar(data) {
  return request({
    url: '/NCS/NewCarSalesOrder/SoLockCar',
    method: 'post',
    data
  })
}

//解锁
export function SoUnlockCar(data) {
  return request({
    url: '/NCS/NewCarSalesOrder/SoUnlockCar',
    method: 'post',
    data
  })
}


