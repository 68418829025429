<template>
  <van-form ref='form' class="jiaoche">
    <van-nav-bar title="交车确认" :left-arrow="showBack" @click-left="goBack">
    </van-nav-bar>
    <!-- <div class="header-button">
      <van-progress pivot-text="橙色" color="#f2826a" :percentage="100" />
    </div> -->
    <div class="content">
      <van-cell v-for="(item, index) in TB_SURVEY_EXECUTE_DET" :key="item.QUESTION_ID">
        <van-field :required="!!item.IS_REQUIRED" :name="item.QUESTION_ID" label-width="100%"
          v-if="item.QUESTION_TYPE != 2" :rules="[{ required: !!item.IS_REQUIRED, message: '请填写必填项' }]">
          <template #label>
            <span class="title"> {{ index + 1 + '、' + item.QUESTION_BODY }}</span>
          </template>
          <template #input>
            <!-- item.QUESTION_TYPE //问题类型  0:单选题; 1:多选题; 2:问答题; 3:时间（交车确认单无此选项） -->
            <van-radio-group style='display:flex;flex-wrap: wrap;' v-model="item.ANSWER_ID[0]"
              v-if="item.QUESTION_TYPE == 0" :rules="[{ required: true, message: '请选择' }]">
              <van-radio :name="items.ANSWER_ID" v-for="items in item.ANSWER_INFO" :key="items.ANSWER_ID"
                style="margin-right:20px">
                {{ items.ANSWER_BODY }}</van-radio>
            </van-radio-group>
            <van-checkbox-group v-model="item.ANSWER_ID" v-if="item.QUESTION_TYPE == 1">
              <van-checkbox :name="items.ANSWER_ID" v-for="items in item.ANSWER_INFO" :key="items.ANSWER_ID">
                {{ items.ANSWER_BODY }}
              </van-checkbox>
            </van-checkbox-group>
          </template>
        </van-field>
        <van-field :label="item.QUESTION_BODY" v-if="item.QUESTION_TYPE == 2" v-model="item.ANSWER_CONTENT"
          :key="item.ANSWER_INFO[0].ANSWER_ID" type="textarea" placeholder="请输入..." rows="4" autosize />
      </van-cell>
    </div>
    <div class="footer-button">
      <!--      :disabled="!!deliveryFormsList.EXECUTE_ID || disable"-->
      <van-button type="primary" round style="width:100%" @click="SubmitForm"
        :disabled="!!deliveryFormsList.EXECUTE_ID || disable">确认</van-button>
    </div>
  </van-form>
</template>
<script>
import { GetDeliveryConfirmation, SaveDeliveryConfirmation } from "../../api/DeliveryList";
export default {
  data () {
    return {
      showBack:true,
      radio: 0,
      list: [],
      deliveryFormsList: {},
      TB_SURVEY_EXECUTE_DET: [],
      disable: false
    }
  },
  methods: {
    async GetDeliveryConfirmation () {
      const params = {
        BIZ_REF_NO: this.$route.query.soNo
      }
      await GetDeliveryConfirmation(params).then(res => {
        if (res.data.IsSuccess)
        {

          this.deliveryFormsList = res.data.result
          this.TB_SURVEY_EXECUTE_DET = res.data.result.TB_SURVEY_EXECUTE_DET.sort(function (a, b) { return a.QUESTION_NO - b.QUESTION_NO; })
        }
      })
    },
    async SaveDeliveryConfirmation () {
      const params = this.deliveryFormsList
      params.TB_SURVEY_EXECUTE_DET = this.TB_SURVEY_EXECUTE_DET
      console.log("提交的数据", params);
      await SaveDeliveryConfirmation(params).then(res => {
        this.$toast(res.data.msg)
        if (res.data.IsSuccess)
        {
          setTimeout(() => {
            this.disable = false;
            let path = "/DeliveryList/DeliveryList"
            if (this.$route.query.type == 'OrderGoods')
            {
              path = "/OrderDetails/OrderDetails"
            }
            this.$router.push({
              path: path,
              query: {
                Store_No: this.$route.query.Store_No,
                EMPLOYEE_NO: this.$route.query.EMPLOYEE_NO,
                soNo: this.$route.query.soNo
              },
            });
          }, 1000);
        }

        console.log(res);
      })
    },
    SubmitForm () {
      this.disable = true;
      this.$refs.form.validate().then(() => {
        // 验证通过
        console.log("成功");
        this.SaveDeliveryConfirmation()
      }).catch(() => {
        //验证失败
        console.log("失败");
        this.$toast('请填写必填项！')
        this.disable = false;
      })
    },
    goBack () {
      if(this.showBack){
        //返回上一页
        this.$router.go(-1);
      }
    },
  },
  mounted () {
    this.GetDeliveryConfirmation()
  },
  created () {
    this.showBack = this.$route.query.isAndroidOrIOS != 'WECHAT' 
  },
}
</script>
<style lang="less" scoped>
.jiaoche {
  height: 100%
}

.content {
  height: calc(100% - 130px);
  overflow: scroll;
  // padding: 0 0 60px 0;
}

.header-button {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  border-top: 1px solid #ddd;
  padding: 10px 20px;
  background: #fff;
}

.footer-button {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #ddd;
  padding: 10px 20px;
  background: #fff;
}

.title {
  font-size: 14px;
  color: #000;
}

.van-cell {
  display: flex;
  flex-direction: column;
}


.van-radio,
.van-checkbox {
  margin: 5px 0;
}

.titleActive {
  color: #f7772c;
}
</style>
