import Vue from 'vue'
import VueRouter from 'vue-router'

import login from '../views/login/login.vue'
import index from '../views/index/index.vue'
import approvalForm from '../views/approvalForm/approvalForm.vue'
import details from '../views/details/details.vue'
import SaleCarType from '../views//SalesOrder/SaleCarType.vue'
import OrderGoods from '../views//OrderGoods/OrderGoods.vue'
import PlaceAnOrder from '../views//PlaceAnOrder/PlaceAnOrder.vue'
import OrderDetails from '../views//OrderDetails/OrderDetails.vue'
import SaleOrderList from '../views//SaleOrderList/SaleOrderList.vue'
import home from '../views/home/home'
import supplierAllBack from '../views/supplierAllBack/supplierAllBack'
import deliveryList from '../views/DeliveryList/DeliveryList.vue'
import deliveryConfirmationForm from '../views/DeliveryList/DeliveryConfirmationForm.vue'
import lockCarList from '../views/LockCarList/LockCarList.vue'
import lockCar  from '../views/LockCarList/LockCar.vue'
import noLockCarInfo  from '../views/LockCarList/NoLockCarInfo.vue'
import salesOrderDeliveryList from '../views/SalesOrderDeliveryList/SalesOrderDeliveryList'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/SaleOrderList/SaleOrderList',
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta:{
      title:'登录'
    }
  },
  {
    path: '/index',
    name: 'index',
    component: index,
    meta:{
      title:'广物移动审批'
    }
  },
  {
    path: '/approvalForm',
    name: 'approvalForm',
    component: approvalForm,
    meta:{
      title:'审批详情'
    }
  },
  {
    path: '/supplierAllBack',
    name: 'supplierAllBack',
    component: supplierAllBack,
    meta:{
      title:'审批表单'
    }
  },
  {
    path: '/details',
    name: 'details',
    component: details,
    meta:{
      title:'商品明细'
    }
  },
  {
    path: '/SaleCarType/SaleCarType',
    name: 'SaleCarType',
    component: SaleCarType,
    meta:{
      title:'车型'
    }
  },
  {
    path: '/OrderGoods/OrderGoods',
    name: 'OrderGoods',
    component: OrderGoods,
    meta:{
      title:'订单商品选择'
    }
  },
  {
    path: '/PlaceAnOrder/PlaceAnOrder',
    name: 'PlaceAnOrder',
    component: PlaceAnOrder,
    meta:{
      title:'订单下单'
    }
  },
   {
    path: '/OrderDetails/OrderDetails',
    name: 'OrderDetails',
    component: OrderDetails,
    meta:{
      title:'订单详情'
    }
  },
   {
    path: '/SaleOrderList/SaleOrderList',
    name: 'SaleOrderList',
    component: SaleOrderList,
    meta:{
      title:'订单列表'
    }
  },
  {
    path: '/DeliveryList/DeliveryList',
    name: 'DeliveryList',
    component: deliveryList,
    meta:{
      title:'交车列表'
    }
  },
  {
    path: '/DeliveryList/DeliveryConfirmationForm',
    name: 'DeliveryConfirmationForm',
    component: deliveryConfirmationForm,
    meta:{
      title:'交车确认单'
    }
  },
  {
    path: '/LockCarList/LockCarList',
    name: 'LockCarList',
    component: lockCarList,
    meta:{
      title:'配车管理'
    }
  },
  {
    path: '/LockCarList/LockCar',
    name: 'LockCar',
    component: lockCar,
    meta:{
      title:'配车'
    }
  },
  {
    path: '/LockCarList/NoLockCarInfo',
    name: 'NoLockCarInfo',
    component: noLockCarInfo,
    meta:{
      title:'车辆详情'
    }
  },
  {
    path: '/SalesOrderDeliveryList/SalesOrderDeliveryList',
    name: 'SalesOrderDeliveryList',
    component: salesOrderDeliveryList,
    meta:{
      title:'出库管理'
    }
  },
  {
    path: '/home',
    name: 'home',
    component: home,
    meta:{
      title:'审批'
    }
  }

]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
      document.title = to.meta.title;
  }
  next();
})

export default router
