<!--
 * /*Author: 杨强
 * /*Date: 2023-09-13 14:27:37
 * /*LastEditTime: 2023-09-18 16:37:04
 * /*LastEditors: 杨强
 * /*Description: 
 * /*FilePath: \bzm-sales-h5\src\views\approvalForm\components\approvalAttachments.vue
-->
<template>
  <div class="formContent">
    <!-- 内容 -->
    <div class="content">
      <div class="content_title" @click="isExpansion">
        <div class="title-name">附件</div>
        <van-icon  name="arrow-down" :class="[isexpansion ? 'spanRotate' : 'spanReset']" />
      </div>
      <div class="content_details" v-show="isexpansion">
        <van-uploader
          v-model="fileList"
          :deletable="false"
          multiple
          :max-count="fileList.length"
          preview-size="55px"
          class="uploader"
          ref="file"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // props: [
  //   "attachments"
  // ],
  props:{
    attachments:{
      type: Array,
      default: function(){
        return []
      }
    }
  },
  data () {
    return {
      isexpansion: false,
      url1: require('../../../assets/up.png'),
      url2: require('../../../assets/down.png'),
      activeNames: '1',
      fileList:[],
    }
  },
  methods: {
    isExpansion () {
      this.isexpansion = !this.isexpansion
    },
    setfileList(){
      this.fileList = []
      this.attachments.forEach(item => {
        this.fileList.push({
          url: item.ATTACHMENTS_PATH,
          isImage:true
        })
      });
    }
    // reset () {
    //   this.isexpansion = false
    // }
  },
  watch:{
    attachments(newVal) {
      console.log(newVal,"attachments-watch")
      this.attachments = newVal
      this.setfileList()
    }
  },
  created(){
    console.log(this.attachments,"attachments -created")
    this.setfileList()
  }
}
</script>

<style lang="less" scoped>
.formContent {
  background: #fff;
  border-radius: 8px;

  .content {
    padding: 0 10px;
    overflow: hidden;
  }

  .content_title {
    padding: 10px 0;
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title-name {
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #111111;
    line-height: 18px;
  }

  .spanRotate {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transition: transform .5s;
  }

  .spanReset {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transition: transform .5s;
  }

  .content_details {
    padding-bottom: 10px;
    font-size: 13px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
  }

 .img_attach{
  width: 60px;
  height: 60px;
 }
 .van-uploader__preview{
  margin-right: 10px;
 }
}
</style>
