<!--
 * /*Author: 杨强
 * /*Date: 2023-10-12 10:08:21
 * /*LastEditTime: 2023-11-03 13:06:26
 * /*LastEditors: 杨强
 * /*Description: 
 * /*FilePath: \bzm-sales-h5\src\views\OrderGoods\OrderGoods.vue
-->
<template>
  <div class="user">
    <van-nav-bar
      title="车辆信息"
      v-if="showBack"
      left-arrow
      @click-left="goBack"
    >
      <template #right> </template>
    </van-nav-bar>
    <van-cell-group
      inset
      class="scrollbar"
      :style="
        showBack ? 'height: calc(100% - 150px);' : 'height: calc(100% - 80px);'
      "
    >
      <van-cell is-link class="topInfo" @click="backpage">
        <div class="carSubTypeName">{{ CARSUBTYPEName }}</div>
        <div class="info">
          裸车指导价
          <span class="price">{{
            parseInt(CARSUBTYPEMSRP) +
            parseInt(InteriorColormsrp) +
            parseInt(AppearanceColormsrp)
          }}</span>
          <span>编码 {{ MODELCODE }}</span>
        </div>
        <div class="info" v-if="vin">
          <span>VIN码：{{ vin }}</span>
        </div>
      </van-cell>
      <div class="scroll">
        <van-field
          required
          v-model="AppearanceColoron"
          :disabled="!!vin"
          is-link
          readonly
          label="外观颜色"
          placeholder="请选择"
          @click="!vin ? (AppearanceColorshow = true) : ''"
        />
        <van-field
          required
          v-model="InteriorColor"
          is-link
          :disabled="!!vin"
          readonly
          label="内饰颜色"
          placeholder="请选择"
          @click="!vin ? (InteriorColorshow = true) : ''"
        />
        <van-field
          required
          v-model="Barecar_price"
          type="number"
          name="裸车价"
          label="裸车价"
          placeholder="请填写"
        />
        <van-field
          v-model="XuanPei_price"
          v-if="xpSwtich"
          type="number"
          name="选配"
          label="选配"
          readonly
          is-link
          @click="
            XPshow = true;
            GetCarInvByClass();
          "
          :error-message="XPMessage"
          placeholder="可选"
        />
        <van-cell-group title="增值业务">
          <van-field
            v-model="ZH_Pirce"
            is-link
            readonly
            label="装潢"
            placeholder="可选"
            @click="changeDecorate()"
            :error-message="ZhErrorMessage"
          >
          </van-field>
        </van-cell-group>
        <van-field
          v-model="Insurance_Pirce"
          is-link
          readonly
          label="保险"
          placeholder="可选"
          @click="GetInsCompany()"
        />
        <van-field
          v-model="EW_Price"
          is-link
          readonly
          label="延保"
          placeholder="可选"
          @click="GetSupplierByStore()"
        />
        <van-field
          v-model="agency_Price"
          is-link
          readonly
          label="代办"
          placeholder="可选"
          @click="
            daiban_show = true;
            GetDSBasInfo();
          "
        />
        <van-field readonly label="购车方式">
          <template #input>
            <van-radio-group v-model="shoukuangCheck" direction="horizontal">
              <van-radio name="贷款" @click="clickshoukuang(0)">贷款</van-radio>
              <van-radio
                name="全款"
                @click="clickshoukuang(1)"
                style="padding-right: 20px"
                >全款</van-radio
              >
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="Financial_Price"
          name="金融"
          required
          is-link
          label="金融"
          readonly
          placeholder="请选择"
          @click="
            click_jinrong_show();
            GetSupplierByStore_Jr();
          "
          v-if="shoukuangCheck == '贷款'"
        />
        <van-field
          label="订单款=裸车价+厂商增配+门店前装+装潢+延保+代办"
          readonly
        />
        <van-field label="订单款总计" value="100" readonly v-model="ZCAmount" />
      </div>
      <!-- </el-scrollbar> -->
    </van-cell-group>
    <div class="footerButton">
      <van-button type="primary" size="large" round @click="btnSub"
        >下一步</van-button
      >
    </div>
    <!-- 选择车型弹窗 -->
    <van-popup
      v-model="Brandshow"
      position="right"
      :style="{ height: '70%', width: '70%' }"
    >
      <van-list>
        <van-card
          v-for="(item, $index) in tabs"
          :key="$index"
          @click="ClickBrand(item)"
        >
          <div>
            {{ item.CARSUBTYPEName }}
            编码：{{ item.CARSUBTYPECode }} 厂商指导价{{ item.CARSUBTYPEMSRP }}
          </div>
        </van-card>
      </van-list>
    </van-popup>
    <!-- 选择外观颜色弹窗 -->
    <van-popup
      v-model="AppearanceColorshow"
      position="right"
      closeable
      :style="{ height: '100%', width: '100%' }"
      class="popupClass"
    >
      <van-cell class="popupTitle"> 外观颜色 </van-cell>
      <div class="popupContent">
        <van-cell
          clickable
          v-for="(item, $index) in AppearanceColorList"
          :key="$index"
          :title="item.colorName"
          :title-class="{
            titleActive: AppearanceColoronCode === item.colorCode,
          }"
          @click="choiceAppearanceColor(item)"
        />
      </div>
    </van-popup>
    <!-- 选择内饰颜色弹窗 -->
    <van-popup
      v-model="InteriorColorshow"
      position="right"
      closeable
      :style="{ height: '100%', width: '100%' }"
      class="popupClass"
    >
      <van-cell class="popupTitle"> 内饰颜色 </van-cell>
      <div class="popupContent">
        <van-cell
          clickable
          v-for="(item, $index) in InteriorColorList"
          :key="$index"
          :title="item.colorName"
          :title-class="{
            titleActive: InteriorColorCode === item.colorCode,
          }"
          @click="InteriorColorColor(item)"
        />
      </div>
    </van-popup>
    <!--点击选配搜索框弹窗-->
    <van-popup
      v-model="XPshow"
      position="right"
      :style="{ height: '100%', width: '100%' }"
      closeable
      class="decorationPopup"
    >
      <van-cell class="popupTitle"> 增配/前装 </van-cell>

      <div class="popupContent scrollbar" style="margin-bottom: 0px">
        <van-cell
          :style="{ background: '#f5f5f5', flex: 'none' }"
          :title="`该车型共${XpCar_List.length || 0}台库存`"
        >
        </van-cell>
        <!-- 列表 -->

        <van-list class="list scroll">
          <van-radio-group
            v-model="selectXPvin"
            max="1"
            @change="checkRadioFlag = false"
          >
            <van-panel
              v-for="item in XpCar_List"
              :key="item.vin"
              class="panel-item"
              :border="false"
              style="border-top: 1px rgb(245, 245, 245) solid"
            >
              <van-radio
                :name="item.VIN"
                label-disabled
                @click="
                  checkRadioFlag ? (selectXPvin = '') : '';
                  checkRadioFlag = true;
                "
                style="
                  float: left;
                  width: 10%;
                  padding-left: 0px;
                  padding-right: 15px;
                "
              ></van-radio>

              <van-cell
                :title="`VIN码：${item.VIN}`"
                style="float: left; width: 90%; padding: 0"
                :border="false"
                class="padding-top0 bold"
              ></van-cell>

              <van-cell
                class="grey"
                style="padding-left: 10%"
                :title="`外观：${item.OUTCOLOR} | 内饰：${item.INCOLOR}`"
                :border="false"
              ></van-cell>

              <van-cell
                class="grey orange"
                style="
                  padding-left: 10%;
                  padding-bottom: 20px;
                  border-bottom: 1px rgb(245, 245, 245) solid;
                "
                :title="`建议零售价：￥${item.PL_AMT}`"
                :border="false"
              ></van-cell>

              <van-panel
                v-for="INV_INCREASED in item.INV_INCREASED"
                :key="INV_INCREASED.GOODS_NO"
                :border="false"
              >
                <van-cell
                  class="bold"
                  title-style="min-width:5rem;"
                  style="padding-left: 10%"
                  :border="false"
                >
                  <div slot="title" style="display: flex">
                    <div style="width: 75%">{{ INV_INCREASED.GOODS_NAME }}</div>
                    <div style="width: 25%">
                      <van-button
                        size="small"
                        disabled
                        style="padding: 0 20px; float: right"
                      >
                        {{ INV_INCREASED.INV_TYPE }}
                      </van-button>
                    </div>
                  </div>
                </van-cell>
                <van-cell
                  class="grey"
                  title-style="min-width:5rem;"
                  style="padding-left: 10%"
                  :title="`商品编码：${INV_INCREASED.GOODS_NO}`"
                  :border="false"
                ></van-cell>
                <van-cell
                  class="grey"
                  title-style="min-width:5rem;"
                  style="padding-left: 10%"
                  :title="`厂家编码：${INV_INCREASED.GOODSVENDOR_NO || ''}`"
                  :border="false"
                ></van-cell>
                <van-cell
                  class="grey"
                  title-style="min-width:5rem;"
                  style="padding-left: 10%"
                  :title="`零售价：${INV_INCREASED.PAY_AMT}  ${
                    INV_INCREASED.PAY_MODE_TEXT ? '(' : ''
                  } ${INV_INCREASED.PAY_MODE_TEXT || ''} ${
                    INV_INCREASED.PAY_MODE_TEXT ? ')' : ''
                  }`"
                  :value="`x${INV_INCREASED.QTY}`"
                  :border="false"
                ></van-cell>
              </van-panel>
            </van-panel>
          </van-radio-group>
        </van-list>
      </div>

      <div class="xpfooterButton">
        <van-button
          type="primary"
          size="large"
          :disabled="XpCar_List.length == 0"
          round
          @click="selectXPVin()"
          >确定</van-button
        >
      </div>
    </van-popup>
    <!--点击装潢中搜索框弹窗-->
    <van-popup
      v-model="ZHshowSearch"
      position="right"
      :style="{ height: '100%', width: '100%' }"
      closeable
      class="decorationPopup"
    >
      <!-- 搜索框 -->
      <van-nav-bar>
        <template #title>
          <form action="/">
            <van-search
              v-model="ZH_value"
              shape="round"
              background="#fff"
              placeholder="商品/OEM/厂家编码/名称"
              @search="ZHonSearch"
              laceholder="商品/OEM/厂家编码/名称"
              @blur="ZHonSearch"
            />
          </form>
        </template>
      </van-nav-bar>
      <div class="popupContent">
        <van-cell
          clickable
          v-for="(item, $index) in ZHSearchList"
          :key="$index"
          :title="item"
          @click="SearchHistory(item)"
        >
          <van-icon
            name="cross"
            @click.stop="SearchHistoryClose(item)"
            class="closeIcon"
          />
        </van-cell>
      </div>
      <!-- 搜索框 -->
    </van-popup>
    <!-- 选择装潢弹窗 -->
    <van-popup
      v-model="ZHshow"
      position="right"
      :style="{ height: '100%', width: '100%' }"
      closeable
      class="decorationPopup"
    >
      <van-nav-bar>
        <template #title>
          <form action="/">
            <van-search
              v-model="ZH_value"
              shape="round"
              background="#fff"
              placeholder="商品/OEM/厂家编码/名称"
              @click="changeSearch()"
              readonly
            />
          </form>
        </template>
      </van-nav-bar>
      <!-- 搜索框 -->
      <van-tabs :active="Decoration" @click="SelectDecoration">
        <van-tab title="套餐" style="margin-bottom: 80px">
          <van-list
            v-model="TC_loading"
            :finished="TC_finished"
            :immediate-check="false"
            finished-text="没有更多了"
            @load="TC_onLoad"
          >
            <van-panel
              class="boutiquePanel"
              v-for="item in ZHPackList"
              :key="item.ITEM_ID"
            >
              <template #header></template>
              <div>
                <div class="goodsName">
                  {{ item.goodsName }}
                </div>
                <div class="manufacturerGoodsNO" style="margin-bottom: 10px">
                  套餐编码 {{ item.goodsNo }}
                </div>
                <div
                  v-for="packitem in item.goodsItemInfo"
                  :key="packitem.goodsNo"
                >
                  <div class="goodsName" style="margin-top: 10px">
                    {{ packitem.goodsName }}
                  </div>
                  <div class="goodsNO">商品编码 {{ packitem.goodsNo }}</div>
                  <div class="goodsNO">
                    厂商编码 {{ packitem.goodsVendorNo }} |
                    {{ packitem.goodsQA }}
                  </div>
                  <div class="goodsNO">单价 ￥{{ packitem.goodsPrice }}</div>
                </div>
                <div class="price" style="margin-top: 10px">
                  套餐价 ￥{{ item.goodsPrice }}
                </div>
              </div>
              <template #footer>
                <van-button
                  size="small"
                  round
                  @click="openAccompanyingAccessories(item)"
                  >随车附件</van-button
                >
                <van-button
                  size="small"
                  type="primary"
                  round
                  @click="
                    ZHAddGoods_Card(item, '套餐', {
                      text: '客户付费',
                      value: 0,
                    })
                  "
                  >加购</van-button
                >
              </template>
            </van-panel>
          </van-list>
        </van-tab>
        <van-tab title="精品" style="margin-bottom: 80px">
          <van-list
            v-model="loading"
            :finished="finished"
            :immediate-check="false"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <van-panel
              class="boutiquePanel"
              v-for="item in ZHjpList"
              :key="item.ITEM_ID"
            >
              <template #header></template>
              <div>
                <div class="goodsName">
                  {{ item.GOODS_NAME }}
                </div>
                <div class="goodsNO">商品编码 ： {{ item.GOODS_NO }}</div>
                <div class="manufacturerGoodsNO">
                  厂商编码 ：{{ item.manufactorGoods_no }}
                </div>
                <div class="manufacturerGoodsNO">
                  库存：
                  {{ item.INV_QTY }}
                </div>
                <van-tag size="medium" color="#f6f5f7" text-color="#9e9e9e">
                  <div class="tagContent">
                    销售模式：{{ item.saleFactor || "销售" }}
                  </div>
                  <van-icon name="play" />
                </van-tag>
                <div class="price">单价 ¥ {{ item.SELL_PRICE }}</div>
              </div>
              <template #footer>
                <van-button
                  size="small"
                  round
                  @click="openAccompanyingAccessories(item)"
                  >随车附件</van-button
                >
                <van-button
                  size="small"
                  type="primary"
                  round
                  @click="
                    ZHAddGoods_Card(item, '精品', {
                      text: '客户付费',
                      value: 0,
                    })
                  "
                  >加购</van-button
                >
              </template>
            </van-panel>
          </van-list>
        </van-tab>
        <van-tab title="备件" style="margin-bottom: 80px">
          <van-list
            v-model="BJ_loading"
            :finished="BJ_finished"
            :immediate-check="false"
            finished-text="没有更多了"
            @load="BJ_onLoad"
          >
            <van-panel
              class="boutiquePanel"
              v-for="item in ZHbjList"
              :key="item.ITEM_ID"
            >
              <template #header></template>
              <div>
                <div class="goodsName">
                  {{ item.GOODS_NAME }}
                </div>
                <div class="goodsNO">商品编码 ： {{ item.GOODS_NO }}</div>
                <div class="manufacturerGoodsNO">
                  厂商编码 {{ item.GOODSVENDOR_NO }} |
                  {{ item.GOODS_INCLASS_NAME }} | 库存 {{ item.INV_QTY }}
                </div>
                <div class="price">单价 ¥ {{ item.SELL_PRICE }}</div>
              </div>
              <template #footer>
                <van-button
                  size="small"
                  round
                  @click="openAccompanyingAccessories(item)"
                  >随车附件</van-button
                >
                <van-button
                  size="small"
                  type="primary"
                  round
                  @click="
                    ZHAddGoods_Card(item, '备件', {
                      text: '客户付费',
                      value: 0,
                    })
                  "
                  >加购</van-button
                >
              </template>
            </van-panel>
          </van-list>
        </van-tab>
      </van-tabs>
      <van-popup v-model="showPicker" round position="bottom">
        <van-picker
          show-toolbar
          :default-index="0"
          :columns="FuFeioption"
          @cancel="showPicker = false"
          @confirm="onfufeiConfirm($event)"
        >
        </van-picker>
      </van-popup>

      <!-- 购物车定位底部失效原因父级有transform属性会导致固定定位失效 -->
      <div class="shoppingCart">
        <div class="shoppingCart-left" @click="ZHGoods_CardList()">
          <van-icon
            color="#656565"
            name="cart-o"
            :badge="
              this.ZHGoods_List.reduce(
                (sum, e) => sum + Number(e.qty || 0),
                0
              ) || ''
            "
          />
          <span class="label">明细</span>
          <van-icon name="arrow-up" />
        </div>
        <van-button size="small" type="primary" round @click="ZHshow = false"
          >确定</van-button
        >
      </div>
    </van-popup>
    <!-- 选择保险弹窗 -->
    <van-popup
      v-model="Insurance_show"
      position="right"
      closeable
      :style="{ height: '100%', width: '100%' }"
      class="popupClass"
    >
      <van-cell class="popupTitle"> 保险</van-cell>
      <!-- 搜索框 -->
      <form action="/">
        <van-search
          v-model="Insurance_value"
          shape="round"
          background="#fff"
          placeholder="保险公司"
          @search="Insurance_Search"
          @clear="Insurance_Search"
          @blur="Insurance_Search"
          @cancel="Insurance_Search"
          @keypress="Insurance_Search"
        />
      </form>
      <div class="popupContent">
        <van-cell
          clickable
          v-for="(item, $index) in InsuranceList"
          :key="$index"
          :title="item.INS_BRANCHNAME"
          :title-class="{
            titleActive: INS_BRANCHNO == item.INS_BRANCHNO,
          }"
          @click="Insurance_Item_click(item)"
        />
      </div>
    </van-popup>
    <!-- 选择保险项目明细弹窗 -->
    <van-popup
      v-model="Insurance_item_show"
      position="right"
      :style="{ height: '100%', width: '100%' }"
      class="insuranceDetailsPopup popupClass"
    >
      <van-nav-bar
        title="保险"
        left-arrow
        @click-left="Insurance_item_show = false"
      />
      <van-cell
        required
        title="保险公司"
        is-link
        :value="Insurance_item_name"
        @click="
          Insurance_item_show = false;
          Insurance_show = true;
        "
        value-class="insuranceCompany"
      />
      <div class="popupContent">
        <!-- <van-checkbox-group v-model="check_result" ref="checkboxGroup"> -->
        <van-cell
          class="van-checkbox-cell-group"
          v-for="(item, index) in houseutilizationColumns"
          :key="index"
        >
          <van-checkbox
            :value="item.isChecked"
            :name="item"
            @click="checkbox(item)"
            ref="checkbox"
            style="font-size: 14px"
            >{{ item.INS_TYPENAME }}</van-checkbox
          >
          <van-cell-group inset v-if="item.isChecked">
            <van-field
              input-align="right"
              type="Number"
              v-model="item.baofei"
              label="保费"
              placeholder="￥0"
              class="fieldItem"
            >
              <template #right-icon>元</template>
            </van-field>
            <van-field
              input-align="right"
              type="Number"
              v-model="item.baoe"
              label="保额"
              placeholder="￥0"
              class="fieldItem"
            >
              <template #right-icon>元</template>
            </van-field>
          </van-cell-group>
        </van-cell>
        <!-- </van-checkbox-group> -->
      </div>
      <div class="totalFooter">
        <div>合计 {{ bx_amount }}</div>
        <van-button
          size="small"
          type="primary"
          round
          :disabled="showInsurance"
          @click="
            Insurance_item_show = false;
            Insurance_show = false;
          "
          >确定</van-button
        >
      </div>
    </van-popup>
    <!-- 选择延保弹窗 -->
    <van-popup
      v-model="yanbao_show"
      position="right"
      closeable
      :style="{ height: '100%', width: '100%' }"
      class="popupClass"
    >
      <van-cell class="popupTitle"> 延保产品 </van-cell>
      <!-- 搜索框 -->
      <form action="/">
        <van-search
          v-model="yanbao_value"
          shape="round"
          background="#fff"
          placeholder="商品/OEM/厂家编码/名称"
          @search="yanbao_Search"
          @clear="yanbao_Search"
          @blur="yanbao_Search"
          @cancel="yanbao_Search"
          @keypress="yanbao_Search"
        />
      </form>
      <div class="popupContent">
        <van-cell
          clickable
          v-for="(item, $index) in yanbaoList"
          :key="$index"
          :title="item.EWPNAME"
          :title-class="{
            titleActive:
              GetSupplierModel.ewpSupplierNo === item.EWPNO ||
              supplierName === item.EWPNAME,
          }"
          @click="yanbao_Product_click(item)"
        />
      </div>
    </van-popup>
    <!-- 选择延保产品弹窗 -->
    <van-popup
      v-model="yanbao_Product_show"
      position="right"
      :style="{ height: '100%', width: '100%' }"
      class="extendedWarrantyProductsPopup popupClass"
    >
      <!-- yanbao_Product_show = false; yanbao_show = false -->
      <van-nav-bar
        title="延保"
        left-arrow
        @click-left="click_Insurance_item_show"
      />
      <div class="popupContent">
        <van-list
          class="ml30 mr30 marginBottom"
          inset
          v-for="(item, $yanbaoindex) in formList"
          :key="$yanbaoindex"
          style="font-size: 20px; margin-padding: 10px"
        >
          <van-field
            label-class="name"
            readonly
            :label="`延保项目${$yanbaoindex + 1}`"
          >
            <template #right-icon>
              <div @click="deleteyanbaotype(item, $yanbaoindex)" class="delete">
                删除
              </div>
            </template>
          </van-field>
          <van-field
            required
            v-model="item.ewProductName"
            is-link
            readonly
            label="延保产品"
            placeholder="请选择"
            @click="ybProducctName_click(item, $yanbaoindex)"
          />

          <van-field
            required
            v-model="item.ewPeriod"
            is-link
            readonly
            label="延保方案"
            placeholder="请选择"
            @click="ybproject_click(item)"
          />

          <van-field
            required
            v-model="item.ewTypeName"
            is-link
            readonly
            label="延保类型"
            placeholder="请选择"
            @click="ybybType_click(item, $yanbaoindex)"
          />
          <van-field
            required
            v-model="item.ewAmount"
            type="number"
            oninput="if(value<0){value=0};value.replace(/\s+/g, '')"
            label="延保金额"
            placeholder="￥0"
          />
          <van-field
            required
            v-model="item.ewStartDate"
            is-link
            readonly
            label="开始时间"
            @click="ybStartDate_click"
          />
          <van-field
            required
            v-model="item.ewEndDate"
            is-link
            readonly
            label="结束时间"
            @click="ybEndDate_click"
          />
          <!-- 开始日期弹出层 -->
          <van-popup
            v-model="startDateShow"
            position="bottom"
            :close-on-click-overlay="false"
          >
            <van-datetime-picker
              v-model="startDate"
              type="date"
              title="选择年月日"
              @cancel="closePopup"
              @confirm="updateData(item, $yanbaoindex, $event, '开始')"
            />
          </van-popup>
          <!-- 开始日期弹出层 -->
          <van-popup
            v-model="endDateShow"
            position="bottom"
            :close-on-click-overlay="false"
          >
            <van-datetime-picker
              v-model="endDate"
              type="date"
              title="选择年月日"
              @cancel="closePopup"
              @confirm="updateData(item, $yanbaoindex, $event, '结束')"
            />
          </van-popup>
        </van-list>
        <!--  -->
        <van-button
          v-if="formList.length == 0 || ENV == 'HW'"
          type="primary"
          block
          plain
          :style="{ border: 'none' }"
          @click="addybProduct"
          >+新增延保产品</van-button
        >
      </div>
      <div class="totalFooter">
        <div>延保总金额 ¥ {{ yanbaoAmount }}</div>
        <van-button
          size="small"
          type="primary"
          round
          @click="click_Insurance_item_show"
          >确定</van-button
        >
      </div>
    </van-popup>
    <!-- 选择延保产品弹窗 -->
    <van-popup
      closeable
      v-model="ybProductListshow"
      position="right"
      :style="{ height: '100%', width: '100%' }"
      class="popupClass"
    >
      <van-cell class="popupTitle">延保产品</van-cell>
      <div class="popupContent">
        <van-cell
          clickable
          v-for="(item, $index) in ybProductList"
          :key="$index"
          :title="item.EWP_NAME"
          :title-class="{
            titleActive:
              (formList[ybIndex] && formList[ybIndex].ewProductNo) ===
              item.EWP_NO,
          }"
          @click="choiceProduct(item)"
        />
      </div>
    </van-popup>
    <!-- 选择延保方案弹窗 -->
    <van-popup
      closeable
      v-model="ybFanganListshow"
      position="right"
      :style="{ height: '100%', width: '100%' }"
      class="popupClass"
    >
      <van-cell class="popupTitle">延保方案</van-cell>
      <div class="popupContent">
        <van-cell
          clickable
          v-for="(item, $index) in ybFanganList"
          :key="$index"
          :title="item.plstCcomment"
          :title-class="{
            titleActive:
              (formList[ybIndex] && formList[ybIndex].ewPeriod) ===
              item.plstCcomment,
          }"
          @click="choiceybFangan(item)"
        />
      </div>
    </van-popup>
    <!-- 选择延保类型方案弹窗 -->
    <van-popup
      closeable
      v-model="ybTypeListshow"
      position="right"
      :style="{ height: '100%', width: '100%' }"
      class="popupClass"
    >
      <van-cell class="popupTitle">延保类型</van-cell>
      <div class="popupContent">
        <van-cell
          clickable
          v-for="(item, $index) in ybTypeList"
          :key="$index"
          :title="item.ewTypeName"
          :title-class="{
            titleActive:
              (formList[ybIndex] && formList[ybIndex].ewTypeName) ===
              item.ewTypeName,
          }"
          @click="choiceybType(item)"
        />
      </div>
    </van-popup>
    <!-- 选择代办产品弹窗 -->
    <van-popup
      closeable
      v-model="daiban_show"
      position="right"
      :style="{ height: '100%', width: '100%' }"
      class="extendedWarrantyProductsPopup popupClass"
    >
      <!-- <van-nav-bar title="代办" left-arrow @click-left="daiban_show = false" /> -->
      <van-cell class="popupTitle">代办</van-cell>
      <div class="popupContent">
        <van-list
          v-for="(item, $index) in dbpushList"
          :key="$index"
          :title="item"
          style="font-size: 20px; margin-padding: 10px"
        >
          <van-field
            label-class="name"
            readonly
            :label="`代办项目${$index + 1}`"
          >
            <template #right-icon>
              <div @click="deletedaibantype(item, $index)" class="delete">
                删除
              </div>
            </template>
          </van-field>
          <van-field
            required
            is-link
            input-align="right"
            v-model="item.GOODS_NAME"
            label="代办类型"
            placeholder="请选择"
            readonly
            class="fieldItem"
            @click="daiban_type_click($index)"
          />
          <van-field
            required
            input-align="right"
            type="Number"
            v-model="item.dbPrice"
            label="代办金额"
            placeholder="￥0"
            class="fieldItem"
          />
          <van-field
            v-if="dbcbShow"
            required
            input-align="right"
            type="Number"
            v-model="item.dbcostPrice"
            label="代办成本"
            placeholder="￥0"
            class="fieldItem"
          />
        </van-list>
        <van-button
          type="primary"
          block
          plain
          :style="{ border: 'none' }"
          @click="adddbProduct"
          >+新增代办项目</van-button
        >
      </div>
      <div class="footerButton">
        <van-button
          type="primary"
          size="large"
          :disabled="WhetherAdd"
          round
          @click="agent()"
          >确定</van-button
        >
      </div>
    </van-popup>
    <!-- 选择代办类型弹窗 -->
    <van-popup
      v-model="daibantype_show"
      position="right"
      :style="{ height: '100%', width: '100%' }"
      class="popupClass decorationPopup"
    >
      <van-nav-bar left-arrow @click-left="daibantype_show = false">
        <template #title>
          <form action="/">
            <van-search
              v-model="DB_value"
              shape="round"
              background="#fff"
              placeholder="代办名称"
              @search="selectDSBasInfo"
              @clear="selectDSBasInfo"
              @blur="selectDSBasInfo"
              @cancel="selectDSBasInfo"
              @keypress="selectDSBasInfo"
            />
          </form>
        </template>
      </van-nav-bar>

      <van-list
        class="popupContent"
        v-model="DB_loading"
        :finished="DB_finished"
        :immediate-check="false"
        finished-text="没有更多了"
        @load="DB_onLoad"
        :offset="10"
      >
        <van-cell
          clickable
          v-for="(item, $index) in daibantype_List"
          :key="$index"
          :title="item.GOODS_NAME"
          :title-class="{
            titleActive:
              (dbpushList[daibanIndex] && dbpushList[daibanIndex].dbcode) ===
              item.GOODS_NO,
          }"
          @click="choicedaibantype(item)"
        />
      </van-list>
    </van-popup>
    <!-- 选择金融产品弹窗 -->
    <van-popup
      v-model="jinrong_show"
      position="right"
      closeable
      :style="{ height: '100%', width: '100%' }"
      class="popupClass"
    >
      <van-cell class="popupTitle"> 金融机构 </van-cell>
      <!-- 搜索框 -->
      <form action="/">
        <van-search
          v-model="jinrong_Value"
          shape="round"
          background="#fff"
          placeholder="金融机构"
          @search="jinrongSearch"
          @blur="jinrongSearch"
          @clear="jinrongSearch"
          @cancel="jinrongSearch"
          @keypress="jinrongSearch"
        />
      </form>
      <div class="popupContent">
        <van-cell
          clickable
          v-for="(item, $index) in jinrongList"
          :key="$index"
          :title="item.PARTNER_NAME"
          :title-class="{
            titleActive: jinrong_OrgNo === item.PARTNER_NO,
          }"
          @click="AddjinrongProduct(item)"
        />
      </div>
    </van-popup>
    <!-- 选择金融弹窗 -->
    <van-popup
      v-model="jinrong_Product_show"
      position="right"
      :style="{ height: '100%', width: '100%' }"
    >
      <van-nav-bar title="金融" left-arrow @click-left="CloseFinance()" />
      <van-field
        is-link
        input-align="right"
        v-model="jinrong_Org"
        label="金融机构"
        readonly
        @click="click_jinrongfangan_show"
        placeholder="请选择"
        class="fieldItem redIcon"
        required
      />
      <van-field
        is-link
        input-align="right"
        v-model="jingrong_fangan"
        label="金融方案"
        readonly
        placeholder="请选择"
        class="fieldItem redIcon"
        required
        @click="Addjinrongfangan()"
      />

      <van-field
        input-align="right"
        type="number"
        oninput="if(value<0)value=0"
        v-model="jinrong_sfPirce"
        label="首付金额"
        placeholder="必填，首付款包含定金金额"
        class="fieldItem redIcon"
        required
      />
      <van-field
        input-align="right"
        type="number"
        oninput="if(value<0)value=0"
        v-model="jinrong_zxPirce"
        label="协助咨询费"
        placeholder="请填写"
        class="fieldItem redIcon"
        required
      />
      <van-field
        input-align="right"
        type="digit"
        v-model="jinrong_dkqx"
        label="贷款期限"
        placeholder="请填写"
        class="fieldItem redIcon"
        required
      />
      <van-field
        v-if="khcdShow"
        input-align="right"
        type="number"
        v-model="cusSelfpayInterestAmt"
        label="客户承担"
        placeholder="请填写"
        class="fieldItem redIcon"
        required
      />
      <div class="footerButton">
        <van-button
          type="primary"
          size="large"
          :disabled="jinrongdisabled"
          round
          @click="jinrong_sub()"
          >确定</van-button
        >
      </div>
    </van-popup>
    <!-- 选择金融方案弹窗 -->
    <van-popup
      v-model="jinrongfangan_show"
      position="right"
      closeable
      :style="{ height: '100%', width: '100%' }"
      class="popupClass"
    >
      <van-cell class="popupTitle"> 金融方案 </van-cell>
      <!-- 搜索框 -->
      <form action="/">
        <van-search
          v-model="jinrongFA_value"
          shape="round"
          background="#fff"
          placeholder="金融方案"
          @search="jinrongFanganSearch"
          @blur="jinrongFanganSearch"
          @clear="jinrongFanganSearch"
          @cancel="jinrongFanganSearch"
          @keypress="jinrongFanganSearch"
        />
      </form>
      <div class="popupContent">
        <van-cell
          clickable
          v-for="(item, $index) in jinrongfanganList"
          :key="$index"
          :title="item.FIN_PRODUCT_NAME"
          :title-class="{
            titleActive: FIN_PRODUCT_NO === item.FIN_PRODUCT_NO,
          }"
          @click="click_jinrongFa(item)"
        />
      </div>
    </van-popup>
    <!-- 选择装潢购物车弹窗 -->
    <van-popup
      v-model="zhcard_show"
      position="right"
      :style="{ height: '100%', width: '100%' }"
      class="decorationDetailsPopup popupClass"
    >
      <van-nav-bar title="装潢明细">
        <!-- right-text="批量打折" -->
        <template #left>
          <van-icon name="cross" @click="zhcard_show = false" />
        </template>
      </van-nav-bar>
      <div class="popupContent">
        <van-cell
          value-class="decorationDetailsItem"
          v-for="(item, $index) in ZHGoods_List"
          :key="(item.Goods_No || item.Goods_No) + '&' + item.payModeNo"
        >
          <div>
            <div class="decorationDetailsItemLeft">
              <div class="goodsName">{{ item.Goods_Name }}</div>

              <div v-if="item.Goods_Source == '套餐'">
                <div>套餐编码: {{ item.itemNo || item.Goods_No }}</div>
                <div>套餐价: {{ item.goodsPrice }}</div>
              </div>
              <div v-else>
                <div>商品编码： {{ item.Goods_No }}</div>
                <div>厂商编码： {{ item.manufactorGoods_no }}</div>
                <div>单价：¥ {{ item.goodsPrice }}</div>
              </div>
              <div>销售模式: {{ item.saleFactor }}</div>
              <div>来源: {{ item.Goods_Source }}</div>
              <div v-if="item.payClass != ''">
                付费类型: {{ item.payClass }}
              </div>
            </div>
            <div class="decorationDetailsItemRight">
              <van-stepper
                disable-input
                v-model="item.qty"
                integer
                @plus="addZHqty($index)"
                @minus="decrement(item)"
                min="0"
              />
            </div>
          </div>
          <div>
            <van-field
              v-model="item.Discountrate"
              @blur="changeDecoration(item, 'zk')"
              type="number"
              name="折扣率"
              label="折扣率"
              value=""
              :disabled="item.isGoodsCombo == 1"
            />
            <van-field
              v-model="item.Subtotal"
              type="number"
              name="小计"
              label="小计"
              value=""
              @blur="changeDecoration(item, 'xj')"
              :disabled="item.isGoodsCombo == 1"
              :readonly="item.isGoodsCombo == 1"
            />
          </div>
        </van-cell>
      </div>
      <!-- 购物车定位底部失效原因父级有transform属性会导致固定定位失效 -->
      <div class="shoppingCart">
        <div class="shoppingCart-left" @click="ZHGoods_CardList()">
          <van-icon
            color="#656565"
            name="cart-o"
            :badge="
              this.ZHGoods_List.reduce(
                (sum, e) => sum + Number(e.qty || 0),
                0
              ) || ''
            "
          />
          <span class="label">明细</span>
          <van-icon name="arrow-down" />
          <div class="label">
            合计：<span class="total">{{ zhAmount }}</span>
          </div>
        </div>
        <van-button
          size="small"
          type="primary"
          round
          @click="zhcard_show = false"
          >确定</van-button
        >
      </div>
    </van-popup>
  </div>
</template>

<script>
import { GetCarSalesOrder } from "../../api/SalesOrderList";
import {
  GetCarColorListByModelCode,
  QueryCarInvByVin,
  GetCarInvByClass,
  GetSupplierByStore,
  GetEWProductInfo,
  GetEWProductList,
  GetWMType,
  GetEwPTime,
  GetSubTypeBySeries,
  GetSalesJPInfo,
  GetSalesTCInfo,
  GetInsCompany,
  GetInsTypeItem,
  GetPayMode,
  GetFinProductBySupplier,
  GetDSBasInfo,
  GetPriceByPayMode,
} from "../../api/OrderGoods";

export default {
  data() {
    return {
      showBack: true,
      XPMessage: "",
      loading: false,
      finished: false,
      DB_loading: false,
      DB_finished: false,
      BJ_loading: false,
      BJ_finished: false,
      TC_loading: false,
      TC_finished: false,
      showInsurance: true,
      ybProductListshow: false,
      ybFanganListshow: false,
      ybTypeListshow: false,
      Decoration: 0,
      decorationTitle: "套餐",
      Pickercolumns: [],
      tabs: [],
      CARSUBTYPECode: this.$route.query.CARSUBTYPECode,
      CARSUBTYPEName: this.$route.query.CARSUBTYPEName,
      CARSUBTYPEMSRP: this.$route.query.CARSUBTYPEMSRP,
      newCARSUBTYPEMSRP: "", //操作后的指导价
      SERIESCODE: this.$route.query.SERIESCODE,
      MODELCODE: this.$route.query.MODELCODE,
      CARTYPECODE: this.$route.query.CARTYPECODE,
      CARTYPENAME: "",
      ISDIRECTSALE: "",
      //表单颜色开始
      AppearanceColorList: [],
      InteriorColorList: [],
      daibantype_List: [
        // { daibanTypeName: "拖车费" },
        // { daibanTypeName: "市场活动费" },
        // { daibanTypeName: "税金" },
        // { daibanTypeName: "出库费" },
      ],
      yanbaoList: [],
      ZHSearchList: [],
      //保险
      InsuranceList: [],
      //金融机构
      jinrongList: [],
      jinrongfanganList: [],
      jinrong_Org: "",
      jinrong_OrgNo: "",
      FIN_PRODUCT_NO: "",
      jingrong_fangan: "",
      jinrong_sfPirce: "",
      jinrong_zxPirce: "",
      jinrong_dkqx: "",
      cusSelfpayInterestAmt: "",
      jinrongPorudct: [],
      baofeilist: [],
      checked: "",
      check_result: [], // 选中数据
      //选配
      XpCar_List: [], //选配车辆
      XuanPei_price: "", //选配金额
      //查询颜色
      CarColorModel: {
        modelCode: "",
      },
      vin: "",
      houseutilizationColumns: [],
      shoukuangCheck: "全款",
      baofei: "",
      baoedu: "",
      check_houseutilization: {},
      //延保
      yanbao_show: false,
      yanbao_value: "",
      bx_amount: "",
      yanbao_Product_show: false,
      yanbaoAmount: 0,
      //延保产品定义字段
      formList: [],
      ybProductList: [],
      ybFanganList: [],
      ybTypeList: [],
      dbpushList: [
        {
          GOODS_NAME: "",
          dbPrice: "",
          dbcode: "",
          dbcostPrice: "",
        },
      ],
      daibantype_show: false,
      zhcard_show: false,
      ybPrictVal: 0,
      currentDate: new Date(),
      Datedata: "",
      radioModel: "",
      Insurance_itemList: [{}],
      AppearanceColoron: "",
      AppearanceColoronCode: "",
      AppearanceColormsrp: 0,
      InteriorColor: "",
      InteriorColorCode: "",
      InteriorColormsrp: 0,
      AppearanceColorshow: false,
      InteriorColorshow: false,
      startDateShow: false,
      endDateShow: false,
      endDate: new Date(),
      startDate: new Date(),
      ZHshow: false,
      ZHshowSearch: false,
      XPshow: false,
      Insurance_show: false,
      Insurance_item_show: false,
      daiban_show: false,
      jinrong_show: false,
      jinrong_Product_show: false,
      jinrongfangan_show: false,
      ZH_value: "",
      ZHSearch_value: "",
      DB_value: "",
      jinrongFA_value: "",
      Insurance_value: "",
      //裸车指导价
      Barecar_price: "",
      //装潢金额
      ZH_Pirce: "",
      //保费金额
      ZhErrorMessage: "", //装潢详情
      Insurance_Pirce: "",
      //延保金额
      EW_Price: "",
      //代办金额
      agency_Price: "",
      //金融金额
      Financial_Price: "",
      ZCAmount: "",

      Insurance_item_name: "",
      InsProductNameArray: [],
      NewInsProductNameArray: [],
      INS_BRANCHNO: "",
      result: "",
      GetCarSalesOrderModel: {
        soNo: "",
      },
      // Optional: "",

      goods_active: "套餐",
      //表单结束
      value: "",
      Index: 1,
      ModelVIN: "",
      CARTYPEModel: {
        brandCode: "",
        brandName: "",
        brandId: "",
        IsNewCar: "",
      },
      Brandshow: false,
      parentMessage: "Parent",
      items: [
        { message: "Foo" },
        { message: "Foo1" },
        { message: "Foo2" },
        { message: "Foo3" },
        { message: "Foo4" },
      ],
      show: false,
      value1: 0,
      value2: "a",
      isShowName: false,
      ZHjpList: [],
      ZHbjList: [],
      ZHPackList: [],
      zhGoodsModel: {
        Goods_Name: "",
        manufactorGoods_no: "",
        saleFactor: "",
        pack_goodsno: "",
        pack_goodsPrice: "",
        goodsPrice: "",
        Goods_Source: "",
        payClass: "",
        Discountrate: "",
        Subtotal: "",
        Goods_describe: "",
      },
      ZHGoods_List: [],
      showPicker: false,
      competitiveProducts: {},

      option1: [{ text: "审批流类型", value: "" }],
      FuFeioption: [],
      GetSupplierModel: {
        orgNo: "",
        useOrgNo: "",
        type: "",
        orgName: "",
      },
      GetEwBasModel: {
        useOrgNo: "",
        ewProductNo: "",
      },
      NewGetEwBasModel: {
        ewProductNo: "",
      },
      //车系
      CarSeriesModel: {
        SeriesCode: "",
      },
      GetSalesJPModel: {
        StoreNO: "",
        Type: "",
        KeyWord: "",
      },
      GetInsCompanyModel: {
        StoreNO: "",
        InsCompanyName: "",
      },
      GetInsTypeItemModel: {
        StoreNO: "",
        INS_COMPANYID: "",
      },
      GetFinProductModel: {
        useOrgNo: "",
        provider_no: "",
        FIN_TYPE_NAME: "",
      },
      GetDSBasInfoModel: {
        StoreNO: "",
        DSItemName: "",
      },
      GetPriceByPayModel: {
        StoreNO: "",
        PayMode: "",
        GoodsNo: "",
      },
      //通过vin查询
      CarInvModel: {
        shopCode: "",
        vin: "",
        subtypeCode: "",
        cartypeCode: "",
        classCode: "",
        inColor: "",
        outColor: "",
      },
      //通过vin查询
      CarXPModel: {
        shopCode: "",
        subtypeCode: "",
        cartypeCode: "",
        classCode: "",
        inColor: "",
        outColor: "",
      },
      ybIndex: 0,
      daibanIndex: 0,
      jinrong_Value: "",
      InsCompanyId: "",
      WhetherAdd: true,
      // 车辆信息
      carInfo: {},
      supplierName: "",
      supplierNameCode: "",
      newval: [],
      //客户承担是否显示
      khcdShow: process.env.VUE_APP_BASE_ENV === "YJ",
      //代办成本是否显示
      dbcbShow: process.env.VUE_APP_BASE_ENV === "YJ",
      selectXPvin: "",
      checkRadioFlag: false,
      soStatus: "",
      INV_INCREASED: [],
      xpCarInfo: {},
      xpSwtich: process.env.VUE_APP_BASE_XP,
    };
  },
  mounted() {
    const otherInfo = JSON.parse(sessionStorage.getItem("otherInfo"));
    console.log("otherInfo-----", otherInfo);
    if (otherInfo) {
      this.backfill(otherInfo);
    }
  },
  methods: {
    goBack() {
      if (this.showBack) {
        //返回上一页
        this.$router.go(-1);
      }
    },
    // 数据回填
    backfill(otherInfo) {
      Object.assign(this._data, otherInfo);
      this.ZHGoods_List = JSON.parse(otherInfo.ZH_item);
      this.dbpushList = JSON.parse(otherInfo.DB_item);
      this.formList = JSON.parse(otherInfo.YB_item);
      this.jinrongPorudct = JSON.parse(otherInfo.JR_item);
      this.check_result = JSON.parse(otherInfo.check_result);
      this.GetSupplierModel.ewpSupplierNo = otherInfo.supplierNameCode;
      this.arrInsOrderItems = otherInfo.arrInsOrderItems
        ? JSON.parse(otherInfo.arrInsOrderItems)
        : {};
      console.log(this.$route.query.MODELCODE, this.$route.query.vin);
      if (this.$route.query.MODELCODE || this.$route.query.vin) {
        this.MODELCODE = this.$route.query.MODELCODE;
        this.vin = this.$route.query.vin;
        this.SERIESCODE = this.$route.query.SERIESCODE;
        this.CARTYPECODE = this.$route.query.CARTYPECODE;
        // this.AppearanceColoron =''
        // this.AppearanceColoronCode =''
        // this.InteriorColor =''
        // this.InteriorColorCode =''
        // this.Barecar_price = ''
      }
      console.log(this.$route.query.MODELCODE, "this.$route.query.MODELCODE");
      console.log(otherInfo.MODELCODE, "otherInfo.MODELCODE");
      this.soStatus = otherInfo.soStatus;
      if (
        this.$route.query.MODELCODE &&
        this.$route.query.MODELCODE !== otherInfo.MODELCODE
      ) {
        this.AppearanceColoron = "";
        this.AppearanceColoronCode = "";
        this.InteriorColor = "";
        this.InteriorColorCode = "";
        this.Barecar_price = "";
      } else {
        if (this.xpSwtich) {
          this.xpCarInfo = JSON.parse(sessionStorage.getItem("xpCarInfo"));
          if (!this.vin) {
            this.vin = otherInfo.soCarVin;
            this.selectXPvin = otherInfo.soCarVin;
            if (this.vin) {
              this.GetCarInvByClass(true);
            }
          }
        }
      }
      console.log(this.AppearanceColoron, "this.AppearanceColoron = ''");
      // console.log(this.vin,"this.vin")
      // console.log(this.xpCarInfo,"this.xpCarInfo----")
      // this.init_XPInfo()
    },
    // 装潢
    changeDecoration(item, name) {
      console.log("修改装潢信息", item);
      this.ZHGoods_List = this.ZHGoods_List.map((res) => {
        if (res.Goods_No == item.Goods_No) {
          if (name == "zk") {
            res.Subtotal = res.Discountrate * (res.goodsPrice * res.qty);
          } else {
            res.Discountrate = (
              Number(res.Subtotal) /
              (res.goodsPrice * res.qty)
            ).toFixed(4);
          }
        }
        return res;
      });
      console.log(this.ZHGoods_List);
    },
    // 数据缓存
    dataCache() {
      // this.ZHGoods_List //装潢
      // this.houseutilizationColumns //保险
      return new Promise((resolve) => {
        let arrgoodsItems = [];
        console.log("0000", this.ZHGoods_List);
        this.ZHGoods_List.forEach((item) => {
          if (item.isGoodsCombo == 1) {
            //套餐销售总价格
            let damountSum = item.goodsItemInfo.reduce(
              (sum, e) => sum + Number(e.amount || 0),
              0
            );
            item.goodsItemInfo.forEach((i) => {
              let goods_item = {};
              goods_item.itemNo = i.goodsNo;
              goods_item.comboNo = item.Goods_No;
              goods_item.sellTypeCode =
                item.sellTypeCode != null &&
                item.sellTypeCode != "" &&
                item.sellTypeCode != "undefined"
                  ? item.sellTypeCode
                  : "XS";
              goods_item.maintainClassNo = "3";
              goods_item.payModeNo = item.payModeNo;
              goods_item.payClass = item.payClass;
              goods_item.qty = i.qty;
              goods_item.damount = Number(i.amount);
              goods_item.discount = Number(1);
              goods_item.price = Number(i.goodsPrice);
              goods_item.ori_Price = Number(i.Ori_Price);
              goods_item.totalComboAmt = Number(item.goodsPrice); //套餐的价格
              goods_item.damountSum = Number(damountSum); //套餐销售总价格
              for (let i = 0; i < item.qty; i++) {
                arrgoodsItems.push(goods_item);
              }
            });
            console.log("添加时的装潢数据", arrgoodsItems);
          } else {
            let goods_item = {};
            goods_item.itemNo = item.Goods_No;
            goods_item.comboNo = "";
            goods_item.sellTypeCode =
              item.sellTypeCode != null &&
              item.sellTypeCode != "" &&
              item.sellTypeCode != "undefined"
                ? item.sellTypeCode
                : "XS";
            goods_item.maintainClassNo = "3";
            goods_item.payModeNo = item.payModeNo;
            goods_item.payClass = item.payClass;
            goods_item.qty = item.qty;
            goods_item.damount = Number(item.Subtotal);
            goods_item.discount = Number(item.Discountrate);
            goods_item.price = Number(item.goodsPrice);
            arrgoodsItems.push(goods_item);
          }
        });
        var arrdbpushList = [];
        for (var db = 0; db < this.dbpushList.length; db++) {
          var collectionItems = {};
          collectionItems.itemNo = this.dbpushList[db].dbcode;
          collectionItems.collectionAmount = this.dbpushList[db].dbPrice;
          if (this.dbcbShow) {
            collectionItems.collectionCostAmount =
              this.dbpushList[db].dbcostPrice;
          }

          arrdbpushList.push(collectionItems);
        }

        var arrloanOrder = [];
        for (var lo = 0; lo < this.jinrongPorudct.length; lo++) {
          var loanOrder = {};
          loanOrder.productProviderNo = this.jinrongPorudct[lo].jinrong_OrgNo;
          loanOrder.productNo = this.jinrongPorudct[lo].FIN_PRODUCT_NO;
          loanOrder.loanTerms = this.jinrongPorudct[lo].jinrong_dkqx;
          loanOrder.loanAmount =
            Number(this.Barecar_price) - Number(this.jinrong_sfPirce);
          console.log("裸车金额-首付金额 ", loanOrder.loanAmount);
          loanOrder.serviceCharge = this.jinrongPorudct[lo].jinrong_zxPirce;
          loanOrder.cusSelfpayInterestAmt = this.cusSelfpayInterestAmt; //客户承担--元基客制化
          loanOrder.firstPayAmount = this.jinrongPorudct[lo].jinrong_sfPirce;
          arrloanOrder.push(loanOrder);
        }

        var arrewOrders = [];
        console.log(this.formList, "this.formList");
        for (var lofrom = 0; lofrom < this.formList.length; lofrom++) {
          var ewOrders = {};
          ewOrders.eWId = this.formList[lofrom].EWOrderNo;
          ewOrders.ewProductNo = this.formList[lofrom].ewProductNo;
          ewOrders.ewAmount = Number(this.formList[lofrom].ewAmount).toFixed(2);
          ewOrders.ewStartDate = this.formList[lofrom].ewStartDate;
          ewOrders.ewEndDate = this.formList[lofrom].ewEndDate;
          ewOrders.WMType = this.formList[lofrom].WMType;
          ewOrders.EwPTime = this.formList[lofrom].EwPTime;
          arrewOrders.push(ewOrders);
        }
        console.log("延保产品信息", this.formList);

        const params = {
          soCarVin: this.vin,
          shoukuangCheck: this.shoukuangCheck,
          ZH_item: JSON.stringify(this.ZHGoods_List),
          Bx_item: JSON.stringify(this.houseutilizationColumns),
          YB_item: JSON.stringify(this.formList),
          DB_item: JSON.stringify(this.dbpushList),
          JR_item: JSON.stringify(this.jinrongPorudct),
          arrgoodsItems: JSON.stringify(arrgoodsItems),
          arrInsOrderItems: JSON.stringify(this.arrInsOrderItems),
          arrloanOrder: JSON.stringify(arrloanOrder),
          arrewOrders: JSON.stringify(arrewOrders),
          arrdbpushList: JSON.stringify(arrdbpushList),
          check_result: JSON.stringify(this.check_result),
          CARSUBTYPECode: this.CARSUBTYPECode,
          CARSUBTYPEName: this.CARSUBTYPEName,
          ISDIRECTSALE: this.ISDIRECTSALE,
          CARSUBTYPEMSRP: this.CARSUBTYPEMSRP,
          SERIESCODE: this.SERIESCODE,
          MODELCODE: this.MODELCODE,
          CARTYPECODE: this.CARTYPECODE,
          soNo: this.soNo,
          orderNo: this.orderNo,
          BRANDCODE: this.BRANDCODE,
          Barecar_price: this.Barecar_price,
          ZH_Pirce: this.ZH_Pirce,
          Insurance_Pirce: this.Insurance_Pirce,
          EW_Price: this.EW_Price,
          agency_Price: this.agency_Price,
          Financial_Price: this.Financial_Price,
          ZCAmount: this.ZCAmount,
          AppearanceColoron: this.AppearanceColoron,
          AppearanceColoronCode: this.AppearanceColoronCode,
          InteriorColor: this.InteriorColor,
          InteriorColorCode: this.InteriorColorCode,
          Insurance_item_name: this.Insurance_item_name,
          INS_BRANCHNO: this.INS_BRANCHNO,
          supplierName: this.supplierName,
          supplierNameCode: this.supplierNameCode,
          jingrong_fangan: this.jingrong_fangan,
          jcDate: this.jcDate,
          shangpai: this.shangpai,
          approveType: this.approveType,
          saleTypeName: this.saleTypeName,
          saleChannelName: this.saleChannelName,
          INTENTION_NO: this.$route.query.INTENTION_NO,
          EMPLOYEE_NO: this.$route.query.EMPLOYEE_NO,
          Store_No: this.$route.query.Store_No,
          isAndroidOrIOS: this.$route.query.isAndroidOrIOS,
          jinrong_Org: this.jinrong_Org,
          jinrong_OrgNo: this.jinrong_OrgNo,
          FIN_PRODUCT_NO: this.FIN_PRODUCT_NO,
          jinrong_sfPirce: this.jinrong_sfPirce,
          jinrong_zxPirce: this.jinrong_zxPirce,
          cusSelfpayInterestAmt: this.cusSelfpayInterestAmt,
          jinrong_dkqx: this.jinrong_dkqx,
          soStatus: this.soStatus,
        };
        console.log("下一步", params);
        sessionStorage.setItem("otherInfo", JSON.stringify(params));
        setTimeout(() => {
          resolve(params);
        }, 1000);
      });
    },
    // 代办
    agent() {
      this.daiban_show = false;
    },
    async QueryCarInvByVin() {
      this.CarInvModel.vin = this.vin;
      this.CarInvModel.shopCode = this.$route.query.Store_No;
      await QueryCarInvByVin(this.CarInvModel).then((res) => {
        console.log("vin获取", res);

        this.CARSUBTYPEName = res.data.result.list[0].SUBTYPE_NAME;
        this.CARSUBTYPEMSRP = res.data.result.list[0].MSRP;
        this.CARSUBTYPECode = res.data.result.list[0].CARTYPE_CODE;
        console.log(this.CARSUBTYPECode, "this.CARSUBTYPECode");
        console.log(this.CARSUBTYPECode, "this.CARSUBTYPECode");
        this.AppearanceColoron = res.data.result.list[0].OUTCOLOR;
        this.AppearanceColoronCode = res.data.result.list[0].CAR_COLOR;
        this.InteriorColor = res.data.result.list[0].INCOLOR;
        this.InteriorColorCode = res.data.result.list[0].CAR_INNER_COLOR;
        this.GetSupplierModel.useCarTypeName = res.data.result.list[0].CARTYPE;
        this.CARTYPENAME = res.data.result.list[0].CARTYPE;
        this.ISDIRECTSALE = res.data.result.list[0].ISDIRECTSALE;
        this.XuanPei_price = res.data.result.list[0].INV_INCREASED.reduce(
          function (prev, curr) {
            return prev + curr.PAY_AMT * curr.QTY;
          },
          0
        );
        this.selectXPvin = this.vin;
        let xpMessage = [];
        if (
          res.data.result.list[0].INV_INCREASED.filter(
            (C) => C.INV_TYPE == "增配"
          ).length > 0
        ) {
          xpMessage.push("已选增配");
        }
        if (
          res.data.result.list[0].INV_INCREASED.filter(
            (C) => C.INV_TYPE == "前装"
          ).length > 0
        ) {
          xpMessage.push("已选前装");
        }
        this.XPMessage = xpMessage.join("+");
      });
    },
    //根据车型获取车辆信息
    async GetCarInvByClass(val) {
      this.CarXPModel.shopCode = this.$route.query.Store_No;
      this.CarXPModel.subtypeCode = this.MODELCODE;
      this.CarXPModel.cartypeCode = this.CARTYPECODE;
      this.CarXPModel.classCode = this.SERIESCODE;
      await GetCarInvByClass(this.CarXPModel).then((res) => {
        this.XpCar_List = [];
        if (this.xpCarInfo.vin) {
          console.log(this.xpCarInfo, "this.xpCarInfo-GetCarInvByClass");
          this.XpCar_List.push({
            VIN: this.xpCarInfo.vin,
            INCOLOR: this.xpCarInfo.INCOLOR,
            OUTCOLOR: this.xpCarInfo.OUTCOLOR,
            CAR_COLOR: this.xpCarInfo.CAR_COLOR,
            CAR_INNER_COLOR: this.xpCarInfo.CAR_INNER_COLOR,
            INV_INCREASED: this.xpCarInfo.INV_INCREASED,
          });
          console.log(this.XpCar_List, "this.XpCar_List-GetCarInvByClass");
        }
        res.data.result.list.forEach((item) => {
          this.XpCar_List.push(item);
        });
        this.XpCar_List.forEach((item) => {
          item.PL_AMT = item.INV_INCREASED.reduce(function (prev, curr) {
            return prev + curr.PL_AMT;
          }, 0);
          item.PAY_AMT = item.INV_INCREASED.filter(
            (C) => C.PAY_MODE_TEXT == "客户付费"
          ).reduce(function (prev, curr) {
            return prev + curr.PAY_AMT * curr.QTY;
          }, 0);
        });
        if (val) {
          this.selectXPVin();
        }
      });
    },
    //选配车辆选中
    selectXPVin() {
      this.XPshow = false;
      this.vin = this.selectXPvin;
      console.log(this.selectXPvin, "this.selectXPvin");
      let selectedCar = this.XpCar_List.filter((c) => c.VIN == this.vin)[0];
      if (selectedCar) {
        this.XuanPei_price = selectedCar.PAY_AMT;
        let xpMessage = [];
        if (
          selectedCar.INV_INCREASED.filter((C) => C.INV_TYPE == "增配").length >
          0
        ) {
          xpMessage.push("已选增配");
        }
        if (
          selectedCar.INV_INCREASED.filter((C) => C.INV_TYPE == "前装").length >
          0
        ) {
          xpMessage.push("已选前装");
        }
        this.XPMessage = xpMessage.join("+");

        this.AppearanceColoron = selectedCar.OUTCOLOR;
        this.AppearanceColoronCode = selectedCar.CAR_COLOR;
        this.InteriorColor = selectedCar.INCOLOR;
        this.InteriorColorCode = selectedCar.CAR_INNER_COLOR;
      } else {
        this.XuanPei_price = "";
        this.AppearanceColoron = "";
        this.AppearanceColoronCode = "";
        this.InteriorColor = "";
        this.InteriorColorCode = "";
        this.XPMessage = "";
        this.vin = "";
        this.GetCarColorListByModelCode();
      }
    },
    click_Insurance_show() {
      if (this.soNo) {
        this.Insurance_item_show = true;
      } else {
        this.Insurance_show = true;
      }
    },
    click_yanbao_show() {
      if (this.soNo) {
        this.yanbao_Product_show = true;
      } else {
        this.yanbao_show = true;
      }
    },
    click_jinrong_show() {
      if (this.soNo != "") {
        this.jinrong_Product_show = true;
      } else {
        this.jinrong_show = true;
      }
    },
    btnSub() {
      if (this.AppearanceColoron == "") {
        this.$toast("外观颜色请选择");
        return;
      }
      if (this.Barecar_price == "") {
        this.$toast("请输入裸车价");
        return;
      }

      if (this.shoukuangCheck == "贷款" && !this.jinrong_Org) {
        this.$toast("请输选择金融机构");
        return;
      }
      let arrgoodsItems = [];
      console.log("0000", this.ZHGoods_List);
      this.ZHGoods_List.forEach((item) => {
        if (item.isGoodsCombo == 1) {
          //套餐销售总价格
          let damountSum = item.goodsItemInfo.reduce(
            (sum, e) => sum + Number(e.amount || 0),
            0
          );
          item.goodsItemInfo.forEach((i) => {
            let goods_item = {};
            goods_item.itemNo = i.goodsNo;
            goods_item.comboNo = item.Goods_No;
            goods_item.sellTypeCode =
              item.sellTypeCode != null &&
              item.sellTypeCode != "" &&
              item.sellTypeCode != "undefined"
                ? item.sellTypeCode
                : "XS";
            goods_item.maintainClassNo = "3";
            goods_item.payModeNo = item.payModeNo;
            goods_item.payClass = item.payClass;
            goods_item.qty = i.qty;
            goods_item.damount = Number(i.amount);
            goods_item.discount = Number(1);
            goods_item.price = Number(i.goodsPrice);
            goods_item.ori_Price = Number(i.Ori_Price);
            goods_item.totalComboAmt = Number(item.goodsPrice); //套餐的价格
            goods_item.damountSum = Number(damountSum); //套餐销售总价格
            for (let i = 0; i < item.qty; i++) {
              arrgoodsItems.push(goods_item);
            }
          });
          console.log("添加时的装潢数据", arrgoodsItems);
        } else {
          let goods_item = {};
          goods_item.itemNo = item.Goods_No;
          goods_item.comboNo = "";
          goods_item.sellTypeCode =
            item.sellTypeCode != null &&
            item.sellTypeCode != "" &&
            item.sellTypeCode != "undefined"
              ? item.sellTypeCode
              : "XS";
          goods_item.maintainClassNo = "3";
          goods_item.payModeNo = item.payModeNo;
          goods_item.payClass = item.payClass;
          goods_item.qty = item.qty;
          goods_item.damount = Number(item.Subtotal);
          goods_item.discount = Number(item.Discountrate);
          goods_item.price = Number(item.goodsPrice);
          arrgoodsItems.push(goods_item);
        }
      });

      // if (this.ZHGoods_List.comboNo)
      // {
      //   this.ZHGoods_List.goodsItemInfo.forEach(item => {
      //     let goods_item = {};
      //     goods_item.itemNo = item.Goods_No;
      //     if (item.isGoodsCombo == 1) goods_item.comboNo = item.Goods_No;
      //     goods_item.sellTypeCode = "XS";
      //     goods_item.maintainClassNo = "3";
      //     goods_item.payModeNo = item.payModeNo;
      //     goods_item.payClass = item.payClass;
      //     goods_item.qty = item.qty;
      //     goods_item.damount = item.Subtotal;
      //     goods_item.discount = item.Discountrate;
      //     goods_item.price = item.goodsPrice;
      //     arrgoodsItems.push(goods_item);
      //   })
      // } else
      // {
      //   this.ZHGoods_List.forEach(item => {
      //     let goods_item = {};
      //     console.log("---", this.ZHGoods_List);
      //     goods_item.itemNo = item.Goods_No;
      //     goods_item.sellTypeCode = "XS";
      //     goods_item.maintainClassNo = "3";
      //     goods_item.payModeNo = item.payModeNo;
      //     goods_item.payClass = item.payClass;
      //     goods_item.qty = item.qty;
      //     goods_item.damount = item.Subtotal;
      //     goods_item.discount = item.Discountrate;
      //     goods_item.price = item.goodsPrice;
      //     arrgoodsItems.push(goods_item);
      //   })

      // }

      var arrdbpushList = [];
      for (var db = 0; db < this.dbpushList.length; db++) {
        var collectionItems = {};
        collectionItems.itemNo = this.dbpushList[db].dbcode;
        collectionItems.collectionAmount = this.dbpushList[db].dbPrice;
        if (this.dbcbShow) {
          collectionItems.collectionCostAmount =
            this.dbpushList[db].dbcostPrice;
        } else {
          collectionItems.collectionCostAmount = this.dbpushList[db].dbPrice;
        }
        arrdbpushList.push(collectionItems);
      }

      var arrloanOrder = [];
      for (var lo = 0; lo < this.jinrongPorudct.length; lo++) {
        var loanOrder = {};
        loanOrder.productProviderNo = this.jinrongPorudct[lo].jinrong_OrgNo;
        loanOrder.productNo = this.jinrongPorudct[lo].FIN_PRODUCT_NO;
        loanOrder.loanTerms = this.jinrongPorudct[lo].jinrong_dkqx;
        loanOrder.loanAmount =
          Number(this.Barecar_price) - Number(this.jinrong_sfPirce);
        console.log("裸车金额-首付金额 ", loanOrder.loanAmount);
        loanOrder.serviceCharge = this.jinrongPorudct[lo].jinrong_zxPirce;
        loanOrder.cusSelfpayInterestAmt =
          this.jinrongPorudct[lo].cusSelfpayInterestAmt; // 元基客制化-客户承担

        loanOrder.firstPayAmount = this.jinrongPorudct[lo].jinrong_sfPirce;
        arrloanOrder.push(loanOrder);
      }

      var arrewOrders = [];
      console.log(this.formList, "this.formList");
      for (var lofrom = 0; lofrom < this.formList.length; lofrom++) {
        var ewOrders = {};
        ewOrders.eWId = this.formList[lofrom].EWOrderNo;
        ewOrders.ewProductNo = this.formList[lofrom].ewProductNo;
        ewOrders.ewAmount = Number(this.formList[lofrom].ewAmount).toFixed(2);
        ewOrders.ewStartDate = this.formList[lofrom].ewStartDate;
        ewOrders.ewEndDate = this.formList[lofrom].ewEndDate;
        ewOrders.WMType = this.formList[lofrom].WMType;
        ewOrders.EwPTime = this.formList[lofrom].EwPTime;
        arrewOrders.push(ewOrders);
      }

      const params = {
        soCarVin: this.vin,
        shoukuangCheck: this.shoukuangCheck,
        ZH_item: JSON.stringify(this.ZHGoods_List),
        Bx_item: JSON.stringify(this.houseutilizationColumns),
        YB_item: JSON.stringify(this.formList),
        DB_item: JSON.stringify(this.dbpushList),
        JR_item: JSON.stringify(this.jinrongPorudct),
        arrgoodsItems: JSON.stringify(arrgoodsItems),
        arrInsOrderItems: JSON.stringify(this.arrInsOrderItems),
        arrloanOrder: JSON.stringify(arrloanOrder),
        arrewOrders: JSON.stringify(arrewOrders),
        arrdbpushList: JSON.stringify(arrdbpushList),
        check_result: JSON.stringify(this.check_result),
        CARSUBTYPECode: this.CARSUBTYPECode,
        CARSUBTYPEName: this.CARSUBTYPEName,
        ISDIRECTSALE: this.ISDIRECTSALE,
        CARSUBTYPEMSRP: this.CARSUBTYPEMSRP,
        SERIESCODE: this.SERIESCODE,
        MODELCODE: this.MODELCODE,
        CARTYPECODE: this.CARTYPECODE,
        soNo: this.soNo,
        orderNo: this.orderNo,
        BRANDCODE: this.BRANDCODE,
        Barecar_price: this.Barecar_price,
        ZH_Pirce: this.ZH_Pirce,
        Insurance_Pirce: this.Insurance_Pirce,
        EW_Price: this.EW_Price,
        agency_Price: this.agency_Price,
        Financial_Price: this.Financial_Price,
        ZCAmount: this.ZCAmount,
        AppearanceColoron: this.AppearanceColoron,
        AppearanceColoronCode: this.AppearanceColoronCode,
        InteriorColor: this.InteriorColor,
        InteriorColorCode: this.InteriorColorCode,
        Insurance_item_name: this.Insurance_item_name,
        INS_BRANCHNO: this.INS_BRANCHNO,
        supplierName: this.supplierName,
        supplierNameCode: this.supplierNameCode,
        jingrong_fangan: this.jingrong_fangan,
        jcDate: this.jcDate,
        shangpai: this.shangpai,
        approveType: this.approveType,
        saleTypeName: this.saleTypeName,
        saleChannelName: this.saleChannelName,
        INTENTION_NO: this.$route.query.INTENTION_NO,
        EMPLOYEE_NO: this.$route.query.EMPLOYEE_NO,
        Store_No: this.$route.query.Store_No,
        isAndroidOrIOS: this.$route.query.isAndroidOrIOS,
        jinrong_Org: this.jinrong_Org,
        jinrong_OrgNo: this.jinrong_OrgNo,
        FIN_PRODUCT_NO: this.FIN_PRODUCT_NO,
        jinrong_sfPirce: this.jinrong_sfPirce,
        jinrong_zxPirce: this.jinrong_zxPirce,
        cusSelfpayInterestAmt: this.cusSelfpayInterestAmt,
        jinrong_dkqx: this.jinrong_dkqx,
        soStatus: this.soStatus,
      };
      console.log("下一步", params);
      sessionStorage.setItem("otherInfo", JSON.stringify(params));

      this.$router.push({
        name: "PlaceAnOrder",
        params: params,
        query: {
          ...this.$route.query,
          INTENTION_NO: this.$route.query.INTENTION_NO,
          EMPLOYEE_NO: this.$route.query.EMPLOYEE_NO,
          Store_No: this.$route.query.Store_No,
          isAndroidOrIOS: this.$route.query.isAndroidOrIOS,
        },
      });
    },
    choiceAppearanceColor(choice) {
      this.AppearanceColoron = choice.colorName;
      this.AppearanceColoronCode = choice.colorCode;
      this.AppearanceColorshow = false;
      // this.CARSUBTYPEMSRP = this.CountMsrp(choice, '1')
      this.AppearanceColormsrp = choice.msrp;
    },
    backpage() {
      this.dataCache().then(() => {
        this.$router.push({
          path: "../SaleCarType/SaleCarType",
          query: {
            INTENTION_NO: this.$route.query.INTENTION_NO,
            EMPLOYEE_NO: this.$route.query.EMPLOYEE_NO,
            Store_No: this.$route.query.Store_No,
            isAndroidOrIOS: this.$route.query.isAndroidOrIOS,
            soNo: this.$route.query.soNo || this.$route.query.soNo,
            OUT_SRC_CUS_NO: this.$route.query.OUT_SRC_CUS_NO,
          },
        });
      });
    },
    //装潢开始
    openAccompanyingAccessories(item) {
      this.competitiveProducts = item;
      this.showPicker = true;
    },
    //新增随车附件
    onfufeiConfirm(e) {
      const item = this.competitiveProducts;
      let source_key = "套餐";
      if (this.Decoration == 0) source_key = "套餐";
      if (this.Decoration == 1) source_key = "精品";
      if (this.Decoration == 2) source_key = "备件";
      console.log("加购增配", item, source_key, e);
      this.ZHAddGoods_Card(item, source_key, e);
      this.showPicker = false;
    },
    //添加购物车
    async ZHAddGoods_Card(item, source_key, e) {
      console.log("加购", item, source_key, e);
      if (source_key == "套餐") {
        let packindex = this.ZHGoods_List.findIndex((items) => {
          console.log("测试", items, item.goodsNo, items.payClass, e.text);
          return (
            items.Goods_No == item.goodsNo &&
            items.payClass == e.text &&
            item.sellTypeCode == "XS"
          );
        });
        console.log("000", packindex);
        if (packindex == -1) {
          this.ZHGoods_List.push({
            isGoodsCombo: 1,
            Goods_Name: item.goodsName,
            Goods_No: item.goodsNo,
            manufactorGoods_no: item.manufactorGoods_no,
            saleFactor: "销售",
            sellTypeCode: item.sellTypeCode || "XS",
            pack_goodsno: item.PackGoods_No,
            pack_goodsPrice: item.goodsPrice,
            goodsPrice: item.goodsPrice,
            Goods_Source: source_key,
            payClass: e.text || "客户付费",
            payModeNo: e.value || "0",
            qty: 1,
            Discountrate: 1,
            Subtotal: item.goodsPrice,
            Goods_describe: "",
            goodsItemInfo: item.goodsItemInfo,
          });
        } else {
          this.ZHGoods_List[packindex].qty =
            Number(this.ZHGoods_List[packindex].qty) + Number(item.count || 1);
          const { qty, Discountrate, goodsPrice } =
            this.ZHGoods_List[packindex];
          this.ZHGoods_List[packindex].Subtotal =
            Number(goodsPrice) * Number(qty) * Number(Discountrate);
        }
      } else {
        var index = this.ZHGoods_List.findIndex((items) => {
          console.log(
            "没有？",
            items.Goods_No == item.GOODS_NO,
            items.payClass == e.text,
            item,
            items
          );
          return items.Goods_No == item.GOODS_NO && items.payClass == e.text;
        });

        if (index == -1) {
          //  获取价格
          this.GetPriceByPayModel.StoreNO = this.$route.query.Store_No;
          this.GetPriceByPayModel.PayMode = e.text || "客户付费";
          this.GetPriceByPayModel.payModeNo = e.value || "0";
          this.GetPriceByPayModel.GoodsNo = item.GOODS_NO;
          await GetPriceByPayMode(this.GetPriceByPayModel).then((res) => {
            console.log("-00000", res.data.result[0]);
            this.ZHGoods_List.push({
              isGoodsCombo: 0,
              Goods_Name: item.GOODS_NAME,
              Goods_No: item.GOODS_NO,
              manufactorGoods_no: item.GOODSVENDOR_NO,
              saleFactor: "销售",
              pack_goodsno: "",
              pack_goodsPrice: "",
              goodsPrice: res.data.result[0].SELL_PRICE,
              Goods_Source: source_key,
              payClass: e.text || "客户付费",
              payModeNo: e.value || "0",
              qty: 1,
              Discountrate: 1,
              Subtotal: res.data.result[0].SELL_PRICE,
              Goods_describe: "",
            });
            console.log("加购", this.ZHGoods_List);
          });
        } else {
          this.ZHGoods_List[index].qty =
            Number(this.ZHGoods_List[index].qty) + Number(item.count || 1);
          const { qty, Discountrate, goodsPrice } = this.ZHGoods_List[index];

          this.ZHGoods_List[index].Subtotal =
            Number(goodsPrice) * Number(qty) * Number(Discountrate);
        }
      }
    },
    ZHGoods_CardList() {
      if (this.ZHGoods_List.length >= 1) {
        this.zhcard_show = !this.zhcard_show;
      }
    },
    decrement(item) {
      event.stopPropagation();
      console.log("我是减法", item);
      if (
        this.ZHGoods_List.reduce((sum, e) => sum + Number(e.qty || 0), 0) == 1
      ) {
        this.ZHGoods_List = [];
        this.zhcard_show = false;
      } else {
        const ZHGoods_List = [];
        this.ZHGoods_List.forEach((v) => {
          console.log("测试", item, v);
          if (item.Goods_No == v.Goods_No && item.payModeNo == v.payModeNo) {
            console.log("item", item, v);
            v.qty--;
            v.Subtotal = v.qty * v.goodsPrice * Number(v.Discountrate);
          }
          if (v.qty > 0) {
            ZHGoods_List.push(v);
          }
        });
        this.ZHGoods_List = ZHGoods_List;
        console.log("ZHGoods_List", ZHGoods_List);
      }
    },
    addZHqty(index) {
      var ZHprice =
        this.ZHGoods_List[index].Subtotal / this.ZHGoods_List[index].qty;
      this.ZHGoods_List[index].qty++;
      if (this.ZHGoods_List[index].Goods_Source == "套餐") {
        this.ZHGoods_List[index].Subtotal =
          this.ZHGoods_List[index].qty * this.ZHGoods_List[index].goodsPrice;
      } else {
        this.ZHGoods_List[index].Subtotal =
          this.ZHGoods_List[index].qty * ZHprice;
      }
      // this.ZH_Pirce = this.ZHGoods_List.reduce(
      //   (sum, e) => { if (e.payModeNo == '0' || e.payClass == '客户付费') { return sum + Number(e.Subtotal || 0) } },
      //   0
      // );
    },
    //装潢结束
    DB_onLoad() {
      this.GetDSBasInfo();
    },
    //选择代办
    choicedaibantype(item) {
      console.log(
        "选择",
        item,
        this.dbpushList,
        !this.dbpushList.some((items) => items.GOODS_NAME == item.GOODS_NAME),
        this.daibanIndex
      );
      if (
        !this.dbpushList.some((items) => items.GOODS_NAME == item.GOODS_NAME)
      ) {
        this.dbpushList[this.daibanIndex].GOODS_NAME = item.GOODS_NAME;
        this.dbpushList[this.daibanIndex].dbcode = item.GOODS_NO;
      } else {
        this.$toast("请勿重复添加代办类型为:" + item.GOODS_NAME);
      }
      this.daibantype_show = false;
      this.agency_Price = this.dbpushList.reduce(
        (sum, e) => sum + Number(e.dbPrice || 0),
        0
      );
    },
    deletedaibantype(item, index) {
      this.$dialog
        .confirm({
          title: "删除",
          message: "确定是否删除?",
        })
        .then(() => {
          this.dbpushList.splice(index, 1);
          this.agency_Price = item.daibanTypeName;
        });
    },
    deleteyanbaotype(item, index) {
      this.$dialog
        .confirm({
          title: "删除",
          message: "确定是否删除?",
        })
        .then(() => {
          console.log("延保数据", index);
          this.formList.splice(index, 1);
        })
        .catch(() => {
          // on cancel
        });
    },
    InteriorColoron_Click() {
      this.InteriorColorshow = true;
    },
    ybStartDate_click() {
      this.startDateShow = true;
    },
    ybEndDate_click() {
      this.endDateShow = true;
    },
    AddjinrongProduct(item) {
      this.jinrong_Product_show = true;
      this.jingrong_fangan = "";
      this.jinrong_Org = item.PARTNER_NAME;
      this.jinrong_OrgNo = item.PARTNER_NO;
      this.FIN_PRODUCT_NO = item.FIN_PRODUCT_NO;
    },
    CloseFinance() {
      // this.jinrong_Org=''
      // this.jingrong_fangan=''
      // this.jinrong_OrgNo=''
      // this.FIN_PRODUCT_NO=''
      // this.jinrong_sfPirce=''
      // this.jinrong_zxPirce=''
      // this.jinrong_dkqx=''
      this.jinrong_Product_show = false;
      this.jinrong_show = false;
    },
    click_jinrongfangan_show() {
      this.jinrong_Product_show = false;
      this.jinrong_show = true;
    },
    jinrongFanganSearch() {
      this.Addjinrongfangan();
    },
    //金融方案
    async Addjinrongfangan() {
      this.jinrongfangan_show = true;
      this.GetFinProductModel.useOrgNo = this.$route.query.Store_No;
      this.GetFinProductModel.provider_no = this.jinrong_OrgNo;
      this.GetFinProductModel.FIN_TYPE_NAME =
        this.jinrongFA_value == "" ? "" : this.jinrongFA_value;
      await GetFinProductBySupplier(this.GetFinProductModel).then((res) => {
        if (res.data.IsSuccess) {
          this.jinrongfanganList = res.data.result;
          this.jinrongFA_value == "";
        }
      });
    },
    //金融方案
    async NewAddjinrongfangan() {
      this.GetFinProductModel.useOrgNo = this.$route.query.Store_No;
      this.GetFinProductModel.provider_no = this.jinrong_OrgNo;
      await GetFinProductBySupplier(this.GetFinProductModel).then((res) => {
        if (res.data.IsSuccess) {
          if (this.soNo != "") {
            this.jinrongfanganList = res.data.result;
            const result = this.jinrongfanganList.filter(
              (u) => u.FIN_PRODUCT_NO == this.LoanOrderproductNo
            );
            if (result.length > 0) {
              this.jingrong_fangan = result[0].FIN_PRODUCT_NAME;
              this.jinrongPorudct[0].jingrong_fangan = this.jingrong_fangan;
            }
          }
          this.jinrongfanganList = res.data.result;
        }
      });
    },
    click_jinrongFa(item) {
      this.jingrong_fangan = item.FIN_PRODUCT_NAME;
      this.FIN_PRODUCT_NO = item.FIN_PRODUCT_NO;
      this.jinrongfangan_show = false;
    },
    //金融确认
    jinrong_sub() {
      this.jinrong_show = false;
      this.jinrong_Product_show = false;
      this.Financial_Price = this.jinrong_zxPirce;
      this.jinrongPorudct = [];
      this.jinrongPorudct.push({
        jinrong_Org: this.jinrong_Org,
        jinrong_OrgNo: this.jinrong_OrgNo,
        FIN_PRODUCT_NO: this.FIN_PRODUCT_NO,
        jingrong_fangan: this.jingrong_fangan,
        jinrong_sfPirce: this.jinrong_sfPirce,
        jinrong_zxPirce: this.jinrong_zxPirce,
        jinrong_dkqx: this.jinrong_dkqx,
        cusSelfpayInterestAmt: this.cusSelfpayInterestAmt,
        loanAmount: Number(this.Barecar_price) - Number(this.jinrong_sfPirce),
      });
    },
    //保险选择
    checkbox(event) {
      this.baofeilist = [];
      //isChecked
      for (let i = 0; i < this.houseutilizationColumns.length; i++) {
        if (
          event.INS_TYPENAME == this.houseutilizationColumns[i].INS_TYPENAME
        ) {
          if (event.isChecked !== true) {
            this.houseutilizationColumns[i].isChecked = true;
          } else {
            this.houseutilizationColumns[i].isChecked = false;
            this.houseutilizationColumns[i].baoe = "";
            this.houseutilizationColumns[i].baofei = "";
          }
        }
      }
    },
    click_Insurance_item_show() {
      let InclusiveVoid = this.formList.filter((res) => res.ewProductNo == "");
      console.log("确定", InclusiveVoid, this.formList);
      if (InclusiveVoid.length > 0) {
        this.$toast("有延保项目未填写完整");
        return;
      }
      this.yanbao_show = false;
      this.yanbao_Product_show = false;
    },
    addybProduct() {
      this.formList.push({
        ewProductName: "",
        ewPeriod: "",
        ewTypeName: "",
        ewProductNo: "",
        EWP_PERIOD_ID: "",
        EwPTime: "",
        WMType: "",
        ewAmount: 0,
        ewStartDate: this.timeFormat(this.currentDate),
        ewEndDate: this.timeFormat(this.currentDate),
      });
    },
    adddbProduct() {
      this.dbpushList.push({
        GOODS_NAME: "",
        dbPrice: "",
        dbcode: "",
      });
    },
    daiban_type_click(index) {
      console.log("这是", index);
      this.daibanIndex = index;
      this.daibantype_show = true;
    },
    InteriorColorColor(choice) {
      this.InteriorColor = choice.colorName;
      this.InteriorColorCode = choice.colorCode;
      this.InteriorColorshow = false;
      // this.CARSUBTYPEMSRP = this.CountMsrp(choice, '2')
      this.InteriorColormsrp = choice.msrp;
    },
    changeSearch() {
      if (localStorage.getItem("ZHSearchList")) {
        this.ZHSearchList = localStorage
          .getItem("ZHSearchList")
          .split(",")
          .reverse();
      }

      this.ZHshowSearch = true;
    },
    changeDecorate() {
      this.ZHshow = true;
      console.log("ZHjpList", this.ZHjpList);
      if (this.Decoration == 1) this.ZHjpList.length > 0 ? "" : this.onLoad();
      if (this.Decoration == 2)
        this.ZHbjList.length > 0 ? "" : this.BJ_onLoad();
      if (this.Decoration == 0)
        this.ZHPackList.length > 0 ? "" : this.TC_onLoad();
    },
    async jinrongSearch() {
      this.GetSupplierModel.useOrgNo = this.$route.query.Store_No;
      this.GetSupplierModel.type = "LOAN";
      this.GetSupplierModel.orgName = this.jinrong_Value;
      console.log("this.yanbao_value", this.yanbao_value);

      await GetSupplierByStore(this.GetSupplierModel).then((res) => {
        if (res.data.IsSuccess) {
          this.jinrongList = res.data.result;
        } else {
          this.jinrongList = [];
        }
      });
    },
    onClickIcon() {
      //加入购物车数据
    },
    //保险弹窗及查询开始
    async Insurance_Search() {
      //保险查询
      this.GetInsCompanyModel.StoreNO = this.$route.query.Store_No;
      this.GetInsCompanyModel.InsCompanyName = this.Insurance_value;

      await GetInsCompany(this.GetInsCompanyModel).then((res) => {
        if (res.data.IsSuccess) {
          this.InsuranceList = res.data.result;
        } else {
          this.InsuranceList = [];
        }
      });
    },
    //保险项目明细选择
    async Insurance_Item_click(Item) {
      this.Insurance_item_show = true;
      this.Insurance_item_name = Item.INS_BRANCHNAME;
      this.INS_BRANCHNO = Item.INS_BRANCHNO;
      // if (this.bx_amount == 0)
      if (this.INS_BRANCHNO == Item.INS_BRANCHNO) {
        //获取保险险种
        this.GetInsTypeItemModel.StoreNO = this.$route.query.Store_No;
        this.GetInsTypeItemModel.INS_COMPANYID = Item.ID;
        await GetInsTypeItem(this.GetInsTypeItemModel).then((res) => {
          let _arr = [];
          res.data.result.map((item) => {
            let pitchOn = this.check_result.filter((res) => res.ID == item.ID);
            if (pitchOn.length > 0) {
              _arr.push(...pitchOn);
            } else {
              _arr.push(Object.assign({}, item, { isChecked: false }));
            }
          });
          console.log("arr", _arr);
          this.houseutilizationColumns = _arr;
        });
      }
    },
    async yanbao_Search() {
      this.GetSupplierModel.useOrgNo = this.$route.query.Store_No;
      this.GetSupplierModel.type = "EW";
      this.GetSupplierModel.orgName = this.yanbao_value;
      await GetSupplierByStore(this.GetSupplierModel).then((res) => {
        if (res.data.IsSuccess) {
          this.yanbaoList = res.data.result;
        } else {
          this.yanbaoList = [];
        }
      });
    },
    // 日期弹出层 取消
    closePopup() {
      this.startDateShow = false;
      this.endDateShow = false;
    },

    // 日期弹出层 确认
    updateData(item, index, e, source) {
      if (source == "开始") {
        this.formList[index].ewStartDate = this.timeFormat(this.startDate);
        this.startDateShow = false;
        this.startDate = this.currentDate;
      } else {
        this.formList[index].ewEndDate = this.timeFormat(this.endDate);
        this.endDateShow = false;
        this.endDate = this.currentDate;
      }
    },
    // 时间格式化
    timeFormat(time) {
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      return year + "/" + month + "/" + day;
    },
    async yanbao_Product_click(item) {
      console.log("选择产品", item);
      this.yanbao_Product_show = true;
      this.supplierName = item.EWPNAME;
      this.supplierNameCode = item.EWPNO;
      if (!this.formList.some((items) => items.ewProductName == item.EWPNAME)) {
        this.GetSupplierModel.useStoreNo = this.$route.query.Store_No;
        this.GetSupplierModel.ewpSupplierNo = item.EWPSUPPLIERNO;
        this.GetSupplierModel.ewpNo = item.EWPNO;
        // this.GetSupplierModel.useCarTypeName
        await GetEWProductInfo(this.GetSupplierModel).then((res) => {
          this.ybProductList = res.data.result;
          if (this.formList.length == 0) {
            this.formList.push({
              ewProductName: res.data.result[0].EWP_NAME,
              ewProductNo: res.data.result[0].EWP_NO,
              EWP_PERIOD_ID: res.data.result[0].EWP_PERIOD_ID,
              ewPeriod: res.data.result[0].EWP_PERIOD_NAME,
              WMType: res.data.result[0].EWP_TYPE_ID,
              ewTypeName: res.data.result[0].EWP_TYPE_NAME,
              EwPTime: res.data.result[0].EWP_PERIOD_ID,
              ewAmount: res.data.result[0].EWP_SALE_PRICE,
              ewStartDate: this.timeFormat(this.currentDate),
              ewEndDate: this.timeFormat(this.currentDate),
            });
          } else {
            this.formList[this.ybIndex].ewProductName =
              res.data.result[0].EWP_NAME;
            this.formList[this.ybIndex].ewProductNo = res.data.result[0].EWP_NO;
            this.formList[this.ybIndex].EWP_PERIOD_ID =
              res.data.result[0].EWP_PERIOD_ID;
            this.formList[this.ybIndex].ewPeriod =
              res.data.result[0].EWP_PERIOD_NAME;
            this.formList[this.ybIndex].WMType = res.data.result[0].EWP_TYPE_ID;
            this.formList[this.ybIndex].ewTypeName =
              res.data.result[0].EWP_TYPE_NAME;
            this.formList[this.ybIndex].EwPTime =
              res.data.result[0].EWP_PERIOD_ID;
            this.formList[this.ybIndex].ewAmount =
              res.data.result[0].EWP_SALE_PRICE;
            this.formList[this.ybIndex].ewStartDate = this.timeFormat(
              this.currentDate
            );
            this.formList[this.ybIndex].ewEndDate = this.timeFormat(
              this.currentDate
            );
          }
        });
      } else {
        this.$toast("请勿重复选择");
        return;
      }
      this.yanbao_show = false;
    },
    onSearch() {},
    ClickBrand(brandItem) {
      console.log("brandItem=" + brandItem.CARSUBTYPECode);
      this.$router.push({
        path: "../OrderGoods/OrderGoods",
        query: {
          INTENTION_NO: this.$route.query.INTENTION_NO,
          EMPLOYEE_NO: this.$route.query.EMPLOYEE_NO,
          Store_No: this.$route.query.Store_No,
          isAndroidOrIOS: this.$route.query.isAndroidOrIOS,
        },
      });
    },

    getRowId(brand) {
      this.Brandshow = true;
      console.log("当前被点击的id=" + brand);
    },
    checkoutName() {
      this.ModelVIN == ""
        ? (this.isShowName = true)
        : (this.isShowName = false);
    },
    //导航切换
    changeNav(index) {
      if (index == 1) {
        this.Index = 1;
      } else if (index == 2) {
        this.Index = 2;
      }
    },
    btnCheckVin() {
      console.log(this.ModelVIN);
      //调用车架号绑定查询车辆信息
    },
    showPopup() {
      this.show = true;
    },
    onCancel() {
      this.show = false;
    },
    onChange() {},
    clickshoukuang(key) {
      if (key == "1") {
        this.shoukuangCheck = "全款";
        this.JR_item = [];
        this.jinrongPorudct = [];
        this.jinrong_Org = "";
        this.jinrong_OrgNo = "";
        this.getLoanOrder = [];
        this.jingrong_fangan = "";
        this.jinrong_sfPirce = "";
        this.jinrong_zxPirce = "";
        (this.cusSelfpayInterestAmt = ""), (this.jinrong_dkqx = "");
      } else {
        this.shoukuangCheck = "贷款";
      }
    },
    //延保产品
    ybProducctName_click(item, key) {
      console.log(
        this.formList,
        "延保",
        this.ybProductListshow,
        this.yanbao_show
      );
      this.ybIndex = key;
      // this.ybProductListshow = true;
      this.yanbao_show = true;
    },
    //延保方案
    async ybproject_click(item) {
      if (item.ewProductName == "") {
        this.$toast("请先选择延保产品！");
        return;
      }
      this.ybFanganListshow = true;
      this.NewGetEwBasModel.ewProductNo = item.ewProductNo;
      await GetEwPTime(this.NewGetEwBasModel).then((res) => {
        this.ybFanganList = res.data.result;
      });
    },
    //延保类型
    async ybybType_click(item) {
      this.ybTypeListshow = true;
      this.NewGetEwBasModel.ewProductNo = item.ewProductNo;
      await GetWMType(this.NewGetEwBasModel).then((res) => {
        this.ybTypeList = res.data.result;
      });
    },
    //获取费用类别
    async GetPayMode() {
      await GetPayMode().then((res) => {
        let newList = res.data.result.map((item) => {
          let text = "text";
          let value = "value";
          return {
            [text]: item.TEXT,
            [value]: item.VALUE,
          };
        });
        this.FuFeioption = [...this.FuFeioption, ...newList];
      });
    },

    choiceProduct(item) {
      console.log("延保产品", item);
      this.formList[this.ybIndex].ewProductName = item.EWP_NAME;
      this.formList[this.ybIndex].ewPeriod = item.EWP_PERIOD_NAME;
      this.formList[this.ybIndex].ewProductNo = item.EWP_NO;
      this.formList[this.ybIndex].EWP_PERIOD_ID = item.EWP_PERIOD_ID;
      this.formList[this.ybIndex].ewTypeName = item.EWP_TYPE_NAME;
      this.formList[this.ybIndex].ewAmount = item.EWP_SALE_PRICE;
      this.ybProductListshow = false;
      this.yanbao_Product_show = true;
    },
    choiceybType(item) {
      console.log("选择延保", item);
      this.formList[this.ybIndex].ewTypeName = item.ewTypeName;
      this.formList[this.ybIndex].WMType = item.ewTypeId;
      this.ybTypeListshow = false;
    },
    choiceybFangan(item) {
      console.log("选择延保", item);
      this.formList[this.ybIndex].ewPeriod = item.plstCcomment;
      this.formList[this.ybIndex].EwPTime = item.plstId;
      this.ybFanganListshow = false;
    },
    // 获取审批类型列表
    // async GetAllBrand () {
    //   await GetAllBrand().then((res) => {
    //     let newList = res.data.result.map((item) => {
    //       let text = "text";
    //       let value = "value";
    //       return {
    //         [text]: item.BRANDNAME,
    //         [value]: item.BRANDCODE,
    //       };
    //     });
    //     this.option1 = [...this.option1, ...newList];
    //     // this.option1.unshift({text: '审批流类型', value: ''})
    //   });
    // },
    // 获取颜色列表
    async GetCarColorListByModelCode() {
      this.CarColorModel.modelCode = this.MODELCODE;
      console.log("获取******", this.MODELCODE);
      await GetCarColorListByModelCode(this.CarColorModel).then((res) => {
        if (res.data.IsSuccess && res.data.result.length > 0) {
          var list = JSON.parse(res.data.result);
          console.log("颜色列表list", list, this.CARSUBTYPEMSRP);
          this.InteriorColorList = list.interiorColorList;
          this.AppearanceColorList = list.exteriorColorList;
          if (this.InteriorColorCode !== "") {
            list.interiorColorList.forEach((v) => {
              if (v.colorCode === this.InteriorColorCode) {
                console.log("内饰", v);
                this.InteriorColormsrp = v.msrp;
                // this.CARSUBTYPEMSRP = parseInt(this.CARSUBTYPEMSRP) + parseInt(v.msrp)
                // this.CARSUBTYPEMSRP = parseInt(this.CARSUBTYPEMSRP) + parseInt(v.msrp)
              }
            });
          }
          if (this.AppearanceColoronCode !== "") {
            list.exteriorColorList.forEach((v) => {
              if (v.colorCode === this.AppearanceColoronCode) {
                console.log("外饰", v, this.CARSUBTYPEMSRP);
                this.AppearanceColormsrp = v.msrp;
                // this.CARSUBTYPEMSRP = parseInt(this.CARSUBTYPEMSRP) + parseInt(v.msrp)
                // this.CARSUBTYPEMSRP = parseInt(this.CARSUBTYPEMSRP) + parseInt(v.msrp)
              }
            });
          }

          console.log("[[[[[]]]]]", this.CARSUBTYPEMSRP);
        }
      });
    },
    //获取延保数据
    async GetSupplierByStore() {
      var useinfo = {
        useStoreNo: "",
        useCarTypeName: "",
      };
      useinfo.useStoreNo = this.$route.query.Store_No;
      console.log("000", this.CARTYPENAME);
      // if (!this.CARTYPENAME)
      // {
      //   useinfo.useCarTypeName = this.GetSupplierModel.useCarTypeName
      // } else
      console.log("---this.CARTYPENAME", this.CARTYPENAME);
      useinfo.useCarTypeName = this.CARTYPENAME;

      await GetEWProductList(useinfo).then((res) => {
        this.yanbaoList = res.data.result;
        console.log("---this.formList", this.formList);
        if (this.formList.length === 0) {
          this.yanbao_show = true;
        } else {
          this.yanbao_Product_show = true;
        }
      });
    },

    //获取金融列表数据
    async GetSupplierByStore_Jr() {
      this.GetSupplierModel.useOrgNo = this.$route.query.Store_No;
      this.GetSupplierModel.type = "LOAN";
      await GetSupplierByStore(this.GetSupplierModel).then((res) => {
        this.jinrongList = res.data.result;
      });
    },

    //根据延保产品获取延保类型
    async GetWMType() {
      this.GetSupplierModel.ewProductNo = "";
      await GetWMType(this.GetSupplierModel).then((res) => {
        this.yanbaoList = res.data.result;
      });
    },

    //车型基础数据
    async GetSubTypeBySeries() {
      console.log("获取ss", this.MODELCODE);
      this.CarSeriesModel.SeriesCode = decodeURIComponent(this.SERIESCODE);
      await GetSubTypeBySeries(this.CarSeriesModel).then((eve) => {
        console.log("获取ss", this.$route.query.MODELCODE, this.CarSeriesModel);
        let res = eve.data;
        if (res.IsSuccess) {
          if (res.result.length > 0) {
            let carsInfo = res.result.filter(
              (i) => i.MODELCODE == this.$route.query.MODELCODE
            );
            console.log("车型基础数据", carsInfo);
            if (carsInfo.length > 0) {
              this.carInfo = carsInfo[0];

              this.CARSUBTYPEName = carsInfo[0].MODELNAME;
              this.CARSUBTYPEMSRP = carsInfo[0].GUIDEPRICE;
              this.CARSUBTYPECode = carsInfo[0].MODELCODE;
              this.CARTYPECODE = carsInfo[0].CARTYPECODE;
              this.CARTYPENAME = carsInfo[0].CARTYPENAME;
              this.GetSupplierModel.useCarTypeName = carsInfo[0].CARTYPENAME;
              this.ISDIRECTSALE = "";
              // this.GetSupplierModel.useCarTypeName = res.data.result.getCarInfo.soCarTypeName;
            }
          }
        }
      });
    },
    onLoad() {
      console.log("上拉加载", this.Decoration);
      this.GetDecoration(this.ZHjpList.length);
    },
    BJ_onLoad() {
      console.log("上拉加载");
      this.GetDecoration(this.ZHbjList.length);
    },
    TC_onLoad() {
      console.log("套餐上拉加载");
      this.GetDecoration(this.ZHPackList.length);
    },
    async GetDecoration(length) {
      this.$store.commit("showLoading");
      if (this.Decoration == 0) this.TC_finished = false;
      if (this.Decoration == 1) this.finished = false;
      if (this.Decoration == 2) this.BJ_finished = false;
      const params = {
        StoreNO: this.$route.query.Store_No,
        Type: this.decorationTitle,
        KeyWord: this.ZH_value,
        iDisplaystart: length,
      };
      let res = [];
      if (this.Decoration == 0) {
        res = await GetSalesTCInfo(params);
      } else {
        res = await GetSalesJPInfo(params);
      }
      if (this.Decoration == 0) this.TC_loading = false;
      if (this.Decoration == 1) this.loading = false;
      if (this.Decoration == 2) this.BJ_loading = false;
      if (res.data.IsSuccess) {
        res.data.result.forEach((o) => {
          if (this.Decoration == 0) o.manufactorGoods_no = o.goodsNo;
          this.ZHPackList.push(o);
          if (this.Decoration == 1) o.manufactorGoods_no = o.GOODS_NO;
          this.ZHjpList.push(o);
          if (this.Decoration == 2) o.manufactorGoods_no = o.GOODS_NO;
          this.ZHbjList.push(o);
        });
        if (res.data.result == null || res.data.result.length === 0) {
          // 加载结束
          if (this.Decoration == 0) this.TC_finished = true;
          if (this.Decoration == 1) this.finished = true;
          if (this.Decoration == 2) this.BJ_finished = true;

          return;
        }
        if (!res.data.msg.hasMore) {
          if (this.Decoration == 0) this.TC_finished = true;
          if (this.Decoration == 1) this.finished = true;
          if (this.Decoration == 2) this.BJ_finished = true;
        } else {
          if (this.Decoration == 0) this.TC_finished = false;
          if (this.Decoration == 1) this.finished = false;
          if (this.Decoration == 2) this.BJ_finished = false;
        }
      } else {
        if (this.Decoration == 0) this.ZHPackList = [];
        if (this.Decoration == 1) this.ZHjpList = [];
        if (this.Decoration == 2) this.ZHbjList = [];
      }
      // })
    },
    // 选择装潢
    SelectDecoration(index, title) {
      console.log("选择", index, title);
      this.decorationTitle = title;
      this.Decoration = index;
      this.ZHonSearch(0);
    },
    SearchHistoryClose(item) {
      let newval = [];
      const names = localStorage.getItem("ZHSearchList");
      if (names != null) {
        newval.push(...localStorage.getItem("ZHSearchList").split(","));
        newval = newval.filter((a) => a !== item);
        this.ZHSearchList = newval;
      }
      localStorage.setItem("ZHSearchList", newval);
    },
    //裝潢页面点击历史值
    SearchHistory(item) {
      this.ZH_value = item;
      this.ZHonSearch();
    },
    //装潢弹窗以及添加购物车
    async ZHonSearch() {
      let newval = [];
      const names = localStorage.getItem("ZHSearchList");
      if (names != null && names != "") {
        newval.push(...localStorage.getItem("ZHSearchList").split(","));
        if (names.split(",").indexOf(this.ZH_value) == -1) {
          if (this.ZH_value !== "") {
            newval.push(this.ZH_value);
            localStorage.setItem("ZHSearchList", newval);
          }
        }
      } else {
        if (this.ZH_value !== "") {
          newval.push(this.ZH_value);
          localStorage.setItem("ZHSearchList", newval);
        }
      }
      this.ZHshowSearch = false;
      console.log("执行");
      this.$store.commit("showLoading");
      this.GetSalesJPModel.StoreNO = this.$route.query.Store_No;
      this.GetSalesJPModel.Type = this.decorationTitle;
      this.GetSalesJPModel.KeyWord = this.ZH_value == "" ? "" : this.ZH_value;
      let list = [];
      let res = [];
      if (this.Decoration == 0) {
        res = await GetSalesTCInfo(this.GetSalesJPModel);
      } else {
        res = await GetSalesJPInfo(this.GetSalesJPModel);
      }

      // await myFunction(this.GetSalesJPModel).then((res) => {
      if (this.Decoration == 0) this.TC_finished = false;
      if (this.Decoration == 1) this.finished = false;
      if (this.Decoration == 2) this.BJ_finished = false;
      if (res.data.IsSuccess) {
        list = [];
        res.data.result.forEach((o) => {
          o.manufactorGoods_no = o.GOODS_NO;
          list.push(o);
        });
      } else {
        list = [];
      }
      if (this.Decoration == 0) this.ZHPackList = list;
      if (this.Decoration == 1) this.ZHjpList = list;
      if (this.Decoration == 2) this.ZHbjList = list;
    },

    //获取保险
    async GetInsCompany() {
      console.log("是否有保险数据", this.INS_BRANCHNO);

      if (this.INS_BRANCHNO) {
        this.Insurance_item_show = true;
        this.Insurance_show = false;
      } else {
        this.Insurance_show = true;
      }
      this.GetInsCompanyModel.StoreNO = this.$route.query.Store_No;
      this.GetInsCompanyModel.InsCompanyName = this.InsCompanyName;
      await GetInsCompany(this.GetInsCompanyModel).then((res) => {
        if (res.data.IsSuccess) {
          this.InsuranceList = res.data.result;
          let orderInfo = JSON.parse(sessionStorage.getItem("orderInfo"));
          let otherInfo = JSON.parse(sessionStorage.getItem("otherInfo"));
          let InsCorpNo = "";
          if (otherInfo) {
            InsCorpNo =
              otherInfo && otherInfo.INS_BRANCHNO ? otherInfo.INS_BRANCHNO : "";
          } else {
            InsCorpNo =
              orderInfo && orderInfo.getInsOrder
                ? orderInfo.getInsOrder.InsCorpNo
                : "";
          }
          if (
            this.InsuranceList.filter((o) => o.INS_BRANCHNO == InsCorpNo)
              .length > 0
          ) {
            this.InsCompanyId = this.InsuranceList.filter(
              (o) => o.INS_BRANCHNO == InsCorpNo
            )[0].ID;
            this.GetInsTypeItem();
          }
        } else {
          this.InsuranceList = res.data.result;
        }
        console.log("保险", this.InsCompanyId);
      });
    },
    //保险项目明细选择
    async GetInsTypeItem() {
      //获取保险险种
      this.GetInsTypeItemModel.StoreNO = this.$route.query.Store_No;
      this.GetInsTypeItemModel.INS_COMPANYID = this.InsCompanyId;
      await GetInsTypeItem(this.GetInsTypeItemModel).then((res) => {
        if (res.data.IsSuccess) {
          let _arr = [];
          res.data.result.map((item) => {
            let pitchOn = this.check_result.filter((res) => res.ID == item.ID);
            if (pitchOn.length > 0) {
              _arr.push(...pitchOn);
            } else {
              if (this.NewInsProductNameArray.indexOf(item.INS_TYPENAME) > -1) {
                _arr.push(Object.assign({}, item, { isChecked: true }));
              } else {
                _arr.push(Object.assign({}, item, { isChecked: false }));
              }
            }
          });
          let otherInfo = JSON.parse(sessionStorage.getItem("otherInfo"));
          let orderInfo = JSON.parse(sessionStorage.getItem("orderInfo"));
          let newLIst = [];
          console.log("this.houseutilizationColumns", _arr);
          if (this.houseutilizationColumns.length == 0) {
            console.log("保险", _arr);
            if (otherInfo && otherInfo.arrInsOrderItems) {
              let InsProductNameArray = otherInfo
                ? JSON.parse(otherInfo.arrInsOrderItems)
                : this.InsProductNameArray;
              let InsProductNameList = [];
              _arr.forEach((o) => {
                let nn = InsProductNameArray.filter(
                  (res) => res.InsProductName == o.INS_TYPENAME
                );
                console.log("---", nn);
                if (nn.length > 0) {
                  InsProductNameList.push({
                    ...o,
                    isChecked: "true",
                    baoe: nn[0].coverageAmount,
                    baofei: nn[0].premiumAmount,
                  });
                } else {
                  InsProductNameList.push(o);
                }
              });
              newLIst = InsProductNameList;
              console.log("InsProductNameList", InsProductNameList);
            } else {
              if (orderInfo) {
                let InsProductNameArray = orderInfo
                  ? orderInfo.getInsOrder.getInsOrderItems
                  : this.InsProductNameArray;
                let InsProductNameList = [];
                _arr.forEach((o) => {
                  let nn = InsProductNameArray.filter(
                    (res) => res.InsProductName == o.INS_TYPENAME
                  );
                  console.log("---", nn);
                  if (nn.length > 0) {
                    InsProductNameList.push({
                      ...o,
                      isChecked: "true",
                      baoe: nn[0].coverageAmount,
                      baofei: nn[0].premiumAmount,
                    });
                  } else {
                    InsProductNameList.push(o);
                  }
                  newLIst = InsProductNameList;
                });
              } else {
                newLIst = _arr;
              }
            }
          } else {
            newLIst = this.houseutilizationColumns;
          }
          console.log("000", newLIst);
          this.houseutilizationColumns = newLIst;
        }
      });
    },
    // 搜索代办
    async selectDSBasInfo() {
      this.GetDSBasInfoModel.StoreNO = this.$route.query.Store_No;
      this.GetDSBasInfoModel.DSItemName = this.DB_value;
      this.GetDSBasInfoModel.iDisplaystart = 0;
      this.GetDSBasInfoModel.iDisplayLength = 22;
      await GetDSBasInfo(this.GetDSBasInfoModel).then((res) => {
        this.daibantype_List = res.data.result;
      });
    },
    //获取代收代付
    async GetDSBasInfo() {
      this.GetDSBasInfoModel.StoreNO = this.$route.query.Store_No;
      this.GetDSBasInfoModel.DSItemName = this.DB_value;
      this.GetDSBasInfoModel.iDisplaystart = this.daibantype_List.length;
      this.GetDSBasInfoModel.iDisplayLength = 22;
      await GetDSBasInfo(this.GetDSBasInfoModel).then((res) => {
        this.DB_loading = false;
        this.daibantype_List.push(...res.data.result);
        if (!res.data.msg.hasMore) this.DB_finished = true;
      });
    },

    async GetCarSalesOrder() {
      this.$store.commit("showLoading");
      this.GetCarSalesOrderModel.soNo =
        this.$route.query.soNo || this.$route.query.soNo;
      if (!sessionStorage.getItem("otherInfo")) {
        await GetCarSalesOrder(this.GetCarSalesOrderModel).then((res) => {
          console.log("--获取数据", res);
          if (res.data.IsSuccess) {
            sessionStorage.setItem(
              "orderInfo",
              JSON.stringify(res.data.result)
            ); //存入 参数： 1.调用的值 2.所要存入的数据
            this.CARTYPENAME = res.data.result.getCarInfo.soCarTypeName;
            this.CARTYPECODE = res.data.result.getCarInfo.soCarTypeCode;
            this.GetSupplierModel.useCarTypeName =
              res.data.result.getCarInfo.soCarTypeName;

            this.CARSUBTYPEName = res.data.result.getCarInfo.soCarSubTypeName;
            this.ISDIRECTSALE = res.data.result.getSoMstrInfo.isDirectSale;
            this.AppearanceColoron = res.data.result.getCarInfo.soCarColorName;
            this.InteriorColor = res.data.result.getCarInfo.soCarInnerColorName;
            this.orderNo = res.data.result.getSoMstrInfo.OrderNo;
            this.vin = res.data.result.getCarInfo.soCarVin;
            this.selectXPvin = this.vin;
            this.jcDate =
              res.data.result.getSoMstrInfo.estimatedDeliveryCarDate;
            this.shangpai =
              res.data.result.getSoMstrInfo.carIDRegistrationWayName;
            this.approveType = res.data.result.getSoMstrInfo.ApprovalType;
            this.InteriorColorCode =
              res.data.result.getCarInfo.soCarInnerColorNo;
            this.AppearanceColoronCode =
              res.data.result.getCarInfo.soCarColorNo;

            this.saleTypeName = res.data.result.getSoMstrInfo.saleTypeName;
            this.saleChannelName =
              res.data.result.getSoMstrInfo.saleChannelName;

            this.CARSUBTYPECode = res.data.result.getCarInfo.soCarSubTypeCode;
            console.log("当前", res.data.result.getCarInfo);
            this.CARSUBTYPEMSRP =
              res.data.result.getCarInfo.soCarSubTypeMSRPrice;
            this.SERIESCODE = res.data.result.getCarInfo.soCarClassCode;
            this.MODELCODE = res.data.result.getCarInfo.soCarSubTypeCode;

            this.ZCAmount = Number(res.data.result.getSoMstrInfo.soAmt).toFixed(
              2
            );
            this.shoukuangCheck =
              res.data.result.payType == "按揭" ? "贷款" : "全款";
            this.ZH_Pirce = res.data.result.getSoMstrInfo.soDecorateAmt;
            this.Financial_Price = res.data.result.getSoMstrInfo.soLoanAmt;
            this.EW_Price = res.data.result.getSoMstrInfo.soWarrantyAmt;
            this.agency_Price = res.data.result.getSoMstrInfo.soAgentAmt;
            // this.Barecar_price = res.data.result.getSoMstrInfo.soCarAmt;
            this.Barecar_price = res.data.result.getCarInfo.soCarSalePrice;
            this.soStatus = res.data.result.getSoMstrInfo.soStatus;
            // 原
            // if (res.data.result.getGoodsInfos != null)
            // {
            //   this.ZHGoods_List = [];
            //   let newArr = [];
            //   res.data.result.getGoodsInfos.map(function (item) {
            //     console.log("====", item);
            //     newArr.push({
            //       Goods_Name: item.itemName,
            //       Goods_No: item.itemNo,
            //       manufactorGoods_no: item.itemNo,
            //       saleFactor: item.sellTypeName,
            //       pack_goodsno: "",
            //       pack_goodsPrice: "",
            //       goodsPrice: Number(item.damount) / Number(item.discount) / Number(item.qty),
            //       Goods_Source: item.maintainClassName,
            //       payClass: item.payModeName,
            //       payModeNo: item.payModeNo,
            //       qty: item.qty,
            //       Discountrate: item.discount,
            //       Subtotal: Number(item.damount),
            //       Goods_describe: "",
            //       comboNo: item.comboNo,
            //       isGoodsCombo: item.isGoodsCombo || 0,
            //     });
            //   });
            //   console.log("详情", newArr);
            //   this.ZHGoods_List = newArr;
            // }
            // 再处理套餐

            this.ZHGoods_List = [];
            this.ZHGoods_List = this.DecorationProcessing(
              res.data.result.getGoodsInfos,
              res.data.result.getGoodsCombos
            );
            // if (!res.data.result.getGoodsCombos && res.data.result.getGoodsInfos != null)
            // {
            //   let newArr = [];
            //   res.data.result.getGoodsInfos.map(function (item) {
            //     console.log("====", item);
            //     newArr.push({
            //       Goods_Name: item.itemName,
            //       Goods_No: item.itemNo,
            //       manufactorGoods_no: item.itemNo,
            //       saleFactor: item.sellTypeName,
            //       pack_goodsno: "",
            //       pack_goodsPrice: "",
            //       goodsPrice: Number(item.damount) / Number(item.discount) / Number(item.qty),
            //       Goods_Source: item.maintainClassName,
            //       payClass: item.payModeName,
            //       payModeNo: item.payModeNo,
            //       qty: item.qty,
            //       Discountrate: item.discount,
            //       Subtotal: Number(item.damount),
            //       Goods_describe: "",
            //       comboNo: item.comboNo,
            //       isGoodsCombo: item.isGoodsCombo || 0,
            //     });
            //   });
            //   console.log("详情", newArr);
            //   this.ZHGoods_List.push(newArr);
            // }

            console.log("套餐", this.ZHGoods_List);
            this.INV_INCREASED = res.data.result.INV_INCREASED || [];
            this.xpCarInfo = {
              vin: this.vin,
              INCOLOR: this.InteriorColor,
              CAR_INNER_COLOR: this.InteriorColorCode,
              OUTCOLOR: this.AppearanceColoron,
              CAR_COLOR: this.AppearanceColoronCode,
              INV_INCREASED: this.INV_INCREASED,
            };
            sessionStorage.setItem("xpCarInfo", JSON.stringify(this.xpCarInfo)); //存入 参数： 1.调用的值 2.所要存入的数据
            this.init_XPInfo();

            if (res.data.result.getCollectionItems != null) {
              this.dbpushList = [];

              let newArr = [];
              for (
                let i = 0;
                i < res.data.result.getCollectionItems.length;
                i++
              ) {
                if (this.dbcbShow) {
                  newArr.push({
                    GOODS_NAME: res.data.result.getCollectionItems[i].itemName,
                    dbPrice:
                      res.data.result.getCollectionItems[i].collectionAmount,
                    dbcostPrice:
                      res.data.result.getCollectionItems[i]
                        .collectionCostAmount,
                    dbcode: res.data.result.getCollectionItems[i].itemNo,
                  });
                } else {
                  newArr.push({
                    GOODS_NAME: res.data.result.getCollectionItems[i].itemName,
                    dbPrice:
                      res.data.result.getCollectionItems[i].collectionAmount,
                    dbcode: res.data.result.getCollectionItems[i].itemNo,
                  });
                }
              }
              this.dbpushList = newArr;
              this.getCollectionItems = res.data.result.getCollectionItems;
              if (this.dbcbShow) {
                this.agency_Price = res.data.result.getCollectionItems.reduce(
                  (sum, e) => sum + Number(e.collectionAmount || 0),
                  0
                );
              } else {
                this.agency_Price = res.data.result.getCollectionItems.reduce(
                  (sum, e) => sum + Number(e.collectionCostAmount || 0),
                  0
                );
              }
            }

            if (res.data.result.getEWOrders != null) {
              this.formList = res.data.result.getEWOrders;
              // this.supplierName = res.data.result.getEWOrders[0].supplierName;
              // this.GetSupplierModel.ewpSupplierNo = res.data.result.getEWOrders[0].supplierNameCode;
              this.GetSupplierModel.ewpSupplierNo =
                res.data.result.getEWOrders[0].ewProductNo;
              this.supplierName = res.data.result.getEWOrders[0].ewProductName;
            }

            if (res.data.result.getLoanOrder != null) {
              this.getLoanOrder = res.data.result.getLoanOrder;
              this.jinrong_Org = this.getLoanOrder.productProviderName;
              this.jinrong_OrgNo = this.getLoanOrder.productProviderNo;
              this.FIN_PRODUCT_NO = this.getLoanOrder.productNo;
              this.Financial_Price = this.getLoanOrder.serviceCharge;
              this.jinrong_zxPirce = this.getLoanOrder.serviceCharge;
              this.jinrong_dkqx = this.getLoanOrder.loanTerms;
              this.jinrong_sfPirce = this.getLoanOrder.firstPayAmount;
              (this.cusSelfpayInterestAmt =
                this.getLoanOrder.cusSelfpayInterestAmt || 11111111), //客户承担--元基客制化
                (this.LoanOrderproductNo = this.getLoanOrder.productNo);

              this.NewAddjinrongfangan();
              // this.GetSupplierByStore();
              this.jinrongPorudct.push({
                jinrong_Org: this.jinrong_Org,
                jinrong_OrgNo: this.jinrong_OrgNo,
                FIN_PRODUCT_NO: this.FIN_PRODUCT_NO,
                jingrong_fangan: this.jingrong_fangan,
                jinrong_sfPirce: this.jinrong_sfPirce,
                jinrong_zxPirce: this.jinrong_zxPirce,
                jinrong_dkqx: this.jinrong_dkqx,
                cusSelfpayInterestAmt: this.cusSelfpayInterestAmt, //客户承担--元基客制化
                loanAmount:
                  Number(this.Barecar_price) - Number(this.jinrong_sfPirce),
              });
            }

            if (res.data.result.getInsOrder != null) {
              this.getInsOrder = res.data.result.getInsOrder;
              this.Insurance_item_name =
                res.data.result.getInsOrder.InsCorpName;
              this.InsProductNameArray =
                res.data.result.getInsOrder.getInsOrderItems;
              this.arrInsOrderItems =
                res.data.result.getInsOrder.getInsOrderItems;

              this.NewInsProductNameArray = res.data.result.getInsOrder
                .getInsOrderItems
                ? res.data.result.getInsOrder.getInsOrderItems.map((obj) => {
                    return obj.InsProductName;
                  })
                : "";
              // 这里
              this.INS_BRANCHNO = res.data.result.getInsOrder.InsCorpNo;
              this.Insurance_Pirce = res.data.result.getSoMstrInfo.soInsAmt;

              // this.check_result = res.data.result.getInsOrder.getInsOrderItems.filter(o=>)
            }
            // this.GetCarColorListByModelCode()
          } else {
            this.$toast(res.data.msg);
          }
        });
      }
    },
    //初始化选配信息
    init_XPInfo() {
      this.XpCar_List.push({
        VIN: this.xpCarInfo.vin,
        INCOLOR: this.xpCarInfo.INCOLOR,
        OUTCOLOR: this.xpCarInfo.OUTCOLOR,
        CAR_COLOR: this.xpCarInfo.CAR_COLOR,
        CAR_INNER_COLOR: this.xpCarInfo.CAR_INNER_COLOR,
        INV_INCREASED: this.xpCarInfo.INV_INCREASED,
      });
      this.XpCar_List.forEach((item) => {
        item.PL_AMT = item.INV_INCREASED.reduce(function (prev, curr) {
          return prev + curr.PL_AMT;
        }, 0);
        item.PAY_AMT = item.INV_INCREASED.filter(
          (C) => C.PAY_MODE_TEXT == "客户付费"
        ).reduce(function (prev, curr) {
          return prev + curr.PAY_AMT * curr.QTY;
        }, 0);
      });
      console.log(this.XpCar_List, " this.XpCar_List");
      let xpMessage = [];
      if (
        this.xpCarInfo.INV_INCREASED.filter((C) => C.INV_TYPE == "增配")
          .length > 0
      ) {
        xpMessage.push("已选增配");
      }
      if (
        this.xpCarInfo.INV_INCREASED.filter((C) => C.INV_TYPE == "前装")
          .length > 0
      ) {
        xpMessage.push("已选前装");
      }
      this.XPMessage = xpMessage.join("+");
      this.XuanPei_price = this.XpCar_List[0].PAY_AMT;
    },
    DecorationProcessing(grade, array) {
      if (!Array.isArray(grade)) return [];
      let combo = [];
      const newGrade = [];
      grade.forEach((item) => {
        if (item.comboNo) {
          const index = newGrade.findIndex(
            (subItem) =>
              subItem.itemNo === item.itemNo &&
              subItem.comboNo === item.comboNo &&
              subItem.payModeNo === item.payModeNo
          );
          if (index > -1) {
            // newGrade[index].qty = Number(newGrade[index].qty) + Number(item.qty)
          } else {
            newGrade.push({
              comboNo: item.comboNo,
              itemNo: item.itemNo,
              discount: item.discount,
              damount: item.damount,
              amount: item.damount,
              payModeName: item.payModeName,
              payModeNo: item.payModeNo,
              qty: item.qty,
              sellTypeCode: item.sellTypeCode,
              sellTypeName: item.sellTypeName,
              goodsPrice:
                Number(item.damount) / Number(item.discount) / Number(item.qty),
              goodsNo: item.itemNo,
              Ori_Price: item.ori_Price,
            });
          }
        } else {
          console.log("非套餐", item);

          combo.push({
            ...item,
            Goods_Name: item.itemName,
            Goods_No: item.itemNo,
            manufactorGoods_no: item.itemNo,
            saleFactor: item.sellTypeName,
            sellTypeCode: item.sellTypeCode,
            sellTypeName: item.sellTypeName,
            pack_goodsno: "",
            pack_goodsPrice: "",
            goodsPrice:
              Number(item.damount) / Number(item.discount) / Number(item.qty),
            Goods_Source: item.maintainClassName,
            payClass: item.payModeName,
            payModeNo: item.payModeNo,
            qty: item.qty,
            Discountrate: item.discount,
            Subtotal: Number(item.damount),
            Goods_describe: "",
            comboNo: item.comboNo,
            isGoodsCombo: item.isGoodsCombo || 0,
          });
          console.log("非套餐", combo);
        }
      });
      console.log("套餐xiangqing", grade, combo);
      array &&
        array.forEach((item) => {
          let c = [];
          newGrade.forEach((value) => {
            if (
              item.itemId == value.itemId &&
              item.comboNo === value.comboNo &&
              item.payModeNo === value.payModeNo
            ) {
              c.push({ ...value });
            }
          });
          console.log("combo", combo);
          combo.push({
            Goods_Name: item.comboName,
            Goods_No: item.comboNo,
            itemNo: item.comboNo,
            manufactorGoods_no: item.comboNo,
            saleFactor: item.sellTypeName,
            sellTypeCode: item.sellTypeCode,
            sellTypeName: item.sellTypeName,
            pack_goodsno: "",
            pack_goodsPrice: "",
            goodsPrice: Number(item.amount),
            Goods_Source: "套餐",
            payClass: item.payModeName,
            payModeNo: item.payModeNo,
            qty: item.qty,
            Discountrate: 1,
            Subtotal: Number(item.amount) * Number(item.qty),
            Goods_describe: "",
            comboNo: item.comboNo,
            isGoodsCombo: item.comboNo ? 1 : 0,
            goodsItemInfo: c,
          });
        });
      return combo;
    },
    CountMsrp(val, type) {
      //val当前选项价格，type:1为车身色，2为内饰色
      var a = parseInt(this.CARSUBTYPEMSRP);
      var b = parseInt(val.msrp);
      var c = null;
      if (type === "1") c = parseInt(this.AppearanceColormsrp);
      else c = parseInt(this.InteriorColormsrp);
      var msrpTotal = String(a + b - c);
      return msrpTotal;
    },
  },
  watch: {
    changeData: function (newV) {
      newV.Barecar_price = newV.Barecar_price === "" ? 0 : newV.Barecar_price;
      newV.ZH_Pirce = newV.ZH_Pirce == undefined ? 0 : newV.ZH_Pirce;
      if (newV.ZH_Pirce == "") {
        newV.ZH_Pirce = 0;
      }
      newV.Insurance_Pirce =
        newV.Insurance_Pirce === "" ? 0 : newV.Insurance_Pirce;
      newV.EW_Price =
        newV.EW_Price === "" ? 0 : Number(newV.EW_Price).toFixed(2);
      newV.agency_Price = newV.agency_Price === "" ? 0 : newV.agency_Price;
      newV.Financial_Price =
        newV.Financial_Price === "" ? 0 : newV.Financial_Price;
      this.ZCAmount = Number(
        parseFloat(newV.Barecar_price) +
          parseFloat(newV.ZH_Pirce) +
          // parseFloat(newV.Insurance_Pirce) +
          parseFloat(newV.EW_Price) +
          parseFloat(newV.agency_Price) +
          parseFloat(newV.XuanPei_price || 0)
        //  +parseFloat(newV.Financial_Price)
      ).toFixed(2);
    },
    houseutilizationColumns: {
      handler: function (newVal, oldVal) {
        this.Insurance_Pirce = newVal
          .reduce((sum, e) => sum + Number(e.baofei || 0), 0)
          .toFixed(2);
        console.log("包车费", this.Insurance_Pirce);

        this.bx_amount = this.Insurance_Pirce;
        // console.log("chongzhi ");
        // this.arrInsOrderItems = []
        console.log("---", newVal, oldVal);
        let newArr = [];
        newVal.forEach((res) => {
          if (res.baoe && res.baofei) {
            res.isChecked = true;
          }
          if (res.isChecked) {
            let InsOrderItems = {};
            InsOrderItems.InsProductName = res.INS_TYPENAME;
            InsOrderItems.coverageAmount = res.baoe;
            InsOrderItems.premiumAmount = res.baofei;
            InsOrderItems.receiveAmount = res.baofei;
            newArr.push(InsOrderItems);
          }
          if (res.isChecked) {
            this.showInsurance = !res.baofei || !res.baoe;
            return;
          }
        });

        this.arrInsOrderItems = newArr;
      },
      deep: true,
    },
    formList: {
      handler: function (newVal) {
        this.EW_Price = newVal
          .reduce((sum, e) => sum + Number(e.ewAmount || 0), 0)
          .toFixed(2);
        this.yanbaoAmount = newVal
          .reduce((sum, e) => sum + Number(e.ewAmount || 0), 0)
          .toFixed(2);
      },
      deep: true,
    },
    dbpushList: {
      handler: function (newVal) {
        this.agency_Price = newVal.reduce(
          (sum, e) => sum + Number(e.dbPrice || 0),
          0
        );
        const ll = newVal.filter(
          (o) => o.GOODS_NAME == "" || o.dbPrice == "" || o.dbPrice == 0
        );
        this.WhetherAdd = ll.length > 0;
      },
      deep: true,
    },
    ZHGoods_List: {
      handler: function (newVal) {
        console.log(",ZHGoods_List", this.ZHGoods_List);
        let ZhErrorMessage = "赠送结算：";
        let type = false;
        this.ZH_Pirce = newVal.reduce((sum, e) => {
          if (e.payModeNo == "0" || e.payClass == "客户付费") {
            return sum + Number(e.Subtotal || 0);
          } else {
            type = true;
            return sum;
          }
        }, 0);
        if (newVal.length > 0) {
          const payClass = Object.assign(newVal).map((value) => value.payClass);
          const payClassSet = [...new Set(payClass)];
          console.log("小计修改", newVal, payClass, payClassSet);
          const newPayClass = payClassSet.filter((v) => v != "客户付费");

          const greaterThanTen = [];
          newPayClass.forEach((i) => {
            let total = 0;
            newVal.forEach((element) => {
              if (element.payClass == i) {
                total += Number(element.Subtotal);
              }
            });
            ZhErrorMessage += `${i}:￥${total};`;
            greaterThanTen.push({ name: i, total: total });
          });
          // greaterThanTen.forEach(i=>{ZhErrorMessage +=`${i.name}:￥${i.total};`})
          this.ZhErrorMessage = type ? ZhErrorMessage : "";
          console.log("小计修改", ZhErrorMessage, greaterThanTen);
        } else {
          this.ZhErrorMessage = "";
        }
      },
      deep: true,
      immediate: true,
    },
    MODELCODE() {
      this.GetCarColorListByModelCode();
    },
  },
  computed: {
    zhAmount: function () {
      let totalPrice = 0;
      for (let item of this.ZHGoods_List) {
        if (item.payModeNo == 0 || item.payClass == "客户付费")
          totalPrice += Number(item.Subtotal || 0);
      }
      console.log("合计", totalPrice);
      return totalPrice;
    },
    changeData() {
      const {
        Barecar_price,
        ZH_Pirce,
        Insurance_Pirce,
        EW_Price,
        agency_Price,
        Financial_Price,
        XuanPei_price,
      } = this;
      return {
        Barecar_price,
        ZH_Pirce,
        Insurance_Pirce,
        EW_Price,
        agency_Price,
        Financial_Price,
        XuanPei_price,
      };
    },
    //金融确定按钮禁用判断
    jinrongdisabled() {
      if (this.khcdShow) {
        return !(
          this.jingrong_fangan &&
          this.jinrong_sfPirce &&
          this.jinrong_zxPirce &&
          this.jinrong_dkqx &&
          this.cusSelfpayInterestAmt
        );
      } else {
        return !(
          this.jingrong_fangan &&
          this.jinrong_sfPirce &&
          this.jinrong_zxPirce &&
          this.jinrong_dkqx
        );
      }
    },
  },
  created() {
    if (this.$route.query.soNo) {
      if (this.$route.query.MODELCODE && !this.$route.query.vin) {
        console.log("=-我2");
        this.GetSubTypeBySeries();
        this.GetCarColorListByModelCode();
      } else if (this.$route.query.vin) {
        this.vin = this.$route.query.vin;
        this.QueryCarInvByVin();
      } else {
        this.GetCarSalesOrder();
      }
    } else {
      if (this.$route.query.MODELCODE && !this.$route.query.vin) {
        console.log("-我3");
        this.GetSubTypeBySeries();
        this.GetCarColorListByModelCode();
      }
      if (this.$route.query.vin) {
        this.vin = this.$route.query.vin;
        this.QueryCarInvByVin();
      }
    }

    this.GetPayMode();
    this.soNo = this.$route.query.soNo;
    this.showBack = this.$route.query.isAndroidOrIOS != "WECHAT";
  },
};
</script>

<style lang="less" scoped>
.user {
  background: #f5f5f5;
  height: 100%;

  /deep/.van-field__error-message {
    white-space: break-spaces;
    color: #878787;
  }

  .content {
    background: #fff;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .user_img {
      width: 90px;
      height: 90px;
      border: 5px solid #f5f5f5;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .user_name {
      margin-top: 10px;
      font-size: 24px;
      font-weight: 500;
      color: #000001;
    }

    .user_store {
      margin-top: 10px;
      font-size: 15px;
      font-weight: 400;
      color: #666666;
      background: #f5f5f5;
      padding: 7px 20px;
      border-radius: 30px;

      .aaa {
        border: 0;
        background: none;
        font-size: 15px;
        width: 100%;
      }
    }
  }

  .boutiquePanel {
    font-size: 0;

    .van-panel__content {
      padding: 15px;
      padding-bottom: 0;
    }

    .goodsName {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    .goodsNO,
    .manufacturerGoodsNO {
      font-size: 12px;
      color: #9f9f9f;
    }

    .price {
      font-size: 12px;
      color: @themeColor;
    }

    .van-tag {
      margin: 10px 0;

      .tagContent {
        min-width: 100px;
      }

      .van-icon-play {
        transform: rotate(90deg);
        margin-left: 10px;
      }
    }

    .van-panel__footer {
      text-align: right;

      .van-button {
        min-width: 66px;
        margin-left: 5px;
      }
    }

    .van-hairline--top::after {
      border-top-width: 0;
    }
  }

  .decorationPopup {
    /deep/.van-nav-bar {
      .van-search {
        .van-icon {
          color: #323232 !important;
        }
      }
    }

    /deep/.van-tabs {
      height: calc(100% - 96px);
      overflow: scroll;

      .van-tabs__content {
        height: calc(100% - 44px);
        overflow: scroll;
      }
    }
  }

  .shoppingCart {
    height: 50px;
    position: absolute;
    bottom: 0;
    font-size: 15px;
    color: #8e8d91;
    display: flex;
    align-items: center;
    padding: 0 15px;
    border-top: 1px solid #ebedf0;
    justify-content: space-between;
    width: 100%;
    background: #fff;

    .shoppingCart-left {
      display: flex;
      align-items: center;

      .label {
        font-size: 13px;
        margin: 0 5px;
      }
    }

    .van-button {
      min-width: 66px;
    }

    .total {
      color: @themeColor;
    }
  }

  .decorationDetailsPopup {
    /deep/ .van-nav-bar {
      .van-nav-bar__left,
      .van-nav-bar__right {
        // font-size: 14px;
      }
    }

    .decorationDetailsItem {
      > div:nth-child(1) {
        display: flex;
        color: #6e6e6e;

        .decorationDetailsItemLeft {
          word-wrap: break-word;
          flex: 1;
          margin-right: 5px;

          .goodsName {
            font-size: 14px;
            color: #201e2f;
            margin-bottom: 5px;
          }
        }

        .decorationDetailsItemRight {
          flex: none;
        }
      }

      > div:nth-child(2) {
        display: flex;
        justify-content: right;

        /deep/.van-field {
          align-items: center;
          padding: 0;

          .van-field__label {
            width: auto;
            color: #181728;
          }

          .van-field__body {
            border: 1px solid #ededed;
            height: 30px;
            padding: 0 5px;
          }
        }

        .van-field:nth-child(1) {
          width: 100px;
          margin-right: 10px;
        }

        .van-field:nth-child(2) {
          width: 120px;
        }

        .van-field::after {
          content: none;
        }
      }
    }

    .popupContent {
      padding-bottom: 50px;
    }
  }

  /deep/.van-dropdown-menu__bar {
    background: none;
    box-shadow: none;
    height: auto;
  }

  .insuranceDetailsPopup {
    .van-cell__title {
      width: 25%;
      flex: none;
    }

    .insuranceCompany {
      color: #323346;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    /deep/.van-field {
      align-items: center;
      padding: 5px;
      width: 150px;

      .van-field__label {
        width: auto;
        color: #181728;
      }

      .van-field__body {
        border: 1px solid #ededed;
        height: 30px;
        padding-right: 5px;

        .van-field__control--right {
          text-align: left;
          padding: 0 5px;
        }
      }
    }

    .van-field::after {
      border-bottom: none;
    }

    .van-hairline--top-bottom::after {
      border-width: 0;
    }

    .popupContent {
      padding-bottom: 50px;
    }
  }

  .totalFooter {
    height: 50px;
    position: absolute;
    bottom: 0;
    font-size: 15px;
    color: #8e8d91;
    display: flex;
    align-items: center;
    padding: 0 15px;
    border-top: 1px solid #ebedf0;
    justify-content: space-between;
    width: 100%;
    background: #fff;

    > div:nth-child(1) {
      color: @themeColor;
      font-weight: bold;
    }

    .van-button {
      min-width: 66px;
    }
  }

  .extendedWarrantyProductsPopup {
    background: #f1f3f2;

    .popupContent {
      // padding-bottom: 50px;
      height: calc(100% - 160px);
      margin-bottom: 20px;
    }

    .van-list {
      margin: 10px 0;
    }

    /deep/ .van-field {
      .name {
        font-size: 14px;
        font-weight: bold;
        color: #2c2b3c;
      }

      .delete {
        color: @themeColor;
      }
    }
  }

  .topInfo {
    align-items: center;
    white-space: initial;
    flex: none;

    .carSubTypeName {
      font-size: 18px;
      font-weight: bold;
    }

    .info {
      font-size: 12px;
      word-wrap: break-word;
      max-height: 50%;
      overflow: scroll;
      margin-top: 10px;

      .price {
        font-size: 14px;
        color: @themeColor;
        margin-right: 10px;
        word-wrap: break-word;
      }
    }
  }

  .scroll {
    overflow: scroll;
  }

  .footerButton {
    // position: fixed;
    // bottom: 0;
    width: 100%;
    padding: 16px;
    background: #fff;
    font-size: 0;
  }

  .xpfooterButton {
    height: 2rem;
    position: absolute;
    bottom: 0;
    font-size: 0.4rem;
    color: #8e8d91;
    display: flex;
    align-items: center;
    padding: 0 0.4rem;
    border-top: 0.02667rem solid #ebedf0;
    justify-content: space-between;
    width: 100%;
    background: #fff;
  }

  .titleActive {
    color: @themeColor;
  }

  .popupTitle {
    font-size: 14px;
    font-weight: bold;
    flex: none;

    .van-cell__value {
      text-align: center;
      padding-top: 2px;
    }
  }

  .popupClass {
    display: flex;
    flex-direction: column;
  }

  .popupContent {
    margin-bottom: 50px;
    overflow: scroll;
  }

  .h50 {
    height: 50px;
    width: 100%;
  }

  .bold {
    font-weight: bolder;
    font-size: 14px;
    padding-top: 2px;
    padding-bottom: 2px;
    // font-size:large;
  }

  .orange {
    color: #f7772c !important;
  }

  /*滚动条样式*/
  /deep/ .scrollbar {
    white-space: nowrap;
    margin: 0;
    background: none;
    // height: calc(100% - 140px);
    display: flex;
    flex-direction: column;
    font-size: 0;
    overflow: scroll;
    padding-bottom: 10px;

    .el-scrollbar {
      display: flex;
      justify-content: space-around;
      padding: 0 10px;
    }

    /deep/ .el-scrollbar__wrap {
      overflow: scroll;
      width: 110%;
      height: 100%;
    }

    .container {
      height: 60vh;
      overflow: auto;
    }

    ::v-deep .redIcon .van-cell--required {
      &:before {
        content: "";
      }

      .van-cell__title.van-field__label {
        span {
          position: relative;

          &:before {
            position: absolute;
            right: -8px;
            color: #ee0a24;
            font-size: 14px;
            content: "*";
          }
        }
      }
    }
  }

  .list {
    padding: 16px;
    padding-top: 0;
    // overflow: scroll;
    // height: calc(100vh - 300px);
  }

  .panel-item {
    margin-bottom: 10px;
    padding-bottom: 10px;

    .van-panel__header {
      border-radius: 10px;

      .van-cell__title {
        color: #5e5e5e;
        //color:grey;
        width: 70%;
        flex: none;
      }
    }

    .van-cell::after {
      content: none;
    }

    .padding-top0 {
      padding-top: 0;
      font-size: 16px;
    }

    .padding-bot0 {
      padding-bottom: 0;
    }

    .van-hairline--top::after {
      border-top-width: 0;
    }
  }

  .grey {
    color: #5e5e5e;
    padding-top: 0px;
    padding-bottom: 0px;

    .van-cell__value--alone {
      color: #5e5e5e;
    }

    .van-tag {
      font-weight: bold;
    }
  }

  /deep/ .van-nav-bar__title {
    width: 77%;
    max-width: 77% !important;
  }

  /deep/ .closeIcon {
    font-size: 22px;
  }
}
</style>
