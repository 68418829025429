<template>
  <div class="approvalHistory">
    <div class="vant-title">审批历史</div>
    <div class="content">
      <van-steps direction="vertical" :active="-1">
        <van-step v-for="(item, index) in historyList" :key="index">
          <div class="vant-approvalList">
            <div class="">
              <div class="approver approvalList_title">
                <div class="approver_name">{{ item.审批人 }}</div>
                <div :class="['approverTag_blue', ExecutionSituation(item)]">{{ item.执行情况 }}</div>
              </div>
              <div class="approvalList_content">{{ item.审批意见 }}</div>
              <div class="approvalList_content date">{{ item.操作时间 }}</div>
            </div>

          </div>
        </van-step>
      </van-steps>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "historyList"
  ],
  methods: {
    ExecutionSituation (item) {
      let value = item.执行情况
      let color = ''
      if (value.indexOf('同意') > -1 || value.indexOf('通过') > -1)
      {
        color = 'green'
      } else if (value.indexOf('拒绝') > -1)
      {
        color = 'red'
      } else
      {
        color = ''
      }
      return color
    }
  },
}
</script>

<style lang="less" scoped>
.approvalHistory {
  background: #fff;
  border-radius: 4px;
  padding: 0 10px;

  .flex {
    display: flex;
  }

  .vant-title {
    padding: 10px 0;
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  // .title::before {
  //   content: '';
  //   width: 4px;
  //   display: inline-block;
  //   height: 22px;
  //   vertical-align: middle;
  //   background: #EF7910;
  //   margin-right: 5px;
  // }


  .content {
    .vant-approvalList {
      color: #000;
      font-size: 13px;

      .approvalList_title {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .approver {
        display: flex;
        align-items: center;

        .approver_name {
          font-size: 15px;
          margin-right: 7px;
        }

        .approverTag_blue {
          background: rgba(102, 102, 102, 0.1);
          color: #333333;
          line-height: 19px;
          padding: 0 5px;
          border-radius: 2px;
        }

        .green {
          background: rgba(34, 178, 136, 0.1);
          color: #22B288;
        }

        .red {
          color: #FF7766;
          background: rgba(255, 119, 102, 0.1);
        }
      }

      .date {
        color: #999999;
      }


      .approvalList_content {
        margin: 10px 0;
        color: #999999;
      }
    }
  }
}

[class*=van-hairline]::after {
  border: 0;
}

/deep/.van-step__circle {
  width: 10px;
  height: 10px;
  background-color: #E6E6E6;
}
</style>