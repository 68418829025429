import request from "../../utils/request";

// 获取整车销售订单列表
export function GetSalesOrderDeliveryList(data) {
  return request({
    url: '/NCS/NewCarSalesOrder/GetSalesOrderDeliveryList',
    method: 'post',
    data
  })
}


//出库
export function SalesOrderDeliveryNew(data) {
  return request({
    url: '/Saas/SalesOrderDeliveryNew',
    method: 'post',
    data
  })
}


