<!--
 * @Description:
 * @Version: 2.0
 * @Autor: zgh
 * @Date: 2023-07-05 14:03:47
 * /*LastEditors: 杨强
 * /*LastEditTime: 2023-08-14 13:40:25
-->
<template>
  <div class="orderList">
    <!-- 搜索框 -->

    <van-nav-bar title="标题"  :left-arrow="showBack" @click-left="goBack">
      <template #title>
        <form action="javascript:return true">
          <van-search v-model="value" shape="round" background="none" placeholder="搜索" @search="CarTypeSearch"
            @blur="CarTypeSearch" @keypress="CarTypeSearch" clearable />
        </form>
      </template>
      <van-icon name="filter-o" slot="right" @click="openPop">筛选</van-icon>
    </van-nav-bar>
    <van-cell :style="{
      background: 'transparent',
      flex: 'none',
    }" :title="`共${totalCount}个订单`"></van-cell>
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" :immediate-check="false"
      @load="GetCarSalesOrderList" class="list" :offset="10">
      <van-panel v-for="item in so_List" :key="item.soNo" :title="`${item.createDate} | ${item.soType}`"
        :status="item.soStatus" @click="click_so_OrderDetails(item)" class="panelItem">
        <van-cell :title="item.subtypeName" :title-style="{ fontSize: '16px', fontWeight: 'bold' }"
          :border="false"></van-cell>
        <van-cell class="grey"
          :title="`指导价：${item.SO_CAR_STD_PRICE} | 外：${item.carColorName} | 内：${item.carInnerColorName}`"
          :border="false"></van-cell>
        <van-cell class="grey" :title="`订单款：${item.soAmt}`" :border="false"></van-cell>
        <van-cell class="grey" :title="`订单编号：${item.soNo}`" :border="false"></van-cell>
        <van-cell class="grey" :border="false">
          <van-tag color="#eef7fe" text-color="#69a3f0" size="medium">客户：{{ item.cusName }}</van-tag>
          <span> 销售归属：{{ item.saleName }}</span>
        </van-cell>
        <template #footer>
        </template>
      </van-panel>
    </van-list>
    <van-popup closeable @closed='closePop' v-model="showFilterItem" position="right"
      :style="{ height: '100vh', width: '100%' }" class="popup-class">
      <filter-item @closeFilterItem="closeFilterItem"> </filter-item>
    </van-popup>

  </div>
</template>

<script>
import { GetCarSalesOrderList } from "../../api/SalesOrderList";
import FilterItem from './components/FilterItem'
export default {
  components: { FilterItem },
  data () {
    return {
      showBack:true,
      loading: false,
      finished: false,
      type1: 0,
      type2: "a",
      option1: [
        { text: "全部商品", value: 0 },
        { text: "新款商品", value: 1 },
        { text: "活动商品", value: 2 },
      ],
      option2: [
        { text: "默认排序", value: "a" },
        { text: "好评排序", value: "b" },
        { text: "销量排序", value: "c" },
      ],
      // CarTypeSearch: "",
      //表单结束
      value: "",
      Index: 1,
      parentMessage: "Parent",
      so_List: [],
      pageSize: {
        hasMore: true,
        pagesize: 10,
        pagestart: 1,
        totalCount: 0
      },
      checkoutName: "",
      show: false,
      value1: 0,
      value2: "a",
      isShowName: false,
      GetCarSalesOrderListModel: {
        storeNo: "",
      },
      totalCount: 0,
      showFilterItem: false,
    };
  },
  mounted () { 

  },
  methods: {
    goBack () {
      if(this.showBack){
        this.backforward()
      }
    },
    backforward () {
      // 返回APP菜单中心页面
      let u = navigator.userAgent
      let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      try
      {
        if (isAndroid)
        {
          if(!window.android){
            // window.history.replaceState()
            window.history.back(window.history.back())
          }else{
            window.android.webClose(); // 调用原生app的函数--webClose是安卓提供的方法
          }
        }
        if (isIOS)
        {
          console.log("isIOS")
          window.webkit.messageHandlers.webClose.postMessage(""); //--webClose是ios提供的方法
        }
      } catch (e)
      {    
        window.history.back()
      }
    },
    clear () {
      alert(1)
      console.log("--",this.value)
      this.value = ''
      this.getCarSalesOrder('search')
    },
    openPop () {
      // 筛选
      this.showFilterItem = true
    },
    closePop () {
      this.showFilterItem = false
    },
    closeFilterItem () {
      this.closePop()
      this.$store.commit('showLoading');
      this.getCarSalesOrder('search')
    },
    // 获取审批类型列表
    GetCarSalesOrderList () {
      console.log("初始化", this.GetCarSalesOrderListModel);
      this.getCarSalesOrder()
    },
    async getCarSalesOrder (search) {
      const createDateStart = sessionStorage.getItem("createDateStart") || ''
      const createDateEnd = sessionStorage.getItem("createDateEnd") || ''
      const isLock = sessionStorage.getItem("isLock") || ''
      const faStatus = sessionStorage.getItem("faStatus") || ''

      const soInvoiceStatus = sessionStorage.getItem("soInvoiceStatus") || ''
      const isRelStatus = sessionStorage.getItem("isRelStatus") || ''
      const isDelivery = sessionStorage.getItem("isDelivery") || ''
      const isSettled = sessionStorage.getItem("isSettled") || ''
      const length = this.so_List.length == 0 ? 0 : this.so_List.length
      const parmas = {
        storeNo: this.$route.query.Store_No,
        iDisplayStart: search ? 0 : length,
        iDisplayLength: 20,
        searchStr: this.value,
        empId: this.$route.query.EMPLOYEE_NO,

        createDateStart: createDateStart,
        createDateEnd: createDateEnd,
        isLock: isLock != '不限' ? isLock : '',
        faStatus: faStatus != '不限' ? faStatus : '',
        soInvoiceStatus: soInvoiceStatus != '不限' ? soInvoiceStatus : '',
        isRelStatus: isRelStatus != '不限' ? isRelStatus : '',
        isDelivery: isDelivery != '不限' ? isDelivery : '',
        isSettled: isSettled != '不限' ? isSettled : '',
      }

      // this.GetCarSalesOrderListModel.storeNo = this.$route.query.Store_No;
      // this.GetCarSalesOrderListModel.iDisplayStart = search ? 0 : length;
      // this.GetCarSalesOrderListModel.iDisplayLength = 10;
      // this.GetCarSalesOrderListModel.searchStr = this.value;
      // this.GetCarSalesOrderListModel.empId = this.$route.query.EMPLOYEE_NO;

      // 筛选条件


      // this.finished = false;
      await GetCarSalesOrderList(parmas).then((res) => {
        this.$store.commit('hideLoading');
        if (res.data.IsSuccess)
        {
          if (search) { this.so_List = res.data.result } else { this.so_List.push(...res.data.result); }
          if (res.data.msg.hasMore) this.finished = false;
          if (!res.data.msg.hasMore) this.finished = true;
          this.loading = false;
          this.pageSize = res.data.msg
          this.totalCount = res.data.msg.totalCount;
        } else
        {
          this.$toast(res.data.msg)
          this.so_List = []
        }
        this.loading = false;
      }).catch(() => {
        this.finished = false;
        this.loading = false;
      })
    },
    click_so_OrderDetails (item) {
      this.$router.push({
        path: "/OrderDetails/OrderDetails",
        query: {
          ...this.$route.query,
          //orderItem: JSON.stringify(item),
          soNo: item.soNo,
        },
      });
    },
    CarTypeSearch () {
      this.getCarSalesOrder('search')
    }
  },
  watch: {},
  computed: {},
  created () {
    this.$store.commit('showLoading');
    this.GetCarSalesOrderList();
    this.showBack = this.$route.query.isAndroidOrIOS != 'WECHAT'
  },
};
</script>

<style lang="less" scoped>
::v-deep .van-search {
  background: none;
}

.orderList {
  height: 100%;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .list {
    padding: 16px;
    padding-top: 0;
    overflow: scroll;
    overflow-y: scroll;
    height: calc(100% - 114px);
  }

  .panelItem {
    margin-bottom: 16px;
    border-radius: 10px;

    .van-panel__header {
      border-radius: 10px;

      .van-cell__title {
        color: #5e5e5e;
        width: 70%;
        flex: none;
      }
    }

    .van-cell::after {
      content: none;
    }

    .van-hairline--top::after {
      border-top-width: 0;
    }
  }

  .grey {
    color: #5e5e5e;
    padding-top: 0px;
    padding-bottom: 0px;

    .van-cell__value--alone {
      color: #5e5e5e;
    }

    .van-tag {
      font-weight: bold;
    }
  }

  .content {
    background: #f2f2f1;

    .user_img {
      width: 90px;
      height: 90px;
      border: 5px solid #f5f5f5;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .user_name {
      margin-top: 10px;
      font-size: 24px;
      font-weight: 500;
      color: #000001;
    }

    .user_store {
      margin-top: 10px;
      font-size: 15px;
      font-weight: 400;
      color: #666666;
      background: #f5f5f5;
      padding: 7px 20px;
      border-radius: 30px;

      .aaa {
        border: 0;
        background: none;
        font-size: 15px;
        width: 100%;
      }
    }
  }
}

// /deep/.van-dropdown-menu__bar {
//   background: none;
//   box-shadow: none;
//   height: auto;
// }

/*滚动条样式*/
/deep/ .scrollbar {
  white-space: nowrap;

  .el-scrollbar {
    display: flex;
    justify-content: space-around;
    padding: 0 10px;
  }

  /deep/ .el-scrollbar__wrap {
    overflow: scroll;
    width: 110%;
    height: 100%;
  }

  .container {
    height: 60vh;
    overflow: auto;
  }
}

/deep/ .van-nav-bar__title {
  width: 77%;
  max-width: 71% !important;
}
/deep/.van-search__content{
padding-right:12px
}
</style>
