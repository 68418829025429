<template>
  <div>
    <home v-if="active == 0"></home>
    <SaleCarType v-if="active == 1"></SaleCarType>
    <SaleOrderList v-if="active == 2"></SaleOrderList>
    <my v-if="active == 3"></my>
    <van-tabbar v-model="active" active-color="#000" @click="onChange">
      <van-tabbar-item>
        <span>审批表单</span>
        <template #icon="props">
          <img :src="props.active ? icon.active_home : icon.inactive_home" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item>
        <span>订单下单</span>
        <template #icon="props">
          <img
            :src="props.active ? icon.active_carType : icon.active_carType"
          />
        </template>
      </van-tabbar-item>
      <van-tabbar-item>
        <span>订单列表</span>
        <template #icon="props">
          <img
            :src="props.active ? icon.active_carType : icon.active_carType"
          />
        </template>
      </van-tabbar-item>
      <van-tabbar-item>
        <span>我的</span>
        <template #icon="props">
          <img
            :src="props.active ? icon.active_carType : icon.active_carType"
          />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import Home from "../home/home.vue";
import SaleCarType from "../SalesOrder/SaleCarType.vue";
import SaleOrderList from "../SaleOrderList/SaleOrderList.vue";
import my from "../my/my.vue";
import { WorkFlowFreeLogin } from "../../api/login";
export default {
  components: {
    Home,
    SaleCarType,
    SaleOrderList,
    my,
  },
  data() {
    return {
      active: 1,
      icon: {
        active_home: require("../../assets/home_gl.png"),
        inactive_home: require("../../assets/home.png"),
        active_my: require("../../assets/my_gl.png"),
        inactive_my: require("../../assets/my.png"),
        active_carType: require("../../assets/my_gl.png"),
        inactive_carType: require("../../assets/my.png"),
      },
      LoginModel: { USER_NAME: "" },
    };
  },
  methods: {
    onChange(index) {
      console.log(index);
      if (index == 0) {
        document.title = "移动审批";
        this.active = 0;
      } else if (index == 1) {
        document.title = "订单下单";
        this.active = 1;
      } else if (index == 2) {
        document.title = "订单列表";
        this.active = 2;
      } else if (index == 3) {
        document.title = "我的";
        this.active = 2;
      }
    },
    async WorkFlowFreeLogin() {
      var EMPLOYEE_NO = this.$route.query.EMPLOYEE_NO;
      this.LoginModel.USER_NAME = EMPLOYEE_NO;
      if (EMPLOYEE_NO != undefined) {
        this.loading = true;
        await WorkFlowFreeLogin(this.LoginModel)
          .then((res) => {
            if (res.data.IsSuccess) {
              console.log(res);
            } else {
              this.loading = false;
              this.$toast(res.data.msg);
            }
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
            this.$toast(err.data.msg);
          });
      }
    },
  },
  created() {
    this.WorkFlowFreeLogin();
  },
};
</script>

<style lang="less" scoped></style>
