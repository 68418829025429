/*
 //Author: 杨强
 //Date: 2023-04-17 10:05:07
 //LastEditTime: 2023-09-13 09:37:41
 //LastEditors: 杨强
 //Description: 
 //FilePath: \bzm-sales-h5 - 副本\src\main.js
 */

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import "@/vantui"
// import 'vue-easytable/libs/themes-base/index.css'
import "vue-easytable/libs/theme-default/index.css";
import VueEasytable from "vue-easytable";
import * as filters from './filters/index'
import store from './store/index'
// import {VTable, VPagination} from 'vue-easytable'

// Vue.component(VTable.name, VTable)
// Vue.component(VPagination.name, VPagination)

Vue.use(VueEasytable);
Vue.use(store);
Vue.prototype.ENV = process.env.VUE_APP_BASE_ENV

Vue.config.productionTip = false
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')