<!--
 * /*Author: 杨强
 * /*Date: 2023-08-03 11:44:15
 * /*LastEditTime: 2023-11-01 16:44:25
 * /*LastEditors: 杨强
 * /*Description: 
 * /*FilePath: \bzm-sales-h5 - 副本\src\views\LockCarList\LockCar.vue
-->
<template>
  <div class="lockcar">
    <!-- 搜索框 -->
    <van-nav-bar title="标题" :left-arrow="showBack" @click-left="goBack">
      <template #title>
        <van-search v-model="SEARCH_STR" shape="round" background="none" placeholder="搜索VIN码"
          @search="SearchNoLockCarList(0)" @blur="SearchNoLockCarList(0)" @clear="SearchNoLockCarList(0)"
          @cancel="SearchNoLockCarList(0)" @keypress="SearchNoLockCarList(0)" />
      </template>
    </van-nav-bar>
    <!-- 订单数据 -->
    <van-panel v-model="itemInfo" :title="itemInfo.estimatedDeliveryCarDate" :status="itemInfo.vin ? '已配车' : '待配车'"
      class="panel-item" style="margin-bottom: 0;">
      <van-cell :title="`${itemInfo.soCarTypeName} ${itemInfo.soCarSubTypeName}`"
        :title-style="{ fontSize: '16px', fontWeight: 'bold' }" :border="false" class="padding-top0"></van-cell>
      <van-cell class="grey" :title="`外观${itemInfo.carColorName} + 内饰${itemInfo.carInnerColorName}`"
        :border="false"></van-cell>
      <van-cell class="grey" :title="`预计交车时间：${itemInfo.estimatedDeliveryCarDate || ''}  |  销售：${itemInfo.saleNmae}`"
        :border="false"></van-cell>
      <van-cell class="grey" :title="`客户：${itemInfo.OWNERNAME || ''}  | 客户手机：${itemInfo.MOBILE}`"
        :border="false"></van-cell>
      <van-cell class="grey" :title="`订单号：${itemInfo.soNo || ''}`" :border="false"></van-cell>
    </van-panel>
    <!-- 库存列表 -->
    <van-cell :style="{ background: '#f5f5f5', flex: 'none', }" :title="`该车型共${totalCount}台库存`">
      <van-dropdown-menu class="dropMenu">
        <van-dropdown-item v-model="orderBy" @change="OrderChange" :options="orderByOptions" />
      </van-dropdown-menu>
    </van-cell>
    <!-- 列表 -->
    <van-radio-group v-model="selectVin">
      <van-list v-model="listLoading" :finished="finished" :immediate-check="false" finished-text="没有更多了"
        @load="SearchNoLockCarList" class="list" offset="20">
        <van-panel v-for="item in noCar_List" :key="item.vin" class="panel-item" >
          <van-radio :name="item.vin" @click="toastXP(item.INV_TYPE)" :disabled="item.INV_TYPE"
            style="float: left;width: 15%; padding-left: 15px; padding-right: 15px;"></van-radio>
          <van-cell :title="`VIN码：${item.vin}`" style="float: left;width:70%; padding:0;"
            :title-style="{ fontSize: '15px' }" :border="false" class="padding-top0"></van-cell>
          <div style="float: left; width:15%;">
            <van-button plain size="mini" style="float: left;" @click="SkipPage(item.vin)"
              type="primary">详情</van-button>
          </div>
          <van-cell class="grey" style="padding-left: 15%;" :title="`所属门店：${item.STORE_NAME}`"
            :border="false"></van-cell>
          <div style="padding-left: 15%; font-size: 15px; margin-top: 5px;">
            <van-button size="small" disabled plain type="primary" style="margin-right: 10px;">库存{{ 0 >=
              parseInt(item.invDays) ? '0' : item.invDays }}天</van-button>
            <van-button size="small" disabled type="default" style="margin-right: 10px;">车龄{{ 0 >=
              parseInt(item.invDays) ? '无' : `${item.invDays}天` }}</van-button>
            <van-button size="small" disabled type="default" style="margin-right: 10px;">{{ item.INV_STATUS
            }}
            </van-button>
            <van-button size="small" v-if="item.INV_TYPE"  disabled plain type="primary" >{{item.INV_TYPE}}</van-button>
          </div>
        </van-panel>
      </van-list>
    </van-radio-group>
    <div class="footer-button">
      <van-button type="primary" @click="submit()" :loading="btnLoading" loading-text="提交中..." round
        style="width:100%">确认</van-button>
    </div>
  </div>
</template>
<script>
import { GetNoLockCarList, SoLockCar } from "../../api/LockCarList";
export default {
  data () {
    return {
      showBack:true,
      SEARCH_STR: '',
      itemInfo: {
        estimatedDeliveryCarDate: '',
        vin: '',
        soCarTypeName: '',
        soCarSubTypeName: '',
        carColorName: '',
        carInnerColorName: '',
        saleNmae: '',
        OWNERNAME: '',
        MOBILE: '',
        soNo: ''
      },
      listLoading: false,
      finished: false,
      noCar_List: [],
      selectVin: '',
      totalCount: 0,
      orderBy: '最大车龄',
      orderByOptions: [
        { text: '车龄最长', value: '最大车龄' },
        { text: '车龄最短', value: '最小车龄' },
      ],
      btnLoading: false
    }
  },
  methods: {
    //配车/锁车列表查询
    SearchNoLockCarList (length) {
      if (length == 0)
      {
        this.listLoading = true
      }
      //参数组合
      const params = {
        storeNo: this.$route.query.Store_No,
        soNo: this.itemInfo.soNo,//this.itemInfo.soNo ||'XS20002002011100001',
        iDisplayStart: length == 0 ? 0 : this.noCar_List.length,
        iDisplayLength: 10,
        invDaysAsc: this.orderBy,
        VIN: this.SEARCH_STR
      }
      GetNoLockCarList(params).then((res) => {
        if (res.data.IsSuccess)
        {
          if (length == 0)
          {
            this.noCar_List = res.data.result.soNoLockCarInfos
          }
          else
          {
            this.noCar_List.push(...res.data.result.soNoLockCarInfos);
          }
          this.totalCount = res.data.msg.totalCount;
          if (res.data.msg.hasMore) this.finished = false;
          if (!res.data.msg.hasMore) this.finished = true;
        } else
        {
          this.$toast(res.data.msg)
          this.noCar_List = []
        }
        this.listLoading = false;
      })
    },
    toastXP(val){
      if(val){
        this.$toast('抱歉，该车辆包含"'+val+'"项目，请至订单中手动配车！')
      }
    },
    //排序
    OrderChange (val) {
      this.orderBy = val
      this.listLoading = true
      this.SearchNoLockCarList(0)
    },
    //跳转车辆详情
    SkipPage (vin) {
      this.$router.push({
        path: "/LockCarList/NoLockCarInfo",
        query: {
          ...this.$route.query,
          vin: vin,
        },
      });
    },
    //配车
    submit () {
      if (!this.selectVin)
      {
        this.$toast('请选择车辆！')
        return
      }
      this.btnLoading = true
      const param = {
        storeNo: this.$route.query.Store_No,
        soNo: this.$route.query.soNo,
        vin: this.selectVin
      }
      SoLockCar(param).then(res => {
        this.btnLoading = false
        if (res.data.IsSuccess)
        {
          this.$toast("配车成功！")
          setTimeout(() => {
            this.$router.push({
              path: "/LockCarList/LockCarList",
              query: {
                Store_No: this.$route.query.Store_No,
                EMPLOYEE_NO: this.$route.query.EMPLOYEE_NO
              },
            });
          }, 1000);
        } else
        {
          this.$toast(res.data.msg)
        }
        console.log(res, "res")
      })
    },
    goBack () {
      if(!this.showBack){
        return
      }
      let isBack = false
      if (window.history.length <= 1)
      {
        isBack = true
        // this.$router.push({ path: '/' })
        this.backforward()
        // 跳转回原生
        return
      } else
      {
        isBack = true
        this.$router.go(-1)
      }
      if (isBack === false)
      {
        setTimeout(() => {
          // this.$router.push({ path: '/' })
          this.backforward()
        }, 500)
      }
    },
    backforward () {
      // 返回APP菜单中心页面
      let u = navigator.userAgent
      let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      try {
        if (isAndroid) {
          window.android.webClose(); // 调用原生app的函数--webClose是安卓提供的方法
        }
        if (isIOS) {
          window.webkit.messageHandlers.webClose.postMessage(""); //--webClose是ios提供的方法
        }
      } catch (e) {
        window.history.back()
      }
    },
  },
  created () {
    this.itemInfo.estimatedDeliveryCarDate = this.$route.query.estimatedDeliveryCarDate,
      this.itemInfo.vin = this.$route.query.vin,
      this.itemInfo.soCarTypeName = this.$route.query.soCarTypeName,
      this.itemInfo.soCarSubTypeName = this.$route.query.soCarSubTypeName,
      this.itemInfo.carColorName = this.$route.query.carColorName,
      this.itemInfo.carInnerColorName = this.$route.query.carInnerColorName,
      this.itemInfo.saleNmae = this.$route.query.saleNmae,
      this.itemInfo.OWNERNAME = this.$route.query.OWNERNAME,
      this.itemInfo.MOBILE = this.$route.query.MOBILE,
      this.itemInfo.soNo = this.$route.query.soNo,
      this.listLoading = true
    this.SearchNoLockCarList(0)
    this.showBack = this.$route.query.isAndroidOrIOS != 'WECHAT' 
  },
}
</script>
<style lang="less" scoped>
::v-deep .van-nav-bar__title {
  width: 88%;
  max-width: 88% !important;
}

.lockcar {
  position: absolute;
  height: 100%;
  width: 100%;
  //height: 100vh;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;

  .list {
    padding: 16px;
    padding-top: 0;
    overflow: scroll;
    height: calc(100vh - 10rem);
  }

  .panel-item {
    margin-bottom: 10px;
    padding-bottom: 10px;

    .van-panel__header {
      border-radius: 10px;

      .van-cell__title {
        color: #5e5e5e;
        //color:grey;
        width: 70%;
        flex: none;
      }
    }

    .van-cell::after {
      content: none;
    }

    .padding-top0 {
      padding-top: 0;
    }

    .padding-bot0 {
      padding-bottom: 0;
    }

    .van-hairline--top::after {
      border-top-width: 0;
    }
  }

  .grey {
    color: #5e5e5e;
    padding-top: 5px;
    padding-bottom: 0px;

    .van-cell__value--alone {
      color: #5e5e5e;
    }

    .van-tag {
      font-weight: bold;
    }
  }


  .footer-button {
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #ddd;
    padding: 10px 20px;
    background: #fff;
  }

  .dropMenu {
    height: 20px;
    background: none;
  }

  .dropMenu>.van-dropdown-menu__bar {
    height: 20px;
    background: none;
    box-shadow: none;
    float: right;
  }

  .dropMenu .van-dropdown-menu__item {
    height: 20px;
    background: none;
  }

  .dropMenu .van-dropdown-menu__title {
    height: 20px;
    background: none;
    font-size: 12px;
  }
}</style>
