import request from "../../utils/request";

// 根据门店获取整车销售订单列表
// export const GetCarSalesOrderList = data => request('/NCS/NewCarSalesOrder/GetCarSalesOrderList', data, 'POST')
export function GetCarSalesOrderList(data) {
  return request({
    url: '/NCS/NewCarSalesOrder/GetCarSalesOrderList',
    method: 'post',
    data
  })
}

// 16、根据订单编号获取整车销售订单详情
// export const GetCarSalesOrder = data => request('/NCS/NewCarSalesOrder/GetCarSalesOrder', data, 'POST')
export function GetCarSalesOrder(data) {
  return request({
    url: '/NCS/NewCarSalesOrder/GetCarSalesOrder',
    method: 'post',
    data
  })
}