import request from "../../utils/request";

//车辆品牌列表
// export const GetAllBrand = data => request('/Saas/GetAllBrand', data, 'POST')
export function GetAllBrand(data) {
  return request({
    url: '/Saas/GetAllBrand',
    method: 'post',
    data
  })
}

//根据品牌获取车系
// export const GetSeriesByBrand = data => request('/Saas/GetSeriesByBrand', data, 'POST')
export function GetSeriesByBrand(data) {
  return request({
    url: '/Saas/GetSeriesByBrand',
    method: 'post',
    data
  })
}

//根据车系获取车型细分

// export const GetSubTypeBySeries = data => request('/Saas/GetSubTypeBySeries', data, 'POST')
export function GetSubTypeBySeries(data) {
  return request({
    url: '/Saas/GetSubTypeBySeries',
    method: 'post',
    data
  })
}

//根据车型细分、以及颜色类型 返回内饰色、外饰色等数据
// export const GetCarColorListByModelCode = data => request('/Saas/GetCarColorListByModelCode', data, 'POST')
export function GetCarColorListByModelCode(data) {
  return request({
    url: '/Saas/GetCarColorListByModelCode',
    method: 'post',
    data
  })
}

//根据VIN查到品牌、车系、车型、车型细分、内外、指导价
// export const QueryCarInvByVin = data => request('/Saas/QueryCarInvByVin', data, 'POST')
export function QueryCarInvByVin(data) {
  return request({
    url: '/Saas/QueryCarInvByVin',
    method: 'post',
    data
  })
}

//根据车辆信息去查找符合并带有增配的在库车
// export const GetCarInvByClass = data => request('/Saas/GetCarInvByClass', data, 'POST')
export function GetCarInvByClass(data) {
  return request({
    url: '/Saas/GetCarInvByClass',
    method: 'post',
    data
  })
}


//根据门店获取延保、金融机构
// export const GetSupplierByStore = data => request('/BAS/BaseInfo/GetSupplierByStore', data, 'POST')
export function GetSupplierByStore(data) {
  return request({
    url: '/BAS/BaseInfo/GetSupplierByStore',
    method: 'post',
    data
  })
}

//根据门店、延保机构获取延保产品
// export const GetEWProductInfo = data => request('/MDM/GetEWProductInfo', data, 'POST')
export function GetEWProductInfo(data) {
  return request({
    url: '/MDM/GetEWProductInfo',
    method: 'post',
    data
  })
}

//获取延保产品列表
// export const GetEWProductList = data => request('/MDM/GetEWProductList', data, 'POST')
export function GetEWProductList(data) {
  return request({
    url: '/MDM/GetEWProductList',
    method: 'post',
    data
  })
}

//根据延保产品获取延保类型

// export const GetWMType = data => request('/BAS/BaseInfo/GetWMType', data, 'POST')
export function GetWMType(data) {
  return request({
    url: '/BAS/BaseInfo/GetWMType',
    method: 'post',
    data
  })
}

//根据延保产品获取延保方案
// export const GetEwPTime = data => request('/BAS/BaseInfo/GetEwPTime', data, 'POST')
export function GetEwPTime(data) {
  return request({
    url: '/BAS/BaseInfo/GetEwPTime',
    method: 'post',
    data
  })
}

//查询精品商品
// export const GetSalesJPInfo = data => request('/SalesOrder/BAS/GetSalesJPInfo', data, 'POST')
export function GetSalesJPInfo(data) {
  return request({
    url: '/SalesOrder/BAS/GetSalesJPInfo',
    method: 'post',
    data
  })
}
// 查询套餐

export function GetSalesTCInfo(data) {
  return request({
    url: '/SalesOrder/BAS/GetSalesTCInfo',
    method: 'post',
    data
  })
}

//查询保险公司
// export const GetInsCompany = data => request('/SalesOrder/BAS/GetInsCompany', data, 'POST')
export function GetInsCompany(data) {
  return request({
    url: '/SalesOrder/BAS/GetInsCompany',
    method: 'post',
    data
  })
}

//查询保险险种
// export const GetInsTypeItem = data => request('/SalesOrder/BAS/GetInsTypeItem', data, 'POST')
export function GetInsTypeItem(data) {
  return request({
    url: '/SalesOrder/BAS/GetInsTypeItem',
    method: 'post',
    data
  })
}

//查询代收代付
// export const GetDSBasInfo = data => request('/SalesOrder/BAS/GetDSBasInfo', data, 'POST')
export function GetDSBasInfo(data) {
  return request({
    url: '/SalesOrder/BAS/GetDSBasInfo',
    method: 'post',
    data
  })
}

//根据费用类别查询商品价格
// export const GetPriceByPayMode = data => request('/SalesOrder/BAS/GetPriceByPayMode', data, 'POST')
export function GetPriceByPayMode(data) {
  return request({
    url: '/SalesOrder/BAS/GetPriceByPayMode',
    method: 'post',
    data
  })
}

//费用类别查询
// export const GetPayMode = data => request('/BAS/BaseInfo/GetPayMode', data, 'POST')
export function GetPayMode(data) {
  return request({
    url: '/BAS/BaseInfo/GetPayMode',
    method: 'post',
    data
  })
}

//获取金融产品
// export const GetFinProductBySupplier = data => request('/BAS/BaseInfo/GetFinProductBySupplier', data, 'POST')
export function GetFinProductBySupplier(data) {
  return request({
    url: '/BAS/BaseInfo/GetFinProductBySupplier',
    method: 'post',
    data
  })
}

//异步免登录
// export const WorkFlowFreeLogin = data => request('/SalesOrder/BAS/WorkFlowFreeLogin', data, 'POST')
export function WorkFlowFreeLogin(data) {
  return request({
    url: '/SalesOrder/BAS/WorkFlowFreeLogin',
    method: 'post',
    data
  })
}