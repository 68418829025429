import request from "../../utils/requestAPI";

// 获取审批表单列表
// export const getApprovalList = data => request('/WorkFlow/WorkFlowQuery/WorkFlowList', data, 'POST')
export function getApprovalList(data) {
  return request({
    url: '/WorkFlow/WorkFlowQuery/WorkFlowList',
    method: 'post',
    data
  })
}

// 获取审批流类型
// export const getFlowType = data => request('/WorkFlow/WorkFlowQuery/WorkFlowType', data, 'POST')
export function getFlowType(data) {
  return request({
    url: '/WorkFlow/WorkFlowQuery/WorkFlowType',
    method: 'post',
    data
  })
}
