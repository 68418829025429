import request from "../../utils/request";

// 获取整车订单详情
// export const GetSalesOrderDetails = data => request('/WorkFlow/WorkFlowQuery/SalesOrderDetails', data, 'POST')
export function GetSalesOrderDetails(data) {
  return request({
    url: '/WorkFlow/WorkFlowQuery/SalesOrderDetails',
    method: 'post',
    data
  })
}


//推送订单记录
// export const GetPrePayAmt = data => request('/SalesOrder/BAS/GetPrePayAmt', data, 'POST')
export function GetPrePayAmt(data) {
  return request({
    url: '/SalesOrder/BAS/GetPrePayAmt',
    method: 'post',
    data
  })
}


//推送订金
// export const PushDepositSave = data => request('/SalesOrder/BAS/PushDepositSave', data, 'POST')
export function PushDepositSave(data) {
  return request({
    url: '/SalesOrder/BAS/PushDepositSave',
    method: 'post',
    data
  })
}

//作废
// export const PushDepositSave = data => request('/SalesOrder/BAS/PushDepositSave', data, 'POST')
export function UpdateSoMstrStatus(data) {
  return request({
    url: '/Saas/UpdateSoMstrStatus',
    method: 'post',
    data
  })
}


// 关闭作废原因
export function OrderCloseDrop(data) {
  return request({
    url: '/Saas/OrderCloseDrop',
    method: 'post',
    data
  })
}
// 作废草稿单
export function UpdateOrderCloseStatus(data) {
  return request({
    url: '/Saas/UpdateOrderCloseStatus',
    method: 'post',
    data
  })
}
// 关闭订单
export function hw_UpdateSoMstrStatus(data) {
  return request({
    url: '/Saas/UpdateSoMstrStatus',
    method: 'post',
    data
  })
}


