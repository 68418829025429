<template>
  <div class="contents">
    <van-cell class="popup-title" title="筛选" />
    <div class="details">
      <div class="typeTitle">配车状态</div>
      <div class="typeButton">
        <van-button size="small" :class="isLock == item.value ? 'active' : ''" v-for="item in sChe" :key="item.lable"
          @click="changeButton('isLock', item.value)">{{
            item.lable }}</van-button>
      </div>
      <div class="typeTitle">收款状态</div>
      <div class="typeButton">
        <van-button size="small" :class="faStatus == item.value ? 'active' : ''" v-for="item in sKuan" :key="item.lable"
          @click="changeButton('faStatus', item.value)">{{
            item.lable }}</van-button>
      </div>
      <div class="typeTitle">开票状态</div>
      <div class="typeButton">
        <van-button size="small" :class="soInvoiceStatus == item.value ? 'active' : ''" v-for="item in KPiao"
          :key="item.lable" @click="changeButton('soInvoiceStatus', item.value)">{{ item.lable }}</van-button>
      </div>
      <div class="typeTitle">出库状态</div>
      <div class="typeButton">
        <van-button size="small" :class="isRelStatus == item.value ? 'active' : ''" v-for="item in cKu" :key="item.lable"
          @click="changeButton('isRelStatus', item.value)">{{ item.lable }}</van-button>
      </div>
      <div class="typeTitle">交车状态</div>
      <div class="typeButton">
        <van-button size="small" :class="isDelivery == item.value ? 'active' : ''" v-for="item in JChe" :key="item.lable"
          @click="changeButton('isDelivery', item.value)">{{ item.lable }}</van-button>
      </div>
      <div class="typeTitle">关单状态</div>
      <div class="typeButton">
        <van-button size="small" :class="isSettled == item.value ? 'active' : ''" v-for="item in gDan" :key="item.lable"
          @click="changeButton('isSettled', item.value)">{{ item.lable }}</van-button>
      </div>
      <van-cell title="订单创建日期" :value="date || '请选择'" @click="show = true">
      </van-cell>
    </div>
    <div class="footerButton">
      <van-button @click="reset">重置</van-button>
      <van-button color="#F7772C" @click="submit">确定</van-button>
    </div>
    <van-calendar v-model="show" type="range" :min-date="minDate" :max-date="maxDate" @confirm="onConfirm" color="#F7772C" :allow-same-day="true"
      @opened="calendarOpened" ref="calendar" :show-confirm="false">
      <template slot="footer">
        <div class="btns">
          <van-button @click="clearTimeAll" type="danger" class="cancel-btn">清除时间</van-button>
        </div>
      </template>
    </van-calendar>
  </div>
</template>
<script>
export default {
  data () {
    return {
      date: '',
      show: false,
      sChe: [{ lable: '不限', value: '不限' }, { lable: '未配车', value: '0' }, { lable: '已配车', value: '1' }],
      sKuan: [{ lable: '不限', value: '不限' }, { lable: '待收款', value: '待收款' }, { lable: '部分收款', value: '部分收款' }, { lable: '已收全款', value: '已收全款' }],
      KPiao: [{ lable: '不限', value: '不限' }, { lable: '未开票', value: '否' }, { lable: '已开票', value: '是' }],
      cKu: [{ lable: '不限', value: '不限' }, { lable: '未出库', value: '未出库' }, { lable: '已出库', value: '已出库' }],
      JChe: [{ lable: '不限', value: '不限' }, { lable: '未交车', value: '0' }, { lable: '已交车', value: '1' }],
      gDan: [{ lable: '不限', value: '不限' }, { lable: '未关单', value: '0' }, { lable: '已关单', value: '1' }],

      minDate: new Date(2022, 1, 1),
      maxDate: new Date(2033, 1, 1),

      createDateStart: '',
      createDateEnd: '',
      isLock: '',//  	是否锁车：是："1"，否："0"
      faStatus: '',//  	收款状态："待收款"，"部分收款"，"已收全款"
      soInvoiceStatus: '',//  是否开票："是"，"否"
      isRelStatus: '',// 	是否出库：是："已出库"， 否："未出库"
      isDelivery: '',//  	是否交车：是："1"，否："0"
      isSettled: '',//  	是否关单：是："1"，否："0"
    }
  },
  created () {
    const nowDate = new Date().getFullYear()
    this.minDate = new Date(nowDate - 10, 1, 1)
    this.maxDate = new Date()

    // 筛选缓存重置
    this.createDateStart = sessionStorage.getItem('createDateStart') || '';
    this.createDateEnd = sessionStorage.getItem('createDateEnd') || '';
    this.isLock = sessionStorage.getItem('isLock') || '';
    this.faStatus = sessionStorage.getItem('faStatus') || '';
    this.soInvoiceStatus = sessionStorage.getItem('soInvoiceStatus') || '';
    this.isRelStatus = sessionStorage.getItem('isRelStatus') || '';
    this.isDelivery = sessionStorage.getItem('isDelivery') || '';
    this.isSettled = sessionStorage.getItem('isSettled') || '';
    this.date = this.createDateStart ? this.createDateStart + '-' + this.createDateEnd : '';
    console.log("22", nowDate, this.minDate);
  },

  methods: {
    calendarOpened () {
      const initTop = document.querySelector('.van-calendar__body').scrollTop
      document.querySelector('.van-calendar__body').scrollTop = initTop - 1
    },
    formatDate (date) {
      const mm = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
      const dd = date.getDate() + 1 < 10 ? '0' + date.getDate() : date.getDate()
      return `${date.getFullYear()}-${mm}-${dd}`;
    },
    onConfirm (date) {
      const [start, end] = date;
      this.show = false;
      this.createDateStart = this.formatDate(start)
      this.createDateEnd = this.formatDate(end)
      this.date = `${this.formatDate(start)} - ${this.formatDate(end)}`;
      sessionStorage.setItem('createDateStart', this.formatDate(start));
      sessionStorage.setItem('createDateEnd', this.formatDate(end));
    },
    changeButton (name, type) {
      if (this.$data[name] == type)
      {
        this.$data[name] = ''
        sessionStorage.setItem(name, type);
      } else
      {
        this.$data[name] = type
        sessionStorage.setItem(name, type);
      }
    },
    clearTimeAll () {
      this.$refs.calendar.reset();
      this.show = false;
      this.createDateStart = ''
      this.createDateEnd = ''
      this.date = '';
      sessionStorage.setItem('createDateStart', '');
      sessionStorage.setItem('createDateEnd', '');
    },
    reset () {
      Object.assign(this.$data, this.$options.data())
      sessionStorage.clear()
    },
    submit () {
      this.$emit('closeFilterItem')
    },


  },
}
</script>
<style lang="less" scoped>
.contents {
  position: relative;
  height: 100%;


  .popup-title {
    font-size: 20px;
    text-align: center;
  }

  .details {
    height: calc(100% - 100px);
    overflow-y: scroll;
    padding-bottom: 100px;
  }

  .van-cell::after {
    border-bottom: 0px;
  }

  .van-icon-cross {
    line-height: 24px;
  }
}

.typeTitle {
  font-size: 16px;
  font-weight: 500;
  padding: 10px;
}

.typeButton {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 10px 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;


  button {
    min-width: calc(25% - 6px);
    box-sizing: border-box;
    margin: 3px;
    font-size: 12px;
    background: rgba(0, 0, 0, 0.05);
    border-color: transparent;
  }

  .active {
    color: #F7772C;
    background: rgba(247, 119, 44, 0.3);
  }
}

.footerButton {
  width: 100%;
  box-sizing: border-box;
  padding: 10px 20px;
  position: absolute;
  border-top: 1px solid #eee;
  background: #fff;
  z-index: 1;
  bottom: 0;
  left: 0;

  button {
    font-size: 12px;
    background: rgba(0, 0, 0, 0.05);

    &:nth-child(1) {
      margin-right: 20px;
      width: calc(30% - 20px);
      box-sizing: border-box;
    }

    &:nth-child(2) {
      width: 70%;
    }
  }

}

.btns {
  z-index: 1;

  button {
    width: 100%;
  }
}

.details /deep/.van-cell__title {
  width: 100px;
  flex: none;
}

.details /deep/.van-cell {
  border-bottom: 1px solid #eee;
}


.van-popup--bottom {
  z-index: 999;
}
</style>