<template>
  <div>
    <!-- logo -->
    <div class="logo">
      <div class="logo_img">
        <img src="../../assets/logo.png" alt="" />
      </div>
      <div class="logo_text">浩物移动审批</div>
    </div>
    <div class="content">
      <div class="content_userName input_box">
        <div class="input">
          <div class="content_name">用户名:</div>
          <div class="content_input">
            <input
              type="text"
              v-model="userInfo.USER_NAME"
              @blur="checkoutName"
            />
          </div>
        </div>
        <div class="warning" v-show="isShowName">
          <span>请输入用户名</span>
        </div>
      </div>
      <div class="content_password input_box">
        <div class="input">
          <div class="content_name">密码:</div>
          <div class="content_input">
            <input
              type="password"
              v-model="userInfo.USER_PASSWORD"
              @blur="checkoutPassword"
            />
          </div>
        </div>
        <div class="warning" v-show="isShowPassword">
          <span>请输入密码</span>
        </div>
      </div>
      <div>
        <van-checkbox class="checkbox" v-model="checked" shape="square"
          >记住密码</van-checkbox
        >
      </div>
    </div>
    <!-- 登录 -->
    <div class="btn">
      <van-button
        class="btn_login"
        :loading="loading"
        loading-text="登录中…"
        type="primary"
        size="large"
        @click="login"
      >
        <img class="btn_icon" src="../../assets/phone.png" alt="" />
        <span>登录</span>
      </van-button>
    </div>
  </div>
</template>

<script>
import { WorkFlowLogin } from "../../api/login";
export default {
  data() {
    return {
      userInfo: {
        USER_NAME: "",
        USER_PASSWORD: "",
      },
      isShowName: false,
      isShowPassword: false,
      checked: true,
      loading: false,
    };
  },
  methods: {
    checkoutName() {
      this.userInfo.USER_NAME == ""
        ? (this.isShowName = true)
        : (this.isShowName = false);
    },
    checkoutPassword() {
      this.userInfo.USER_PASSWORD == ""
        ? (this.isShowPassword = true)
        : (this.isShowPassword = false);
    },
    async login() {
      var that = this;
      this.checkoutName();
      this.checkoutPassword();
      if (!this.isShowName && !this.isShowPassword) {
        this.loading = true;
        await WorkFlowLogin(this.userInfo)
          .then((res) => {
            if (res.data.IsSuccess) {
              if (this.checked) {
                console.log("true");
                this.setCookie(
                  that.userInfo.USER_NAME,
                  that.userInfo.USER_PASSWORD,
                  7
                );
              }
              this.loading = false;
              console.log(res.data.result[0]);
              sessionStorage.setItem(
                "userInfo",
                JSON.stringify(res.data.result[0])
              );
              sessionStorage.setItem(
                "storeList",
                JSON.stringify(res.data.result[0].门店信息)
              );
              sessionStorage.setItem(
                "store",
                JSON.stringify(res.data.result[0].门店信息[0].门店名称)
              );
              this.$router.push("../index");
            } else {
              this.loading = false;
              this.$toast(res.data.msg);
            }
          })
          .catch((err) => {
            this.loading = false;
            this.$toast(err.data.msg);
          });
      }
    },
    setCookie(c_name, c_pwd, exdays) {
      var exdate = new Date();
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays);
      window.document.cookie =
        "userName" + "=" + c_name + ";path=/;expires=" + exdate.toGMTString();
      window.document.cookie =
        "userPwd" + "=" + c_pwd + ";path=/;expires=" + exdate.toGMTString();
    },
    getCookie() {
      if (document.cookie.length > 0) {
        var arr = document.cookie.split("; ");
        for (var i = 0; i < arr.length; i++) {
          var arr2 = arr[i].split("=");
          if (arr2[0] == "userName") {
            this.userInfo.USER_NAME = arr2[1];
          } else if (arr2[0] == "userPwd") {
            this.userInfo.USER_PASSWORD = arr2[1];
          }
        }
      }
    },
  },
  mounted() {
    this.getCookie();
  },
};
</script>

<style lang="less" scoped>
.logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10% 0;
  .logo_img {
    width: 81px;
    height: 80px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .logo_text {
    width: 202px;
    height: 33px;
    font-size: 17px;
    font-weight: 500;
    color: #000000;
  }
}
.content {
  font-size: 17px;
  padding: 0 30px;
  .input_box {
    height: 58px;
    .input {
      display: flex;
      width: 100%;
      align-items: center;
      .content_name {
        width: 25%;
      }
      .content_input {
        width: 75%;
        input {
          width: 100%;
          border: 1px solid #ccc;
          border-radius: 5px;
          padding: 5px;
        }
      }
    }
    .warning {
      width: 100%;
      padding-left: 25%;
      color: red;
      font-size: 12px;
      margin-top: 3px;
    }
  }
  .checkbox {
    margin: 3px 0;
  }
}
.btn {
  padding: 0 30px;
  .btn_login {
    border-radius: 4px;
    .van-button__text {
      display: flex;
      align-items: center;
    }
    .btn_icon {
      width: 10px;
      height: 14px;
      vertical-align: middle;
      margin-right: 6px;
    }
  }
}
</style>
